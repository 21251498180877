import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Ignorecase_data } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { DiagnosispopupComponentComponent } from '../diagnosispopup-component/diagnosispopup-component.component';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { typeofExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-diag-app-create',
  templateUrl: './diag-app-create.component.html',
  styleUrls: ['./diag-app-create.component.scss']
})
export class DiagAppCreateComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public currentLimit = 1;
  public pres_diag_idvalue;
  public disableDOBFlag: boolean = false;
  public disableAgeFlag: boolean = false;
  public somePlaceholder;
  public typeFilter;
  public firstNamePatient;
  public firstNamePatient1;
  public diagId;
  public credit_flag: boolean = false;
  public clientDetailsArray: any = [];
  // public clientFirstName;
  // public clientMidName;
  // public clientLastName;
  public appointmentValue;
  public clientID;
  public conformFlag: boolean;
  public userType: boolean;
  public newUserFlag: boolean;
  public existuserFlag: boolean;
  public searchFlag: boolean;
  public bookNowFlag: boolean;
  public showUltraTestFlag: boolean;
  public showCultureTestFlag: boolean;
  public showBloodTestFlag: boolean;
  public showUrineTestFlag: boolean;
  public showFeaceTestFlag: boolean;
  public showXrayTestFlag: boolean;
  public showScanTestFlag: boolean;
  public showElectroCardioTestFlag: boolean;
  public typeApp;
  public Filter;
  public Filter1;
  public referedNameArray;
  public locationArray: any = [];
  public cityArray: any = [];
  public stateArray: any = [];
  public countryArray: any = [];
  public referedDoctorArray: any = [];
  public salListArray: any = [];
  public testListArray: any = [];
  public bloodTestArray: any = [];
  public urineTestArray: any = [];
  public feaceTestArray: any = [];
  public XrayTestArray: any = [];
  public scanTestArray: any = [];
  public ultraTestArray: any = [];
  public CultureTestArray: any = [];
  public electroCardioTestArray: any = [];
  public bloodTestlistArray: any = [];
  public urineTestlistArray: any = [];
  public faceTestlistArray: any = [];
  public xrayTestlistArray: any = [];
  public scanTestlistArray: any = [];
  public ultraTestlistArray: any = [];
  public cultureTestlistArray: any = [];
  public electroCardioTestlistArray: any = [];
  public testGroupArray: any = [];
  public sendData: any = {};
  public testNameArray;
  public visitSession = "Morning";
  public getVisitingSessionArray;
  public diagTest: string;
  public clientAddress1: string = null!;
  public clientAddress2: string = null!;
  public clientLocation: string = null!;
  public clientCity: string = null!;
  public clientState: string = null!;
  public clientCountry: string = null!;
  public clientLocationId: string = null!;
  public clientCityId: string = null!;
  public clientStateId: string = null!;
  public clientcountryId: string = null!;
  public clientZipCode: string = null!;
  public referedBy: string = '';
  public clientNumber: number;
  public clientSymptom: string;
  public clientDOB: string = null!;
  public clnt_sal: string;
  public BP_01: string;
  public BP_02: string;
  public ageURL: string;
  public appDate: string;
  public locationURL: string;
  public locationNameURL: string;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  //public DoctorIDURL: string;
  public dateURL: string;
  //public saveURL: string;
  public salURL: string;
  //public splURL: string;
  public zipcodeURL: string;
  public hospId: string;
  public clientId: string;
  public relationId: string;
  public subRelationId: string;
  public clientGender: string;
  public clientAge;
  public clientType: string;
  public currentDate: string;
  public currentTime: string;
  public currentYear: string;
  public homecare: string;
  public appointmentDate;
  public Select = "Select";
  public maxLength: number = 0;

  public concession;
  public amount;
  public inGST: boolean;
  public amountAfternoon;
  public priceTable: any = [];
  public balance = "0.00";
  public splArray;
  public speclization;
  public sessionFlag: boolean = true
  public timeSession;
  public startTime;
  public endTime;
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public paymentArray: any = [];
  public insuranceFlag;
  public payType: string;
  private pay_desc: string;
  public insuranceId: string;
  public insurancyArray: any = [];
  public ynopt2: boolean;
  public ynopt1: boolean;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public inpatient: boolean = false;
  public doctorName;
  public doctorRegId;
  public billingListArray: any = [];
  public selectedDocName;
  public appoint;
  public appID;
  public patinetName;
  public savePrescriptionFlag: boolean;
  public presID;
  public advance = "0.00";
  public remaingBalance = "0.00";
  public test_name;
  // include biapsy
  public biopsyTestlistArray: any = [];
  public biopsyTestArray: any = [];
  public showBiopsyTestFlag: boolean;
  public diagTestGroup;
  public test_group;
  public diagtest_flag: boolean = false;
  public selTestGroupArray: any = [];
  public paymentsarray: any = [];
  public paytypelist: any = [];
  public payType1: string;
  public amountval: any;
  public showTestGroupFlag;
  public userinfo;
  public pat_type;
  private sno: number = 1;
  public paytypreq: boolean = false;
  public isoutsource: boolean = false;
  public ageRead: boolean = false;
  public outarray: any = [];
  private outpayarray: any = [];
  public priority = "0";
  public Blood_test: any = [];
  public test_flag;
  public testtypename: any = [];

  //public saveflag: boolean = false;
  public loc_name;
  public subtestname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestxname: any = [];
  public subtestbname: any = [];
  public subtesturname: any = [];
  public subtestfname: any = [];
  public subtestcname: any = [];

  public bloodtest: boolean = true;
  public ultratest: boolean = true;
  public scantest: boolean = true;
  public xraytest: boolean = true;
  public biopsytest: boolean = true;
  public urinetest: boolean = true;
  public faecestest: boolean = true;
  public culturetest: boolean = true;

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
  public mediceList = [];
  public presListView: boolean;
  public pritn_blood_flag: boolean = false;

  public pritn_scan_flag: boolean = false;
  public pritn_us_flag: boolean = false;
  public pritn_xray_flag: boolean = false;
  public pritn_bio_flag: boolean = false;
  public pritn_ft_flag: boolean = false;
  public pritn_ut_flag: boolean = false;
  public pritn_culture_flag: boolean = false;

  public print_diag: boolean;
  public print_medidiag: boolean = false;
  public checked: boolean = false;

  public blood_test: any = [];
  public urine_test: any = [];
  public feace_test: any = [];
  public scan_test: any = [];
  public xray_test: any = [];
  public ultra_test: any = [];
  public culture_test: any = [];
  public biopsy_test: any = [];
  public isChecked: boolean = false;
  public showclient: boolean = true;
  private nodob: boolean = false;
  public test_exists: boolean = false;
  specimenType_data: any = [];
  testtube_data: any = [];
  selected_tube_colors: any = [];
  testFlag: boolean = true;
  packageFlag: boolean = false;
  public profileFlag: boolean = false;
  public package_list: any = [];
  public addtest_Flag: boolean = false;
  public saveFlag: boolean = true;
  public showTest_type: any;
  public labarray: any = [];
  public radioarray: any = [];
  labTestArray: any = [];
  radioTestArray: any = [];
  diag_app_show_test_det: boolean;

  public labTest_array: any = [];
  public radioTest_array: any = [];
  public microTest_array: any = [];
  public profiles: any = [];
  public LabtestName: any;
  public radiotestName: any;
  public microtestName: any;
  public profilesName: any;
  selected_profiles: any = [];

  public filteredLabArray: any = [];
  public filteredRadioArray: any = [];
  public filteredMicroArray: any = [];
  public filteredProfilesArray: any = [];
  public selected_profileIds: any = [];
  public prescription_tests: any = [];
  public pres_diag_id: any;
  doctorList: any[];
  public doctorID: string;
  public docflag: boolean = true;
  public searchby;
  public typeflag;
  public patientName;
  public searchtext;
  public patientid
  public patientList: any = [];
  public filteredArr: any = [];
  public basic_detailflag;
  public profileImg;
  public clientRegId;
  public subRelId: boolean;
  public mrno;
  public clntSal;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public dobDisabledFlag: boolean;
  public clntAge;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public whatsappNumber;
  public referredDoctor;
  public retrvDisabledFlag: boolean;
  public center_type;
  public diag_center_id: any;
  public packages_array: any = [];
  public selected_packages: any = [];
  public selected_packageIds: any = [];
  public filteredPackagesArray: any = [];
  public isTestVal: boolean;
  public diagpayflag: boolean = false;
  public paid_balanceFlag: boolean;
  public show_patType_selection: boolean = false;
  public discount_amt: any = '0.00';
  public bill_amount: any = '0.00';
  public addPackageBillArray: any = [];
  public addBillArray: any = [];
  package_balance: string;
  package_bill_amount: any;
  package_discount_amt: string;
  test_balance: string;
  test_bill_amount: any;
  test_discount_amt: string;

  package_paidBill: string;
  package_remainingBalance: string;
  test_paidBill: string;
  test_remainingBalance: string;
  test_paid_percentage: any = [];
  test_paymentsarray: any = [];
  package_paid_percentage: any = [];
  package_paymentsarray: any = [];
  testEqualSplitValue: any;
  test_pay_arrry_tot_amt: any;
  package_pay_arrry_tot_amt: any;
  packageEqualSplitValue: any;
  hptl_clinic_id: any;
  package_id: any;
  print_template: any;
  hospitalLogo: any;
  printlogostyle: any;
  billId: any;
  selected_profileIds_frSave: any = [];
  selected_packageIds_frSave: any = [];

  constructor(public messageservice: MenuViewService,
    public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService,
    public http: HttpClient, public dialog: MatDialog, public frontdeskservice: MenuViewService) {
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.typeFilter = "0";
    this.concession = "0";
    this.conformFlag = true;
    this.searchFlag = false;
    this.typeApp = "New";
    this.userType = true;
    this.clientType = "Existing";
    this.existuserFlag = false;
    this.newUserFlag = true;
    this.bookNowFlag = true;
    this.zipcodeURL = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.locationURL = ipaddress.getIp + "usercontroller/loc";
    this.locationNameURL = ipaddress.getIp + "usercontroller/locbyname";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.salURL = ipaddress.getIp + "gen/sal";
    this.dateURL = ipaddress.getIp + "adm/curdate";
    this.ageURL = ipaddress.getIp + "adm/ddif";
    this.testtypename = [];
  }

  ngOnInit(): void {
    var hospital = Helper_Class.getInfo().hospitals;
    this.userinfo = Helper_Class.getInfo();
    console.log("this.userinfo", this.userinfo)
    this.Filter = "name";
    this.Filter1 = "name";
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.showTest_type = this.userinfo.show_by_test_type;
      this.clientcountryId = this.userinfo.country;//localStorage.getItem("hptl_country");
      this.clientStateId = this.userinfo.state;//localStorage.getItem("hptl_state");
      this.clientCityId = this.userinfo.city; //localStorage.getItem("hptl_city");
      this.clientLocationId = this.userinfo.location //localStorage.getItem("hptl_location");
      this.diag_app_show_test_det = this.userinfo.diag_app_show_test_det == 1 ? true : false;
      this.show_patType_selection = this.userinfo.allow_inp_booking == 1 ? true : false;

    } else {
      this.clientcountryId = hospital[0].country;//localStorage.getItem("hptl_country");
      this.clientStateId = hospital[0].state;//localStorage.getItem("hptl_state");
      this.clientCityId = hospital[0].city; //localStorage.getItem("hptl_city");
      this.clientLocationId = hospital[0].location //localStorage.getItem("hptl_location");
      this.showTest_type = this.userinfo.hospitals[0].show_by_test_type;
      this.diag_app_show_test_det = this.userinfo.hospitals[0].diag_app_show_test_det == 1 ? true : false;
    }
    console.log("chck data------------" + JSON.stringify(FrontDesk_Helper.getreceptionistcreatepage()))

    if (FrontDesk_Helper.getreceptionistcreatepage() != undefined) {
      if (FrontDesk_Helper.getreceptionistcreatepage().appType != undefined) {
        if (FrontDesk_Helper.getreceptionistcreatepage().appType == "hospital") {
          this.center_type = FrontDesk_Helper.getreceptionistcreatepage().appType
        } else if (FrontDesk_Helper.getreceptionistcreatepage().appType == "diagnosis") {
          this.center_type = FrontDesk_Helper.getreceptionistcreatepage().appType
        }
      }
    }
    console.log("chck data------------" + JSON.stringify(this.center_type))
    if (this.userinfo.bill_print_template != undefined) {
      this.print_template = this.userinfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }
    console.log(this.print_template);
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;

    console.log("this.showTest_type", this.showTest_type)
    this.pat_type = "out-patient";
    this.ageRead = false;
    this.changeClientType('client', 1, 'no');
    //this.navParams.get("center_id");
    this.showBloodTestFlag = true;
    this.showUrineTestFlag = true;
    this.showFeaceTestFlag = true;
    this.showXrayTestFlag = true;
    this.showScanTestFlag = true;
    this.showUltraTestFlag = true;
    this.showCultureTestFlag = true;
    this.showElectroCardioTestFlag = true;

    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.sessionTimeStart.push("0" + i);
      } else {
        this.sessionTimeStart.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.sessionTimeEnd.push("0" + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }

    if (hospital[0].bill_pay_type_req == "1") {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }

    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk" || this.userinfo.user_type == "Admin" || this.userinfo.user_type == "nurse") {
      this.diagId = this.userinfo.diag_centre_id;
      this.diag_center_id = this.userinfo.diag_centre_id;
      this.hptl_clinic_id = this.userinfo.hptl_clinic_id;
      console.log(this.diagId);
      console.log(this.diag_center_id);
      console.log(this.hptl_clinic_id);
    } else {
      this.diagId = this.userinfo.hospitals[0].hptl_clinic_id;
    }

    if (this.userinfo.reg_clnt_app_booking == "1") {
      this.ageRead = false;
      this.showclient = true;

    } else {
      this.showclient = false;
      this.ageRead = true;
    }

    this.ynopt1 = true;
    this.insuranceFlag = true;
    this.savePrescriptionFlag = false;
    this.diagTestGroup = "test";
    this.searchType('patientid');

    this.appGetdate();
    this.getSalutations();
    //this.getSpeciality();
    this.getDiagTestTypes();
    this.getPaymentType();
    this.getInsurers();

    this.getSpecimentType();
    this.selectSession();
    this.getDoctor();
    //this.getPatientList();
  }

  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by Name";
      this.getPatientListByName(this.patientName)

    }
    else if (data == "patientid") {
      this.typeflag = "patientid";
      this.patientName = "";

      this.searchtext = "search by Mr no"
      this.patientid = true;
      this.getPatientListByName(this.patientName)
    }
    else {
      this.typeflag = "mobile";
      this.patientName = "";

      this.searchtext = "search by Mobile"
      this.getPatientListByName(this.patientName)
    }
  }

  getPatientListByName(name) {
    this.patientList = [];
    this.filteredArr = [];
    var send_data;
    console.log("verify send_data " + JSON.stringify({
      hptl_clinic_id: this.diagId,
      country: ipaddress.country_code,
      type: "patientid",
      name: this.patientName,
      prov_id: this.userinfo.user_id,
      location: "front-desk",
      center_type: this.center_type, pat_type: this.pat_type
    }))
    if (name != "") {
      this.patientList = [];
      this.filteredArr = [];
      if (this.searchby == "patientid" && name.length > 8) {
        this.patientList = [];
        this.filteredArr = [];
        send_data = {
          hptl_clinic_id: this.userinfo.hptl_clinic_id,
          country: ipaddress.country_code,
          type: "patientid",
          name: this.patientName,
          prov_id: this.userinfo.user_id,
          location: "front-desk",
          center_type: this.center_type, patient_type: this.pat_type,
        }
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("verify this " + JSON.stringify(obj))
              this.patientList = [];
              this.filteredArr = [];
              if (obj.clients != null) {
                for (var i = 0; i < obj.clients.length; i++) {
                  var name;
                  if (obj.clients[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                  } else {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                  }

                  this.patientList.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                    pres_diag_id: obj.clients[i].pres_diag_id

                  });

                  this.filteredArr.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                    pres_diag_id: obj.clients[i].pres_diag_id
                  });
                }
                this.retrieveClient(this.filteredArr[0], '')
              }
            },
            error => { });

      } else if (name.length > 2 && this.searchby != "patientid") {
        this.patientList = [];
        this.filteredArr = [];
        send_data = {
          hptl_clinic_id: this.userinfo.hptl_clinic_id,
          country: ipaddress.country_code,
          type: this.searchby,
          name: encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),
          prov_id: this.userinfo.user_id,
          location: "front-desk",
          center_type: this.center_type, patient_type: this.pat_type,
        }

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("verify this " + JSON.stringify(obj))
              this.patientList = [];
              this.filteredArr = [];
              if (obj.clients != null) {
                for (var i = 0; i < obj.clients.length; i++) {
                  var name;
                  if (obj.clients[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                  } else {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                  }

                  this.patientList.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),

                  });

                  this.filteredArr.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                  });
                }
                //Helper_Class.setPatientList(this.patientList);
              }
            },
            error => { });

      } else {
        this.patientList = [];
        this.filteredArr = [];
        this.patientList = Helper_Class.getPatientList();
        this.filteredArr = Helper_Class.getPatientList();
      }
    } else {
      this.patientList = [];
      this.filteredArr = [];
      this.getPatientList();
    }

  }

  retrieveClient(person, pres_diag_id) {
    // this.pres_diag_idvalue = person.pres_drug_id
    console.log("person.pres_drug_id" + JSON.stringify(pres_diag_id))

    console.log("person.pres_drug_id" + JSON.stringify(person))
    if (this.searchby == "patientid") {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          Diagnosis_Helper.setClientId(null);
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))

          if (obj.client_id != null) {
            this.basic_detailflag = true
            Diagnosis_Helper.setClientId(obj);

            this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
            if (this.searchby == "patientid") {
              this.clientRegId = person.client_reg_id;
              this.relationId = person.relation_id;
              this.subRelId = person.sub_rel_id;

            } else {
              this.clientRegId = person.client_reg_id;
              this.relationId = person.relation_id;
              this.subRelId = person.sub_rel_id;
            }
            if (obj.salutation != undefined) {
              this.clntSal = obj.salutation + ".";
            } else {
              this.clntSal = "";
            }

            this.clnt_sal = obj.salutation_id;
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            if (this.clntMiddleName != "") {
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntLastName;
            } else {
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
            }
            if (obj.patient_id != undefined) {
              this.mrno = obj.patient_id
            }
            if (obj.ref_source != null) {
              this.ref_source = obj.ref_source;
            }

            if (obj.dob != null) {
              this.clntDOB = Date_Formate(obj.dob);

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }
            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            if (obj.emerg_contact1 != undefined) {
              this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            }
            if (obj.emerg_contact2 != undefined) {
              this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            }
            //emerg_contact1,emerg_contact2
            if (obj.address2 != null && obj.address2 != "") {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2);
              this.address = this.clntAddress1 + "" + this.clntAddress2
            } else {
              this.address = this.clntAddress1
            }
            this.clientCityId = obj.city;
            this.clientStateId = obj.state;
            this.clientcountryId = obj.country;
            this.clntLocation = this.checkData(obj.location_desc);
            this.clntCountry = this.checkData(obj.country_desc);
            this.clntState = this.checkData(obj.state_desc);
            this.clntCity = this.checkData(obj.city_desc);
            this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
            this.pres_diag_idvalue = pres_diag_id
          }
          this.retrvDisabledFlag = true;
          this.addtest_Flag = true;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getDoctor() {
    var senddata = {
      hptl_clinic_id: this.userinfo.diag_centre_id,
      type: "diagnosis",
    }
    console.log('.......senddata....' + JSON.stringify(senddata))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/docbyhs/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.doctorList = [];
          console.log('...........', obj)
          if (obj.doctors != null) {
            this.docflag = false;
            for (var i = 0; i < obj.doctors.length; i++) {
              var doc_name = "";
              if (obj.doctors[i].middle_name != undefined || obj.doctors[i].middle_name != null) {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.doctorList.push({
                prov_id: obj.doctors[i].prov_id,
                name: doc_name,
              })
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  backOption() {
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.frontdeskservice.sendMessage("manageappointments");
    } else {
      this.diagnosisservice.sendMessage("appointment");
    }
  }

  filterByPatients() {//based client select placeholder will be change
    if (this.Filter == "name") {
      this.somePlaceholder = "Search by first name";
      this.typeFilter = "0";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;

    } else if (this.Filter == "mobile") {
      this.somePlaceholder = "Search by mobile number";
      this.typeFilter = "1";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 10;

    } else if (this.Filter == "patient_id") {
      this.somePlaceholder = "Search by patient id";
      this.typeFilter = "2";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;
    }
  }

  filterByPatients1() {//based client select placeholder will be change
    if (this.Filter1 == "name") {
      this.somePlaceholder = "Search by first name";
      this.typeFilter = "0";
      this.firstNamePatient1 = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;

    } else if (this.Filter1 == "mobile") {
      this.somePlaceholder = "Search by mobile number";
      this.typeFilter = "1";
      this.firstNamePatient1 = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 10;

    } else if (this.Filter1 == "patient_id") {
      this.somePlaceholder = "Search by patient id";
      this.typeFilter = "2";
      this.firstNamePatient1 = "";
      this.clientDetailsArray = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;
    }
  }

  getDiagTestTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtests/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testListArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.test_types != null) {
            this.testListArray = obj.test_types;
          }
          this.diagTest = "Blood Test";
          this.diagTestChange();

          if (obj.outsource != undefined) {
            this.isoutsource = true;
          }
        },
        error => { });
  }

  getTestGroups() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpkgd/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testGroupArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.packages != null) {
            this.testGroupArray = obj.packages;
          }
        },
        error => { });
  }

  searchUpper() {
    if (this.Filter == "name")
      this.firstNamePatient = this.firstNamePatient.toLocaleUpperCase();
  }

  searchUpper1() {
    if (this.Filter1 == "name")
      this.firstNamePatient1 = this.firstNamePatient1.toLocaleUpperCase();
  }

  toUpperFirstName() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  toUpperMidName() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  toUpperLastName() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  appGetdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.dateURL, { country: ipaddress.country_code }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        this.currentDate = Date_Formate(dataval.current_date);
        this.currentTime = dataval.current_time;
        var timeval = this.currentTime.split(":");
        if (parseInt(timeval[0]) > 12) {
          this.startTime = (parseInt(timeval[0]) - 12).toString();
          if (this.startTime.length == 1) {
            this.startTime = "0" + this.startTime;
          }

        } else {
          this.startTime = timeval[0];
        }
        //this.startTime = timeval[0];
        this.endTime = timeval[1];
        this.currentYear = dataval.current_date.split('-')[0];
        this.appointmentDate = this.currentDate;
        this.appDate = dataval.current_date;
        this.currentDate = dataval.current_date;
        this.getVisitingSessionArray = [{ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
        if (parseInt(this.startTime) < 12) {
          this.visitSession = this.getVisitingSessionArray[0].description;
        } else if (parseInt(this.startTime) >= 12 && parseInt(this.startTime) <= 16) {
          this.visitSession = this.getVisitingSessionArray[1].description;
        } else {
          this.visitSession = this.getVisitingSessionArray[2].description;
        }


      },
      error => { });
  }

  changeName() {
    this.clientDetailsArray = [];
  }

  selectUserType() {
    if (this.clientType == "New") {
      this.userType = false;
      this.newUserFlag = false;
      this.existuserFlag = true;
      this.searchFlag = true;
      this.clientId = "0";
      this.relationId = "1";
      this.clntGender = "Male";
      // this.clientFirstName = null;
      // this.clientMidName = null;
      // this.clientLastName = null;
      // this.clntGender = "";
      this.clntAge = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null!;
      this.clientNumber = null!;
      this.clientSymptom = "";
      this.clientDOB = null!;
      this.referedBy = "";
      this.saveFlag = true;

      this.clientZipCode = this.userinfo.hospitals[0].zipcode;
      this.clientLocationId = this.userinfo.hospitals[0].location;
      this.clientLocation = this.userinfo.hospitals[0].location_desc;
      this.selectLocation(this.clientLocation)
      this.clientCityId = this.userinfo.hospitals[0].city;
      this.clientCity = this.userinfo.hospitals[0].city_desc;
      this.clientStateId = this.userinfo.hospitals[0].state;
      this.clientState = this.userinfo.hospitals[0].state_desc;
      this.clientcountryId = this.userinfo.hospitals[0].country;
      this.clientCountry = this.userinfo.hospitals[0].country_desc;

      if (this.countryArray.length != 0) {
        //this.countryChange(this.clientcountryId, "0");
      } else {
        this.getCountries(this.clientStateId, "0");
      }
      this.clnt_sal = "select";


    } else {
      this.userType = true;
      this.newUserFlag = true;
      this.existuserFlag = false;
      this.searchFlag = false;
      this.clntFirstName = null;
      this.clntMiddleName = null;
      this.clntLastName = null;
      this.clientNumber = null!;
    }
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salURL, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.salListArray.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clnt_sal = "select";
          this.changeSalutation(this.clnt_sal);
        }
      });
  }

  getSpeciality() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.splArray = obj.specialization;
        },
        error => { });
  }

  zipcode_change() {
    if (this.clientZipCode.length > 4) {
      this.locationArray = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcodeURL, {
        zipcode: this.clientZipCode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clientLocationId = obj.locations[0].location_id;
              this.clientLocation = obj.locations[0].description;
              this.clientZipCode = obj.locations[0].pincode;
              this.getCities(this.clientLocation, 0);
            } else {
              this.locationArray = obj.locations;
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.locationArray = [];
    }
  }

  changeLocation(locat_desc) {
    this.clientLocation = locat_desc;
    this.locationArray = [];

    if (this.clientLocation != null && this.clientLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameURL, {
        name: this.clientLocation,
        //city_id: this.clientCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.locationArray = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.locationArray = [];
    }
  }

  selectLocation(location) {
    this.clientLocation = location;
    for (var i = 0; i < this.locationArray.length; i++) {
      if (this.locationArray[i].location_id == location.location_id) {
        this.clientLocationId = this.locationArray[i].location_id;
        this.clientLocation = this.locationArray[i].description;
        this.clientZipCode = this.locationArray[i].pincode;
      }
    }
    this.locationArray = [];
    this.getCities(this.clientLocation, '0');
  }

  getCities(state, flag) {
    this.cityArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, { loc_name: this.clientLocation, zipcode: this.clientZipCode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.cityArray = dataval.cities;
            if (this.cityArray.length == 1) {
              for (var i = 0; i < this.cityArray.length; i++) {
                if (this.cityArray[i].city_id == this.clientCityId) {
                  this.clientCityId = this.cityArray[i].city_id;
                  this.clientCity = this.cityArray[i].city_desc;
                  this.getStates(this.clientCityId, 0)

                  break;
                } else {
                  this.clientCityId = this.cityArray[0].city_id;
                  this.clientCity = this.cityArray[0].city_desc;
                  this.getStates(this.clientCityId, 0)
                }
              }
            } else {
              this.clientCityId = this.cityArray[0].city_id;
              this.clientCity = this.cityArray[0].city_desc;
              this.getStates(this.clientCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    for (var i = 0; i < this.cityArray.length; i++) {
      if (this.cityArray[i].city_desc == clnt_city) {
        this.clientCityId = this.cityArray[i].city_id;
        this.clientCity = this.cityArray[i].city_desc;
        this.getStates(this.clientCityId, '0');
      }
    }
  }

  getStates(city, flag) {
    this.stateArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateURL, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.stateArray = dataval.states;
            for (var i = 0; i < this.stateArray.length; i++) {
              if (this.stateArray[i].state_id == this.clientStateId) {
                this.clientStateId = this.stateArray[i].state_id;
                this.clientState = this.stateArray[i].state_desc;
                this.getCountries(this.clientStateId, '0');
                break;

              } else {
                this.clientStateId = this.stateArray[0].state_id;
                this.clientState = this.stateArray[0].state_desc;
                this.getCountries(this.clientStateId, '0');
              }
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.stateArray.length; i++) {
      if (this.stateArray[i].stage_desc == clnt_state) {
        this.clientStateId = this.stateArray[i].state_id;
        this.clientState = this.stateArray[i].stage_desc;
        this.getCountries(this.clientStateId, '0');
      }
    }
  }

  getCountries(stateid, flag) {
    this.countryArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryURL, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.countryArray = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.countryArray = dataval.countries;
          for (var c = 0; c < this.countryArray.length; c++) {
            if (this.countryArray[c].country_id == this.clientcountryId) {
              this.clientCountry = this.countryArray[c].country_desc;
              this.clientcountryId = this.countryArray[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getLocation(location) {
    this.clientLocation = location;
    for (var i = 0; i < this.locationArray.length; i++) {
      if (this.locationArray[i].location_id == location.location_id) {
        this.clientLocationId = this.locationArray[i].location_id;
        this.clientLocation = this.locationArray[i].description;
        this.clientZipCode = this.locationArray[i].pincode;
      }
    }
    this.locationArray = [];
  }

  changePatientName(name, pat_type) {//based client search filters patient  names
    var type_name;
    if (name != undefined && name != "") {
      if (this.Filter == "name")
        type_name = "name";
      else if (this.Filter == "mobile")
        type_name = "mobile";
      else
        type_name = "patient_id";

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid',
        {
          first_name: name,
          type: type_name,
          hosp_diag: "diagnosis",
          patient_type: pat_type,
          hptl_clinic_id: this.diagId, center_type: this.center_type
        },
        { headers: headers })
        .subscribe(
          response => {
            this.clientDetailsArray = [];
            var obj = JSON.parse(JSON.stringify(response));

            if (obj.clients != null && obj.clients.length != 0) {
              this.clientDetailsArray = obj.clients;
              this.addtest_Flag = true;
            }
            else {
              this.toastr.error(Message_data.clientNotFound);
              this.addtest_Flag = false;
            }

            if (obj.clients.length == 1) {
              this.clientID = this.clientDetailsArray[0].patient_id;
            } else {
              for (var i = 0; i < obj.clients.length; i++) {
                if (obj.clients[i].patient_id != undefined) {
                  this.clientID = obj.clients[i].patient_id;
                }
              }
            }

          }, error => {
            this.toastr.error(Message_data.network);
          });
    } else {
      this.toastr.error(Message_data.fillText);
    }
  }

  // changeClientId(client) {// get the the client detials based on patient id
  //   this.clientFirstName = "";
  //   this.clientMidName = undefined;
  //   this.clientLastName = "";
  //   for (var i = 0; i < this.clientDetailsArray.length; i++) {
  //     if (this.clientDetailsArray[i].patient_id == client) {
  //       this.clientFirstName = this.clientDetailsArray[i].first_name;
  //       this.clientMidName = this.clientDetailsArray[i].middle_name;
  //       this.clientLastName = this.clientDetailsArray[i].last_name;
  //       this.clientId = this.clientDetailsArray[i].client_reg_id;
  //       this.relationId = this.clientDetailsArray[i].relation_id;
  //       this.subRelationId = this.clientDetailsArray[i].sub_rel_id;
  //       this.retrieveClient();
  //     }
  //   }
  // }

  // ChangeAge() {
  //   if (this.clientAge != "") {
  //     this.disableDOBFlag = true;
  //     this.clientDOB = "";
  //     this.saveFlag = false;

  //   } else {
  //     this.disableDOBFlag = false;
  //   }

  //   this.nodob = true;
  //   var dateval = this.currentDate.split("-");
  //   this.clientDOB = (parseInt(dateval[0]) - parseInt(this.clientAge)) + "-01-01";
  //   this.disableDOBFlag = false;
  // }

  clickDate(data) {
    this.clientDOB = data;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageURL, {
      dobdate: Date_Formate(this.clientDOB),
      curdate: Date_Formate(this.currentDate),
    }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));

        if (obj != undefined) {
          this.clientAge = obj.value;
          this.disableAgeFlag = true;
        }
      });
  }

  // retrieveClient() {
  //   var senddata;

  //   if (this.subRelationId != undefined) {
  //     senddata = {
  //       user_id: this.clientId,
  //       relation_id: this.relationId,
  //       sub_rel_id: this.subRelationId,
  //       country: ipaddress.country_code
  //     }
  //   } else {
  //     senddata = {
  //       user_id: this.clientId,
  //       relation_id: this.relationId,
  //       country: ipaddress.country_code
  //     }
  //   }
  //   console.log("list length --" + JSON.stringify(senddata))
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
  //     senddata,
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = JSON.parse(JSON.stringify(response));
  //         console.log("list length --" + JSON.stringify(obj))
  //         if (obj.client_id != null) {
  //           this.addtest_Flag = true;
  //           this.saveFlag = false;
  //           Helper_Class.setClientId(obj.client_id);
  //           // Editable Fields
  //           this.clientFirstName = encrypt_decript.Decript(obj.first_name);

  //           if (obj.middle_name != null) {
  //             this.clientMidName = encrypt_decript.Decript(obj.middle_name);
  //           }
  //           if (obj.salutation != null) {
  //             for (var i = 0; i < this.salListArray.length; i++) {
  //               if (this.salListArray[i].sal_desc == obj.salutation) {
  //                 this.clnt_sal = this.salListArray[i].sal_id;
  //               }
  //             }
  //           }
  //           this.clientLastName = encrypt_decript.Decript(obj.last_name);
  //           this.clientGender = encrypt_decript.Decript(obj.gender);
  //           this.clientDOB = obj.dob != undefined ? obj.dob : null!;
  //           if (obj.age != undefined) {
  //             this.clientAge = obj.age;
  //           }
  //           this.clientNumber = encrypt_decript.Decript(obj.mobile);

  //           if (obj.address1 != null && obj.address1 != "") {
  //             this.clientAddress1 = encrypt_decript.Decript(obj.address1);
  //           }

  //           if (obj.address2 != null && obj.address2 != "") {
  //             this.clientAddress2 = encrypt_decript.Decript(obj.address2);
  //           }

  //           this.clientLocation = obj.location_desc;
  //           this.clientLocationId = obj.location;
  //           this.clientCountry = obj.country_desc;
  //           this.clientcountryId = obj.country;
  //           this.clientState = obj.state_desc;
  //           this.clientStateId = obj.state;
  //           this.clientCity = obj.city_desc;
  //           for (var k = 0; k < this.cityArray.length; k++) {
  //             if (obj.city_desc == this.cityArray[k].city_desc) {
  //               this.clientCity = this.cityArray[k].city_desc;
  //             }
  //           }
  //           this.clientZipCode = encrypt_decript.Decript(obj.zipcode);

  //           if (obj.tests != undefined && obj.tests.length != 0) {
  //             this.prescription_tests = obj.tests;
  //             this.addtest_Flag = false;

  //             // this.pres_diag_id = obj.tests[0].pres_diag_id;


  //             for(var i = 0; i < this.prescription_tests.length; i++){
  //               this.pres_diag_id = this.prescription_tests[i].pres_diag_id;
  //               if (this.prescription_tests[i].department == "Laboratory") {
  //                 // new array
  //                 this.labarray.push({
  //                   test_id: this.prescription_tests[i].test_id,
  //                   test_name: this.prescription_tests[i].test_name,
  //                   price: this.prescription_tests[i].price,
  //                   diag_type: this.prescription_tests[i].test_cat,
  //                 })
  //               } else {
  //                 this.radioarray.push({
  //                   test_id: this.prescription_tests[i].test_id,
  //                   test_name: this.prescription_tests[i].test_name,
  //                   price: this.prescription_tests[i].price,
  //                   diag_type: this.prescription_tests[i].test_cat,
  //                 })
  //               }
  //             }
  //             console.log("this.labarray", this.labarray , "this.radioarray", this.radioarray)
  //             if (this.labarray.length > 0 && this.labarray != undefined){
  //               this.addAmount(this.labarray, "Laboratory")

  //               for (var i = 0; i < this.labarray.length; i++){
  //                 this.labTestArray.push({
  //                   test_id: this.labarray[i].test_id,
  //                   test_name: this.labarray[i].test_name,
  //                   diag_type: this.labarray[i].diag_type,
  //                 });
  //               }
  //             }

  //             if (this.radioarray.length > 0 && this.radioarray != undefined){
  //               this.addAmount(this.radioarray, "Radiology")

  //               for (var i = 0; i < this.radioarray.length; i++){
  //                 this.radioTestArray.push({
  //                   test_id: this.radioarray[i].test_id,
  //                   test_name: this.radioarray[i].test_name,
  //                   diag_type: this.radioarray[i].diag_type,
  //                 });
  //               }
  //             }
  //           }
  //         }
  //       },
  //       error => {
  //         this.toastr.error(Message_data.network);
  //       });
  // }

  finekeyInArray_addTest(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].tests.length; j++) {
        if (array[i].tests[j].diag_tests_id === id) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  finekeyInArray(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  finekeyInArray_frPriceTab(array, id, type_of_test) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].test_id === id && array[i].type_of_test === type_of_test) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  contains(elem) {
    for (var i in this) {
      if (this[i] == elem) return true;
    }
    return false;
  }

  getAgeValue(data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageURL, {
      dobdate: data,
      curdate: this.currentDate,
    }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));

        if (obj != undefined) {
          this.clientAge = obj.value;
        }
      })
  }

  diagTestChange() {
    if (this.diagTest != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtestdetails/',
        {
          diag_centre_id: this.diagId,
          test_type: this.diagTest
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.test_details != null) {
              if (Ignorecase_data("Blood Test", this.diagTest) == true) {
                if (this.bloodTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.bloodTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.bloodTestlistArray;
                } else {
                  this.testNameArray = this.bloodTestlistArray;
                }

              } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
                if (this.urineTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.urineTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.urineTestlistArray;
                } else {
                  this.testNameArray = this.urineTestlistArray;
                }

              } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
                if (this.faceTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.faceTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.faceTestlistArray;
                } else {
                  this.testNameArray = this.faceTestlistArray;
                }

              } else if (Ignorecase_data("Scan", this.diagTest) == true) {
                if (this.scanTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.scanTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.scanTestlistArray;
                } else {
                  this.testNameArray = this.scanTestlistArray;
                }

              } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
                if (this.xrayTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.xrayTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.xrayTestlistArray;
                } else {
                  this.testNameArray = this.xrayTestlistArray;
                }

              } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
                if (this.ultraTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.ultraTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.ultraTestlistArray;
                } else {
                  this.testNameArray = this.ultraTestlistArray;
                }
              } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
                if (this.electroCardioTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.electroCardioTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.electroCardioTestlistArray;
                } else {
                  this.testNameArray = this.electroCardioTestlistArray;
                }
              } else if (Ignorecase_data("Culture", this.diagTest) == true) {
                if (this.cultureTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.cultureTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.cultureTestlistArray;
                } else {
                  this.testNameArray = this.cultureTestlistArray;
                }
              } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
                if (this.biopsyTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.biopsyTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.biopsyTestlistArray;
                } else {
                  this.testNameArray = this.biopsyTestlistArray;
                }
              }
            }
          },
          error => { });
    } else {
      this.testNameArray = [];
    }
  }

  check_addedTests(profile_array, lab_array, radio_array, package_array) {
    var flag = false;
    for (var i = 0; i < profile_array.tests.length; i++) {
      if (lab_array != undefined && lab_array.length != 0) {
        for (var j = 0; j < lab_array.length; j++) {
          if (profile_array.tests[i].diag_tests_id == lab_array[j].test_id) {
            flag = true;
            this.isTestVal = true;
            break;
          }
        }
      }

      if (radio_array != undefined && radio_array.length != 0) {
        for (var k = 0; k < radio_array.length; k++) {
          if (profile_array.tests[i].diag_tests_id == radio_array[k].test_id) {
            flag = true;
            this.isTestVal = true;
            break;
          }
        }
      }

      if (package_array != undefined && package_array.length != 0) {
        for (var q = 0; q < package_array.length; q++) {
          for (var r = 0; r < package_array[q].tests.length; r++) {
            if (profile_array.tests[i].diag_tests_id == package_array[q].tests[r].diag_tests_id) {
              flag = true;
              this.isTestVal = false;
              break;
            }
          }
        }
      }
    }

    if (package_array != undefined && package_array.length != 0) {
      for (var l = 0; l < package_array.length; l++) {
        for (var m = 0; m < package_array[l].tests.length; m++) {
          if (profile_array.diag_profile_id == package_array[l].tests[m].diag_tests_id) {
            flag = true;
            this.isTestVal = false;
            break;
          }
        }
      }
    }

    return flag;
  }

  packndprofileVal(packagesArr, profIds, profiles) {
    var flag = false;
    for (var i = 0; i < packagesArr.tests.length; i++) {
      if (profIds != undefined && profIds.length != 0) {
        for (var j = 0; j < profIds.length; j++) {
          if (packagesArr.tests[i].diag_tests_id == profIds[j]) {
            flag = true;
            break;
          }
        }
      }

      if (profiles != undefined && profiles.length != 0) {
        for (var k = 0; k < profiles.length; k++) {
          for (var l = 0; l < profiles[k].tests.length; l++) {
            if (packagesArr.tests[i].diag_tests_id == profiles[k].tests[l].diag_tests_id) {
              flag = true;
              break;
            }
          }
        }
      }
    }

    return flag;
  }

  packndtestVal(packagesArr, labarray, radioarray) {
    var flag = false;
    for (var i = 0; i < packagesArr.tests.length; i++) {
      if (labarray != undefined && labarray.length != 0) {
        for (var j = 0; j < labarray.length; j++) {
          if (packagesArr.tests[i].diag_tests_id == labarray[j].test_id) {
            flag = true;
            break;
          }
        }
      }

      if (radioarray != undefined && radioarray.length != 0) {
        for (var k = 0; k < radioarray.length; k++) {
          if (packagesArr.tests[i].diag_tests_id == radioarray[k].test_id) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }

  packndpackVal(packagesArr, packIds) {
    var flag = false;
    if (this.packages_array != undefined && this.packages_array.length != 0) {
      for (var i = 0; i < this.packages_array.length; i++) {
        for (var j = 0; j < packIds.length; j++) {
          if (this.packages_array[i].health_pkg_id == packIds[j]) {
            for (var k = 0; k < packagesArr.tests.length; k++) {
              for (var l = 0; l < this.packages_array[i].tests.length; l++) {
                if (packagesArr.tests[k].diag_tests_id == this.packages_array[i].tests[l].diag_tests_id) {
                  flag = true;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return flag;
  }

  // check_addedTests(profile_array, lab_array, radio_array) {
  //   var flag = false;
  //   for (var i = 0; i < profile_array.tests.length; i++) {
  //     if (lab_array != undefined && lab_array.length != 0) {
  //       for (var j = 0; j < lab_array.length; j++) {
  //         if (profile_array.tests[i].diag_tests_id == lab_array[j].test_id) {
  //           for (var k = 0; k < this.labTest_array.length; k++) {
  //             if (this.labTest_array[k].diag_tests_id == lab_array[j].test_id) {
  //               this.labTest_array[k].checked = false;
  //             }
  //           }
  //           this.labarray.splice(j, 1);
  //           flag = true;
  //           break;
  //         }
  //       }
  //     }

  //     if (lab_array != undefined && radio_array.length != 0) {
  //       for (var k = 0; k < radio_array.length; k++) {
  //         if (profile_array.tests[i].diag_tests_id == radio_array[k].test_id) {
  //           for (var j = 0; j < this.radioTest_array.length; j++) {
  //             if (this.radioTest_array[j].diag_tests_id == radio_array[k].test_id) {
  //               this.radioTest_array[j].checked = false;
  //             }
  //           }
  //           this.radioarray.splice(k, 1);
  //           flag = true;
  //           break;
  //         }
  //       }
  //     }
  //   }

  //   return flag;
  // }


  clickTest(e: any, data) {
    if (e == true) {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          var results
          results = this.check_addedTests(this.profiles[i], this.labarray, this.radioarray, this.selected_packages);

          if (results != true) {
            this.profiles[i].checked = true;
            this.selected_profiles.push(this.profiles[i])
            this.selected_profileIds.push(this.profiles[i].diag_profile_id)
          } else {
            if (this.isTestVal == true) {
              this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
            } else {
              this.toastr.error("The profile or profile's test is in the selected package", null, { timeOut: 3000 });
            }
          }
        }
      }

    } else {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          this.profiles[i].checked = false;
          for (var h = 0; h < this.selected_profiles.length; h++) {
            if (this.selected_profiles[h].diag_profile_id == data) {
              this.selected_profiles.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selected_profileIds.length; k++) {
            if (this.selected_profileIds[k] == data) {
              this.selected_profileIds.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selected_profiles)
  }

  clickPackage(e: any, data) {
    if (e == true) {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].health_pkg_id == data) {
          var results, test_results, pack_results;
          results = this.packndprofileVal(this.packages_array[i], this.selected_profileIds, this.selected_profiles);
          test_results = this.packndtestVal(this.packages_array[i], this.labarray, this.radioarray);
          pack_results = this.packndpackVal(this.packages_array[i], this.selected_packageIds);

          if (results != true && test_results != true && pack_results != true) {
            this.packages_array[i].checked = true;
            this.selected_packages.push(this.packages_array[i])
            this.selected_packageIds.push(this.packages_array[i].health_pkg_id)
          } else {
            this.toastr.error("Package contains a selected profile or test", null, { timeOut: 3000 });
          }
        }
      }

    } else {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].health_pkg_id == data) {
          this.packages_array[i].checked = false;
          for (var h = 0; h < this.selected_packages.length; h++) {
            if (this.selected_packages[h].health_pkg_id == data) {
              this.selected_packages.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selected_packageIds.length; k++) {
            if (this.selected_packageIds[k] == data) {
              this.selected_packageIds.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_packages", this.selected_packages)
  }

  addAmount(test, type_of_test) {
    if (type_of_test != "Profile" && type_of_test != "Package") {
      var subTest;

      for (var i = 0; i < test.length; i++) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid',
          {
            test_id: test[i].test_id,
            type: "diagnosis",
            country: "IN"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("priceTable------->", obj)
              this.amount = obj.price;
              var perce = this.concession / 100;
              var amt = this.amount;
              var amt_cons = this.amountAfternoon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              var paydesc;
              if (this.paymentArray.length != 0) {
                for (var i = 0; i < this.paymentArray.length; i++) {
                  if (this.payType == this.paymentArray[i].pay_id) {
                    paydesc = this.paymentArray[i].pay_desc;
                  }
                }
              }
              this.amountAfternoon = Math.round(tot);
              var results;
              if (this.priceTable.length != 0) {
                for (var j = 0; j < this.priceTable.length; j++) {
                  results = this.finekeyInArray_frPriceTab(this.priceTable, obj.diag_tests_id, type_of_test);

                  if (results != true) {
                    var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                    this.priceTable.push({
                      sno: this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1,
                      priority: this.priority,
                    });
                    this.addBillArray.push({
                      sno: this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1,
                      priority: this.priority,
                    });
                    this.changeTestAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                    this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                    this.sno += 1;
                  }
                }

              } else {
                var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                this.priceTable.push({
                  sno: this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1,
                  priority: this.priority,
                });

                this.addBillArray.push({
                  sno: this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1,
                  priority: this.priority,
                });

                this.sno += 1;
                this.changeTestAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
              }

              this.balance = "0.00";
              for (var i = 0; i < this.priceTable.length; i++) {
                this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
              }
              this.changeAdvance();
            });
      }
      this.saveFlag = false;
    } else if (type_of_test == "Package") {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            // results = this.finekeyInArray(this.priceTable, test[i].test_id);
            results = this.finekeyInArray_frPriceTab(this.priceTable, test[i].test_id, type_of_test);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.addPackageBillArray.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });

              this.changePackageAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.sno += 1;
            }
          }
          console.log(this.priceTable);

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });

          this.addPackageBillArray.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.changePackageAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.sno += 1;
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        this.changeAdvance();
      }
      this.saveFlag = false;

    } else {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            // results = this.finekeyInArray(this.priceTable, test[i].test_id);
            results = this.finekeyInArray_frPriceTab(this.priceTable, test[i].test_id, type_of_test);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.addBillArray.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.changeTestAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.sno += 1;
            }
          }
          console.log(this.priceTable);

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.addBillArray.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.sno += 1;
          this.changeTestAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        this.changeAdvance();
      }
      this.saveFlag = false;
    }
  }

  removeTest(testid, type, remove_test) {
    if (this.priceTable.length == 1) {
      this.advance = "0.00";
      this.paymentsarray = [];
    }

    if (type == "Laboratory") {
      for (var i = 0; i < this.labTestArray.length; i++) {
        if (this.labTestArray[i].test_id == testid) {
          this.labTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }

    } else if (type == "Radiology") {
      for (var i = 0; i < this.radioTestArray.length; i++) {
        if (this.radioTestArray[i].test_id == testid) {
          this.radioTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }

    } else if (type == "Profile" || type == "Package") {
      for (var i = 0; i < this.selTestGroupArray.length; i++) {
        if (this.selTestGroupArray[i].test_id == testid) {
          if (this.selTestGroupArray[i].tests != undefined) {
            for (var k = 0; k < this.selTestGroupArray[i].tests.length; k++) {
              if (this.selTestGroupArray[i].tests[k].department == '1') {
                for (var j = 0; j < this.labTestArray.length; j++) {
                  if (this.selTestGroupArray[i].tests[k].diag_tests_id == this.labTestArray[j].test_id) {
                    this.labTestArray.splice(i, 1);
                    break;
                  }
                }
              } else {
                for (var j = 0; j < this.radioTestArray.length; j++) {
                  if (this.selTestGroupArray[i].tests[k].diag_tests_id == this.radioTestArray[j].test_id) {
                    this.radioTestArray.splice(i, 1);
                    break;
                  }
                }
              }
            }
          }
        }
      }
      for (var h = 0; h < this.selected_packages.length; h++) {
        if (this.selected_packages[h].health_pkg_id == testid) {
          this.selected_packages.splice(h, 1)
        }
      }

      for (var k = 0; k < this.selected_packageIds.length; k++) {
        if (this.selected_packageIds[k] == testid) {
          this.selected_packageIds.splice(k, 1)
        }
      }

      for (var i = 0; i < this.selTestGroupArray.length; i++) {
        if (this.selTestGroupArray[i].test_id == testid) {
          this.selTestGroupArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.selTestGroupArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].diag_profile_id == testid) {
          this.testGroupArray[i].checked = false;
          break;
        }
      }

      console.log("remove test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
    }
    var sno = 1; this.sno = 1;
    for (var i = 0; i < this.priceTable.length; i++) {
      this.priceTable[i].sno = sno;
      sno += 1;
      this.sno += 1;
    }
    this.balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
    }
    this.changeAdvance();
    this.changeAmount(remove_test.quantity, remove_test.amount_txt, remove_test.concession, remove_test.test_id, remove_test.type_of_test);
    this.calculateOutPrice();
  }

  clickAppDate(data) {
    this.appDate = data;
    var dataval = Date_Formate(this.currentDate);

    if (data != dataval) {
      this.getVisitingSessionArray = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      this.visitSession = this.getVisitingSessionArray[0].description;
    }
  }

  selectSession() {
    if (this.visitSession == "Morning") {
      this.timeSession = "AM";
      this.startTime = "06";
      this.endTime = "00";
    } else if (this.visitSession == "Evening") {
      this.startTime = "06";
      this.endTime = "00";
      this.timeSession = "PM";
    } else {
      this.timeSession = "PM";
      this.startTime = "12";
      this.endTime = "00";
    }
    this.sessionFlag = false;
    this.enableBooking();
  }

  enableBooking() {
    if (this.visitSession != undefined) {
      var flag = false;
      if (this.clientId != undefined && this.bloodTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.urineTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.feaceTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.XrayTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.scanTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.ultraTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.CultureTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.electroCardioTestArray.length != 0) {
        flag = true;
      }
      if (flag == true) {
        this.bookNowFlag = false;
      }
    }
  }

  // concession calculation
  changeAmount(quantity, amount, concession, testId, type_of_test) {
    var perce;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }

    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      if (testId == this.priceTable[i].test_id && type_of_test == this.priceTable[i].type_of_test) {
        this.priceTable[i].fee = Math.round(tot);
        this.priceTable[i].concession = concession;
      }
    }

    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (testId == this.addPackageBillArray[i].test_id && type_of_test == this.addPackageBillArray[i].type_of_test) {
        this.addPackageBillArray[i].fee = Math.round(tot);
        this.addPackageBillArray[i].concession = concession;
      }
    }

    for (var i = 0; i < this.addBillArray.length; i++) {
      if (testId == this.addBillArray[i].test_id && type_of_test == this.addBillArray[i].type_of_test) {
        this.addBillArray[i].fee = Math.round(tot);
        this.addBillArray[i].concession = concession;
      }
    }

    this.balance = "0.00";
    this.bill_amount = "0.00";
    this.discount_amt = "0.00";
    for (var i = 0; i < this.priceTable.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.priceTable[i].test_id == testId && type_of_test == this.priceTable[i].type_of_test) {
        this.priceTable[i].quantity = quantity;
      }

      this.balance = (
        parseFloat(this.balance) +
        parseFloat(this.priceTable[i].amount_txt) *
        parseFloat(this.priceTable[i].quantity)
      ).toFixed(2);

      this.discount_amt = (
        parseFloat(this.discount_amt) +
        parseFloat(this.priceTable[i].amount_txt) *
        parseFloat(this.priceTable[i].quantity) *
        (parseFloat(this.priceTable[i].concession) / 100)
      ).toFixed(2);

      this.bill_amount = (
        parseFloat(this.bill_amount) + parseFloat(this.priceTable[i].fee)
      ).toFixed(2);

      console.log("BILL DETAILS " + JSON.stringify(this.priceTable))
    }
    this.changeTestAmount(quantity, amount, concession, testId, type_of_test);
    this.changePackageAmount(quantity, amount, concession, testId, type_of_test);
    this.changeAdvance();
  }

  changePackageAmount(quantity, amount, concession, testId, type_of_test) {
    var perce;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (testId == this.addPackageBillArray[i].test_id && type_of_test == this.addPackageBillArray[i].type_of_test) {
        this.addPackageBillArray[i].fee = Math.round(tot);
        this.package_id = this.addPackageBillArray[i].test_id;
        console.log(this.package_id);
      }
    }
    this.package_balance = "0.00";
    this.package_bill_amount = "0.00";
    this.package_discount_amt = "0.00";
    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.addPackageBillArray[i].test_id == testId && type_of_test == this.addPackageBillArray[i].type_of_test) {
        this.addPackageBillArray[i].quantity = quantity;
      }

      this.package_balance = (
        parseFloat(this.package_balance) +
        parseFloat(this.addPackageBillArray[i].amount_txt) *
        parseFloat(this.addPackageBillArray[i].quantity)
      ).toFixed(2);

      this.package_discount_amt = (
        parseFloat(this.package_discount_amt) +
        parseFloat(this.addPackageBillArray[i].amount_txt) *
        parseFloat(this.addPackageBillArray[i].quantity) *
        (parseFloat(this.addPackageBillArray[i].concession) / 100)
      ).toFixed(2);

      this.package_bill_amount = (
        parseFloat(this.package_bill_amount) + parseFloat(this.addPackageBillArray[i].fee)
      ).toFixed(2);
    }
    this.changeAdvance();
  }

  changeTestAmount(quantity, amount, concession, testId, type_of_test) {
    var perce;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.addBillArray.length; i++) {
      if (testId == this.addBillArray[i].test_id && type_of_test == this.addBillArray[i].type_of_test) {
        this.addBillArray[i].fee = Math.round(tot);
      }
    }
    this.test_balance = "0.00";
    this.test_bill_amount = "0.00";
    this.test_discount_amt = "0.00";
    for (var i = 0; i < this.addBillArray.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.addBillArray[i].test_id == testId && type_of_test == this.addBillArray[i].type_of_test) {
        this.addBillArray[i].quantity = quantity;
      }

      this.test_balance = (
        parseFloat(this.test_balance) +
        parseFloat(this.addBillArray[i].amount_txt) *
        parseFloat(this.addBillArray[i].quantity)
      ).toFixed(2);

      this.test_discount_amt = (
        parseFloat(this.test_discount_amt) +
        parseFloat(this.addBillArray[i].amount_txt) *
        parseFloat(this.addBillArray[i].quantity) *
        (parseFloat(this.addBillArray[i].concession) / 100)
      ).toFixed(2);

      this.test_bill_amount = (
        parseFloat(this.test_bill_amount) + parseFloat(this.addBillArray[i].fee)
      ).toFixed(2);
    }
    this.changeAdvance();
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clientGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clientGender = "Female";
    else if (e == "5")
      this.clientGender = "Transgender";
    else {
      this.clientGender = "";
    }
  }

  //Get payment type
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
          this.payType = this.paymentArray[0].pay_id;
          this.pay_desc = this.paymentArray[0].pay_desc;
          this.payType1 = this.paymentArray[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insuranceFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;

    } else {
      this.insuranceFlag = true;
    }
    for (var i = 0; i < this.paymentArray.length; i++) {
      if (this.payType == this.paymentArray[i].pay_id) {
        this.pay_desc = this.paymentArray[i].pay_desc;
        break;
      }
    }
  }

  //Get payment type
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurancyArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  changeGender(e) {
    if (this.clientAge != undefined && this.clientAge != null && this.clientAge != "") {
      if (e == "Male") {
        if (this.clientAge <= 15)
          this.clnt_sal = "6";
        else
          this.clnt_sal = "1";
      } else if (e == "Female") {
        if (this.clientAge <= 15)
          this.clnt_sal = "7";
        else
          this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    } else {
      if (e == "Male") {
        this.clnt_sal = "1";
      } else if (e == "Female") {
        this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    }
  }

  changeClientType(type, id, value) {
    if (type == "patient") {
      if (value == "yes") {
        this.inpatient = false;
        this.ynopt1 = true;
        this.pat_type = "outpatient";
        this.saveFlag = false;
        this.getPatientList();

      } else {
        this.inpatient = true;
        this.ynopt1 = false;
        this.pat_type = "inpatient";
        this.clientType = "Existing";
        this.saveFlag = true;
        this.getPatientList();
      }

    } else {
      if (value == "yes") {
        this.ynopt2 = true;
        this.clientType = "New";
        this.selectUserType();
        this.addtest_Flag = true;
        this.saveFlag = false;

      } else {
        this.ynopt2 = false;
        this.clientType = "Existing";
        this.selectUserType();
        this.addtest_Flag = false;
        this.saveFlag = true;
      }
    }
    this.currentLimit = 1;
    this.patientList = [];
    this.filteredArr = [];
    //this.getPatientList();
  }

  getReferralData(e) {
    this.referedDoctorArray = [];
    this.referedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/dbyp',
        {
          center_id: this.diagId,
          doc_name: this.doctorName,
          type: "diag"
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.referedNameArray = obj.doctors;
            if (this.referedNameArray != undefined) {
              for (var i = 0; i < this.referedNameArray.length; i++) {
                var docname;
                if (this.referedNameArray[i].middle_name != undefined) {
                  docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].middle_name + " " + this.referedNameArray[i].last_name;
                } else {
                  if (this.referedNameArray[i].last_name != undefined) {
                    docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].last_name;
                  } else {
                    docname = this.referedNameArray[i].first_name
                  }
                }
                this.referedDoctorArray.push({
                  docname: docname,
                  doc_reg_id: this.referedNameArray[i].prov_id,
                  hptl_clinic_id: this.referedNameArray[i].hptl_clinic_id,
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          });
    }
    else {
      this.referedDoctorArray = [];
    }
  }

  getReferDoctor(id) {
    var item1 = this.doctorName;
    for (var i = 0; i < this.referedNameArray.length; i++) {
      if (this.referedNameArray[i].prov_id == id) {
        this.hospId = this.referedNameArray[i].hptl_clinic_id;
        this.doctorRegId = this.referedNameArray[i].prov_id;
        break;
      }
    }
    // this.doctorName = item;
    // this.doctorRegId = 
    this.referedDoctorArray = [];
    this.getAppointments();
  }

  getAppointments() {
    this.billingListArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      {
        doc_reg_id: this.doctorRegId,
        hptl_clinic_id: this.hospId,
        country: ipaddress.country_code.toString(),
        imei: Diagnosis_Helper.getIPAddress(),
        type: "doctor",
        inpat_type: "self",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.patients != null) {
            for (var i = 0; i < obj.patients.length; i++) {
              var patientName;
              if (obj.patients[i].middle_name != undefined) {
                this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              } else {
                if (obj.patients[i].last_name != undefined) {
                  this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                } else {
                  this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name);
                }

              }
              var sessiondata = null;
              if (obj.patients[i].session != undefined && obj.patients[i].session != undefined && obj.patients[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.patients[i].session);
              }
              this.billingListArray.push({
                doc_reg_id: this.doctorRegId,
                doc_app_id: obj.patients[i].doc_app_id,
                client_reg_id: obj.patients[i].client_reg_id,
                relation_id: obj.patients[i].relation_id,
                sub_rel_id: obj.patients[i].sub_rel_id,
                first_name: obj.patients[i].first_name,
                middle_name: obj.patients[i].middle_name,
                last_name: obj.patients[i].last_name,
                hptl_clinic_id: this.hospId,
                session: sessiondata,
                docid_name: this.selectedDocName,
                patientName: patientName
              });
            }
          }
        },
        error => { });
  }

  saveDiagnosisPrescription() {
    var blood_array: any = [];
    var urine_arry: any = [];
    var scan_arry: any = [];
    var Faeces_tests: any = [];
    var Xray_tests: any = [];
    var ultra_tests: any = [];
    var culture_test: any = [];
    var biopsy_test: any = [];
    var valid_flag = false;
    if (this.bloodTestArray.length != 0) {
      for (var i = 0; i < this.bloodTestArray.length; i++) {
        blood_array.push(this.bloodTestArray[i].test_id);
      }
    }
    if (this.urineTestArray.length != 0) {
      for (var i = 0; i < this.urineTestArray.length; i++) {
        urine_arry.push(this.urineTestArray[i].test_id);
      }
    }
    if (this.feaceTestArray.length != 0) {
      for (var i = 0; i < this.feaceTestArray.length; i++) {
        Faeces_tests.push(this.feaceTestArray[i].test_id);
      }
    }
    if (this.scanTestArray.length != 0) {
      for (var i = 0; i < this.scanTestArray.length; i++) {
        scan_arry.push(this.scanTestArray[i].test_id);
      }
    }
    if (this.XrayTestArray.length != 0) {
      for (var i = 0; i < this.XrayTestArray.length; i++) {
        Xray_tests.push(this.XrayTestArray[i].test_id);
      }
    }
    if (this.ultraTestArray.length != 0) {
      for (var i = 0; i < this.ultraTestArray.length; i++) {
        ultra_tests.push(this.ultraTestArray[i].test_id);
      }
    }
    if (this.CultureTestArray.length != 0) {
      for (var i = 0; i < this.CultureTestArray.length; i++) {
        culture_test.push(this.CultureTestArray[i].test_id);
      }
    }
    if (this.biopsyTestArray.length != 0) {
      for (var i = 0; i < this.biopsyTestArray.length; i++) {
        biopsy_test.push(this.biopsyTestArray[i].test_id);
      }
    }
    var sen_pass;
    if (this.subRelationId != "" && this.subRelationId != undefined) {
      sen_pass = {
        appointment_id: this.appID,
        client_reg_id: this.clientId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelationId,
        date: this.currentDate,
        diagnosis: "diag",
        disease: "",
        diagnosis_id: this.diagId,
        country: ipaddress.country_code,
        //   spl_id:this.patient_list.spl_id,
        "Blood Test": blood_array,
        "Urine Test": urine_arry,
        "Faeces Test": Faeces_tests,
        "Scan": scan_arry,
        "X-ray": Xray_tests,
        "Ultra Sound": ultra_tests,
        "Culture": culture_test,
        "Biopsy": biopsy_test,
      };

    } else {
      sen_pass = {
        appointment_id: this.appID,
        client_reg_id: this.clientId,
        relation_id: this.relationId,
        date: this.currentDate,
        diagnosis: "diag",
        disease: "",
        country: ipaddress.country_code,
        diagnosis_id: this.diagId,
        //  spl_id:this.patient_list.spl_id,
        "Blood Test": blood_array,
        "Urine Test": urine_arry,
        "Faeces Test": Faeces_tests,
        "Scan": scan_arry,
        "X-ray": Xray_tests,
        "Ultra Sound": ultra_tests,
        "Culture": culture_test,
        "Biopsy": biopsy_test,
      };
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj["pres_id"] != null) {
            Helper_Class.set_diag_pres_id(obj["pres_id"]);
            this.presID = obj.pres_id;
            this.savePrescriptionFlag = false;
            this.saveApp()
          } else {
            this.toastr.error(Message_data.unableToSavePrescription);
          }
        },
        error => { });
  }

  changeAdvance() { //add the bill details
    this.paidCalculation();
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.bill_amount) - parseFloat(this.advance)).toFixed(2);
      // this.amountval = parseFloat(this.advance)
      if (parseFloat(this.advance) > parseFloat(this.bill_amount)) {
        this.amountval = parseFloat(this.bill_amount).toFixed(2);

      } else {
        this.amountval = parseFloat(this.advance).toFixed(2);

      }
    } else {
      this.advance = "0.00";
      this.remaingBalance = this.bill_amount;
    }
  }

  paidCalculation() {
    var temp_paidBill: any = parseFloat(this.advance);
    if (this.addBillArray != undefined && this.addBillArray.length != 0 && this.test_bill_amount != '0.00') {
      if (temp_paidBill != '') {
        console.log(temp_paidBill + " " + this.test_bill_amount)
        if (temp_paidBill >= this.test_bill_amount) {
          this.test_paidBill = this.test_bill_amount;
          this.test_remainingBalance = '0.00';
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.test_bill_amount)).toFixed(2);

        } else {
          this.test_paidBill = parseFloat(temp_paidBill).toFixed(2);
          this.test_remainingBalance = (parseFloat(this.test_bill_amount) - parseFloat(temp_paidBill)).toFixed(2);
          temp_paidBill = '0.00';
        }
      }
    }

    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0 && this.package_bill_amount != '0.00') {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.package_bill_amount) {
          this.package_paidBill = this.package_bill_amount;
          this.package_remainingBalance = '0.00';
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.package_bill_amount)).toFixed(2);
        } else {
          this.package_paidBill = parseFloat(temp_paidBill).toFixed(2);
          this.package_remainingBalance = Math.ceil(parseFloat(this.package_bill_amount) - parseFloat(temp_paidBill)).toFixed(2);
          temp_paidBill = '0.00';
        }
      }
    }
  }

  getTest(test) {
    if (test.length >= 3) {
      if (this.testNameArray.length != 0) {
        if (this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testNameArray.length; j++) {
            this.testNameArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testNameArray.length; j++) {
              if (this.testNameArray[j].test_name.includes(chg[i].test_name) == true) {
                this.testNameArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testNameArray.length; j++) {
        this.testNameArray[j].show = false;
      }
    }
  }

  getTestGroup(test) {
    if (test.length >= 3) {
      if (this.testGroupArray.length != 0) {
        if (this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testGroupArray.length; j++) {
            this.testGroupArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testGroupArray.length; j++) {
              if (this.testGroupArray[j].package_name.includes(chg[i].package_name) == true) {
                this.testGroupArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testGroupArray.length; j++) {
        this.testGroupArray[j].show = false;
      }
    }
  }

  checkAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  diagTestGroupChange() {
    if (this.diagTestGroup == "package") {
      if (this.testGroupArray.length == 0) {
        this.getTestGroups();
      }
      this.diagtest_flag = true;

    } else {
      this.getDiagTestTypes();
      this.diagtest_flag = false;
    }
  }
  public paytype_cr;


  paymentArrayCalculation() {
    this.test_paid_percentage = [];
    this.test_paymentsarray = [];
    this.package_paid_percentage = [];
    this.package_paymentsarray = [];
    this.test_pay_arrry_tot_amt = '0.00';
    this.testEqualSplitValue = '0.00';
    this.package_pay_arrry_tot_amt = '0.00';
    this.packageEqualSplitValue = '0.00';

    console.log("Function Called.....");
    if (this.paymentsarray.length != 0) {
      if (this.addBillArray.length != 0) {
        console.log("paymentsarray ", this.paymentsarray)
        console.log("test_bill_amount ", this.test_bill_amount)
        console.log("test_paidBill ", this.test_paidBill)
        if (this.test_bill_amount != '' && this.test_bill_amount != '0.00') {
          if (this.test_paidBill != '' && this.test_paidBill != '0.00') {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != '0.00') {
                this.test_paymentsarray.push({
                  pay_id: this.paymentsarray[i].pay_id,
                  pay_desc: this.paymentsarray[i].pay_desc,
                  amount: Math.round((this.test_bill_amount / this.bill_amount) * parseFloat(this.paymentsarray[i].amount)).toFixed(2)
                })
              }
            }

            if (this.test_paymentsarray.length != 0) {
              for (var i = 0; i < this.test_paymentsarray.length; i++) {
                this.test_pay_arrry_tot_amt = parseFloat(this.test_pay_arrry_tot_amt) + parseFloat(this.test_paymentsarray[i].amount);
              }
              console.log("test_pay_arrry_tot_amt", this.test_pay_arrry_tot_amt);
              if (this.test_pay_arrry_tot_amt < this.test_paidBill) {
                this.testEqualSplitValue = ((parseFloat(this.test_paidBill) - parseFloat(this.test_pay_arrry_tot_amt)) / this.test_paymentsarray.length).toFixed(2);
                console.log("testEqualSplitValue", this.testEqualSplitValue);
                for (var i = 0; i < this.test_paymentsarray.length; i++) {
                  this.test_paymentsarray[i].amount = (parseFloat(this.test_paymentsarray[i].amount) + parseFloat(this.testEqualSplitValue)).toFixed(2);
                  console.log(this.test_paymentsarray[i].amount);
                }
              } else if (this.test_pay_arrry_tot_amt > this.test_paidBill) {
                this.testEqualSplitValue = ((parseFloat(this.test_pay_arrry_tot_amt) - parseFloat(this.test_paidBill)) / this.test_paymentsarray.length).toFixed(2);
                console.log("testEqualSplitValue", this.testEqualSplitValue);
                for (var i = 0; i < this.test_paymentsarray.length; i++) {
                  this.test_paymentsarray[i].amount = (parseFloat(this.test_paymentsarray[i].amount) - parseFloat(this.testEqualSplitValue)).toFixed(2);
                  console.log(this.test_paymentsarray[i].amount);
                }
              }
            }
            console.log('DiagPaymentsArray', this.test_paymentsarray);
          }
        }
      }

      if (this.addPackageBillArray.length != 0) {
        if (this.package_bill_amount != '' && this.package_bill_amount != '0.00') {
          if (this.package_paidBill != '' && this.package_paidBill != '0.00') {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != '0.00') {
                this.package_paymentsarray.push({
                  pay_id: this.paymentsarray[i].pay_id,
                  pay_desc: this.paymentsarray[i].pay_desc,
                  amount: Math.round((this.package_bill_amount / this.bill_amount) * parseFloat(this.paymentsarray[i].amount)).toFixed(2)
                })
              }
            }

            if (this.package_paymentsarray.length != 0) {
              for (var i = 0; i < this.package_paymentsarray.length; i++) {
                this.package_pay_arrry_tot_amt = parseFloat(this.package_pay_arrry_tot_amt) + parseFloat(this.package_paymentsarray[i].amount);
              }
              console.log("package_pay_arrry_tot_amt", this.package_pay_arrry_tot_amt);
              if (this.package_pay_arrry_tot_amt < this.package_paidBill) {
                this.packageEqualSplitValue = ((parseFloat(this.package_paidBill) - parseFloat(this.package_pay_arrry_tot_amt)) / this.package_paymentsarray.length).toFixed(2);
                console.log("packageEqualSplitValue", this.packageEqualSplitValue);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) + parseFloat(this.packageEqualSplitValue)).toFixed(2);
                  console.log(this.package_paymentsarray[i].amount);
                }
              } else if (this.package_pay_arrry_tot_amt > this.package_paidBill) {
                this.packageEqualSplitValue = ((parseFloat(this.package_pay_arrry_tot_amt) - parseFloat(this.package_paidBill)) / this.package_paymentsarray.length).toFixed(2);
                console.log("packageEqualSplitValue", this.packageEqualSplitValue);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) - parseFloat(this.packageEqualSplitValue)).toFixed(2);
                  console.log(this.package_paymentsarray[i].amount);
                }
              }
            }
            console.log('PackagePaymentsArray', this.package_paymentsarray);
          }
        }
      }
    }
  }

  saveApp() {
    this.sendData = {};
    var test_outsource: any = [];
    var priority_List: any = [];
    var labarray: any = [];
    var radioarray: any = [];
    var valid_flag = false;
    var errortext = "Fill ";

    if (this.advance != "0.00") {
      if (this.paymentsarray.length == 0) {
        errortext += " Payment type"
        valid_flag = true;
      }
    }

    if (this.clnt_sal == undefined || this.clnt_sal == null) {
      errortext += " Salutation"
      valid_flag = true;
    }

    if (this.clntFirstName == "" || this.clntFirstName == undefined || this.clntFirstName == null) {
      errortext += " First name"
      valid_flag = true;
    }

    if (this.clntLastName == "" || this.clntLastName == undefined || this.clntLastName == null) {
      errortext += " Last name"
      valid_flag = true;
    }

    if (this.clntContNumber == undefined || this.clntContNumber == null) {
      errortext += " Mobile"
      valid_flag = true;
    }

    if (this.clntAge == undefined || this.clntAge == null) {
      errortext += " Age"
      valid_flag = true;
    }

    if (this.visitSession != "Select") {
      this.visitSession = this.visitSession;

    } else {
      errortext += " Session"
      valid_flag = true;
    }
    if (this.clntAddress1 == "" || this.clntAddress1 == null || this.clntAddress1 == undefined) {
      errortext += " Address"
      valid_flag = true;
    }

    if (this.priceTable.length == 0) {
      errortext += " Tests"
      valid_flag = true;
    }

    if (valid_flag == true) {
      this.toastr.error(errortext);

    } else if (valid_flag == false) {
      if (this.savePrescriptionFlag == true) {
        //  this.saveDiagnosisPrescription()
      } else {
        if (this.labTestArray.length != 0) {
          for (var i = 0; i < this.labTestArray.length; i++) {
            labarray.push(this.labTestArray[i].test_id);
          }
        }

        if (this.radioTestArray.length != 0) {
          for (var i = 0; i < this.radioTestArray.length; i++) {
            radioarray.push(this.radioTestArray[i].test_id);
          }
        }

        for (var l = 0; l < this.priceTable.length; l++) {
          if (this.priceTable[l].outsource != undefined || this.priceTable[l].priority != undefined) {
            test_outsource.push({
              test_type: this.priceTable[l].type_of_test,
              test_id: this.priceTable[l].test_id,
              outsource: this.priceTable[l].outsource,
              priority: this.priceTable[l].priority,
            })
          }
        }

        for (var i = 0; i < this.priceTable.length; i++) {
          priority_List.push({
            test_type: this.priceTable[i].type_of_test,
            test_id: this.priceTable[i].test_id,
            priority: this.priceTable[i].priority,
          })
        }

        this.sendData = {
          "profiles": this.selected_profileIds_frSave,
          "labtest": labarray,
          "radiotest": radioarray,
          "package": this.selected_packageIds_frSave,
        }

        var zcode;
        if (this.clntZipcode != undefined) {
          zcode = encrypt_decript.Encript(this.clntZipcode).toString();
        }

        var addr1;
        if (this.clntAddress1 != null) {
          addr1 = encrypt_decript.Encript(this.clntAddress1).toString()

        } else {
          addr1 = null;
        }

        var addr2;
        if (this.clntAddress2 != null) {
          addr2 = encrypt_decript.Encript(this.clntAddress2).toString()
        } else {
          addr2 = null;
        }

        var address: any = [];
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clientLocationId,
          city: this.clientCityId,
          state: this.clientStateId,
          country: this.clientcountryId,
          zipcode: zcode,
          type: "pres"
        });

        var mname;
        if (this.clntMiddleName != undefined) {
          mname = this.clntMiddleName.toString();
        }
        var lname;
        if (this.clntLastName != undefined) {
          lname = encrypt_decript.Encript(this.clntLastName).toString();
        }
        var fname;
        if (this.clntFirstName != undefined) {
          fname = encrypt_decript.Encript(this.clntFirstName).toString();
        }
        var gender;
        if (this.clientGender != undefined) {
          gender = encrypt_decript.Encript(this.clientGender).toString();
        }
        this.sendData.pres_diag_id = this.pres_diag_id;
        this.sendData.client_reg_id = this.clientRegId;
        this.sendData.relation_id = this.relationId;
        this.sendData.sub_rel_id = this.subRelId;
        this.sendData.salutation = this.clnt_sal;
        this.sendData.first_name = fname;
        this.sendData.middle_name = mname;
        this.sendData.last_name = lname;
        if (this.clientDOB != undefined) {
          this.sendData.dob = this.clientDOB;//.split("-")[2]+"-"+this.clientDOB.split("-")[1]+"-"+this.clientDOB.split("-")[0];
        }

        this.sendData.age = this.clientAge;
        this.sendData.gender = gender;
        this.sendData.mem_mobile = encrypt_decript.Encript(this.clientNumber).toString();
        this.sendData.app_type = this.clientType;
        this.sendData.address = address;
        if (this.referedBy.trim() === '') {
          // Handle empty value, maybe show a message to the user
        } else {
          this.sendData.referred_by = this.referedBy;
        }

        this.sendData.diag_centre_id = this.diag_center_id;
        this.sendData.pres_id = undefined;
        this.sendData.appointment_id = this.inpatient == true ? this.appID : undefined;
        this.sendData.provider = "diagnosis";
        this.sendData.date = this.appDate;//.split('-')[2] + "-" + this.appDate.split('-')[1] + "-" + this.appDate.split('-')[0];
        this.sendData.session = this.visitSession;
        this.sendData.spl_id = this.speclization;
        this.sendData.time = this.startTime + ":" + this.endTime;
        this.sendData.pat_type = this.pat_type;
        this.sendData.country = ipaddress.country_code;
        this.sendData.doc_reg_id = this.doctorID;
        this.sendData.pres_diag_id = this.pres_diag_idvalue;
        // this.sendData.book_now = "1";
        console.log("SAVE DATA " + JSON.stringify(this.sendData))

        var billid, pkgid;
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "appointment/dbappn", this.sendData, { headers: headers }).subscribe(
          data => {
            var response_data = JSON.parse(JSON.stringify(data));
            var billcreate_value;
            if (response_data.status == "1") {
              this.selected_profileIds_frSave = [];
              this.selected_packageIds_frSave = [];
              this.toastr.success(response_data.result);
              if (this.userinfo.diag_bill_generate == "1") {
                this.paymentArrayCalculation();
                console.log("addPackageBillArray", this.addPackageBillArray)
                console.log("addBillArray", this.addBillArray)
                this.paid_balanceFlag = true;
                if (this.userinfo.bill_pay_type_req == "0") {
                  this.credit_flag = true;
                }

                if (this.addPackageBillArray.length != 0) {
                  var userid = Helper_Class.getInfo().user_id;
                  var package_status = parseInt(this.package_remainingBalance) <= 0 ? '0' : '1';

                  if (response_data.diag_app_id != undefined) {
                    var balance = "0";
                    for (var i = 0; i < this.addPackageBillArray.length; i++) {
                      balance = (parseFloat(balance) + parseFloat(this.addPackageBillArray[i].fee)).toFixed(2);
                    }

                    var paid_flag;
                    if (balance == this.package_remainingBalance) {
                      paid_flag = "Un paid";

                    } else if (balance != "0.00" && (this.package_paidBill != "0.00") && balance != this.package_paidBill) {
                      paid_flag = "Partially paid";

                    } else if (this.package_remainingBalance == "0.00" || this.package_remainingBalance == "0") {
                      paid_flag = "Fully paid";
                    }

                    var cons, fee, disc;
                    var finalAmount;
                    for (var c = 0; c < this.addPackageBillArray.length; c++) {
                      cons = this.addPackageBillArray[c].concession;
                      fee = this.addPackageBillArray[c].fee;
                      disc = parseFloat(this.addPackageBillArray[c].amount_txt) -
                        parseFloat(this.addPackageBillArray[c].fee);
                      finalAmount = encrypt_decript.Encript(this.addPackageBillArray[c].fee.toString()).toString();
                    }

                    // if (parseInt(this.package_bill_amount) == 0) {

                    // } else {
                    //   finalAmount = encrypt_decript.Encript(fee).toString();
                    // }

                    billcreate_value = {
                      hptl_clinic_id: this.diag_center_id,
                      client_reg_id: response_data.client_reg_id,
                      relation_id: this.relationId,
                      sub_rel_id: this.subRelationId,
                      diag_app_id: response_data.diag_app_id,
                      balance: this.remaingBalance,
                      paid_amt: fee,
                      paid_flag: paid_flag,
                      bill_amount: finalAmount,
                      created_by: this.userinfo.user_id,
                      app_type: "package",
                      bills: this.addPackageBillArray,
                      country: ipaddress.country_code,
                      citizenship: "indian",
                      card_no: this.cardNumber,
                      card_holder_name: this.cardHolderName,
                      transaction_no: this.transactionID,
                      bank_name: this.bankName,
                      pres_id: this.presID,
                      payments: this.package_paymentsarray,
                      outpay: this.outpayarray,
                      discount: disc,
                      total_amount: this.package_balance,
                      bill_package_id: this.package_id,
                      userid: userid,
                      status: package_status,
                      concession: cons,
                      fee: fee,
                      rate_card: "Service wise",
                    }
                    console.log("billcreate_value" + JSON.stringify(billcreate_value))

                    var headers = new HttpHeaders();
                    headers.append('Content-Type', 'application/json');
                    this.http.post(ipaddress.getIp.toString() + 'bill/savebill/', billcreate_value,
                      // this.http.post(ipaddress.getIp.toString() + '', billcreate_value,
                      { headers: headers })
                      .subscribe(
                        response => {
                          var response_data = JSON.parse(JSON.stringify(response));
                          console.log("response_data " + JSON.stringify(response_data))

                          if (response_data.key == "1") {
                            if (billid == undefined) {
                              billid = response_data.bill_no
                            }
                            if (pkgid == undefined) {
                              pkgid = response_data.pbill_no;
                            }

                            var billdetail = {
                              pkg_bill_id: pkgid,
                              bill_view_id: billid,
                              Diag_center_id: this.diagId,

                              flow: "dig_package",
                            }
                            // this.billId = response_data.bill_no;
                            Diagnosis_Helper.setbilldetailview(billdetail);
                            if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                              this.frontdeskservice.sendMessage("diaBillingDetailsView");

                            } else {
                              this.diagnosisservice.sendMessage("diagbilldetailview");
                            }
                          }
                        })
                  }
                }

                if (this.addBillArray.length != 0) {
                  if (response_data.diag_app_id != undefined) {
                    var balance = "0";
                    for (var i = 0; i < this.addBillArray.length; i++) {
                      balance = (parseFloat(balance) + parseFloat(this.addBillArray[i].fee)).toFixed(2);

                      // this.changeTestAmount(this.addBillArray[i].quantity, this.addBillArray[i].amount_txt, this.addBillArray[i].concession, this.addBillArray[i].test_id, this.addBillArray[i].type_of_test);
                    }
                    var paid_flag;
                    if (balance == this.test_remainingBalance) {
                      paid_flag = "Un paid";
                    } else if (balance != "0.00" && (this.test_paidBill != "0.00") && balance != this.test_paidBill) {
                      paid_flag = "Partially paid";
                    } else if (this.test_remainingBalance == "0.00" || this.test_remainingBalance == "0") {
                      paid_flag = "Fully paid";
                    }
                    var outpayarray = [];

                    var finalAmount;
                    if (parseInt(this.test_bill_amount) == 0) {
                      finalAmount = encrypt_decript.Encript(this.test_discount_amt).toString();
                    } else {
                      finalAmount = encrypt_decript.Encript(this.test_bill_amount).toString();
                    }
                    console.log("AfterCalculated FinalAmount", finalAmount);

                    billcreate_value = {
                      diag_centre_id: this.diagId,
                      client_reg_id: response_data.client_reg_id,
                      relation_id: this.relationId,
                      sub_rel_id: this.subRelationId,
                      appointment_id: response_data.diag_app_id,
                      balance: this.test_remainingBalance,
                      paid_amt: this.test_paidBill,
                      paid_flag: paid_flag,
                      bill_amount: finalAmount,
                      created_by: this.userinfo.user_id,
                      app_type: "diagnosis",
                      bills: this.addBillArray,
                      country: ipaddress.country_code,
                      citizenship: "indian",
                      card_no: this.cardNumber,
                      card_holder_name: this.cardHolderName,
                      transaction_no: this.transactionID,
                      bank_name: this.bankName,
                      pres_id: this.presID,
                      payments: this.test_paymentsarray,
                      outpay: this.outpayarray,
                      discount: this.test_discount_amt,
                      total_amount: this.test_balance,
                      rate_card: "Service wise",
                    }

                    console.log("billcreate_value ((())) " + JSON.stringify(billcreate_value))

                    var headers = new HttpHeaders();
                    headers.append('Content-Type', 'application/json');
                    this.http.post(ipaddress.getIp.toString() + 'bill/savebill/', billcreate_value,
                      // this.http.post(ipaddress.getIp.toString() + '', billcreate_value,
                      { headers: headers })
                      .subscribe(
                        response => {
                          var response_data = JSON.parse(JSON.stringify(response));

                          if (response_data.key == "1") {
                            Diagnosis_Helper.setbilldetailview(undefined);
                            if (billid == undefined) {
                              billid = response_data.bill_no
                            }
                            if (pkgid == undefined) {
                              pkgid = response_data.pbill_no;
                            }
                            var billdetail = {
                              pkg_bill_id: pkgid,
                              bill_view_id: billid,
                              Diag_center_id: this.diagId,
                              flow: "dig_walking",
                            }
                            Diagnosis_Helper.setbilldetailview(billdetail);
                            this.frontdeskservice.sendMessage("manageappointments");
                            // if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                            //   this.frontdeskservice.sendMessage("manageappointments");

                            // } else {
                            //   this.diagnosisservice.sendMessage("diagbilldetailview");
                            // }
                          }
                        })
                  }
                }

              } else {
                if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                  this.frontdeskservice.sendMessage("manageappointments");
                } else {
                  this.diagnosisservice.sendMessage("appointment");
                }
              }
            } else {
              this.toastr.error(response_data.result);
            }
          }, error => {
          }
        );
      }
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  calculateOutPrice() {
    this.outpayarray = [];
    if (this.priceTable.length != 0) {
      for (var p = 0; p < this.priceTable.length; p++) {
        var outarray = this.priceTable[p].outarray;
        if (outarray != undefined) {
          for (var oa = 0; oa < outarray.length; oa++) {
            if (this.priceTable[p].outsource == outarray[oa].out_id) {
              if (this.outpayarray != undefined && this.outpayarray.length != 0) {
                if (this.isExists(this.outpayarray, this.priceTable[p].outsource) == true) {
                  for (var i = 0; i < this.outpayarray.length; i++) {
                    if (this.outpayarray[i].outsource == this.priceTable[p].outsource) {
                      var bill_amt = parseFloat(this.outpayarray[i].amount) + (parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100)));
                      this.outpayarray[i].amount = bill_amt;
                      break;
                    }
                  }

                } else {
                  this.outpayarray.push({
                    outsource: this.priceTable[p].outsource,
                    amount: parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100))
                  })
                  break;
                }

              } else {
                this.outpayarray.push({
                  outsource: this.priceTable[p].outsource,
                  amount: parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100))
                })
              }
            }
          }
        }
      }
    }
  }

  isExists(data, val) {
    var exitval;
    for (var i = 0; i < data.length; i++) {
      if (data[i].outsource == val) {
        exitval = true;
        break;
      }
    }
    return exitval;
  }

  addPaymentDetails() {
    var bamt = 0;
    if (this.paymentArray.length != 0) {
      for (var i = 0; i < this.paymentArray.length; i++) {
        if (this.payType1 == this.paymentArray[i].pay_id) {
          if (this.paymentsarray.length != 0) {
            for (var j = 0; j < this.paymentsarray.length; j++) {
              bamt += parseInt(this.paymentsarray[j].amount);
            }
            bamt += parseFloat(this.amountval.toString());
          } else {
            bamt += parseFloat(this.amountval.toString());
          }

          if (bamt > parseInt(this.advance)) {
            this.toastr.error("Amount cannot be more than paid amount")
          } else if (this.amountval == 0 || this.amountval < 0) {
            this.toastr.error('Amount cannot be zero or minus');
          } else {
            // var amount = this.amountval.toString();
            // this.paymentsarray.push({
            //   pay_id: this.paymentArray[i].pay_id,
            //   pay_desc: this.paymentArray[i].pay_desc,
            //   amount: parseFloat(amount).toFixed(2)
            // })
            // this.amountval = 0;

            if (this.amountval < parseFloat(this.advance) || this.amountval == parseFloat(this.advance)) {
              if (parseFloat(this.advance) > parseFloat(this.balance)) {
                if (bamt > parseFloat(this.balance)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paymentArray[i].pay_id,
                    pay_desc: this.paymentArray[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.balance) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              } else {
                if (bamt > parseFloat(this.advance)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paymentArray[i].pay_id,
                    pay_desc: this.paymentArray[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.advance) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              }
            } else {
              this.amountval = 0;
            }
          }
        }
      }
    }
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = data.amount;
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  getTests() {
    this.messageservice.sendMessage("out-test-map");
  }

  search_test() {
    this.Blood_test = [];
    this.testGroupArray = [];

    this.labarray = [];
    this.radioarray = [];
    this.selected_profiles = [];
    this.selected_packages = [];
    this.selected_profileIds = [];
    this.selected_packageIds = [];

    var order_by;
    if (this.showTest_type == "0") {
      order_by = "sub_dept";
    } else {
      order_by = "test_type";
    }

    // if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
    //   this.diagId = this.userinfo.diag_centre_id;
    // } else {
    //   this.diagId = this.diagId;
    // }
    console.log({
      diag_centre_id: this.diag_center_id,
      hptl_clinic_id: this.diagId,
      orderby: order_by,
    });
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/', {

      diag_centre_id: this.diag_center_id,
      hptl_clinic_id: this.diagId,
      orderby: order_by,
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("search_test----obj", obj)

          this.labTest_array = obj.labtests;
          this.radioTest_array = obj.radiotests;
          this.microTest_array = obj.microtests;
          this.profiles = obj.profiles;
          this.packages_array = obj.packages;

          this.filteredLabArray = obj.labtests;
          this.filteredRadioArray = obj.radiotests;
          this.filteredMicroArray = obj.microtests;
          this.filteredProfilesArray = obj.profiles;
          this.filteredPackagesArray = obj.packages;
        },
        error => { })
  }

  closeSelectedTest(type, id, subtype) {
    for (var k = 0; k < this.Blood_test.length; k++) {
      if (type == this.Blood_test[k].test_type_name) {
        for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
          if (this.Blood_test[k].sub_tests[j].test_id == id) {
            this.Blood_test[k].sub_tests[j].checked = false;
          }
        }
      }
    }

    if (type == "Blood Test") {
      for (var h = 0; h < this.subtestname.length; h++) {
        if (this.subtestname[h].test_id == id) {
          this.subtestname.splice(h, 1);
          if (this.subtestname.length == 0) {
            this.bloodtest = true;
          }
        }
      }
    }

    if (type == "Scan") {
      for (var h = 0; h < this.subtestsname.length; h++) {
        if (this.subtestsname[h].test_id == id) {
          this.subtestsname.splice(h, 1);
          if (this.subtestsname.length == 0) {
            this.scantest = true;
          }
        }
      }
    }

    if (type == "Ultra Sound") {
      for (var h = 0; h < this.subtestuname.length; h++) {
        if (this.subtestuname[h].test_id == id) {
          this.subtestuname.splice(h, 1);
          if (this.subtestuname.length == 0) {
            this.ultratest = true;
          }
        }
      }
    }

    if (type == "X-ray") {
      for (var h = 0; h < this.subtestxname.length; h++) {
        if (this.subtestxname[h].test_id == id) {
          this.subtestxname.splice(h, 1);
          if (this.subtestxname.length == 0) {
            this.xraytest = true;
          }
        }
      }
    }
    if (type == "Biopsy") {
      for (var h = 0; h < this.subtestbname.length; h++) {
        if (this.subtestbname[h].test_id == id) {
          this.subtestbname.splice(h, 1);
          if (this.subtestbname.length == 0) {
            this.biopsytest = true;
          }
        }
      }
    }
    if (type == "Urine Test") {
      for (var h = 0; h < this.subtesturname.length; h++) {
        if (this.subtesturname[h].test_id == id) {
          this.subtesturname.splice(h, 1);
          if (this.subtesturname.length == 0) {
            this.urinetest = true;
          }

        }
      }
    }
    if (type == "Faeces Test") {
      for (var h = 0; h < this.subtestfname.length; h++) {
        if (this.subtestfname[h].test_id == id) {
          this.subtestfname.splice(h, 1);
          if (this.subtestfname.length == 0) {
            this.faecestest = true;
          }
        }
      }
    }
    if (type == "Culture") {
      for (var h = 0; h < this.subtestcname.length; h++) {
        if (this.subtestcname[h].test_id == id) {
          this.subtestcname.splice(h, 1);
          if (this.subtestcname.length == 0) {
            this.culturetest = true;
          }
        }
      }
    }
    var flag;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }
  }

  saveTestDetails() {
    var sen_pass = {
      diagnosis_id: this.diagId,
      "blood": this.blood_test,
      "urine": this.urine_test,
      "faeces": this.feace_test,
      "scan": this.scan_test,
      "x-ray": this.xray_test,
      "ultra": this.ultra_test,
      "culture": this.culture_test,
      "biopsy": this.biopsy_test,
      "laboratory": this.labarray,
      "radiology": this.radioarray,
    }

    this.addTest2();

    this.blood_test = [];
    this.urine_test = [];
    this.feace_test = [];
    this.scan_test = [];
    this.xray_test = [];
    this.ultra_test = [];
    this.culture_test = [];
    this.biopsy_test = [];
    this.labarray = [];
    this.radioarray = [];
  }

  checkPackId(id) {
    var Pack_flag = false;

    if (this.selTestGroupArray.length != 0) {
      for (var j = 0; j < this.selTestGroupArray.length; j++) {
        for (var k = 0; k < this.selTestGroupArray[j].tests.length; k++) {
          if (this.selTestGroupArray[j].tests[k].diag_tests_id == id) {
            Pack_flag = true;
            setTimeout(() => {
              this.toastr.error("The test is added in profile");
            }, 1000)
            break;
          }
        }
      }
    }
    return Pack_flag;
  }

  checkTestId(data) {
    var test_flag = false;

    if (this.priceTable.length != 0 && data.tests != undefined) {
      for (var i = 0; i < this.priceTable.length; i++) {
        for (var j = 0; j < data.tests.length; j++) {
          if (this.priceTable.length != 0 && this.priceTable[i].test_id == data.tests[j].diag_tests_id) {
            test_flag = true;
            setTimeout(() => {
              this.toastr.error("A test in profile is already been added");
            }, 1000)
          }
        }
      }
    }
    return test_flag;
  }

  addTest2() {
    var results;
    var check_Package;
    var check_test;
    this.subtestname = [];

    this.blood_test = [];
    this.urine_test = [];
    this.feace_test = [];
    this.scan_test = [];
    this.xray_test = [];
    this.ultra_test = [];
    this.culture_test = [];
    this.biopsy_test = [];

    // if (this.diagTestGroup == "test") {
    if (this.labarray.length != 0 || this.radioarray.length != 0) {
      if (this.labarray.length != 0) {
        for (var i = 0; i < this.labarray.length; i++) {
          console.log("BEFORE REMOVAL " + JSON.stringify(this.labarray))
          if (this.labTestArray.length != 0) {
            results = this.finekeyInArray(this.labTestArray, this.labarray[i].test_id);
            check_Package = this.checkPackId(this.labarray[i].test_id);

            if (results != true && check_Package != true) {
              this.labTestArray.push({
                test_id: this.labarray[i].test_id,
                test_name: this.labarray[i].test_name,
                diag_type: this.labarray[i].diag_type,
              });
            } else {
              this.labarray.splice(i, 1);

            }

          } else {
            check_Package = this.checkPackId(this.labarray[i].test_id);
            if (check_Package != true) {
              this.labTestArray.push({
                test_id: this.labarray[i].test_id,
                test_name: this.labarray[i].test_name,
                diag_type: this.labarray[i].diag_type,
              });
            } else {
              this.labarray.splice(i, 1);

            }
          }
          console.log("after removal " + JSON.stringify(this.labarray))
        }
        this.addAmount(this.labarray, "Laboratory");

      } if (this.radioarray.length != 0) {
        for (var i = 0; i < this.radioarray.length; i++) {

          if (this.radioTestArray.length != 0) {
            results = this.finekeyInArray(this.radioTestArray, this.radioarray[i].test_id);
            check_Package = this.checkPackId(this.radioarray[i].test_id);

            if (results != true && check_Package != true) {
              this.radioTestArray.push({
                test_id: this.radioarray[i].test_id,
                test_name: this.radioarray[i].test_name,
                diag_type: this.radioarray[i].diag_type,
              });
            } else {
              this.radioarray.splice(i, 1);
              break;
            }
          } else {
            check_Package = this.checkPackId(this.radioarray[i].test_id);
            if (check_Package != true) {
              this.radioTestArray.push({
                test_id: this.radioarray[i].test_id,
                test_name: this.radioarray[i].test_name,
                diag_type: this.radioarray[i].diag_type,
              });
            } else {
              this.radioarray.splice(i, 1);
              break;
            }
          }
        }
        this.addAmount(this.radioarray, "Radiology");
      }
    }

    console.log("selected test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))

    this.labarray = [];
    this.radioarray = [];

    // } else {
    if (this.profiles != undefined) {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            results = this.finekeyInArray(this.selTestGroupArray, this.profiles[i].diag_profile_id);
            check_test = this.checkTestId(this.profiles[i]);

            if (results != true) {
              if (check_test != true) {
                this.selTestGroupArray.push({
                  test_id: this.profiles[i].diag_profile_id,
                  test_name: this.profiles[i].profile_name,
                  price: this.profiles[i].price,
                  tests: this.profiles[i].tests,
                  diag_type: this.profiles[i].diag_type,
                  department: this.profiles[i].department,
                });
              } else {
                this.toastr.error("A test in profile is already been added");
              }
            }

          } else {
            check_test = this.checkTestId(this.profiles[i]);
            if (check_test != true) {
              this.selTestGroupArray.push({
                test_id: this.profiles[i].diag_profile_id,
                test_name: this.profiles[i].profile_name,
                price: this.profiles[i].price,
                tests: this.profiles[i].tests,
                diag_type: this.profiles[i].diag_type,
              });

            } else {
              this.toastr.error("Selected test is already added in selected profile");
            }
          }
          console.log("INSIDE PROFILE ADD ", JSON.stringify(this.selTestGroupArray))
        }
      }
      this.addAmount(this.selTestGroupArray, "Profile");
      console.log("selected test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
      console.log("selected test lan" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }
      this.selected_profiles = [];
      this.selTestGroupArray = [];
    }

    if (this.packages_array != undefined) {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            // results = this.finekeyInArray(this.selTestGroupArray, this.profiles[i].diag_profile_id);
            // check_test = this.checkTestId(this.profiles[i]);
            this.selTestGroupArray.push({
              test_id: this.packages_array[i].health_pkg_id,
              test_name: this.packages_array[i].package_name,
              price: this.packages_array[i].price,
              tests: this.packages_array[i].tests,
              diag_type: this.packages_array[i].diag_type,
              department: this.packages_array[i].department,
            });
          } else {
            this.selTestGroupArray.push({
              test_id: this.packages_array[i].health_pkg_id,
              test_name: this.packages_array[i].package_name,
              price: this.packages_array[i].price,
              tests: this.packages_array[i].tests,
              diag_type: this.packages_array[i].diag_type,
            });
          }
          console.log("INSIDE PACKAGE ADD ", JSON.stringify(this.selTestGroupArray))
        }
      }
      this.addAmount(this.selTestGroupArray, "Package");
      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }
      this.selected_packages = [];
      this.selTestGroupArray = [];
    }

    this.selected_profileIds_frSave = [...this.selected_profileIds];
    this.selected_packageIds_frSave = [...this.selected_packageIds];

    console.log("selected_packageIds", this.selected_packageIds)
    console.log("selected_profileIds", this.selected_profileIds)
  }

  getSpecimentType() {
    this.specimenType_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gstbd', {
      diag_centre_id: this.diagId
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.specimen_types != undefined) {
            for (var i = 0; i < obj.specimen_types.length; i++) {
              this.specimenType_data.push({
                specimen_type_id: obj.specimen_types[i].specimen_type_id,
                specimen_desc: obj.specimen_types[i].specimen_desc,
                tube_color: obj.specimen_types[i].tube_color,
                tube_type_id: obj.specimen_types[i].tube_type_id,
              })
            }
          }
        },
        error => { })

  }

  getDiagTesttubes() {
    this.testtube_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', { diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // var dept_data = obj.diag_dept_list;
          var diagtube_data = obj.tube_type_list;

          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push(
                {
                  tube_type_id: diagtube_data[i].tube_type_id,
                  tube_desc: diagtube_data[i].tube_desc,
                  status: diagtube_data[i].status,
                }
              )
            }
          }
        }, error => { });
  }

  getTubetype(sampleData, data) {
    this.selected_tube_colors = [];
    var tube_array = [];

    for (var i = 0; i < this.specimenType_data.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j] == this.specimenType_data[i].specimen_type_id) {
          tube_array = this.specimenType_data[i].tube_color;
          for (var k = 0; k < tube_array.length; k++) {
            this.selected_tube_colors.push(tube_array[k])
          }
        }
      }
    }

    for (var k = 0; k < this.priceTable.length; k++) {
      if (this.priceTable[k].test_id == sampleData.test_id) {
        this.priceTable[k].tube_color = this.selected_tube_colors
      }
    }
  }

  async ChangeTestType(event, id, data, type, subtest, test_type, testdept) {
    try {
      this.getFrequency(event, id, data, subtest, type, test_type, testdept);
    }
    catch (err) { }
  }

  getFrequency(event, id, data, subtest, type, test_type, testdept) {
    var send_data = {
      client_reg_id: this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelationId,
      date: this.currentDate,
      test_id: subtest.diag_tests_id,
      interval: subtest.frequency_val,
      test_type: test_type,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gftt', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj)
          if (obj.test_exists != undefined) {
            if (obj.test_exists == false) {
              if (event == true) {
                if (this.test_exists == false) {
                  var results, presults;
                  results = this.finekeyInArray_addTest(this.selected_profiles, id);
                  presults = this.finekeyInArray_addTest(this.selected_packages, id);
                  if (results != true && presults != true) {
                    if (testdept == 'labtest') {
                      for (var k = 0; k < this.labTest_array.length; k++) {
                        if (this.labTest_array[k].diag_tests_id == id) {
                          this.labTest_array[k].checked = true;
                        }
                      }
                    } else if (testdept == 'radiotest') {
                      for (var k = 0; k < this.radioTest_array.length; k++) {
                        if (this.radioTest_array[k].diag_tests_id == id) {
                          this.radioTest_array[k].checked = true;
                        }
                      }
                    } else if (testdept == 'microtest') {
                      for (var k = 0; k < this.microTest_array.length; k++) {
                        if (this.microTest_array[k].diag_tests_id == id) {
                          this.microTest_array[k].checked = true;
                        }
                      }
                    } else {
                      for (var k = 0; k < this.profiles.length; k++) {
                        if (this.profiles[k].diag_tests_id == id) {
                          this.profiles[k].checked = true;
                        }
                      }
                    }

                    var Tat_val
                    if (subtest.total_TAT != undefined && subtest.total_TAT != null) {
                      Tat_val = 'TAT - ' + subtest.total_TAT;
                    } else {
                      Tat_val = '';
                    }

                    if (subtest.department == "Laboratory") {
                      // new array
                      this.labarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        TAT: Tat_val
                      })
                    } else {
                      this.radioarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        TAT: Tat_val
                      })
                    }
                    console.log("New Arrays", this.labarray, this.radioarray)

                  } else {
                    this.toastr.error("Selected test is already added in selected profile or package", null, { timeOut: 3000 });
                  }
                } else {
                  this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
                }
              } else if (event == false) {

                if (testdept == 'labtest') {
                  for (var k = 0; k < this.labTest_array.length; k++) {
                    if (this.labTest_array[k].diag_tests_id == id) {
                      this.labTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'radiotest') {
                  for (var k = 0; k < this.radioTest_array.length; k++) {
                    if (this.radioTest_array[k].diag_tests_id == id) {
                      this.radioTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'microtest') {
                  for (var k = 0; k < this.microTest_array.length; k++) {
                    if (this.microTest_array[k].diag_tests_id == id) {
                      this.microTest_array[k].checked = false;
                    }
                  }
                } else {
                  for (var k = 0; k < this.profiles.length; k++) {
                    if (this.profiles[k].diag_tests_id == id) {
                      this.profiles[k].checked = false;
                    }
                  }
                }

                for (var h = 0; h < this.labarray.length; h++) {
                  if (this.labarray[h].test_id == id) {
                    this.labarray.splice(h, 1);
                  }
                }

                for (var h = 0; h < this.radioarray.length; h++) {
                  if (this.radioarray[h].test_id == id) {
                    this.radioarray.splice(h, 1);
                  }
                }
              }

              console.log("New Arrays", this.labarray, this.radioarray)
            } else {
              this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
            }
          }
        }, error => { });
  }

  changeTestorPack(type) {
    if (type == "test") {
      this.testFlag = true;
      this.packageFlag = false;
      this.profileFlag = false;
      this.saveTestDetails();
      this.diagTestGroup = "test"
    } else if (type == "package") {
      this.testFlag = false;
      this.packageFlag = true;
      this.profileFlag = false;
      this.saveTestDetails();
      this.diagTestGroup = "package"

      if (this.diagTestGroup == "package") {
        if (this.testGroupArray.length == 0) {
          this.getTestGroups();
        }
        this.diagtest_flag = true;
      } else {
        this.getDiagTestTypes();
        this.diagtest_flag = false;
      }
    } else {
      this.testFlag = false;
      this.packageFlag = false;
      this.profileFlag = true;
      this.diagTestGroup = "test"
    }
  }

  datalist(pat_type) {
    const dialogRef2 = this.dialog.open(DiagnosispopupComponentComponent, {
      width: '700px',
      height: '700px',
      data: {
        hptl_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
        hosp_diag: "diagnois",
        patient_type: pat_type,
      }
    });
    dialogRef2.afterClosed().subscribe(result => {
      console.log("resul  yyugjhb" + JSON.stringify(result))
      if (result != null) {
        console.log("resul  yyugjhb" + JSON.stringify(result))
        this.clientId = result.patient_id,
          this.relationId = "1",
          this.firstNamePatient1 = result.patientname
        var send_data = {
          clientId: result.patient_id,
          relationId: "1",
          firstNamePatient1: result.patientname
        }
        this.retrieveClient(send_data, '');

      }
    });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }

  getPatientList() {//get patient list
    var isInpat;
    if (this.inpatient == true) {
      isInpat = "1";
    } else {
      isInpat = "0";
    }
    console.log("pa  list" + JSON.stringify({
      hptl_clinic_id: this.userinfo.hospitals[0].hptl_clinic_id,
      country: ipaddress.country_code,
      imei: ipaddress.getIp,
      limit: 200,
      filterby: "Patient Id",
      type: "front-desk",
      start_no: this.currentLimit,
      prov_id: this.userinfo.user_id,
      isInpatient: isInpat,
    }))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
      {
        hptl_clinic_id: this.userinfo.hospitals[0].hptl_clinic_id,
        country: ipaddress.country_code,
        imei: ipaddress.getIp,
        limit: 200,
        filterby: "Patient Id",
        type: "front-desk",
        start_no: this.currentLimit,
        prov_id: this.userinfo.user_id,
        isInpatient: isInpat,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("gac url" + JSON.stringify(obj))
          if (obj.clients != null) {
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }
              if (obj.clients[i].pres_diag_id != undefined) {
                var pres_diag_id = obj.clients[i].pres_diag_id
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id,
                pres_diag_id: obj.clients[i].pres_diag_id
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id,
                pres_diag_id: obj.clients[i].pres_diag_id
              });
            }
            Helper_Class.setPatientList(this.patientList);
          }
          console.log("verify this &&7" + JSON.stringify(this.filteredArr))
        },
        error => { });
  }

  tableFilter(e) {
    this.patientName = e;
    if (this.patientName != undefined) {
      this.filteredArr = this.patientList.filter(item => item.full_name.toLowerCase().includes(this.patientName.toLowerCase()));
    }
  }

  getTestByName(data, testName, testType) {
    var order_by;
    if (this.showTest_type == "0") {
      order_by = "sub_dept";
    } else {
      order_by = "test_type";
    }

    var send_data;
    if (testName.length > 2) {
      send_data = {
        orderby: order_by,
        test_name: testName,
        test_type_id: testType,
        diag_centre_id: this.diagId,
      }

    } else {
      send_data = {
        orderby: order_by,
        test_type_id: testType,
        diag_centre_id: this.diagId,
      }
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gtbc/', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("VALUES ARE " + JSON.stringify(obj))
          for (var i = 0; i < this.Blood_test.length; i++) {
            if (testType == this.Blood_test[i].test_type_id) {
              this.Blood_test[i].sub_tests = obj.diag_tests;
            }
          }
        });
  }

  mainFilter(testName) {
    this.filterLabArray(testName)
    this.filterRadioArray(testName)
    this.filterMicroArray(testName)
    this.filterProfileArray(testName)
    this.filterPackageArray(testName)
  }

  filterLabArray(LabtestName) {
    this.filteredLabArray = this.labTest_array.filter(item =>
      item.test_name.toLowerCase().includes(LabtestName.toLowerCase())
    );
  }

  filterRadioArray(radiotestName) {
    this.filteredRadioArray = this.radioTest_array.filter(item =>
      item.test_name.toLowerCase().includes(radiotestName.toLowerCase())
    );
  }

  filterMicroArray(microtestName) {
    this.filteredMicroArray = this.microTest_array.filter(item =>
      item.test_name.toLowerCase().includes(microtestName.toLowerCase())
    );
  }

  filterProfileArray(profileName) {
    this.filteredProfilesArray = this.profiles.filter(item =>
      item.profile_name.toLowerCase().includes(profileName.toLowerCase())
    );
  }

  filterPackageArray(packageName) {
    this.filteredPackagesArray = this.packages_array.filter(item =>
      item.package_name.toLowerCase().includes(packageName.toLowerCase())
    );
  }
}
