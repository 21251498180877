<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Estimate</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backtoApplist('view')" *ngIf="!billEstBackButtonFlag"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" style="cursor: pointer;" /></a>&nbsp;
            <a (click)="backtoApplist('est_list')" *ngIf="billEstBackButtonFlag"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" style="cursor: pointer;" /></a><span *ngIf="billEstBackButtonFlag">&nbsp;</span>
            <a *ngIf="printbutton && billEstUpdateButtonFlag" (click)="print_area()" ><img src="../../../assets/img/printer.svg" 
              width="25px" height="25px" style="cursor: pointer;" /></a>&nbsp;
            <a *ngIf="newbutton && !billEstUpdateButtonFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
              class="saveimgbtn_inpatinfo" style="margin-left: 7px;" style="cursor: pointer;" /></a> <span *ngIf="!billEstUpdateButtonFlag">&nbsp;</span>
            <a *ngIf="newbutton && billEstUpdateButtonFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/update_button.svg"
              class="saveimgbtn_inpatinfo" style="margin-left: 7px;" style="cursor: pointer;" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="padding-top: 5px;">
        <div *ngIf="!listview">
          <div class="col-12" *ngIf="createpage_flag"> 
            <div class="cover_div" *ngFor="let billprint of billing_print_array" style="margin-top: 0px !important;">
              <div class="header_lable">Patient details</div>
                <div class="content_cover">             
                  <div class="row">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="profileimage">
                    </div>
                    <div class="col-10">
                      <div class="row">                                                       
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>MR No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mrno}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Patient</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{clnt_saldesc}}{{patient_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Age/Gender</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Mobile</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>      
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Doctor</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{doctor_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> 
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Inpatient Id</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inp_hosp_id}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>DOA</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3" *ngIf="payment_mode != undefined && payment_mode != '' && insurance_flag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Pay mode</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{payment_mode}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> 
                        <div class="col-3" *ngIf="insurer != undefined && insurer != '' && insurance_flag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Ins Comp</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{insurer}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3" *ngIf="bill_type_pharma != undefined && bill_type_pharma != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill type</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{bill_type_pharma}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>          
                        <div class="col-3" *ngIf="e_contact != '' && e_contact != undefined">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Emg con</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{e_contact}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>  
                        <div class="col-3" *ngIf="bill_est_id != undefined && bill_est_id != '' && billEstUpdateButtonFlag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Estimate Bill No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{bill_est_id}} 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Ward/Bed</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{wardName}} <span *ngIf="bed_no != '' && bed_no != undefined">/</span> {{bed_no}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                                                                
                        <div class="col-6">
                          <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                    <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Address</b></td>
                                    <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.address1}} {{billprint.address2}} {{billprint.location}} {{billprint.city}} {{billprint.state}}
                                  </td>
                                </tr>                 
                              </tbody>
                          </table>
                        </div>
                      </div>      
                    </div>
                  </div>               
                </div>
            </div>
          </div>  
          <div class="col-12" *ngIf="createpage_flag">
            <div class="cover_div">
              <div class="header_label">Estimate Details</div>
              <div class="content_cover">
                <div class="row section">
                  <div class="col-12">
                    <div class="label-row">
                      <mat-label class="section-label">Type of Admission:</mat-label>
                      <div class="checkbox-group">
                        <mat-label class="checkbox-label">
                          Medical
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isAdmissionTypeChecked('medical')"
                            (change)="onAdmissionTypeChange('medical')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Surgical
                          <mat-checkbox 
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isAdmissionTypeChecked('surgical')"
                            (change)="onAdmissionTypeChange('surgical')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          HD
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isAdmissionTypeChecked('hd')"
                            (change)="onAdmissionTypeChange('hd')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Day care
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isAdmissionTypeChecked('day_care')"
                            (change)="onAdmissionTypeChange('day_care')">
                          </mat-checkbox>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row section">
                  <div class="col-12">
                    <div class="label-row">
                      <mat-label class="section-label">Medical:</mat-label>
                      <div class="checkbox-group">
                        <mat-label class="checkbox-label">
                          Fever
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isChecked('fever')"
                            (change)="onCheckboxChange('fever')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Pain
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isChecked('pain')"
                            (change)="onCheckboxChange('pain')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Breathing
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isChecked('breathing')"
                            (change)="onCheckboxChange('breathing')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Giddiness
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isChecked('giddiness')"
                            (change)="onCheckboxChange('giddiness')">
                          </mat-checkbox>
                        </mat-label>
                        <mat-label class="checkbox-label">
                          Swelling
                          <mat-checkbox
                            style="padding-left: 4px;"
                            color="primary"
                            [checked]="isChecked('swelling')"
                            (change)="onCheckboxChange('swelling')">
                          </mat-checkbox>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row section">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Ward<br>
                      <select class="input-field" [(ngModel)]="ward_id">
                        <option *ngFor="let ward of ward_list" value={{ward.ward_id}}>
                          {{ward.ward_name}}
                        </option>
                      </select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Day Count<br>
                      <input type="text" class="input-field" [(ngModel)]="day_count" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Room Rent (1 * days)<br>
                      <input type="text" class="input-field" [(ngModel)]="room_rent" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Dr. Fees<br>
                      <input type="text" class="input-field" [(ngModel)]="dr_fees" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Procedures<br>
                      <input type="text" class="input-field" [(ngModel)]="procedures" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Diagnostics<br>
                      <input type="text" class="input-field" [(ngModel)]="diagnostics" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Dietary<br>
                      <input type="text" class="input-field" [(ngModel)]="Dietry" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <mat-label class="input-label">Total<br>
                      <input type="text" class="input-field" [(ngModel)]="total" />
                    </mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="listview">
          <div class="row" style="margin-top: -10px">
            <div class="col-10">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 select_bottom">
                  <mat-label class="matlabel">Doctor<br>
                    <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)" [(ngModel)]="doctor">
                      <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">View<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="viewChange(view_type)"
                      [(ngModel)]="view_type">
                      <option value="graph">Graphical</option>                
                      <option value="tabular">Tabular</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">
                    <div class="flex-container">
                      <div class="label">Floor</div>
                      <select class="ipcss widthappt" (ngModelChange)="changefloorvalue($event)" [(ngModel)]="floorvalue">
                        <option *ngFor="let floorcheck of floorarray" [value]="floorcheck">{{ floorcheck }}</option>
                      </select>
                    </div>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">Filter by<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="Filterbydata(selectvalue)"
                      [(ngModel)]="selectvalue" >
                      <option value="mrno">MR no</option>
                      <option value="date">Date</option>
                      <option value="name">Name</option>
                      <option value="location">Location</option>                   
                      <option value="mobile">Mobile</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                  <mat-label class="matlabel">MR no<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mrno" matInput (keyup)="getdatavaluemrno($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mobileflag">
                  <mat-label class="matlabel">Mobile<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mobile" matInput (keyup)="getdatamobilenumber($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                  <mat-label class="matlabel">Name<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="name" matInput (blur)="mrnobased()"  (keyup)="getdatavalue($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="locationflag">
                  <mat-label class="matlabel">Location<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="location"  matInput  (keyup)="getdatavaluelocation($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="fdateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">From&nbsp;</mat-label>
                    <input type="date"  (change)="selectFromDate(fromDate)" class="ipcss" id="appt_date" [(ngModel)]="fromDate" #matInput
                      max="{{currentMaxDate}}"  >
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">To&nbsp;</mat-label>
                    <input type="date" (change)="selectToDate(toDate)" class="ipcss"  id="appt_date"  [(ngModel)]="toDate" #matInput
                      max="{{currentMaxDate}}" >
                  </div>
                </div>
                <div class="col-1 d-flex justify-content-start" style="margin-top: 23px !important;" *ngIf="nameflag || locationflag || todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <a (click)="filtercheck()">
                    <img src="../../../assets/ui_icons/search_icon_new.svg" width='18px' height="auto" style="cursor: pointer;" class="topvalue" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" style="text-align: right;margin-top: 23px !important;">
              <p class="textformat"> Count: <strong>{{count}}</strong></p>
            </div>             
          </div>          
          <div *ngIf="tabular_format">
            <p class="nodata" *ngIf="patientList.length == 0">No Patient(s) found</p>
            <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>MR no</th>
                  <th>Inpatient id</th>                
                  <th style="text-align: center !important;">Patient name</th>
                  <th>Speciality</th>
                  <th>Admitted on</th>
                  <th>Surgery date</th>
                  <th>Ward</th>
                  <th>MLC case</th>
                  <th style="width: 5%;">                  
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of patientList; let i = index">
                  <td (click)='viewPatient(person)'>
                    <img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>               
                  <td (click)='viewPatient(person)'>
                    <p class="ptagvalue">{{person.patient_id}}</p></td>           
                  <td (click)='viewPatient(person)' 
                    style="font-size: 12px;">{{ person.hosp_inp_id }}</td>                 
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>                  
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;text-align: left !important;">{{ person.speciality }}</td>                 
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;">{{ person.admit_date }}</td>               
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;">{{ person.surgery_date }}</td>
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
                  <td (click)='viewPatient(person)'
                    style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.mlc }}</td>
                  <td style="font-size: 12px;width: 5%;">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="graphicalformat">
            <p class="nodata" *ngIf="patientList.length == 0">No Patient(s) found</p>
            <div class="row">
              <div class="col-2" *ngFor="let person of patientList" style="text-align: center;">
                <div
                  (click)='viewPatient(person)'>
                  <div class="bed_bed_no">{{person.patient_name}}</div>
                  <div class="bed_bed_no1">{{person.bed_no}}</div>
                  <div><img src="{{person.bed_img}}" class="bed_img">
                  </div>
                  <div class="bed_bed_name d-flex justify-content-center">
                    {{person.ward_text}}</div>
                  <div class="bed_bed_name1 d-flex justify-content-center">
                    {{person.hosp_inp_id}}</div>
                  <div class="bed_bed_name2 d-flex justify-content-center">
                    {{person.admit_date}}</div>
                  <div class="bed_bed_name2 d-flex justify-content-center">
                    {{person.doctor_name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="graph_defaultimg">
            <p class="nodata" *ngIf="patientList == undefined || patientList.length == 0">No Patient(s) found</p>
            <div class="row">
              <div class="col-2 " *ngFor="let person of patientList" style="text-align: center;padding: 10px;">
                <div class="defaultimg">
                  <div class="defaultimg1" 
                    (click)='viewPatient(person)'>
                    <div>
                      <img src="{{person.profile_image}}" class="cycling" />
                    </div>
                    <div>
                      <p class="ptagvalue">{{person.patient_name}}</p>
                      <p class="ptagvalue">{{person.patient_id}}</p>
                      <p class="ptagvalue">{{person.hosp_inp_id}}</p>
                      <p class="ptagvalue">{{person.gender_age}}</p>
                      <p class="ptagvalue">{{person.locationcheck}}/{{person.districtcheck}}</p>
                      <div style="border-top: 1px solid #91badd;">
                        <p class="ptagvalue" style="font-weight: 600; color: red;">{{person.ward_text}}</p>
                      </div>
                      <p class="ptagvalue">ADT: {{person.admitdatetime}}</p>
                      <p class="ptagvalue">{{ person.doctor_name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>   
        </div>
        <div [hidden]="true" #printbanner id="printbanner" *ngFor="let billprint of billing_print_array">
          <table style="border:'0';width:100%;">
            <thead>
              <tr>
                <th style="width:100%;">
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;">
                    <div style="width: 100%;">
                      <img alt="image" src={{hospitalLogo}} [style]="printlogostyle">
                    </div>
                    <div style="width: 100%;">
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 105px;">
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{clnt_saldesc}}{{patient_name}}</td>
                            <td style="padding-left: 5px;"><strong>Inpatient Id</strong></td>
                            <td>: {{billprint.inp_hosp_id}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{clnt_age}} / {{gender}}</td>
                            <td style="padding-left: 5px;"><strong>Admission Date/Time</strong></td>
                            <td>: {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Est No</strong></td>
                            <td>: {{bill_est_id}}</td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{billprint.address1}} {{billprint.address2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{bill_date | date : "dd-MM-yyyy"}} {{time}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>: {{billprint.location}} {{billprint.city}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Ward/bed no</strong></td>
                            <td>: {{wardName}} <span *ngIf="bed_no != '' && bed_no != undefined">/</span> {{bed_no}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>: {{billprint.state}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td></td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;"
                                src={{barcode_url}} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="100%">
                  <p [style]="p_tag_style"><b>Estimate Bill Details</b></p>
                  <div style="margin-left: 20px;margin-right: 30px;margin-top: -10px;">
                    <div class="col-12">
                      <div class="cover_div">
                        <div class="content_cover">
                          <div class="row section">
                            <div class="col-12 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Type of Admission:</span>
                                <span class="value" *ngIf="isAdmissionTypeChecked('medical')">Medical</span>
                                <span class="value" *ngIf="isAdmissionTypeChecked('surgical')">Surgical</span>
                                <span class="value" *ngIf="isAdmissionTypeChecked('hd')">HD</span>
                                <span class="value" *ngIf="isAdmissionTypeChecked('day_care')">Day care</span>
                              </mat-label>
                            </div>
                            <div class="col-12 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Medical:</span>
                                <span class="value" *ngIf="isChecked('fever')">Fever</span>
                                <span class="value" *ngIf="isChecked('pain')">Pain</span>
                                <span class="value" *ngIf="isChecked('breathing')">Breathing</span>
                                <span class="value" *ngIf="isChecked('giddiness')">Giddiness</span>
                                <span class="value" *ngIf="isChecked('swelling')">Swelling</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Ward:</span>
                                <span class="value">{{wardName}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Day Count:</span>
                                <span class="value">{{day_count}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Room Rent(1 * days):</span>
                                <span class="value">{{room_rent}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Dr. Fees:</span>
                                <span class="value">{{dr_fees}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Procedures:</span>
                                <span class="value">{{procedures}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Diagnostics:</span>
                                <span class="value">{{diagnostics}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Dietary:</span>
                                <span class="value">{{Dietry}}</span>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                              <mat-label class="input-label">
                                <span class="section-label">Total:</span>
                                <span class="value">{{total}}</span>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot style="margin-bottom: 10px;">
              <tr>
                <td width="100%">
                  <table style="width: 100%;margin-top: 50px !important;">
                    <tr>
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: left !important;margin-left: 15px;">
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <tr>
                            <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                              <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                            </div>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div [hidden]="true" #printnoheader id="printnoheader" *ngFor="let billprint of billing_print_array">
          <table style="border:'0';width: 100%;">   
            <thead>
              <tr>
                <th>
                  <div style=" flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div style="width: 100%; height: 155px;">
                      <div style="height: 5px !important;"></div>
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 105px;">
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{clnt_saldesc}}{{patient_name}}</td>
                            <td style="padding-left: 5px;"><strong>Inpatient Id</strong></td>
                            <td>: {{billprint.inp_hosp_id}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{clnt_age}} / {{gender}}</td>
                            <td style="padding-left: 5px;"><strong>Admission Date/Time</strong></td>
                            <td>: {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Est No</strong></td>
                            <td>: {{bill_est_id}}</td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{billprint.address1}} {{billprint.address2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{bill_date | date : "dd-MM-yyyy"}} {{time}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>: {{billprint.location}} {{billprint.city}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Ward/bed no</strong></td>
                            <td>: {{wardName}} <span *ngIf="bed_no != '' && bed_no != undefined">/</span> {{bed_no}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>: {{billprint.state}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td></td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;"
                                src={{barcode_url}} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>             
            <tbody>  
            <tr>
              <td width="100%">
                <p [style]="p_tag_style"><b>Estimate Bill Details</b></p>
                <div style="margin-left: 20px;margin-right: 30px;margin-top: -10px;">
                  <div class="col-12">
                    <div class="cover_div">
                      <div class="content_cover">
                        <div class="row section">
                          <div class="col-12 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Type of Admission:</span>
                              <span class="value" *ngIf="isAdmissionTypeChecked('medical')">Medical</span>
                              <span class="value" *ngIf="isAdmissionTypeChecked('surgical')">Surgical</span>
                              <span class="value" *ngIf="isAdmissionTypeChecked('hd')">HD</span>
                              <span class="value" *ngIf="isAdmissionTypeChecked('day_care')">Day care</span>
                            </mat-label>
                          </div>
                          <div class="col-12 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Medical:</span>
                              <span class="value" *ngIf="isChecked('fever')">Fever</span>
                              <span class="value" *ngIf="isChecked('pain')">Pain</span>
                              <span class="value" *ngIf="isChecked('breathing')">Breathing</span>
                              <span class="value" *ngIf="isChecked('giddiness')">Giddiness</span>
                              <span class="value" *ngIf="isChecked('swelling')">Swelling</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Ward:</span>
                              <span class="value">{{wardName}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Day Count:</span>
                              <span class="value">{{day_count}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Room Rent(1 * days):</span>
                              <span class="value">{{room_rent}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Dr. Fees:</span>
                              <span class="value">{{dr_fees}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Procedures:</span>
                              <span class="value">{{procedures}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Diagnostics:</span>
                              <span class="value">{{diagnostics}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Dietary:</span>
                              <span class="value">{{Dietry}}</span>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 padding">
                            <mat-label class="input-label">
                              <span class="section-label">Total:</span>
                              <span class="value">{{total}}</span>
                            </mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;margin-top: 50px !important;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
          </tfoot>
          </table>
        </div> 
      </mdb-card-body>
    </mdb-card>
  </div>
</div>


