import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../../assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ChangeDetectorRef } from '@angular/core';
import { error } from 'console';

@Component({
  selector: 'app-pharma-supplier-payment',
  templateUrl: './pharma-supplier-payment.component.html',
  styleUrls: ['./pharma-supplier-payment.component.scss']
})
export class PharmaSupplierPaymentComponent implements OnInit {
  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public invoicedetails: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public suppId;
  public fromDate;
  public toDate;
  public currentDate;
  public currDate;
  public totalAmount;
  public pendingAmount;
  private tmr = new Date();
  public supplierList: any = [];
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public cardFlag: boolean;
  public chequeFlag: boolean;
  public transactionFlag: boolean;
  public insurFlag: boolean;
  public showpayflag: boolean = true;
  public showimage: boolean = false;
  public showarray: boolean = true;
  public paid_amount: number = 0;
  public payTypeList: any = [];
  public insurerList: any = [];
  public payHistList: any = [];
  public payType;
  public insurerId;
  public cardHolderName;
  public transactionId;
  public cardNumber
  public bankName;
  private closeResult;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public paytotal = 0;
  public paymentcheckbox;
  public accepted: Boolean;
  public checkboxflag: Boolean;
  public selectedInvoice: any = [];
  public rearrangeddata: any = [];
  public filterby: string;
  public pagetype: string;
  public reporttype: string;
  public url: string;
  public hsp_id: string;
  public conddocdata: any = [];
  public selectedarray: any = [];
  public username;
  selectedDuration: string = 'select';
  public docbutton: boolean;

  constructor(public http: HttpClient, public routes: ActivatedRoute,
    public router: Router, public toastr: ToastrService, private modalService: NgbModal, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.checkboxflag = false
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'pharmacy') {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.userInfo.user_id;

    } else {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
    }
   
    this.getSuppliers();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      info: false,
      "lengthMenu": [[25, 50, 100, -1], [25, 50, 100, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],

      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search invoice, date, or due date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (this.userInfo.user_type == "Admin") {
      this.editbutton = true;
    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "60") {

            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }
      }
    }
    if (Helper_Class.getInfo().middle_name != undefined) {
      this.username = Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().middle_name + " " + Helper_Class.getInfo().last_name;
    } else {
      this.username = Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().last_name;
    }
    this.reporttype = Helper_Class.getReportType()
    if (this.reporttype == 'doc_payment') {
      this.pagetype = 'Doctor payments';
      this.url = 'misr/gcdor';
      this.hsp_id =
        this.userInfo.user_type == 'Admin' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'front-desk' ?
          this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'Accounts' ? this.userInfo.hospitals[0].hptl_clinic_id :
            this.userInfo.user_type == 'Billing' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'doctor' ?
              this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.hospitals[0].hosp_id
      this.getPaymentType();
    } else {
      this.pagetype = ' Supplier payments';
      this.url = '';

    }
  }

  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
    this.getInvoiceDetails()
  }

  getSuppliers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/", {
      pharmacy_id: this.pharmacyID,
    }, { headers: headers }).subscribe(
      data => {
        this.supplierList = [];
        var suppliers = JSON.parse(JSON.stringify(data))
        this.supplierList.push({
          supp_id: 'All',
          name: 'All'
        })
        if (suppliers.supplier_details != null && suppliers.supplier_details.length != 0) {
          for (var i = 0; i < suppliers.supplier_details.length; i++) {
            this.supplierList.push({
              supp_id: suppliers.supplier_details[i].supp_id,
              name: suppliers.supplier_details[i].name,
            })
          }
          // this.supplierList = suppliers.supplier_details;
          this.suppId = this.supplierList[0].supp_id;
        }
        this.getDate();
      },
      error => {
        this.toastr.error(Message_data.unableToFetchsupp);
      }
    )
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.currDate = obj.current_date;

          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getInvoiceDetails();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
    this.getInvoiceDetails()
  }

  getInvoiceDetails() {
    var send_data;
    if (this.reporttype == 'doc_payment') {
      send_data = {
        hptl_clinic_id: this.hsp_id,
        from_date: this.fromDate,
        to_date: this.toDate
      }
      console.log("send data" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.url, send_data, { headers: headers }).subscribe(data => {
        var dataval = JSON.parse(JSON.stringify(data));
        console.log("dataval = " + JSON.stringify(dataval))
        this.conddocdata = [];
        this.selectedarray = []
        var docdata = dataval.conduction_log
        if (docdata != undefined) {
          if (docdata.length != 0) {
            let serialNumber = 1;
            docdata.forEach(docdata => {
              var docname, patientname;
              if (docdata.first_name != undefined) {
                if (docdata.middle_name != undefined) {
                  patientname = encrypt_decript.Decript(docdata.first_name) + " " + encrypt_decript.Decript(docdata.middle_name) + " " + encrypt_decript.Decript(docdata.last_name);
                } else {
                  patientname = encrypt_decript.Decript(docdata.first_name) + " " + encrypt_decript.Decript(docdata.last_name);
                }
              } else {
                patientname = ''
              }
              if (docdata.dr_first_name != undefined) {
                if (docdata.dr_middle_name != undefined) {
                  docname = docdata.dr_first_name + " " + docdata.dr_middle_name + " " + docdata.dr_last_name;
                } else {
                  docname = docdata.dr_first_name + " " + docdata.dr_last_name;
                }
              } else {
                docname = ''
              }
              docdata.paid_date = docdata.paid_date != undefined && docdata.paid_date != "" ? Date_Formate(docdata.paid_date) : "";
              docdata.paid_time = docdata.paid_time != undefined && docdata.paid_time != "" ? Time_Formate(docdata.paid_time) : "";
              docdata.balance = docdata.balance != undefined && docdata.balance != "" ? parseFloat(docdata.balance) : "0.00";
              docdata.paid_amount = docdata.paid_amount != undefined && docdata.paid_amount != "" ? parseFloat(docdata.paid_amount) : "0.00";
              docdata.test_cost = docdata.test_cost != undefined && docdata.test_cost != "" ? parseFloat(docdata.test_cost) : "0.00";
              docdata.paid_by = docdata.paid_by != undefined && docdata.paid_by != "" ? docdata.paid_by : "";
              docdata.disabledcheckbox = docdata.paid_flag == "Fully paid" ? true : docdata.paid_flag == 'fully paid' ? true : docdata.paid_flag == 'paid' ? true : docdata.paid_flag == 'Paid' ? true : false;

              this.conddocdata.push({
                sn: serialNumber++,
                diag_appointment_id: docdata.diag_appointment_id,
                doc_reg_id: docdata.doc_reg_id,
                test_name: docdata.test_name,
                test_id: docdata.test_id,
                name: patientname,
                docname: docname,
                paid_amount: docdata.paid_amount,
                balance: docdata.balance,
                actual_balance: docdata.balance,
                actual_paid_amount: docdata.paid_amount,
                paid_by: docdata.paid_by,
                paid_date: docdata.paid_date + " " + docdata.paid_time,
                test_cost: docdata.test_cost,
                conduct_date: Date_Formate(docdata.approved_date),
                conduct_time: Time_Formate(docdata.approved_time),
                pay_mode: docdata.pay_mode,
                payment_mode: docdata.payment_mode,
                paid_flag: docdata.paid_flag || '',
                actual_paid_flag: docdata.paid_flag,
                disabledcheckbox: docdata.disabledcheckbox,
                paymentcheckbox: docdata.disabledcheckbox,
                type:docdata.type,
                mr_no:docdata.mr_no,
              })
            })
            this.docbutton = true;
          } else {
            this.docbutton = false;
          }
        } else {
          this.docbutton = false;
        }
        console.log("conduction_doctor = " + JSON.stringify(this.conddocdata))
      }, error => { })
    } else {
      send_data = {
        pharma_id: this.pharmacyID,
        supp_id: this.suppId,
        from_date: this.fromDate,
        to_date: this.toDate,
      }
      console.log("send data" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/gidps', {
        pharma_id: this.pharmacyID,
        supp_id: this.suppId,
        from_date: this.fromDate,
        to_date: this.toDate,
      },
        { headers: headers })
        .subscribe(
          response => {
            this.invoicedetails = [];

            var obj = JSON.parse(JSON.stringify(response));
            console.log("object = " + JSON.stringify(obj))

            if (obj != undefined && obj.invoice_details.length != 0) {
              this.showpayflag = true;
              this.totalAmount = (obj.inv_amount).toFixed(2);
              this.pendingAmount = (obj.balance).toFixed(2);
              for (var i = 0; i < obj.invoice_details.length; i++) {
                var balance = 0;
                var paidflag;

                if (obj.invoice_details[i].payment != undefined) {

                  balance = obj.invoice_details[i].invoice_amount - obj.invoice_details[i].payment;
                  paidflag = obj.invoice_details[i].paid_flag;
                } else {
                  balance = parseInt(obj.invoice_details[i].invoice_amount);
                  paidflag = "Unpaid";
                }

                var invdate = obj.invoice_details[i].invoice_date.split("-");
                var duedate = obj.invoice_details[i].inv_due_date.split("-");
                var dueflag;
                if ((obj.invoice_details[i].inv_due_date <= this.currDate) && balance != 0) {
                  dueflag = 1;
                } else {
                  dueflag = 0;
                }

                if (obj.invoice_details[i].invoice_date != undefined && this.currDate != undefined) {
                  var currentDate = new Date(this.currDate);
                  var invoiceDate = new Date(obj.invoice_details[i].invoice_date);
                  // Calculate the difference in milliseconds
                  var timeDifference = currentDate.getTime() - invoiceDate.getTime();
                  // Convert the difference to days
                  var daysDifference = timeDifference / (1000 * 60 * 60 * 24);
                }

                if (obj.invoice_details[i].inv_due_date !== undefined && this.currDate !== undefined) {
                  var invDueDate_inv_due_date = new Date(obj.invoice_details[i].inv_due_date);
                  var currentDate_inv_due_date = new Date(this.currDate);
                  var timeDifference_inv_due_date = invDueDate_inv_due_date.getTime() - currentDate_inv_due_date.getTime();
                  var daysDifference_inv_due_date = timeDifference_inv_due_date / (1000 * 60 * 60 * 24);
                  obj.invoice_details[i].diff = daysDifference_inv_due_date;
                }

                var disableflag;
                if (balance != 0) {
                  disableflag = false;

                } else {
                  disableflag = true;
                }

                if (obj.pay_history != undefined) {
                  this.payHistList = obj.pay_history;
                  this.showimage = true;
                }

                obj.invoice_details[i].paymentcheckbox = false;

                this.invoicedetails.push({
                  receivables_id: obj.invoice_details[i].receivables_id,
                  supp_id: obj.invoice_details[i].supp_id,
                  name: obj.invoice_details[i].name,
                  invoice_no: obj.invoice_details[i].invoice_no,
                  invoice_date: invdate[2] + "-" + invdate[1] + "-" + invdate[0],
                  inv_due_date: duedate[2] + "-" + duedate[1] + "-" + duedate[0],
                  invoice_amount: obj.invoice_details[i].invoice_amount,
                  payment_date: obj.invoice_details[i].payment_date,
                  payment: obj.invoice_details[i].payment,
                  balance: balance,
                  balance_actual: balance,
                  paid_flag: paidflag,
                  dueflag: dueflag,
                  paid_amount: "0",
                  disableflag: disableflag,
                  lag: daysDifference,
                  invdiffdays: obj.invoice_details[i].diff,
                  paymentcheckbox: obj.invoice_details[i].paymentcheckbox,
                  prevamt: "0",
                })
              }
            }
            this.getPaymentType();
            this.getInsurers();

          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }

  }

  calculateDaysDifference(inv_due_date: Date): number {
    var currentDate = new Date(this.currDate);
    var timeDifference = inv_due_date.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }

  showPaumentHistory(content: any) {
    this.showarray = !this.showarray;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {

      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';

    } else {
      return `with: 140%`;
    }
  }

  calculatePaidAmount(invoice) {
    console.log(invoice.paid_amount)
    console.log("invoice_claculate_start" + JSON.stringify(invoice) + "selectedInvoice_claculate_start" + JSON.stringify(this.selectedInvoice))
    for (var i = 0; i < this.invoicedetails.length; i++) {

      this.showpayflag = false;
      this.insurFlag = true;
      this.cardFlag = true;
      this.cardFlag = true;
      this.transactionFlag = true;
      this.chequeFlag = true;
      this.chequeFlag = true;
      console.log("2" + invoice.paid_amount)
      if (this.invoicedetails[i].receivables_id == invoice.receivables_id) {
        console.log("invoicedetails" + JSON.stringify(this.invoicedetails[i]))
        var prevpaidamt = this.invoicedetails[i].prevamt;
        if (invoice.paymentcheckbox == false) {

          if (invoice.paid_amount != 0) {
            invoice.paymentcheckbox = true;
            this.suppId = invoice.supp_id
            this.selectedInvoice.push(invoice);
          } else if (invoice.paid_amount == 0) {
            invoice.paymentcheckbox = false;
          }
        }

        if (invoice.paid_amount == "") {
          this.invoicedetails[i].paid_amount = "0";
        }

        var bal;

        if (invoice.payment != undefined) {
          if (invoice.paid_amount > invoice.balance) {
            bal = 0
          } else {
            bal = parseInt(this.invoicedetails[i].invoice_amount) - (parseInt(invoice.payment) + parseInt(invoice.paid_amount));
          }
        } else {
          if (invoice.paid_amount > invoice.balance) {
            bal = 0
          } else {
            bal = parseInt(this.invoicedetails[i].invoice_amount) - (parseInt(invoice.paid_amount));
          }
        }
        console.log("THE CHECK BOX " + invoice.paymentcheckbox)
        console.log("3" + invoice.paid_amount)
        if (invoice.paymentcheckbox == true) {
          console.log("provided ===== " + this.invoicedetails[i].prevamt + " === " + invoice.paid_amount);
          if (invoice.paid_amount > invoice.balance) {
            // this.paid_amount -= parseInt(prevpaidamt);
            this.invoicedetails[i].paid_amount = invoice.balance;
            this.paid_amount += invoice.balance;
            this.invoicedetails[i].balance = bal;
            this.invoicedetails[i].prevamt = parseInt(invoice.balance);
          } else {
            //  this.paid_amount -= parseInt(prevpaidamt);
            this.paid_amount += parseFloat(invoice.paid_amount);
            console.log("4" + this.paid_amount)
            this.invoicedetails[i].balance = bal;
            this.invoicedetails[i].prevamt = parseInt(invoice.paid_amount);
            this.invoicedetails[i].paid_amount = invoice.paid_amount;
          }

        } else if (invoice.paymentcheckbox == false && invoice.paid_amount != 0) {
          this.paid_amount -= parseInt(invoice.paid_amount);
          this.invoicedetails[i].balance = invoice.paid_amount;
          invoice.paid_amount = 0
        } else {
          // this.paid_amount -= parseInt(invoice.paid_amount);
          this.invoicedetails[i].balance = invoice.balance_actual;
          invoice.paid_amount = 0
        }
        if (bal == 0) {
          this.invoicedetails[i].paid_flag = "Fully paid";

        } else if (bal == this.invoicedetails[i].invoice_amount) {
          this.invoicedetails[i].paid_flag = "Unpaid";

        } else {
          this.invoicedetails[i].paid_flag = "Partially paid";
        }
      }
    }
    this.checkboxflag = true;
    console.log("selectedInvoice_claculate_end" + JSON.stringify(this.selectedInvoice))
  }

  getExcelData(data) {
    var excel_data = [];
    var header = ['Supplier', 'Invoice', 'Invoice date', 'Invoice amount', 'Paid amount', 'Balance', 'Status']
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Stock status report');
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let invoice of this.invoicedetails) {
      excel_data.push({
        name: invoice.name,
        invoice_no: invoice.invoice_no,
        invoice_date: invoice.invoice_date,
        invoice_amount: invoice.invoice_amount,
        paid_amount: invoice.paid_amount,
        balance: invoice.balance,
        paid_flag: invoice.paid_flag
      })
    }
    for (let x1 of excel_data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "supplier_payments" + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  save_data() {
    var send_data;
    if (this.reporttype == 'doc_payment') {

      var paymentarray = [];

      for (var d = 0; d < this.selectedarray.length; d++) {
        paymentarray.push({
          doc_reg_id: this.selectedarray[d].doc_reg_id,
          diag_app_id: this.selectedarray[d].diag_appointment_id,
          test_id: this.selectedarray[d].test_id,
          amount: this.selectedarray[d].test_cost,
          payment_mode: this.selectedarray[d].payment_mode,
          paid_amount: this.selectedarray[d].paid_amount,
          balance: this.selectedarray[d].balance,
          paid_flag: this.selectedarray[d].paid_flag,
          paid_by: this.username,
          country: ipaddress.country_code,
        })
      }

      send_data = {
        payments: paymentarray,
        country: ipaddress.country_code,
      }
      console.log("doc_payment_save_data = " + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/scdp', send_data, { headers: headers }).subscribe(response => {
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.key == "1") {
          this.toastr.success("Saved successfully")
          this.selectedarray = []
          this.getInvoiceDetails()
        } else {
          this.toastr.error("Error in update")
        }
      }, error => {
      });
    } else {
      this.selectedInvoice.forEach(invoice => {
        const supplierIndex = this.rearrangeddata.findIndex(supplier => supplier.supplier_id == invoice.supp_id)
        if (supplierIndex === -1) {
          this.rearrangeddata.push({
            supplier_id: invoice.supp_id,
            pharma_id: this.pharmacyID,
            amount: parseFloat(invoice.paid_amount),
            pay_type: this.payType,
            insurer: this.insurerId,
            card_no: this.cardNumber,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionId,
            bank_name: this.bankName,
            payments: [{
              receivables_id: invoice.receivables_id,
              supp_id: invoice.supp_id,
              name: invoice.name,
              invoice_no: invoice.invoice_no,
              invoice_date: invoice.invoice_date,
              inv_due_date: invoice.inv_due_date,
              invoice_amount: invoice.invoice_amount,
              balance: invoice.balance,
              balance_actual: invoice.balance_actual,
              paid_flag: invoice.paid_flag,
              dueflag: invoice.dueflag,
              paid_amount: invoice.paid_amount,
              disableflag: invoice.disableflag,
              lag: invoice.lag,
              invdiffdays: invoice.invdiffdays,
              paymentcheckbox: invoice.paymentcheckbox,
              prevamt: invoice.prevamt
            }],
            country: ipaddress.country_code,
          })
        } else {
          this.rearrangeddata[supplierIndex].amount += parseFloat(invoice.paid_amount)
          this.rearrangeddata[supplierIndex].payments.push({
            receivables_id: invoice.receivables_id,
            supp_id: invoice.supp_id,
            name: invoice.name,
            invoice_no: invoice.invoice_no,
            invoice_date: invoice.invoice_date,
            inv_due_date: invoice.inv_due_date,
            invoice_amount: invoice.invoice_amount,
            balance: invoice.balance,
            balance_actual: invoice.balance_actual,
            paid_flag: invoice.paid_flag,
            dueflag: invoice.dueflag,
            paid_amount: invoice.paid_amount,
            disableflag: invoice.disableflag,
            lag: invoice.lag,
            invdiffdays: invoice.invdiffdays,
            paymentcheckbox: invoice.paymentcheckbox,
            prevamt: invoice.prevamt
          })
        }
      })

      send_data = {
        supp_payment: this.rearrangeddata
      }
      console.log("updated_data " + JSON.stringify(this.rearrangeddata))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/sspp', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key == "1") {
              this.paid_amount = 0.00;
              this.selectedInvoice = []
              this.cardNumber = "";
              this.cardHolderName = "";
              this.transactionId = "";
              this.bankName = "";
              this.toastr.success("Payment details update successfully");
              this.accepted = false
              this.suppId = this.supplierList[0].supp_id;
              this.getInvoiceDetails()
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }
  }

  pay_change() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4" || this.payType == "5" || this.payType == "6" || this.payType == "9")
      this.transactionFlag = false;
    else if (this.payType == "2") {
      this.transactionFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "7")
      this.chequeFlag = false;
    else
      this.insurFlag = true;
  }

  getInsurers() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
          this.pay_change();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  check_amt_to_payment(event: Event, invoice) {
    var flag = true;
    const checkbox = event.target as HTMLInputElement;
    if (invoice.paymentcheckbox == true) {
      if (this.selectedInvoice.length != 0) {
        if (invoice.supp_id != this.selectedInvoice[0].supp_id) {
          checkbox.checked = true; // Prevent checking the box
          invoice.paymentcheckbox = true;
          // this.selectedInvoice.push(invoice);
          flag = false;
        } else {
          flag = false;
        }
      } else {
        // this.selectedInvoice.push(invoice);
        flag = false;
      }
      // invoice.balance_actual=invoice.balance
      if (flag == false) {
        this.selectedInvoice.push(invoice);
        this.suppId = invoice.supp_id

        if (invoice.payment != undefined) {
          if (invoice.paid_amount > invoice.balance) {
            invoice.paid_amount = (invoice.balance);
          } else {
            invoice.paid_amount = (invoice.invoice_amount - invoice.payment);
          }
        } else {
          if (invoice.paid_amount > invoice.balance) {
            invoice.paid_amount = (invoice.balance);
          } else {
            invoice.paid_amount = invoice.invoice_amount;
          }
        }
        // this.paid_amount += parseInt(invoice.paid_amount);
        this.calculatePaidAmount(invoice)
      }
      console.log("selectedInvoice_ check_amt_to_payment" + JSON.stringify(this.selectedInvoice))
    } else if (invoice.paymentcheckbox == false) {
      for (var i = 0; i < this.selectedInvoice.length; i++) {
        console.log("invoice_no" + invoice.invoice_no + "==" + this.selectedInvoice[i].invoice_no)
        if (invoice.receivables_id == this.selectedInvoice[i].receivables_id) {

          this.paid_amount -= parseInt(invoice.paid_amount);
          // invoice.balance = invoice.balance_actual;
          invoice.paid_amount = 0
          this.selectedInvoice.splice(i, 1);
          console.log("selectedInvoice_ check_amt_to_payment" + JSON.stringify(this.selectedInvoice))
        }
      }
      this.calculatePaidAmount(invoice)
    }
    this.checkboxflag = true
  }

  check_amt_to_payment_conduction(event: Event, data) {
    console.log(" check_amt_data = " + JSON.stringify(data))
    const checkbox = event.target as HTMLInputElement;
    if (data.payment_mode != undefined && data.payment_mode != '') {
      data.paymentcheckbox = true
      this.paidamount_calculation(data, checkbox.checked)
      console.log("paidamount = " + JSON.stringify(this.selectedarray))
    } else {
      data.paid_amount = data.actual_paid_amount
      for(var c=0;c < this.conddocdata.length;c++){
        if(data.diag_appointment_id ==  this.conddocdata[c].diag_appointment_id){
          this.conddocdata[c].paymentcheckbox = false
          checkbox.checked=false
          this.cdr.detectChanges();
        }
      }
      
      this.toastr.error("Payment mode should not be undefined")
    }
    console.log(" check_amt_data 2 = " + JSON.stringify(this.conddocdata))
  }

  paidamount_calculation(data, value) {
    console.log(" data = " + JSON.stringify(data) + "value = " + value)

    if (value == true) {
      this.conddocdata.forEach(conduction_data => {
        if (conduction_data.diag_appointment_id === data.diag_appointment_id) {
          conduction_data.paid_amount = conduction_data.balance
          conduction_data.balance = 0
          conduction_data.paid_flag = "Fully paid"
          this.selectedarray.push(conduction_data)
        }
      })
    } else {
      this.conddocdata.forEach(conduction_data => {
        if (conduction_data.diag_appointment_id === data.diag_appointment_id) {
          conduction_data.balance = conduction_data.actual_balance
          conduction_data.paid_amount = 0
          // if(conduction_data.paid_amount )
          conduction_data.paid_flag = conduction_data.actual_paid_flag
          conduction_data.paymentcheckbox = false
          this.selectedarray.splice(conduction_data, 1);
        }
      })
    }

  }

  calculatePaidAmount_conduct(data) {
    if (data.payment_mode != undefined && data.payment_mode != '') {
      if (data.paid_amount <= data.actual_balance) {
        if (data.paid_amount != data.actual_paid_amount) {
          if (data.balance_actual != undefined && data.balance_actual != '') {
            data.balance_actual = data.balance_actual
          } else {
            data.balance_actual = 0
          }
          if (data.paid_amount != undefined && data.paid_amount != '') {
            data.paid_amount = data.paid_amount
          } else {
            data.paid_amount = 0
          }
          var balanceamt = parseFloat(data.actual_balance) - parseFloat(data.paid_amount)
          data.balance = balanceamt
          data.paymentcheckbox = true
          if (data.balance > 0) {
            data.paid_flag = "Partially paid"
          } else if (data.balance == 0) {
            data.paid_flag = "Fully paid"
          }
          const index = this.selectedarray.findIndex(
            (item) => item.diag_appointment_id === data.diag_appointment_id
          );
          if (index !== -1) {
            this.selectedarray[index] = data;
          } else {
            this.selectedarray.push(data);
          }
        }else if(data.paid_flag == "Partially paid" && data.paid_amount == data.actual_balance){
          if (data.balance_actual != undefined && data.balance_actual != '') {
            data.balance_actual = data.balance_actual
          } else {
            data.balance_actual = 0
          }
          if (data.paid_amount != undefined && data.paid_amount != '') {
            data.paid_amount = data.paid_amount
          } else {
            data.paid_amount = 0
          }
          var balanceamt = parseFloat(data.actual_balance) - parseFloat(data.paid_amount)
          data.balance = balanceamt
          data.paymentcheckbox = true
          if (data.balance > 0) {
            data.paid_flag = "Partially paid"
          } else if (data.balance == 0) {
            data.paid_flag = "Fully paid"
          }
          const index = this.selectedarray.findIndex(
            (item) => item.diag_appointment_id === data.diag_appointment_id
          );
          if (index !== -1) {
            this.selectedarray[index] = data;
          } else {
            this.selectedarray.push(data);
          }
        } 
        else {
          data.paid_amount = data.actual_paid_amount
          data.balance = data.actual_balance
          data.paid_flag = data.actual_paid_flag
          data.paymentcheckbox = false
          this.conddocdata.forEach(conduction_data => {
            if (conduction_data.diag_appointment_id === data.diag_appointment_id) {
              this.selectedarray.splice(conduction_data, 1);
            }
          })
        }
      }
      else {
        this.toastr.error("paid amount should be greater than" + data.balance)
      }
    } else {
      data.paid_amount = data.actual_paid_amount
      this.toastr.error("Payment mode should not be undefined")
    }

  }


}
