<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Stock Returns</h5>
                        </div>

                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                                (click)="saveOrder()" />
                            <a *ngIf="printbutton" (click)="printreturndata()"><img style="width:22px"
                                    src="../../../assets/img/printer.svg"></a>
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row" style="margin-top: -10px !important;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Filter by<br>
                                        <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtertype" (change)="pagetype(filtertype)">
                                            <option disabled>Select</option>
                                            <option value="products">Products</option>
                                            <option value="suppliers">Suppliers</option>
                                        </select>
                                    </mat-label>
                                </div>

                                <div class="col-3" [hidden]="productflag">
                                    <br>
                                    <input type="text" style="position: relative;left:20px" class="ipcss order_width getMedcien" maxlength="50"
                                        [(ngModel)]="returnsproductname" (keyup)="get_stock()" maxlength="50" />
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="suppliersflag">
                                    <mat-label class="matlabel">Supplier / distributor name<br>
                                        <input type="text" class="ipcss widthappt" required [(ngModel)]="supp_name" 
                                            matInput list="dropValues" (change)="supplier_change()" (click)="clearSupplier()">
                                        <datalist [(ngModel)]="supp_name" id="dropValues" clickable >
                                            <option type="button" *ngFor="let supplier of supplierList" value={{supplier.name}}>
                                                {{supplier.name}}
                                            </option>
                                        </datalist>
                                    </mat-label>
                                </div>

                                <div *ngIf="stockData.length != 0" class="col-3" style="margin-left:5px !important;width:10%">
                                    <br>
                                    <mat-label class="matlabel" style="position: relative;bottom: 17px !important;">Per page<br>
                                        <select class="ipcss" [(ngModel)]="pagelist" (change)="get_stock()">
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="full">Full</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>

                        <p class="nodata" *ngIf="stockData.length == 0">No Product(s) found</p>
                        <div *ngIf="stockData.length != 0">
                            <div class="col-12 stock_ledger_table">
                                <table class="table table-nowrap table-sm dataTable">
                                    <thead>
                                        <tr>
                                            <th style="width:16%;">Medicine</th>
                                            <th style="width:16%;">Generic name</th>
                                            <th style="width:10%;" [hidden]="!suppliersflag">Supplier</th>
                                            <th style="width:9%;">Batch</th>
                                            <th style="width:10%;">Expiry date</th>
                                            <th style="width:12%;">Return qty</th>
                                            <th style="width:16%;">Reasons</th>
                                            <th style="width: 3%;">
                                                <!-- <img *ngIf="masterSelected == false" (click)="checkUncheckAll('select')"
                                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />

                                                <img *ngIf="masterSelected == true" (click)="checkUncheckAll('unselect')"
                                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" /> -->
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stock of stockData">
                                            <td class="align_left">{{stock.medicine}}</td>
                                            <td class="align_left">{{stock.genericName}}</td>
                                            <td class="align_left"  [hidden]="!suppliersflag">{{stock.supp_name}}</td>
                                            <td class="align_left">{{stock.batchNo}}</td>
                                            <td> {{stock.exp_date}} </td>
                                            <td>
                                                <input type="text" class="ipcss" style="text-align:right;"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    maxlength="4" required [(ngModel)]="stock.qty_onhand" (keyup)="quantity(stock)" #matInput />
                                            </td>
                                            <td>
                                                <mat-label>
                                                    <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="stock.return_reason" (ngModelChange)="changeSelected(stock)">
                                                        <mat-option *ngFor="let reason of reasondata" value={{reason.return_reason}}>{{reason.return_reason}}</mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </td>
                                            <td>
                                                <img *ngIf="stock.checked == false" (click)="isAllSelected(stock,'select')"
                                                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />

                                                <img *ngIf="stock.checked == true" (click)="isAllSelected(stock,'unselect')"
                                                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="stockData != undefined && stockData.length !=0 && prodname_flag == true">
                                <div class="row" style="text-align: center;margin-top: 5px;">
                                    <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images"
                                            src="../../../assets/ui_icons/arrow_blue.svg"
                                            (click)="pageLimitPrevious()" />
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <span class="current_font">{{currentPage}}/{{numberOfPages()||1}}</span>
                                    </div>

                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images"
                                            src="../../../assets/ui_icons/arrow_blue r.svg" (click)="pageLimitnext()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="nodata" *ngIf="stockData.length == 0">No product(s) found</p> -->
                    <br><br><br>
                    <div [hidden]="returnsdata">
                        <table id="printStockRec" style="font-size: 12px; width: 130%;">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th style="width:150px">Medicine</th>
                                    <th style="width:150px">Generic name</th>
                                    <th style="width:100px">Supplier</th>
                                    <th>Batch</th>
                                    <th>Expiry date</th>
                                    <th>Return qty</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="align_left" style="width:150px"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>