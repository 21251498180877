import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Message_data } from 'src/assets/js/Message_data';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';

@Component({
  selector: 'app-sales-report-gst',
  templateUrl: './sales-report-gst.component.html',
  styleUrls: ['./sales-report-gst.component.scss']
})

export class SalesReportGstComponent implements OnInit {
  @ViewChild('printHBR') printHBR: ElementRef;
  @ViewChild('printDBR') printDBR: ElementRef;
  @ViewChild('printIPR') printIPR: ElementRef;
  @ViewChild('printIR') printIR: ElementRef
  @ViewChild('printSRD') printSRD: ElementRef;
  @ViewChild('printBillRefund') printBillRefund: ElementRef;
  @ViewChild('printBillDue') printBillDue: ElementRef;
  @ViewChild('printBedOcc') printBedOcc: ElementRef;
  @ViewChild('printabc') printabc: ElementRef;
  @ViewChild('printproductrec') printproductrec: ElementRef;
  @ViewChild('printlabrepo') printlabrepo: ElementRef;
  @ViewChild('printCHBR') printCHBR: ElementRef;
  @ViewChild('printpatreport') printpatreport: ElementRef;
  @ViewChild('printSURREPO') printSURREPO: ElementRef;
  @ViewChild('printsalesGST') printsalesGST: ElementRef;
  @ViewChild('printStockRec') printStockRec: ElementRef;
  @ViewChild('printgsd') printgsd: ElementRef;
  @ViewChild('printSDI') printSDI: ElementRef;
  @ViewChild('printReturnsrepo') printReturnsrepo: ElementRef;
  @ViewChild('printproduct') printproduct: ElementRef;
  @ViewChild('printCRHBR') printCRHBR: ElementRef;
  @ViewChild('printCRDBR') printCRDBR: ElementRef;
  @ViewChild('printpharma') printpharma: ElementRef;
  @ViewChild('printNVR') printnvr: ElementRef;
  @ViewChild('totalamount') totalamount: ElementRef;
  @ViewChild('discountamount') discountamount: ElementRef;
  @ViewChild('cashamount') cashamount: ElementRef;
  @ViewChild('cardamount') cardamount: ElementRef;
  @ViewChild('insureamount') insureamount: ElementRef;
  @ViewChild('upiamount') upiamount: ElementRef;
  @ViewChild('creditamount') creditamount: ElementRef;
  @ViewChild('printsalesamountgst') printsalesamountgst: ElementRef;
  @ViewChild('printsalesreturns') printsalesreturns: ElementRef;
  @ViewChild('printsalesfinal') printsalesfinal: ElementRef;
  // totalreturns=this.printsalesreturns.nativeElement.innerHTML;
  // finalamount=this.printsalesfinal.nativeElement.innerHTML;
  @ViewChild('printpurchaseOrder') printpurchaseOrder: ElementRef;
  @ViewChild('printusertable') printusertable: ElementRef;
  @ViewChild('printchargetable') printchargetable: ElementRef;
  @ViewChild('printCRHBRtotal') printCRHBRtotal: ElementRef;
  @ViewChild('printCRHBRcash') printCRHBRcash: ElementRef;
  @ViewChild('printCRHBRcard') printCRHBRcard: ElementRef;
  @ViewChild('printCRHBRinsurance') printCRHBRinsurance: ElementRef;
  @ViewChild('printCRHBRupi') printCRHBRupi: ElementRef;
  @ViewChild('printCRHBRusertable') printCRHBRusertable: ElementRef;
  @ViewChild('printCRDBRtotal') printCRDBRtotal: ElementRef;
  @ViewChild('printCRDBRcash') printCRDBRcash: ElementRef;
  @ViewChild('printCRDBRcard') printCRDBRcard: ElementRef;
  @ViewChild('printCRDBRinsurance') printCRDBRinsurance: ElementRef;
  @ViewChild('printCRDBRupi') printCRDBRupi: ElementRef;
  @ViewChild('printpharmatotal') printpharmatotal: ElementRef;
  @ViewChild('printpharmacash') printpharmacash: ElementRef;
  @ViewChild('printpharmacard') printpharmacard: ElementRef;
  @ViewChild('printpharmainsurance') printpharmainsurance: ElementRef;
  @ViewChild('printpharmaupi') printpharmaupi: ElementRef;
  // @ViewChild('printbanner') printbanner:ElementRef;
  // #printbanner id="printbanner"
  @ViewChild('printimage') printimage: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  public colspann: number = 4;
  public pharmacistId: string;
  public fromDate;
  public toDate;
  public salesList: any = [];
  public userList: any = []
  public userInfo;
  public currentDate;
  public totalAmount = "0.00";
  public DiscountAmount = "0.00";
  public cashAmount = "0.00";
  public cardAmount = "0.00";
  public insureAmount = "0.00";
  public upiAmount = "0.00";
  public creditAmount: any = "0.00";
  public doctotalAmount = "0.00";
  public doccashAmount = "0.00";
  public doccardAmount = "0.00";
  public docinsureAmount = "0.00";
  public docupiAmount = "0.00";
  public diagtotalAmount = "0.00";
  public diagcashAmount = "0.00";
  public diagcardAmount = "0.00";
  public diaginsureAmount = "0.00";
  public diagupiAmount = "0.00";
  public pharmatotalAmount = "0.00";
  public pharmacashAmount = "0.00";
  public pharmacardAmount = "0.00";
  public pharmainsureAmount = "0.00";
  public pharmaupiAmount = "0.00";
  public title = 'Sales report';
  public header;
  public hptlClinicId;
  public pharmaid;
  public DiagnosisCenterId;
  public reportType;
  public url;
  public reportHeading;
  public scheduleType = "All";
  public scheduleList;
  public scheduleFlag: boolean = false;
  public totalAmtFlag: boolean = false;

  public doctorList: any;
  public doctorFlag: boolean;
  public patientFlag: boolean;
  public doctorId;
  public pharmacyID;
  public pattype;
  public Filter_by_Id = 'Doctor';
  //public Filter_by_Id;
  public fieldtype = 'Doctor';
  public filedname;
  public speciality_id;
  public speciality_name;
  public Bill_refundfilter;
  public bed_occupancyfilter;
  public productfilter;
  public product_medicine_name;
  public medicne_code;
  public productflag: boolean;

  public product_medicine_name_flag: boolean;
  public ReferedmedicineNameArray: any = [];
  public ReferedmedicineArray: any = [];
  public Refered
  public srd_flag: boolean;
  public applystyle: boolean;
  public billrefd_flag: boolean;
  public bed_occupancyflag: boolean;
  public captilise: any;
  public filtduration;
  //added by christo
  public pattyperepoFlag: boolean = false;
  public surgdoctorFlag: boolean;
  public pattyperepo;
  public patientnameList: any;
  public userId = "All";
  public userFlag = false;

  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public amountcard: boolean = false;
  public ishide: boolean = false;
  public DoctorName: boolean;
  public SpecialityName: boolean;
  public ChargeName: boolean;
  public bill_pay_type;
  public userdataList: any = [];
  public chargeList: any = [];
  public userdatatableheader;
  public bill_pay_id;
  public docbackbutton: boolean = false;
  public diagurl;
  public docurl;
  public pharmaurl;
  public docsalesList: any = [];
  public docuserdataList: any = [];
  public consoldatedreport;
  public pharmacistID;
  public pharmauserName;
  public diagnosissalesList: any = [];
  public pharmasalesList: any = [];
  public applyheight: boolean;
  public applywidth;
  isSuccessful: boolean = false;
  public diagheader;
  public pharmaheader;
  public docuserdatatableheader;
  public currentSession: string;
  public presentdate;
  public currenttime;
  public hospital_logo;
  public print_template;
  public printlogostyle;
  public printFlag;
  public printlogowithname;
  public printnoheader;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public amount: boolean;
  public loader;
  public currentdatesessionlist = [];
  public checkdate;
  public checksession;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public filteredSaleslistArray: any;
  public selected_filter: any = [];
  public table_th_data: any = [];
  public group_by;

  public columns = {
    paid: true,
    cash: true,
    upi: true,
    card: true,
    insurance: true,
    due: true,
    ipOp: true,
    advance: true,
    discount: true,
    doctor: true,
    paidon: true,
    Sno: true,
    OrderNo: true,
    OrderDate: true,
    BillAmount: true,
    Disc: true,
    Exempt: true,
    FiGST: true,
    TWCGST: true,
    TWSGST: true,
    TWEGST:true,
    SISGST: true,
    SICGST: true,
    EIGst: true,
    NICgst: true,
    NISgst: true,
    TWGst: true,
    FOcgst: true,
    FOsgst: true,
    CGST: true,
    SGST: true
  };
  public grp_by_flag: boolean;
  public newPat_arr: any = [];
  public followUpPat_arr: any = [];
  public showNewPat_arr: boolean;
  public showOldPat_arr: boolean;
  public printFontSize: any = "11px";

  public BillAmt_Subtotal: any;
  public discountAmt_Subtotal: any;
  public paidAmt_Subtotal: any;
  public cash_Subtotal: any;
  public card_Subtotal: any;
  public upi_Subtotal: any;
  public ins_Subtotal: any;
  public due_Subtotal: any;

  public BillAmt_Subtotal_1: any;
  public discountAmt_Subtotal_1: any;
  public paidAmt_Subtotal_1: any;
  public cash_Subtotal_1: any;
  public card_Subtotal_1: any;
  public upi_Subtotal_1: any;
  public ins_Subtotal_1: any;
  public due_Subtotal_1: any;
  table_filters: { id: string; value: string; }[];
  public colspan;
  public DBR_flag: boolean = false;
  public Filter_by_dbr: any = 'Doctor';
  public op_SalestlistArr: any = [];
  public ip_SalestlistArr: any = [];
  department_data: any[];
  diag_dept: any;
  test_category_data: any[];
  diag_test_categ: string;
  DBR_dept_flag: boolean = false;
  public salestotalamount;
  public returnarray: any = [];
  public returntotalamount: any = 0
  public finalamount: any = 0;
  public combinedArray: any = [];

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.selected_filter = [
    'paid', 'cash', 'upi', 'card', 'insurance', 'due', 'doctor', 'discount', 'ipOp', 'advance', 'paidon',"Sno","OrderNo","OrderDate","BillAmount","Disc","Exempt","FiGST",
    "TWCGST","TWSGST","TWEGST","SISGST","SICGST","EIGst","NICgst","NISgst","TWGst","FOcgst","FOsgst","CGST","SGST"];
  }

  ngOnInit(): void {
    this.docbackbutton = false;
    this.userInfo = Helper_Class.getInfo();

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.hptlClinicId = Helper_Class.getInfo().hptl_clinic_id;
      this.bill_pay_type = this.userInfo.bill_pay_type_req
      this.pharmacistID = Helper_Class.getInfo().po_approver;
    }
    else {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hptlClinicId = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.userInfo.user_id;
      this.bill_pay_type = this.userInfo.hospitals[0].bill_pay_type_req;
    }


    // this.pharmacistID = Helper_Class.getIsAdmin() != undefined ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_id;
    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "123" && Helper_Class.getmodulelist()[i].description == "Sales report") {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }

      }
    }
    
    this.pharmauserName;
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    if (this.userInfo.middle_name != undefined)
      this.pharmauserName = this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name;
    else {
      if (this.userInfo.last_name != undefined)
        this.pharmauserName = this.userInfo.first_name + " " + this.userInfo.last_name;
      else
        this.pharmauserName = this.userInfo.first_name;
    }
    
    var billtrue = Helper_Class.getbillpagetrue()

    if (billtrue == "doctorbill") {
      this.bill_pay_type = this.userInfo.hospitals[0].bill_pay_type_req;
    }

    if(this.userInfo.user_type =="pharmacy") {
      this.pharmaid = this.userInfo.hospitals[0].hptl_clinic_id;

    } else {
      this.pharmaid = this.userInfo.pharma_id
    }
    
    this.DiagnosisCenterId = this.userInfo.diag_center_id
    
    this.getDoctor();
    this.getusername();
    this.reportType = Helper_Class.getReportType();
    this.scheduleFlag = false;
    this.doctorFlag = false;
    this.surgdoctorFlag = false;
    this.pattyperepo = "All";
    this.patientFlag = false;
    this.srd_flag = false;
    this.bed_occupancyflag = false;
    this.doctorId = "All";
    this.pattype = "All";
    this.Bill_refundfilter = "hospital";
    this.bed_occupancyfilter = "ward";
    this.productfilter = "product";
    this.filtduration = "select";

    if (Helper_Class.getReportType().flow != undefined) {
      this.reportType = Helper_Class.getReportType().report_type;

      this.pharmacyID = Helper_Class.getReportType().pharmacyID;

    } else {
      this.reportType = Helper_Class.getReportType();
    }
    this.getCurrentDate();

    if (this.reportType == "salesGst") {
      this.header = [
        "S.no"," Order no","Order date","Bill amount","Disc","Exempt","5% GST"," 2.5% CGST","2.5% SGST","12% GST",
        "6% CGST","6% SGST","18% GST","9% CGST","9% SGST","28% GST","14% CGST","14% SGST","CGST","SGST",];
      // this.amountcard=true;
      this.applystyle = true;
      this.url = "misr/gsr";
      this.reportHeading = "Sales report GST";

    } else if (this.reportType == "stockReceivable") {
      this.header = ['S.no', 'Invoice no', 'Product Name', 'Batch No', 'Quantity Received Date', 'Total Quantity', 'MRP Value', 'MRP', 'CGST / SGST', 'CGST / SGST Price', 'SGST Value', 'Total SGST'];
      this.applystyle = true;
      this.url = "misr/gsrr";
      this.reportHeading = "Stock Receivables";

    } else if (this.reportType == "gsd") {
      this.getSchedule();
      this.header = ['S.no', 'Order No', 'Order Date', 'Product Name', 'Doctor Name', 'Patient Name', 'Batch No', 'Supplier/Manufacturer', 'Schedule Type', 'Quantity Sold', 'Expiry Date'];
      this.url = "misr/gsd";
      this.applystyle = true;
      this.scheduleFlag = true;
      this.reportHeading = "Schedule for Drug Inspector";

    } else if (this.reportType == "recreceivableReport") {
      this.header = ['S.no', 'Order No', 'Product Name', 'Created By', 'Drug Id', 'Batch No', 'Quantity', 'Amount',];
      this.url = "misr/grr";
      this.reportHeading = "Receivable Report";

    } else if (this.reportType == "SDI") {
      this.header = ['S.no', 'Order No', 'Order Date', 'Product Name', 'Batch No', 'Manufacturer Name', 'Quantity Sold', 'Reminding Quantity', 'Amount',];
      this.url = "misr/gsdi ";
      // this.amountcard=true;
      this.applystyle = true;
      this.reportHeading = "Sales For Drug Inspector";

    } else if (this.reportType == "HBR") {
      this.header = [ 'S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient','MR no', 'Paitent Name', 
        'Doctor', "App type", 'Bill Amount', 'Paid','Discount', 'Cash', 'Card', 'UPI', 'Insurance','Due/Credit', "Pay type"
        ,"Payment status", "Advance",'Paid date'];
      this.url = "misr/gbbyh ";
      this.doctorFlag = true;
      this.reportHeading = "Hospital Bills Report";
      this.srd_flag = true;
      this.userdatatableheader = ['S.no', 'User Name', 'Bill', 'Cash', 'Card', 'UPI', 'Cheque', 'Insurance', 'Due/Credit']
      // this.amountcard=true;
      this.filterby();

      this.applystyle = true;


      if (this.userInfo.user_type == "Admin") {
        this.userFlag = true;
      }

    } else if (this.reportType == "NVR") {
      this.reportHeading = "Not Visted Patients"
      this.applystyle = true;
      this.url = "misr/gnvp";
      this.header = ['S.no', 'Name', 'Mobile Number', 'Session', 'Date'];

    } else if (this.reportType == "CR" || this.reportType == 'consolidat_reportadmin') {
      this.reportHeading = "Consolidated report";
      this.diagurl = "misr/gbbyd ";
      this.docurl = "misr/gbbyh ";
      this.pharmaurl = "pharmacontrol/gsrep/";
      this.applystyle = true;
      this.applyheight = true;

      this.header = ['S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient', 'Paitent Name', 'Doctor', "App type", 'Bill Amount', 'Paid', 'Cash', 'Card', 'UPI', 'Insurance', 'Due/Credit', "Pay type", "Payment status", "Advance"]
      this.docuserdatatableheader = ['S.no', 'User Name', 'Bill', 'Cash', 'Card', 'UPI', 'Cheque', 'Insurance', 'Due/Credit'];
      this.diagheader = ['S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient', 'Paitent Name', 'Advance', 'Balance', 'Bill Amount', "Pay type", "Payment status"];
      this.pharmaheader = ['Bill no', 'Buyer', 'Create By', 'Purchase type', 'Amount', 'Deliver To', 'Bill date', 'Balance', "Payment status"];
    
    } else if (this.reportType == "CHBR") {
      this.header = ["S.no", "Bill No", "Product", "Selling Price", "MRP (Book)", "MRP (Sold)", "Amount", "Sold by", "Date", 'Quantity Sold', 'Quantity On'];
      this.url = "misr/gcsr";
      this.applystyle = true;
      this.reportHeading = "Consolidated sales report";

    } else if (this.reportType == "Patreport") {
      this.header = ["S.no", "Name ", "Mobile", "Patient ID", "Gender", 'Age'];
      this.pattyperepoFlag = true;
      this.applystyle = true;
      this.url = "misr/gchr";
      this.reportHeading = "Patient Report";

    } else if (this.reportType == "SURREPO") {
      this.header = ["S.no", "Client Id", "Name", "Mobile No", "Age", "Gender", "Surgery", "Surgery Date"];
      this.surgdoctorFlag = true;
      this.url = "misr/gsrh";
      this.applystyle = true;
      this.reportHeading = "Surgery Report"

    } else if (this.reportType == "DBR") {
      this.header = ['S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient', 'Paitent Name', 'Advance', 'Balance', 'Bill Amount', "Pay type", "Payment status"];
      this.url = "misr/gbbyd ";
      this.applystyle = true;
      this.DBR_flag = true;
      this.reportHeading = "Diagnosis Bills Report";
      if (this.userInfo.user_type == "Admin") {
        this.userFlag = true;
      }
      this.dbr_filterby()

    } else if (this.reportType == "IR") {
      this.header = ['S.no', 'Order No', 'Provider Name', 'Product Name', 'Batch No', 'Quantity', 'Balance', 'Discount', 'Amount'];
      this.url = "misr/gir";
      this.applystyle = true;
      this.reportHeading = "Inward Reports";

    } else if (this.reportType == "IPR") {
      this.header = ['S.no', 'Admission Date / Time', 'Patient Name', 'Age', 'Doctor Name', 'Bed No', 'Discharge Date / Time',];
      this.url = "misr/ginpr";
      this.applystyle = true;
      this.reportHeading = "Inpatient Reports";

    } else if (this.reportType == "SRD") {
      this.url = "misr/ghr";
      this.header = ['S.no', 'Name', 'Count']
      this.reportHeading = "Doctor wise report";
      this.doctorFlag = true;
      this.srd_flag = true;
      this.filterby()

    } else if (this.reportType == "Bill_refund") {
      this.header = ['S.no', 'Bill No', 'Name', 'Bill Date', 'Refund Amount'];
      this.url = "misr/grdbh";
      this.reportHeading = "Bill Refund";
      this.billrefd_flag = true;

    } else if (this.reportType == "Bill_due") {
      this.header = ['S.no', 'Bill No', 'Name', 'Bill Date', 'Bill Due'];
      this.url = "misr/grdbh";
      this.reportHeading = "Bill Due";

    } else if (this.reportType == "bed_occupancy") {
      this.url = "misr/gbor";
      this.bed_occupancyflag = true;
      this.reportHeading = "Bed Occupancy Ratio";
      this.wardbedfilterby()

    } else if (this.reportType == "abc_analysis") {
      this.url = "misr/gabca";
      this.header = ['S.no', 'Drug id', 'Quantity', 'Amount', 'rand', 'ABC Analysis']
      this.reportHeading = "ABC Analysis";

    } else if (this.reportType == "sum_of_purchaseOrder") {
      this.reportHeading = "Sum of Purchase Order Report";
      this.url = "misr/gpor";
      this.header = ['S.no', 'Pharmacy ID', 'Date', 'Amount'];

    } else if (this.reportType == "product") {
      this.url = "misr/gptr";
      this.productflag = true;
      this.reportHeading = "Product";
      this.header = ['S.no', 'Product Name', 'Quantity', 'Sales Amount']

    } else if (this.reportType == "product_receivables") {
      this.url = "misr/grr";
      this.reportHeading = "Product Receivables";
      this.header = ['S.no', 'Product Name', 'Invoice No', 'Batch No', 'Quantity', 'Free Quantity', 'Received Date'];

    } else if (this.reportType == "lab_test_report") {
      this.url = "misr/gdtwr";
      this.reportHeading = "Lab Testwise Report";
      this.header = ['S.no', 'Bill Id', 'Bill Date', 'Bill Amount', 'Test Name', 'Test Type'];

    } else if (this.reportType == "returnsReport") {
      this.url = "pharmacontrol/gprt";
      this.applystyle = true;
      this.reportHeading = "Product Return Report";
      this.header = ['S.no', 'Medicine', 'Supplier', 'Batch No', 'Expiry date', 'Return qty', 'Return Date'];
    }
    this.getRetrievalData();


  }
  conbillstyle() {
    this.isSuccessful = !this.isSuccessful;
    // this.applywidth=true;
    // this.applywidth!=true;
  }

  backpage() {
    window.history.back();
    this.router.navigate(['/doctor-home'], { relativeTo: this.routes })
  }

  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/gsch", { headers: headers }).subscribe(
      data => {
        this.scheduleList = [];
        var obj = data.json();
        if (obj != undefined && obj.schedules.length != 0) {
          this.scheduleList = obj.schedules;
        }
      }, error => {
        this.toastr.error(Message_data.unableToFetchDocDet);
      });
  }

  filterby() {
    this.salesList = [];
    if (this.Filter_by_Id == "Doctor") {
      this.applystyle = true;
      // this.DoctorName=true;
      this.SpecialityName = false;
      this.ChargeName = false;
      this.fieldtype = "Doctor"
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: "doctor"
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'misr/gdsc', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.details != null) {
              console.log("step 1 =", obj.details)
              this.doctorList = [];

              this.doctorList.push({
                id: "All",
                name: "All",
              })

              for (var i = 0; i < obj.details.length; i++) {
                var doc_name;
                if (obj.details[i].middle_name != undefined || obj.details[i].middle_name != null) {
                  doc_name = obj.details[i].first_name + " " + obj.details[i].middle_name + " " + obj.details[i].last_name;
                } else {
                  doc_name = obj.details[i].first_name + " " + obj.details[i].last_name;
                }

                this.doctorList.push({
                  id: obj.details[i].doc_reg_id,
                  name: doc_name,
                })
              }
            }
          }, error => {
            this.toastr.error(Message_data.unableToFetchDocDet);
          });

    } else if (this.Filter_by_Id == "Speciality") {
      this.applystyle = true;
      this.SpecialityName = true;
      this.ChargeName = false;

      this.header = ['S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient', 'Paitent Name', 'Speciality', "App type", 'Bill Amount', 'Paid', 'Cash', 'Card', 'UPI', 'Insurance', 'Due/Credit', "Pay type", "Payment status", "Advance"];
      console.log("specialty")
      this.fieldtype = "Speciality"
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: "speciality"
      }
      this.applystyle = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'misr/gdsc', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(obj)

            if (obj.details != null) {
              console.log("step 1 =", obj.details)
              this.doctorList = [];

              this.doctorList.push({
                id: "All",
                name: "All",
              })

              for (var i = 0; i < obj.details.length; i++) {
                this.doctorList.push({
                  id: obj.details[i].spl_id,
                  name: obj.details[i].spl_name,
                })
                // this.sortDoctorListAlphabetically();

              }
              console.log("Speciality", this.doctorList)
            }

          }, error => {
            this.toastr.error(Message_data.unableToFetchDocDet);
          });

    } else if (this.Filter_by_Id == "Service") {
      this.applystyle = true;
      this.ChargeName = true;
      this.SpecialityName = false;
      this.ChargeName = false;

      this.header = ['S.no', 'Bill No', 'Created By', 'Created Date', 'In/Out Patient', 'Paitent Name', 'Charge', "App type", 'Bill Amount', 'Paid', 'Cash', 'Card', 'UPI', 'Insurance', 'Due/Credit', "Pay type", "Payment status", "Advance"];
      this.fieldtype = "Service"
      console.log("service")
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: "charge"
      }
      this.applystyle = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'misr/gdsc', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            console.log(obj)
            if (obj.details != null) {
              console.log("step 1 =", obj.details)
              this.doctorList = [];

              this.doctorList.push({
                id: "All",
                name: "All",
              })

              for (var i = 0; i < obj.details.length; i++) {
                this.doctorList.push({
                  id: obj.details[i].charge_id,
                  name: obj.details[i].charge_desc
                })
                // this.sortDoctorListAlphabetically();
                console.log("charge", this.doctorList)
              }
            }

          }, error => {
            this.toastr.error(Message_data.unableToFetchDocDet);
          });

    } else {
      console.log("error in finding the type")
    }
  }

  dbr_filterby() {
    console.log(this.Filter_by_dbr)
    if (this.Filter_by_dbr == 'Department') {
      this.getDiagDepartment();
      this.DBR_dept_flag = true;
      this.doctorFlag = false;

    } else if (this.Filter_by_dbr == 'Doctor') {
      this.DBR_dept_flag = false;
      this.doctorFlag = true;
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: "doctor"
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'misr/gdsc', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.details != null) {
              console.log("step 1 =", obj.details)
              this.doctorList = [];

              this.doctorList.push({
                id: "All",
                name: "All",
              })

              for (var i = 0; i < obj.details.length; i++) {
                var doc_name;
                if (obj.details[i].middle_name != undefined || obj.details[i].middle_name != null) {
                  doc_name = obj.details[i].first_name + " " + obj.details[i].middle_name + " " + obj.details[i].last_name;
                } else {
                  doc_name = obj.details[i].first_name + " " + obj.details[i].last_name;
                }

                this.doctorList.push({
                  id: obj.details[i].doc_reg_id,
                  name: doc_name,
                })
              }
            }
          }, error => {
            this.toastr.error(Message_data.unableToFetchDocDet);
          });
    } else {
      this.DBR_dept_flag = false;
      this.doctorFlag = false;
    }
  }

  productfilterby() {
    if (this.productfilter == 'product Type') {
      this.salesList = []
      this.product_medicine_name_flag = true
    } else {
      this.salesList = []
      this.product_medicine_name_flag = false
    }
  }

  medicineData_name(e) {
    this.ReferedmedicineArray = [];
    this.ReferedmedicineNameArray = [];
    console.log(this.product_medicine_name)
    if (this.product_medicine_name != undefined && this.product_medicine_name.length > 2) {
      console.log("produc type if condition")
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes',
        JSON.stringify({
          medicine_name: this.product_medicine_name
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("Medicine name", JSON.stringify(obj))
            this.ReferedmedicineNameArray = obj.med_details;
            for (let i = 0; i < this.ReferedmedicineNameArray.length; i++) {
              this.captilise = this.ReferedmedicineNameArray[i].med_name.toLowerCase()
              this.ReferedmedicineArray.push({
                med_name: this.captilise.charAt(0).toUpperCase() + this.captilise.slice(1),
                med_code: this.ReferedmedicineNameArray[i].med_code
              })
            }
            // this.ReferedmedicineArray = this.ReferedmedicineArray.filter(function (this: any, el) {
            //   return el.toLowerCase().indexOf(this.doctor_txt.toLowerCase()) > -1;
            //  }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    else {

      this.ReferedmedicineArray = [];
      // this.ReferedDoctorArray = [];
    }
  }

  select_refer_medicine(item) {
    this.product_medicine_name = item.med_name;
    this.medicne_code = item.med_code;
    this.ReferedmedicineArray = [];
  }

  wardbedfilterby() {
    if (this.bed_occupancyfilter == 'ward') {
      this.salesList = [];
      this.header = ['S.no', 'Ward name']
    } else if (this.bed_occupancyfilter == 'bed') {
      this.salesList = [];
      this.header = ['S.no', 'Ward name', 'Bed no']
    }
  }
  selectedText() {
  }
  //ended by me
  getDoctor() {
    var senddata = {
      hptl_clinic_id: this.hptlClinicId
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/docbyhosp/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.doctors != null) {

            this.doctorList = [];
            this.doctorList.push({
              id: "All",
              name: "All",
            })
            for (var i = 0; i < obj.doctors.length; i++) {
              var doc_name;
              if (obj.doctors[i].middle_name != undefined || obj.doctors[i].middle_name != null) {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.doctorList.push({
                id: obj.doctors[i].doc_reg_id,
                name: doc_name,
              })
              console.log('...........', this.doctorList)
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  getusername() {
    if (this.reportType = "HBR") {
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: 'hospital'
      }
    }
    else if (this.reportType = "DBR") {
      var senddata = {
        hptl_clinic_id: this.hptlClinicId,
        type: 'diagnosis'
      }
    } else {
      console.log("usertypenone")
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/grbh', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("user name--", obj)
          if (obj.reps != null) {
            this.userList = [];
            for (var i = 0; i < obj.reps.length; i++) {
              var user_name;
              if (obj.reps[i].middle_name != undefined || obj.reps[i].middle_name != null) {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].middle_name + " " + obj.reps[i].last_name;
              } else {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].last_name;
              }

              this.userList.push({
                id: obj.reps[i].user_id,
                name: user_name,
              })

              console.log('...........', this.doctorList)
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  selectDate(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getSalesData() {
    console.log("report type inside getSalesData", this.reportType)
    console.log("Filter by id", this.Filter_by_Id)
    var filtdur;
    if (this.filtduration != "select") {
      filtdur = this.filtduration;
    }
    this.ishide = true;
    this.salesList = [];
    var send_data
    send_data = {
      hptl_clinic_id: this.hptlClinicId,
      from_date: this.fromDate,
      to_date: this.toDate,
      duration: filtdur,
    };

    if (this.reportType == "gsd") {
      send_data.schedule = this.scheduleType;
    }
    if (this.reportType == "IR") {
      send_data.hptl_clinic_id = this.pharmacyID;
    }

    if (this.reportType == "HBR") {
      send_data = {
        hptl_clinic_id: this.hptlClinicId,
        from_date: this.fromDate,
        to_date: this.toDate,
        duration: filtdur,
        bill_pay_type_req: this.bill_pay_type
      };
      this.applystyle = true;
      if (this.Filter_by_Id == "Doctor") {
        send_data.doc_id = this.doctorId;
        send_data.patient_type = this.pattype;
        send_data.type = "doctor";
        this.DoctorName = true;

      } else if (this.Filter_by_Id == "Speciality") {
        send_data.spl_id = this.doctorId;
        send_data.patient_type = this.pattype;
        send_data.type = "speciality";
        this.SpecialityName = true;

      } else if (this.Filter_by_Id == "Service") {
        send_data.charge_id = this.doctorId;
        send_data.patient_type = this.pattype;
        send_data.type = "charge";
        this.ChargeName = true;
      }
      // if (this.userInfo.user_type != "Admin") {

      // }

      if (this.userInfo.user_type == "Admin" && this.userInfo.admin == undefined) {
        this.applystyle = true;
        if (this.userId != "All") {
          send_data.userid = this.userId;
        }
      } else if (this.userInfo.user_type == "doctor" && (this.userInfo.admin != undefined && this.userInfo.admin == "1")) {
        this.applystyle = true;
        if (this.userId != "All") {
          send_data.userid = this.userId;
        }
      } else {
        if (this.userInfo.user_type != 'Accounts') {
          send_data.userid = this.userInfo.user_id;
        }
      }
    }
    if (this.reportType == "CR" || this.reportType == 'consolidat_reportadmin') {
      var diag_send_data = {
        bill_pay_type_req: this.bill_pay_type,
        doc_reg_id: this.doctorId,
        duration: filtdur,
        from_date: this.fromDate,
        hptl_clinic_id: this.hptlClinicId,
        to_date: this.toDate,
        userid: this.userInfo.user_id,

      };

      if (this.userInfo.user_type == "Admin") {
        var hospt_send_data;
        hospt_send_data = {
          bill_pay_type_req: this.bill_pay_type,
          doc_id: "All",
          duration: filtdur,
          from_date: this.fromDate,
          hptl_clinic_id: this.hptlClinicId,
          patient_type: "All",
          to_date: this.toDate,
          type: "doctor"
        };
      } else {
        hospt_send_data = {
          bill_pay_type_req: this.bill_pay_type,
          doc_id: "All",
          duration: filtdur,
          from_date: this.fromDate,
          hptl_clinic_id: this.hptlClinicId,
          patient_type: "All",
          to_date: this.toDate,
          type: "doctor",
          userid: this.userInfo.user_id
        };
      }

      var pharma_send_data;
      pharma_send_data = {
        hptl_clinic_id: this.hptlClinicId,
        type: "All",
        from_date: this.fromDate,
        to_date: this.toDate,
        duration: filtdur,
        paytype: "All",
        order_type: "All",
        createdby: this.userId
      };
      console.log("hospital send-------", hospt_send_data)
      console.log("this url", this.docurl)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.docurl, hospt_send_data,
        { headers: headers }).subscribe(
          data => {
            var docdataval = data.json();
            var docdataval1 = docdataval.report_details
            var userdata = docdataval.users
            if (docdataval != null) {
              this.salesList.length != 0
              this.consoldatedreport = true
              if (docdataval1 != undefined || docdataval1 != null) {
                var docobj = this.reportType == "returnsReport" ? docdataval.products : docdataval.report_details;
                var docuserobj = this.reportType == "returnsReport" ? docdataval.products : docdataval.users;
              }
              if (this.reportType == "CR" || this.reportType == 'consolidat_reportadmin') {
                for (var i = 0; i < docobj.length; i++) {
                  var name;
                  if (docobj[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(docobj[i].first_name) + " " + encrypt_decript.Decript(docobj[i].middle_name) + " " + encrypt_decript.Decript(docobj[i].last_name);
                  } else {
                    if (docobj[i].last_name != undefined) {
                      name = encrypt_decript.Decript(docobj[i].first_name) + " " + encrypt_decript.Decript(docobj[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(docobj[i].first_name);
                    }
                  }
                  if (docobj[i].cash == undefined || null) {
                    docobj[i].cash = "0.00"
                  }
                  if (docobj[i].card == undefined || null) {
                    docobj[i].card = "0.00"
                  }
                  if (docobj[i].upi == undefined || null) {
                    docobj[i].upi = "0.00"
                  }
                  if (docobj[i].insurance == undefined || null) {
                    docobj[i].insurance = "0.00"
                  }
                  if (docobj[i].balance == undefined || null) {
                    docobj[i].balance = "0.00"
                  }


                  var bill_amount = docobj[i].bill_amount != undefined ? encrypt_decript.Decript(docobj[i].bill_amount) : "";
                  this.salesList.push({
                    sno: i + 1,
                    billing_id: docobj[i].billing_id,
                    user_name: docobj[i].user_name,
                    created_date: Date_Formate(docobj[i].created_date),
                    patient_type: docobj[i].patient_type,
                    patient_name: name,
                    doc_name: docobj[i].doc_name,
                    apptype: docobj[i].app_type,
                    bill_amount: bill_amount,
                    paid_amount: docobj[i].paid_amount,
                    cash: docobj[i].cash,
                    card: docobj[i].card,
                    upi: docobj[i].upi,
                    insurance: docobj[i].insurance,
                    balance: docobj[i].balance,
                    pay_type: docobj[i].pay_type,
                    paid: docobj[i].paid_flag,
                    advance: docobj[i].advance,
                    pay_date: docobj[i].pay_date
                  });

                  if (docdataval.total_amount != undefined) {
                    this.doctotalAmount = (parseFloat(docdataval.total_amount)).toFixed(2);

                  }
                  if (docdataval.cash_amount != undefined) {
                    this.doccashAmount = (parseFloat(docdataval.cash_amount)).toFixed(2);
                  }
                  if (docdataval.card_amount != undefined) {
                    this.doccardAmount = (parseFloat(docdataval.card_amount)).toFixed(2);
                  }
                  if (docdataval.ins_amount != undefined) {
                    this.docinsureAmount = (parseFloat(docdataval.ins_amount)).toFixed(2);
                  }
                  if (docdataval.upi_amount != undefined) {
                    this.docupiAmount = (parseFloat(docdataval.upi_amount)).toFixed(2);
                  }
                  console.log("this total amount of docotor" + this.doctotalAmount)
                  this.forcoverdiv = false;
                  // this.amountcard = true;
                  this.backbutton = true;
                  // this.ishide=true;
                }
                this.docuserdataList = [];
                for (var j = 0; j < docuserobj.length; j++) {
                  console.log("user name=====>>" + docuserobj[j].user_name)
                  if (docuserobj[j].user_name != undefined || null) {
                    this.docuserdataList.push({
                      sno: j + 1,
                      username: docuserobj[j].user_name,
                      bill: docuserobj[j].total,
                      cash: docuserobj[j].cash,
                      card: docuserobj[j].card,
                      upi: docuserobj[j].upi,
                      check: docuserobj[j].cheque,
                      insurance: docuserobj[j].insurance,
                      balance: docuserobj[j].balance
                    })
                  }
                }

              }
            }
          },
          error => { });
      //diagnosis
      console.log("diag Send data-------", diag_send_data)
      console.log("diag this url", this.diagurl)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.diagurl, diag_send_data,
        { headers: headers }).subscribe(
          data => {
            var diagdataval = data.json();
            var diagdataval1 = diagdataval.report_details
            var userdata = diagdataval.users
            // var datavalclients = dataval.clients
            // var datavalsurg = dataval.surgeries
            console.log("array name diagdataval11", JSON.stringify(diagdataval1))
            console.log("whole diagdata ---------", JSON.stringify(diagdataval))
            this.salesList.length != 0
            this.consoldatedreport = true
            if (diagdataval1 != undefined || diagdataval1 != null) {
              var diagobj = this.reportType == "returnsReport" ? diagdataval.products : diagdataval.report_details;
              // var docuserobj=this.reportType == "returnsReport" ? diagdataval.products : diagdataval.users;
            }
            for (var i = 0; i < diagobj.length; i++) {
              var name;
              if (diagobj[i].middle_name != undefined) {
                name = encrypt_decript.Decript(diagobj[i].first_name) + " " + encrypt_decript.Decript(diagobj[i].middle_name) + " " + encrypt_decript.Decript(diagobj[i].last_name);
              } else {
                if (diagobj[i].last_name != undefined) {
                  name = encrypt_decript.Decript(diagobj[i].first_name) + " " + encrypt_decript.Decript(diagobj[i].last_name);
                } else {
                  name = encrypt_decript.Decript(diagobj[i].first_name);
                }
              }
              var bill_amount = diagobj[i].bill_amount != undefined ? encrypt_decript.Decript(diagobj[i].bill_amount) : "";
              this.diagnosissalesList.push({
                sno: i + 1,
                billing_id: diagobj[i].billing_id,
                user_name: diagobj[i].Bill_generated_by,
                created_date: Date_Formate(diagobj[i].created_date),
                patient_type: diagobj[i].patient_type,
                patient_name: name,
                advance: diagobj[i].advance,
                balance: diagobj[i].balance,
                bill_amount: bill_amount,
                pay_type: diagobj[i].pay_type,
                paid: diagobj[i].paid_flag,
              });
              if (diagdataval.total_amount != undefined) {
                this.diagtotalAmount = (parseFloat(diagdataval.total_amount)).toFixed(2);

              }
              if (diagdataval.cash_amount != undefined) {
                this.diagcashAmount = (parseFloat(diagdataval.cash_amount)).toFixed(2);
              }
              if (diagdataval.card_amount != undefined) {
                this.diagcardAmount = (parseFloat(diagdataval.card_amount)).toFixed(2);
              }
              if (diagdataval.ins_amount != undefined) {
                this.diaginsureAmount = (parseFloat(diagdataval.ins_amount)).toFixed(2);
              }
              if (diagdataval.upi_amount != undefined) {
                this.diagupiAmount = (parseFloat(diagdataval.upi_amount)).toFixed(2);
              }
              this.forcoverdiv = false;
              // this.amountcard = true;
              this.backbutton = true;
              // this.ishide=true;
            }

          },
          error => { });

      //pharma bill report


      console.log("pharma send data", pharma_send_data)
      console.log("pharma url", this.pharmaurl)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.pharmaurl, pharma_send_data,
        { headers: headers }).subscribe(
          data => {
            var pharmadataval = data.json();
            console.log(" pharma dataval" + JSON.stringify(pharmadataval))
            if (pharmadataval != null) {
              // this.noData = true;
              // this.hasData = false;
              // this.salesArray = [];
              if (pharmadataval.orders != undefined) {
                for (var i = 0; i < pharmadataval.orders.length; i++) {
                  var name;
                  if (this.reportType == "CR" || this.reportType == 'consolidat_reportadmin') {
                    //   name = pharmadataval.orders[i].deliver_to;
                    // } else {
                    if (pharmadataval.orders[i].deliver_to != undefined) {
                      name = pharmadataval.orders[i].deliver_to;
                    } else {
                      if (pharmadataval.orders[i].middle_name != undefined) {
                        name = encrypt_decript.Decript(pharmadataval.orders[i].first_name) + " " + encrypt_decript.Decript(pharmadataval.orders[i].middle_name) + " " + encrypt_decript.Decript(pharmadataval.orders[i].last_name);
                      } else {
                        name = encrypt_decript.Decript(pharmadataval.orders[i].first_name) + " " + encrypt_decript.Decript(pharmadataval.orders[i].last_name);
                      }
                    }
                  }
                  var orddate = pharmadataval.orders[i].order_date.split("-");
                  var amount = pharmadataval.orders[i].amount != undefined ? (parseFloat(pharmadataval.orders[i].amount)).toFixed(2) : "0.00";
                  if (pharmadataval.orders[i].balance != undefined) {
                    var pharmabalance = pharmadataval.orders[i].balance != undefined ? (parseFloat(pharmadataval.orders[i].balance)).toFixed(2) : "0.00";
                  }
                  if (pharmadataval.orders[i].balance == '') {
                    var pharmabalance = pharmadataval.orders[i].balance != '' ? (parseFloat(pharmadataval.orders[i].balance)).toFixed(2) : "0.00";
                  }

                  if (pharmadataval.orders[i].paid_flag != undefined && pharmadataval.orders[i].paid_flag != null && pharmadataval.orders[i].paid_flag != "") {
                    var pharmapayFlag = pharmadataval.orders[i].paid_flag.replace("_", " ");
                    pharmapayFlag = pharmapayFlag.charAt(0).toUpperCase() + pharmapayFlag.substr(1).toLowerCase();
                  }
                  console.log("pharma balance", pharmadataval.orders[i].balance)
                  // if(dataval.orders[i].balance == "undefined"){
                  //   dataval.order[i].balance = " "
                  // }

                  this.pharmasalesList.push({
                    order_id: pharmadataval.orders[i].order_id,
                    name: name,
                    created_by: pharmadataval.orders[i].created_by,
                    order_type: pharmadataval.orders[i].purchase_type,
                    mrp_book: pharmadataval.orders[i].mrp_book,
                    mrp_sold: pharmadataval.orders[i].mrp_sold,
                    amount: parseFloat(amount).toFixed(2),
                    deliver_to: pharmadataval.orders[i].deliver_to,
                    order_date: orddate[2] + "-" + orddate[1] + "-" + orddate[0],
                    qty_beforesales: pharmadataval.orders[i].qty_beforesale,
                    qty_sold: pharmadataval.orders[i].qty_sold,
                    qty_onhand: pharmadataval.orders[i].qty_onhand,
                    // balance: parseFloat(balance).toFixed(2),
                    balance: pharmabalance,
                    paid: pharmapayFlag,
                  })
                  this.forcoverdiv = false;
                  // this.amountcard=true;
                  this.backbutton = true;
                  // this.ishide=true;
                }

                if (pharmadataval.total_amount != undefined) {
                  this.pharmatotalAmount = (parseFloat(pharmadataval.total_amount)).toFixed(2);

                }

                if (pharmadataval.cash_amount != undefined) {
                  this.pharmacashAmount = (parseFloat(pharmadataval.cash_amount)).toFixed(2);
                }
                if (pharmadataval.card_amount != undefined) {
                  this.pharmacardAmount = (parseFloat(pharmadataval.card_amount)).toFixed(2);
                }
                if (pharmadataval.ins_amount != undefined) {
                  this.pharmainsureAmount = (parseFloat(pharmadataval.ins_amount)).toFixed(2);
                }
                if (pharmadataval.upi_amount != undefined) {
                  this.pharmaupiAmount = (parseFloat(pharmadataval.upi_amount)).toFixed(2);
                }
              }
              // else {
              //   this.salesArray = [];
              //   this.noData = false;
              //   this.hasData = true;
              // }
            }
          }, error => { });

      // var pharma_send_data={

      // }

    }

    if (this.reportType == 'CHBR') {
      send_data.pharma_id = this.pharmacistId
    }
    if (this.reportType == 'Patreport') {
      send_data.clientType = this.pattyperepo
    }
    if (this.reportType == 'SURREPO') {
      send_data.doc_reg_id = this.doctorId;
    }
    if (this.reportType == "DBR") {
      // send_data.userid=this.userInfo.user_id;
      if (this.userInfo.user_type != "Admin") {
        send_data.userid = this.userInfo.user_id;
        send_data.bill_pay_type_req = this.bill_pay_type
        if (this.Filter_by_Id == "Doctor") {
          this.applystyle = true;
          send_data.doc_reg_id = this.doctorId;
          console.log("Send data ++-------", send_data)
        }
        if (this.Filter_by_Id == "Speciality") {
          send_data.spl_id = this.doctorId;
          console.log("Send data ++-------", send_data)
        }
        if (this.Filter_by_Id == "Service") {
          send_data.charge_id = this.doctorId;
          console.log("Send data ++-------", send_data)
        }
      }
      if (this.userInfo.user_type == "Admin") {
        if (this.userId != "All") {
          send_data.userid = this.userId;
          send_data.bill_pay_type_req = this.bill_pay_type;
        } else if (this.userId == "All") {
          send_data.bill_pay_type_req = this.bill_pay_type
        }
      }
    }

    if (this.reportType == "returnsReport") {
      send_data = {
        pharma_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
        duration: filtdur,
      };
    }

    if (this.reportType == "SRD") {
      if (this.Filter_by_Id == "Doctor") {
        send_data.doc_reg_id = this.doctorId;
        console.log("Send data ++-------", send_data)
      }

      if (this.Filter_by_Id == "Speciality") {
        send_data.spl_id = this.doctorId;
        console.log("Send data ++-------", send_data)
      }

      if (this.Filter_by_Id == "Service") {
        send_data.charge_id = this.doctorId;
        console.log("Send data ++-------", send_data)
      }
    }

    if (this.reportType == "Bill_refund") {
      send_data.type = 'hospital'
    }

    if (this.reportType == "Bill_due") {
      send_data.type = "bill_due";
    }

    if (this.reportType == "bed_occupancy") {
      if (this.bed_occupancyfilter == 'ward') {
        send_data.type = this.bed_occupancyfilter
      }

      if (this.bed_occupancyfilter == 'bed') {
        send_data.type = this.bed_occupancyfilter
      }
    }

    if (this.reportType == "abc_analysis") {
      if (this.pharmaid != undefined && this.userInfo.user_type != "pharmacy") {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.pharmaid

      } else {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.userInfo.hospitals[0].hptl_clinic_id;
      }
    }
    if (this.reportType == "salesGst") {
      send_data.pharma_id = this.pharmaid
    }
    if (this.reportType == "sum_of_purchaseOrder") {
      if (this.pharmaid != undefined || null) {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.pharmaid
      }
    }

    if (this.reportType == "product") {
      if (this.productfilter == "product") {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.pharmaid
      }

      if (this.productfilter == "product Type") {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.pharmaid
        send_data.product_type_id = this.medicne_code;
      }
    }

    if (this.reportType == "product_receivables") {
      if (this.pharmaid != undefined || null) {
        delete send_data.hptl_clinic_id;
        send_data.pharma_id = this.pharmaid
      }
    }

    if (this.reportType == "lab_test_report") {
      if (this.DiagnosisCenterId != undefined) {
        delete send_data.hptl_clinic_id;
        send_data.diag_centre_id = this.DiagnosisCenterId
      }
    }

    if (this.reportType == "stockReceivable") {
      send_data.country = ipaddress.country_code
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.url, send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log("sales_data ="+JSON.stringify(dataval))
          var dataval1 = dataval.report_details;
          var dataNVR = dataval.reps;
          var userdata = dataval.users;
          var datavalclients = dataval.clients;
          var datavalsurg = dataval.surgeries;
          var dataPRR = dataval.products;
         
          if (dataval != null) {
            this.totalAmount = "0.00";
            this.DiscountAmount = "0.00";
            this.cashAmount = "0.00";
            this.cardAmount = "0.00";
            this.insureAmount = "0.00";
            this.upiAmount = "0.00";
            this.creditAmount = "0.00";
            if (dataval1 != undefined || dataval1 != null) {
              var objreturn = this.reportType == "returnsReport" ? dataval.products : dataval.return_details;
              var obj = this.reportType == "SalesGst" ? '' : dataval.report_details;

              var userobj = this.reportType == "returnsReport" ? dataval.products : dataval.users;
              var chargeobj = dataval.charge_group;

            } else if (datavalclients != undefined || datavalclients != null) {
              var obj = this.reportType == "returnsReport" ? dataval.products : dataval.clients;

            } else if (datavalsurg != undefined || datavalsurg != null) {
              var obj = this.reportType == "" ? dataval.products : dataval.surgeries;

            } else if (dataNVR != undefined || dataNVR != null) {
              var obj = this.reportType == "returnsReport" ? dataval.products : dataval.reps;

            } else if (dataPRR != undefined || dataPRR != null) {
              var obj = this.reportType == "returnsReport" ? dataval.products : dataval.products;

            } else {
              console.log("object type none")
            }

            if (obj != undefined) {
              if (this.reportType == "salesGst") {
                this.combinedArray = [];

                this.salestotalamount = dataval.total_amount != undefined ? (parseFloat(dataval.total_amount)).toFixed(2) : 0.00;
                for (var i = 0; i < obj.length; i++) {
                  var amount = obj[i].amount != undefined ? (parseFloat(obj[i].amount)).toFixed(2) : "0.00";

                  // if (obj[i].order_date != undefined) {
                  //   var order_date = Date_Formate(obj[i].order_date)
                  // } else {
                  //   var order_date = ''
                  // }
                  var clntname;
                  if(obj[i].first_name != undefined) {
                    if(obj[i].middle_name != undefined) {
                      clntname=encrypt_decript.Decript(obj[i].first_name)+" "+encrypt_decript.Decript(obj[i].middle_name)+" "+encrypt_decript.Decript(obj[i].last_name)
                    } else {
                      clntname=encrypt_decript.Decript(obj[i].first_name)+" "+encrypt_decript.Decript(obj[i].last_name)
                    }

                  } else { 
                    clntname=obj[i].delivered_person;
                  }
                  this.salesList.push({
                    sno: i + 1,
                    order_id: obj[i].order_id,
                    order_date: obj[i].order_date,
                    order_time: obj[i].order_time,
                    name: obj[i].clntname,
                    bill_amount: obj[i].bill_amount,
                    bill_disc: obj[i].bill_disc,
                    Exempt: obj[i].Exempt,
                    five_gst: obj[i]["5_GST"],
                    two_cgst: obj[i]["2_5_CGST"],
                    two_sgst:obj[i]["2_5_SGST"],
                    twosgst: obj[i]["2_5_SGST"],
                    twelve_gst: obj[i]["12_GST"],
                    six_cgst: obj[i]["6_CGST"],
                    six_sgst: obj[i]["6_SGST"],
                    eighteen_gst: obj[i]["18_GST"],
                    nine_cgst: obj[i]["9_CGST"],
                    nine_sgst: obj[i]["9_SGST"],
                    twenty_gst: obj[i]["28_GST"],
                    fourteen_cgst: obj[i]["14_CGST"],
                    fourteen_sgst: obj[i]["14_SGST"],
                    TotalCGST: obj[i].TotalCGST,
                    TotalSGST: obj[i].TotalSGST,
                    Total_Value: obj[i].total_amount,
                  });

                  this.forcoverdiv = false;
                  this.amountcard = true;
                  this.backbutton = true;
                }
                this.combinedArray=this.salesList

              } else if (this.reportType == "SRD") {
                if (dataval != undefined && dataval != null) {
                  for (var i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      name: dataval1[i].name,
                      count: dataval1[i].app_count,
                      fee: dataval1[i].fee,
                      amount: dataval1[i].amount
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "Bill_refund") {
                this.applystyle = true
                console.log(this.reportType)
                if (data != undefined && dataval != null) {
                  for (var i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      billid: dataval1[i].bill_id,
                      client_reg_id: dataval1[i].client_reg_id,
                      name: dataval1[i].name,
                      billdate: Date_Formate(dataval1[i].bill_date),
                      refund_amount: parseFloat(dataval1[i].amount).toFixed(2),
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "Bill_due") {
                this.applystyle = true
                if (data != undefined && dataval != null) {
                  for (var i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      billid: dataval1[i].bill_id,
                      client_reg_id: dataval1[i].client_reg_id,
                      name: dataval1[i].name,
                      bill_date: Date_Formate(dataval1[i].bill_date),
                      refund_amount: parseFloat(dataval1[i].amount).toFixed(2)
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "bed_occupancy") {
                if (data != undefined && dataval != null) {
                  for (i = 0; i < dataval1.length; i++) {
                    if (dataval1[i].ward_name === undefined || null) {
                      dataval1.splice(i, 1);
                      i--; // Decrement index to account for the removed element
                    } else {
                      this.salesList.push({
                        sno: i + 1,
                        wardname: dataval1[i].ward_name,
                        bedno: dataval1[i].bed_no
                      });
                    }
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "abc_analysis") {
                this.applystyle = true
                if (data != undefined) {
                  for (i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      table_name: dataval1[i].table_name,
                      product_rank: dataval1[i].price_rank,
                      product_name: dataval1[i].product_name,
                      quantity: dataval1[i].quantity,
                      sales: dataval1[i].sales,
                      type_name: dataval1[i].type_name,
                      ABC: dataval1[i].ABC
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "product") {
                if (data != undefined) {
                  for (i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      product_name: dataval1[i].product_name,
                      quantity: dataval1[i].quantity,
                      sales_amount: parseFloat(dataval1[i].sales_amount).toFixed(2)
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "product_receivables") {
                this.applystyle = true;
                if (data != undefined) {
                  for (i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      product_name: dataval1[i].product_name,
                      invoice_no: dataval1[i].invoice_no,
                      batch_no: dataval1[i].batch_no,
                      quantity: dataval1[i].total_qty,
                      free_quantity: dataval1[i].free_qty,
                      received_date: dataval1[i].received_date
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                    this.ishide = true;
                  }
                }

              } else if (this.reportType == "lab_test_report") {
                this.applystyle = true
                if (data != undefined && data != null) {
                  for (let i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      billingid: dataval1[i].billing_id,
                      billingdate: dataval1[i].bill_date,
                      amount: parseFloat(dataval1[i].fee).toFixed(2),
                      testname: dataval1[i].test_name,
                      testtype: dataval1[i].test_type_name
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                    this.ishide = true;
                  }
                }

              } else if (this.reportType == "sum_of_purchaseOrder") {
                for (i = 0; i < dataval1.length; i++) {
                  if (dataval1[i].po_id === undefined || null) {
                    dataval1.splice(i, 1);
                    i--; // Decrement index to account for the removed element
                  } else {
                    this.salesList.push({
                      sno: i + 1,
                      po_id: dataval1[i].po_id,
                      po_date: dataval1[i].po_date,
                      amount: parseFloat(dataval1[i].amount).toFixed(2)
                    })
                  }
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "stockReceivable") {
                for (var i = 0; i < obj.length; i++) {
                  var amount = obj[i].amount != undefined ? (parseFloat(obj[i].amount)).toFixed(2) : "0.00";
                  this.salesList.push({
                    sno: i + 1,
                    invoice_no: obj[i].invoice_no,
                    product_name: obj[i].product_name,
                    batch_no: obj[i].batch_no,
                    qty_received_date: Date_Formate(obj[i].qty_received_date),
                    received_qty: obj[i].total_qty,
                    free_qty: obj[i].free_qty,
                    mrp_value: obj[i].mrp_value,
                    mrp: obj[i].mrp,
                    cgst: obj[i].cgst + ' / ' + obj[i].sgst,
                    sgst_price: parseFloat(obj[i].sgst_price).toFixed(2) + " / " + parseFloat(obj[i].cgst_price).toFixed(2),
                    sgst_value: parseFloat(obj[i].sgst_value).toFixed(2),
                    total_gst: parseFloat(obj[i].total_gst).toFixed(2),
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "gsd") {
                for (var i = 0; i < obj.length; i++) {
                  var amount = obj[i].amount != undefined ? (parseFloat(obj[i].amount)).toFixed(2) : "0.00";
                  this.salesList.push({
                    sno: i + 1,
                    order_id: obj[i].order_id,
                    order_date: Date_Formate(obj[i].order_date),
                    product_name: obj[i].product_name,
                    doctor_name: obj[i].doctor_name,
                    delivered_person: obj[i].delivered_person,
                    batch_no: obj[i].batch_no,
                    supplier: obj[i].supplier,
                    category: obj[i].category,
                    qty_sold: obj[i].qty_sold,
                    expiry_date: Date_Formate(obj[i].expiry_date),
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "SDI") {
                for (var i = 0; i < obj.length; i++) {
                  var amount = obj[i].amount != undefined ? (parseFloat(obj[i].amount)).toFixed(2) : "0.00";
                  this.salesList.push({
                    sno: i + 1,
                    order_id: obj[i].order_id,
                    order_date: Date_Formate(obj[i].order_date),
                    product_name: obj[i].product_name,
                    batch_no: obj[i].batch_no,
                    mfg_name: obj[i].mfg_name,
                    sold_qty: obj[i].sold_qty,
                    qty_hand: obj[i].qty_hand,
                    amount: obj[i].amount,
                  });
                  this.forcoverdiv = false;
                  this.amountcard = true;
                  this.backbutton = true;
                }

              }
              else if (this.reportType == "NVR") {

                // var headers = new Headers();
                // var date
                // headers.append('Content-Type', 'application/json');
                // this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
                // { headers: headers })
                // .subscribe(
                // response => {
                // var obj = JSON.parse(response["_body"]);
                // console.log("current time",JSON.stringify(obj))
                // this.currentDate = obj.current_date;

                // this.toDate = obj.current_date;
                // var currenttime1=obj.current_time.split(":");
                // this.currenttime=currenttime1[0]
                // this.presentdate=obj.current_date;
                // this.updateCurrentSession(this.presentdate,this.currenttime)

                //   },
                //   error => 
                //   {
                //     this.toastr.error(Message_data.defaultErr);
                //   });

                console.log("Currentdaatesession----++++", this.currentdatesessionlist)
                // for(var j= 0;j<this.currentdatesessionlist.length;j++){

                // }



                for (var i = 0; i < dataNVR.length; i++) {


                  var patient_name, mobileno;
                  if (dataNVR[i].middle_name != undefined || dataNVR[i].middle_name != null) {
                    patient_name = dataNVR[i].first_name + " " + dataNVR[i].middle_name + " " + dataNVR[i].last_name;
                  }

                  else {

                    patient_name = dataNVR[i].first_name + " " + dataNVR[i].last_name;
                  }
                  if (dataNVR[i].first_name == undefined || null) {
                    patient_name = " ";
                  }
                  if (dataNVR[i].mobile == undefined || null) {
                    mobileno = " ";
                  }
                  if (dataNVR[i].mobile != undefined || null) {
                    mobileno = encrypt_decript.Decript(dataNVR[i].mobile)
                  }
                  for (const item of this.currentdatesessionlist) {
                    // Push date and session values into their respective arrays
                    this.checkdate = Date_Formate(item.date);
                    this.checksession = item.session;
                  }
                  // this.checkdate="18-05-2023"; 
                  console.log("check date", this.checkdate);
                  var presentcheckdate = Date_Formate(dataNVR[i].app_date);
                  var presentchecksession = encrypt_decript.Decript(dataNVR[i].session)
                  console.log("dataNVR date" + presentcheckdate);
                  console.log("checksession", presentchecksession)

                  if (this.checkdate == presentcheckdate) {

                    // this.salesList.push({
                    //   sno: i + 1,
                    //   patientname: patient_name,
                    //   number: encrypt_decript.Decript(dataNVR[i].mobile),
                    //   date: Date_Formate(dataNVR[i].app_date),
                    //   session:presentchecksession
                    // });
                    if (this.checksession == "Morning") {
                      dataNVR.splice(i, 1);
                      i--;
                    }
                    else if (this.checksession == "Afternoon") {
                      if (presentchecksession == "Morning") {
                        this.salesList.push({
                          sno: i + 1,
                          patientname: encrypt_decript.Decript(patient_name),
                          number: mobileno,
                          date: Date_Formate(dataNVR[i].app_date),
                          session: presentchecksession
                        });
                      }

                    }

                    else {
                      if (presentchecksession == "Morning" || presentchecksession == "Afternoon") {
                        this.salesList.push({
                          sno: i + 1,
                          patientname: encrypt_decript.Decript(patient_name),
                          number: mobileno,
                          date: Date_Formate(dataNVR[i].app_date),
                          session: presentchecksession
                        });

                      }
                    }
                  } else {
                    this.salesList.push({
                      sno: i + 1,
                      patientname: encrypt_decript.Decript(patient_name),
                      number: mobileno,
                      date: Date_Formate(dataNVR[i].app_date),
                      session: presentchecksession
                    });
                  }

                  // this.forcoverdiv = false;
                  // this.backbutton = true;


                  // if(this.checkdate == dataNVR[i].app_date){

                  // }
                  // console.log("check date",this.checkdate)
                  // console.log("check session",this.checksession)

                  // this.salesList.push({
                  //   sno: i + 1,
                  //   patientname:patient_name,
                  //    number: encrypt_decript.Decript(dataNVR[i].mobile),
                  //    date: Date_Formate(dataNVR[i].app_date)

                  // });

                  // for (const item of this.currentdatesessionlist) {
                  //   // Push date and session values into their respective arrays
                  //   this.checkdate = item.date;
                  //   this.checksession = Date_Formate(item.session);
                  // }

                  this.forcoverdiv = false;
                  this.backbutton = true;

                }
              }
              else if (this.reportType == "HBR") {
                console.log("objectHBR = "+JSON.stringify(obj))
                this.chargeList = [];
                for (var i = 0; i < obj.length; i++) {
                  var name;
                  if (obj[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                  } else {
                    if (obj[i].last_name != undefined) {
                      name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(obj[i].first_name);
                    }
                  }
                  if (obj[i].cash == undefined || null) {
                    obj[i].cash = "0.00"
                  }
                  if (obj[i].card == undefined || null) {
                    obj[i].card = "0.00"
                  }
                  if (obj[i].upi == undefined || null) {
                    obj[i].upi = "0.00"
                  }
                  if (obj[i].insurance == undefined || null) {
                    obj[i].insurance = "0.00"
                  }
                  if (obj[i].balance == undefined || null) {
                    obj[i].balance = "0.00"
                  }
                  if (obj[i].discount == undefined || null) {
                    obj[i].discount = "0.00"
                  }
                  var dateval;
                  if (obj[i].pay_date != undefined) {
                    dateval = Date_Formate(obj[i].pay_date);
                  }
                  if(obj[i].pay_time != undefined){
                    var paytime = Time_Formate(obj[i].pay_time)
                  }else{
                    var paytime=''
                  }
                  var created_time;
                  if(obj[i].created_time != undefined){
                    created_time=Time_Formate(obj[i].pay_time)
                  }else{
                    created_time=''
                  }

                  var bill_amount = obj[i].bill_amount != undefined ? encrypt_decript.Decript(obj[i].bill_amount) : "";
                  this.salesList.push({
                    sno: i + 1,
                    billing_id: obj[i].billing_id,
                    user_name: obj[i].user_name,
                    created_date: Date_Formate(obj[i].created_date)+" "+created_time,
                    patient_type: obj[i].patient_type,
                    mr_no:obj[i].mr_no || '',
                    patient_name: name,
                    doc_name: obj[i].doc_name,
                    apptype: obj[i].app_type,
                    bill_amount: bill_amount,
                    paid_amount: obj[i].paid_amount,
                    discount: obj[i].discount,
                    cash: obj[i].cash,
                    card: obj[i].card,
                    upi: obj[i].upi,
                    insurance: obj[i].insurance,
                    balance: obj[i].balance,
                    pay_type: obj[i].pay_type,
                    paid: obj[i].paid_flag,
                    advance: obj[i].advance,
                    pay_date: dateval+" "+paytime,
                  });

                  this.forcoverdiv = false;
                  this.amountcard = true;
                  this.backbutton = true;
                  // this.ishide=true;
                }
                if (chargeobj != undefined && chargeobj.length != 0) {

                  for (var k = 0; k < chargeobj.length; k++) {
                    if (chargeobj[k].charge_desc != undefined || null) {
                      this.chargeList.push({
                        sno: k + 1,
                        charge: chargeobj[k].charge_desc,
                        billamt: chargeobj[k].bill_amount,
                        paidamt: chargeobj[k].paid_amount,
                        cash: chargeobj[k].cash,
                        card: chargeobj[k].card,
                        upi: chargeobj[k].upi,
                        cheque: chargeobj[k].cheque,
                        insurance: chargeobj[k].insurance,
                      })
                    }
                  }
                }

                if (userobj != undefined && userobj.length != 0) {
                  this.userdataList = [];
                  for (var j = 0; j < userobj.length; j++) {
                    console.log("user name=====>>" + userobj[j].user_name)
                    if (userobj[j].user_name != undefined || null) {
                      this.userdataList.push({
                        sno: j + 1,
                        username: userobj[j].user_name,
                        bill: userobj[j].total,
                        cash: userobj[j].cash,
                        card: userobj[j].card,
                        upi: userobj[j].upi,
                        cheque: userobj[j].cheque,
                        discount: userobj[j].discount,
                        insurance: userobj[j].insurance,
                        balance: userobj[j].balance
                      })
                    }
                  }
                }

                if (this.salesList.length != 0) {
                  this.BillAmt_Subtotal = 0;
                  this.discountAmt_Subtotal = 0;
                  this.paidAmt_Subtotal = 0;
                  this.cash_Subtotal = 0;
                  this.card_Subtotal = 0;
                  this.upi_Subtotal = 0;
                  this.ins_Subtotal = 0;
                  this.due_Subtotal = 0;

                  for (var i = 0; i < this.salesList.length; i++) {
                    if (this.salesList[i].bill_amount != undefined) {
                      this.BillAmt_Subtotal = this.BillAmt_Subtotal + parseInt(this.salesList[i].bill_amount);
                    }
                    if (this.salesList[i].paid_amount != undefined) {
                      this.paidAmt_Subtotal = this.paidAmt_Subtotal + parseInt(this.salesList[i].paid_amount);
                    }
                    if (this.salesList[i].discount != undefined) {
                      this.discountAmt_Subtotal = this.discountAmt_Subtotal + parseInt(this.salesList[i].discount);
                    }
                    if (this.salesList[i].cash != undefined) {
                      this.cash_Subtotal = this.cash_Subtotal + parseInt(this.salesList[i].cash);
                    }
                    if (this.salesList[i].card != undefined) {
                      this.card_Subtotal = this.card_Subtotal + parseInt(this.salesList[i].card);
                    }
                    if (this.salesList[i].upi != undefined) {
                      this.upi_Subtotal = this.upi_Subtotal + parseInt(this.salesList[i].upi);
                    }
                    if (this.salesList[i].balance != undefined) {
                      this.due_Subtotal = this.due_Subtotal + parseInt(this.salesList[i].balance);
                    }
                  }
                }

                if (this.pattype == 'out-pat') {
                  this.newPat_arr = [];
                  this.followUpPat_arr = [];
                  for (var i = 0; i < this.salesList.length; i++) {
                    if (this.salesList[i].apptype == 'New' && this.salesList[i].patient_type == 'OP') {
                      this.newPat_arr.push(this.salesList[i]);
                    } else if (this.salesList[i].apptype == 'Followup' && this.salesList[i].patient_type == 'OP') {
                      this.followUpPat_arr.push(this.salesList[i]);
                    }
                  }

                  if (this.group_by == 'all') {
                    if (this.newPat_arr.length != 0) {
                      this.showNewPat_arr = true;
                    } else {
                      this.showNewPat_arr = false;
                    }
                    if (this.followUpPat_arr.length != 0) {
                      this.showOldPat_arr = true;
                    } else {
                      this.showOldPat_arr = false;
                    }
                  } else if (this.group_by == 'new') {
                    this.showNewPat_arr = true;
                    this.showOldPat_arr = false;
                  } else {
                    this.showNewPat_arr = false;
                    this.showOldPat_arr = true;
                  }

                  console.log("this.newPat_arr-----------", this.newPat_arr)
                  console.log("this.followUpPat_arr-----------", this.followUpPat_arr)

                  if (this.newPat_arr.length != 0) {
                    this.BillAmt_Subtotal = 0;
                    this.discountAmt_Subtotal = 0;
                    this.paidAmt_Subtotal = 0;
                    this.cash_Subtotal = 0;
                    this.card_Subtotal = 0;
                    this.upi_Subtotal = 0;
                    this.ins_Subtotal = 0;
                    this.due_Subtotal = 0;

                    for (var i = 0; i < this.newPat_arr.length; i++) {
                      if (this.newPat_arr[i].bill_amount != undefined) {
                        this.BillAmt_Subtotal = this.BillAmt_Subtotal + parseInt(this.newPat_arr[i].bill_amount);
                      }
                      if (this.newPat_arr[i].paid_amount != undefined) {
                        this.paidAmt_Subtotal = this.paidAmt_Subtotal + parseInt(this.newPat_arr[i].paid_amount);
                      }
                      if (this.newPat_arr[i].discount != undefined) {
                        this.discountAmt_Subtotal = this.discountAmt_Subtotal + parseInt(this.newPat_arr[i].discount);
                      }
                      if (this.newPat_arr[i].cash != undefined) {
                        this.cash_Subtotal = this.cash_Subtotal + parseInt(this.newPat_arr[i].cash);
                      }
                      if (this.newPat_arr[i].card != undefined) {
                        this.card_Subtotal = this.card_Subtotal + parseInt(this.newPat_arr[i].card);
                      }
                      if (this.newPat_arr[i].upi != undefined) {
                        this.upi_Subtotal = this.upi_Subtotal + parseInt(this.newPat_arr[i].upi);
                      }
                      if (this.newPat_arr[i].balance != undefined) {
                        this.due_Subtotal = this.due_Subtotal + parseInt(this.newPat_arr[i].balance);
                      }
                    }
                  }

                  if (this.followUpPat_arr.length != 0) {
                    this.BillAmt_Subtotal_1 = 0;
                    this.discountAmt_Subtotal_1 = 0;
                    this.paidAmt_Subtotal_1 = 0;
                    this.cash_Subtotal_1 = 0;
                    this.card_Subtotal_1 = 0;
                    this.upi_Subtotal_1 = 0;
                    this.ins_Subtotal_1 = 0;
                    this.due_Subtotal_1 = 0;

                    for (var i = 0; i < this.followUpPat_arr.length; i++) {
                      if (this.followUpPat_arr[i].bill_amount != undefined) {
                        this.BillAmt_Subtotal_1 = this.BillAmt_Subtotal_1 + parseInt(this.followUpPat_arr[i].bill_amount);
                      }
                      if (this.followUpPat_arr[i].paid_amount != undefined) {
                        this.paidAmt_Subtotal_1 = this.paidAmt_Subtotal_1 + parseInt(this.followUpPat_arr[i].paid_amount);
                      }
                      if (this.followUpPat_arr[i].discount != undefined) {
                        this.discountAmt_Subtotal_1 = this.discountAmt_Subtotal_1 + parseInt(this.followUpPat_arr[i].discount);
                      }
                      if (this.followUpPat_arr[i].cash != undefined) {
                        this.cash_Subtotal_1 = this.cash_Subtotal_1 + parseInt(this.followUpPat_arr[i].cash);
                      }
                      if (this.followUpPat_arr[i].card != undefined) {
                        this.card_Subtotal_1 = this.card_Subtotal_1 + parseInt(this.followUpPat_arr[i].card);
                      }
                      if (this.followUpPat_arr[i].upi != undefined) {
                        this.upi_Subtotal_1 = this.upi_Subtotal_1 + parseInt(this.followUpPat_arr[i].upi);
                      }
                      if (this.followUpPat_arr[i].balance != undefined) {
                        this.due_Subtotal_1 = this.due_Subtotal_1 + parseInt(this.followUpPat_arr[i].balance);
                      }
                    }
                  }
                }

              } else if (this.reportType == "CHBR") {
                if (dataval != undefined && dataval != null) {
                  for (var i = 0; i < dataval1.length; i++) {
                    this.salesList.push({
                      sno: i + 1,
                      bill_no: dataval1[i].order_id,
                      batchno: dataval1[i].batch_no,
                      product_name: dataval1[i].product_name,
                      mrponbook: dataval1[i].mrp_book,
                      mrpsold: dataval1[i].mrp_sold,
                      soldqty: dataval1[i].sold_qty,
                      amount: dataval1[i].amount,
                      created: dataval1[i].created_by,
                      date: Date_Formate(dataval1[i].order_date),
                      sold_qty: dataval1[i].sold_qty

                    })
                    this.forcoverdiv = false;
                    this.amountcard = true;
                    this.backbutton = true;
                    // this.ishide=true;
                  }
                }

              } else if (this.reportType == "Patreport") {
                if (dataval != undefined && dataval != null) {
                  for (var i = 0; i < datavalclients.length; i++) {
                    var patient_name;
                    if (datavalclients[i].middle_name != undefined || datavalclients[i].middle_name != null) {
                      patient_name = datavalclients[i].first_name + " " + datavalclients[i].middle_name + " " + datavalclients[i].last_name;
                    } else {
                      patient_name = datavalclients[i].first_name + " " + datavalclients[i].last_name;
                    }
                    this.patientnameList = patient_name
                    console.log('...........name', this.patientnameList)
                    this.salesList.push({
                      sno: i + 1,
                      name: encrypt_decript.Decript(this.patientnameList),
                      MobileNo: encrypt_decript.Decript(datavalclients[i].mobile),
                      gender: encrypt_decript.Decript(datavalclients[i].gender),
                      age: datavalclients[i].age,
                      patId: datavalclients[i].patient_id
                    })
                    this.forcoverdiv = false;
                    this.backbutton = true;
                  }
                }

              } else if (this.reportType == "SURREPO") {
                for (var i = 0; i < datavalsurg.length; i++) {
                  var patient_name;
                  var surgon_name;
                  if (datavalsurg[i].dr_middle_name != undefined || datavalsurg[i].dr_middle_name != null) {
                    surgon_name = datavalsurg[i].dr_first_name + " " + datavalsurg[i].dr_middle_name + " " + datavalsurg[i].dr_last_name;
                  } else {
                    surgon_name = datavalsurg[i].dr_first_name + " " + datavalsurg[i].dr_last_name;
                  }

                  if (datavalsurg[i].middle_name != undefined || datavalsurg[i].middle_name != null) {
                    patient_name = datavalsurg[i].dr_first_name + " " + datavalsurg[i].dr_middle_name + " " + datavalsurg[i].dr_last_name;
                  } else {
                    patient_name = datavalsurg[i].first_name + " " + datavalsurg[i].last_name;
                  }

                  // this.patientnameList = patient_name
                  console.log("patient name ----->>" + encrypt_decript.Decript(patient_name))
                  this.salesList.push({
                    sno: i + 1,
                    patientid: datavalsurg[i].patient_id,
                    name: encrypt_decript.Decript(patient_name),
                    MobileNo: encrypt_decript.Decript(datavalsurg[i].mobile),
                    age: datavalsurg[i].age,
                    gender: encrypt_decript.Decript(datavalsurg[i].gender),
                    surgonname: surgon_name,
                    surgery: datavalsurg[i].surgery,
                    date: Date_Formate(datavalsurg[i].surgery_date)
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "DBR") {
                for (var i = 0; i < obj.length; i++) {
                  var name;
                  if (obj[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                  } else {
                    if (obj[i].last_name != undefined) {
                      name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(obj[i].first_name);
                    }
                  }


                  if (obj[i].paid_amount == undefined || null) {
                    obj.paid_amount = "0.00"
                  }
                  if (obj[i].cash == undefined || null) {
                    obj[i].cash = "0.00"
                  }
                  if (obj[i].card == undefined || null) {
                    obj[i].card = "0.00"
                  }
                  if (obj[i].upi == undefined || null) {
                    obj[i].upi = "0.00"
                  }
                  if (obj[i].insurance == undefined || null) {
                    obj[i].insurance = "0.00"
                  }
                  if (obj[i].balance == undefined || null) {
                    obj[i].balance = "0.00"
                  }
                  if (obj[i].discount == undefined || null) {
                    obj[i].discount = "0.00"
                  }



                  var bill_amount = obj[i].bill_amount != undefined ? encrypt_decript.Decript(obj[i].bill_amount) : "";
                  this.salesList.push({
                    sno: i + 1,
                    billing_id: obj[i].billing_id,
                    user_name: obj[i].Bill_generated_by,
                    created_date: Date_Formate(obj[i].created_date),
                    patient_type: obj[i].patient_type,
                    patient_name: name,
                    advance: obj[i].advance,
                    balance: obj[i].balance,
                    discount: obj[i].discount,
                    bill_amount: bill_amount,
                    paid_amount: obj[i].paid_amount,
                    cash: obj[i].cash,
                    card: obj[i].card,
                    upi: obj[i].upi,
                    insurance: obj[i].insurance,
                    // balance: obj[i].balance,
                    pay_type: obj[i].pay_type,
                    paid: obj[i].paid_flag,
                  });
                  this.forcoverdiv = false;
                  this.amountcard = true;
                  this.backbutton = true;
                }

                this.salesList = this.salesList.filter(item => item.patient_type == 'OP').concat(this.salesList.filter(item => item.patient_type == 'IP'));

                for (var j = 0; j < this.salesList.length; j++) {
                  this.salesList[j].sno = j + 1;
                }

              } else if (this.reportType == "IR") {
                for (var i = 0; i < obj.length; i++) {
                  this.salesList.push({
                    sno: i + 1,
                    order_id: obj[i].order_id,
                    prop_name: obj[i].prop_name,
                    prod_name: obj[i].prod_name,
                    batch_no: obj[i].batch_no,
                    quantity: obj[i].quantity,
                    balance: obj[i].balance,
                    discount: obj[i].discount,
                    amount: obj[i].amount,
                  });
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "IPR") {
                for (var i = 0; i < obj.length; i++) {
                  var name;
                  if (obj[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                  } else {
                    if (obj[i].last_name != undefined) {
                      name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(obj[i].first_name);
                    }
                  }
                  var discharge_date = obj[i].discharge_date != undefined ? Date_Formate(obj[i].discharge_date) + "  " + obj[i].discharge_time : "";
                  if (obj[i].admitted_date != undefined) {
                    const timeParts = obj[i].admitted_time.split(':');
                    obj[i].admitted_time = `${timeParts[0]}:${timeParts[1]}`;
                  }
                  var admission_date = obj[i].admitted_date != undefined ? Date_Formate(obj[i].admitted_date) + "  " + obj[i].admitted_time : "";
                  this.salesList.push({
                    sno: i + 1,
                    admission: admission_date,
                    patient_name: name,
                    age: obj[i].age,
                    doctor_name: obj[i].doctor_name,
                    bed_no: obj[i].bed_no,
                    discharge: discharge_date,
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }

              } else if (this.reportType == "returnsReport") {
                for (var i = 0; i < obj.length; i++) {
                  this.salesList.push({
                    sno: i + 1,
                    product_name: obj[i].product_name,
                    Supplier: obj[i].supp_name,
                    batch: obj[i].batch_no,
                    expiryDate: obj[i].expiry_date,
                    qty: obj[i].qty_return,
                    returned_date: obj[i].returned_date
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true;
                }
              }

              if (dataval.total_amount != undefined) {
                this.totalAmount = (parseFloat(dataval.total_amount)).toFixed(2);
                this.totalAmtFlag = true;
              }
              if (dataval.discount != undefined) {
                this.DiscountAmount = (parseFloat(dataval.discount)).toFixed(2);
                // this.totalAmtFlag = true;
              }
              if (dataval.cash_amount != undefined) {
                this.cashAmount = (parseFloat(dataval.cash_amount)).toFixed(2);
              }

              if (dataval.card_amount != undefined) {
                this.cardAmount = (parseFloat(dataval.card_amount)).toFixed(2);
              }
              if (dataval.ins_amount != undefined) {
                this.insureAmount = (parseFloat(dataval.ins_amount)).toFixed(2);
              }
              if (dataval.upi_amount != undefined) {
                this.upiAmount = (parseFloat(dataval.upi_amount)).toFixed(2);
              }
              //added

              if (dataval.credit_amount != undefined) {
                this.creditAmount = (parseFloat(dataval.credit_amount)).toFixed(2);
              }

              // this.creditAmount);
            } else {
              this.salesList = [];
            }
          }
        },
        error => { });
  }

  getExcelData(type) {
    //hospital
    if (this.salesList.length != 0) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Sales report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 10;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 30;
      worksheet.getColumn(5).width = 20;
      worksheet.getColumn(6).width = 15;
      worksheet.getColumn(7).width = 20;
      worksheet.getColumn(8).width = 20;
      worksheet.getColumn(9).width = 20;
      worksheet.getColumn(10).width = 20;
      worksheet.getColumn(11).width = 20;
      worksheet.getColumn(12).width = 20;
      if (this.reportType != "salesGst") {
        var headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        });

        for (let x1 of this.salesList) {
          let x2 = Object.keys(x1);
          
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
      } else {
        //salesgt
        var headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        });

        for (let x1 of this.salesList) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
      }

      if (this.reportType == 'HBR') {
        var cashdata = ['', '', '', '', '', '', '', 'Cash', this.cashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(8);
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(9);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        let paidamt = cashrow.getCell(10);
        paidamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        let balamt = cashrow.getCell(11);
        balamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        var carddata = ['', '', '', '', '', '', '', 'Card', this.cardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(8);
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(9);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', '', '', 'Insurance', this.insureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(8);
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(9);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', '', '', 'UPI', this.upiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(8);
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(9);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', '', '', 'Total', this.totalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(8);
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(9);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }
      }

      // added
      if (this.reportType == 'CR') {
        var cashdata = ['', '', '', '', '', '', '', 'Cash', this.doccashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(8);
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(9);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        let paidamt = cashrow.getCell(10);
        paidamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        let balamt = cashrow.getCell(11);
        balamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        var carddata = ['', '', '', '', '', '', '', 'Card', this.doccardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(8);
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(9);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', '', '', 'Insurance', this.docinsureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(8);
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(9);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', '', '', 'UPI', this.docupiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(8);
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(9);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var finaldata = ['', '', '', '', '', '', '', 'Total', this.doctotalAmount];
        let finalrow = worksheet.addRow(finaldata);
        let totalval = finalrow.getCell(8);
        totalval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let totalamt = finalrow.getCell(9);
        totalamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        totalamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
      }

      // added
      if (this.reportType == 'salesGst') {     
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', '', '', '', 'Total', this.totalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(8);
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(9);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }

          var finaldatareturn = ['', '', '', '', '', '', '', '', 'Return', this.returntotalamount];
          let finalrowreturn = worksheet.addRow(finaldatareturn);
          let totalvalreturn = finalrowreturn.getCell(8);
          totalvalreturn.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamtreturn = finalrowreturn.getCell(9);
          totalamtreturn.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalamtreturn.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }

          var finaldatafinal = ['', '', '', '', '', '', '', '', 'Final', this.finalamount];
          let finalrowfinal = worksheet.addRow(finaldatafinal);
          let totalvalfinal = finalrowfinal.getCell(8);
          totalvalfinal.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamtfinal = finalrowfinal.getCell(9);
          totalamtfinal.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalamtfinal.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }
      }

      if (this.reportType == 'SDI') {
        var cashdata = ['', '', '', '', '', '', '', '', 'Cash', this.cashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(8);
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(9);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', '', '', '', '', 'Card', this.cardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(8);
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(9);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', '', '', '', 'Insurance', this.insureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(8);
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(9);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', '', '', '', 'UPI', this.upiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(8);
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(9);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', '', '', '', 'Total', this.totalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(8);
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(9);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }
      }

      if (this.reportType == 'DBR') {
        var cashdata = ['', '', '', '', '', '', '', 'Cash', this.cashAmount];
        let cashrow = worksheet.addRow(cashdata);

        let cashval = cashrow.getCell(8);
        cashval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(9);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', '', '', '', 'Card', this.cardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(8);
        cardval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(9);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', '', '', 'Insurance', this.insureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(8);
        insval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(9);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', '', '', 'UPI', this.upiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(8);
        upival.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(9);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', '', '', 'Total', this.totalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(8);
          totalval.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(9);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }

      }
      if (this.reportType == 'CHBR') {
        var cashdata = ['', '', '', '', '', 'Cash', this.cashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(6);
        cashval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(7);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', '', 'Card', this.cardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(6);
        cardval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(7);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', 'Insurance', this.insureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(6);
        insval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(7);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', 'UPI', this.upiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(6);
        upival.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(7);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', 'Total', this.totalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(6);
          totalval.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(7);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }

      }

      var emptyRow = worksheet.addRow(" ")
      var emptyRowsec = worksheet.addRow(" ")
      var headerRow = worksheet.addRow(this.userdatatableheader);
      emptyRow.eachCell(() => { })
      emptyRowsec.eachCell(() => { })
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      });
      for (let x4 of this.userdataList) {
        let x5 = Object.keys(x4);
        let temp = []
        for (let y of x5) {
          temp.push(x4[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }


      // var emptyRow = worksheet.addRow(" ")
      // var emptyRowsec = worksheet.addRow(" ")
      var headerRow = worksheet.addRow(this.docuserdatatableheader);
      emptyRow.eachCell(() => { })
      emptyRowsec.eachCell(() => { })
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      });
      for (let x8 of this.docuserdataList) {
        let x9 = Object.keys(x8);
        let temp = []
        for (let y of x9) {
          temp.push(x8[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }



      //diagnosis
      var emptyRow = worksheet.addRow(" ")
      var emptyRowsec = worksheet.addRow(" ")
      var headerRow = worksheet.addRow(this.diagheader);
      emptyRow.eachCell(() => { })
      emptyRowsec.eachCell(() => { })
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      });
      for (let x6 of this.diagnosissalesList) {
        let x7 = Object.keys(x6);
        let temp = []
        for (let y of x7) {
          temp.push(x6[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }

      if (this.reportType == "CR" && this.reportHeading == "Consolidated  report") {
        var cashdata = ['', '', '', '', '', '', '', 'Cash', this.diagcashAmount];
        let cashrow = worksheet.addRow(cashdata);

        let cashval = cashrow.getCell(8);
        cashval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(9);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', '', '', '', 'Card', this.diagcardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(8);
        cardval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(9);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', '', '', 'Insurance', this.diaginsureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(8);
        insval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(9);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', '', '', 'UPI', this.diagupiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(8);
        upival.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let upiamt = upirow.getCell(9);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        if (this.totalAmtFlag == true) {
          var finaldata = ['', '', '', '', '', '', '', 'Total', this.diagtotalAmount];
          let finalrow = worksheet.addRow(finaldata);
          let totalval = finalrow.getCell(8);
          totalval.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
          totalval.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
          let totalamt = finalrow.getCell(9);
          totalamt.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
          totalamt.font = {
            bold: true,
            color: { argb: '000000' },
            size: 12
          }
        }

      }

      //pharma
      var emptyRow = worksheet.addRow(" ")
      var emptyRowsec = worksheet.addRow(" ")
      var headerRow = worksheet.addRow(this.pharmaheader);
      emptyRow.eachCell(() => { })
      emptyRowsec.eachCell(() => { })
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      });
      for (let x10 of this.pharmasalesList) {
        let x11 = Object.keys(x10);
        let temp = []
        for (let y of x11) {
          temp.push(x10[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }

      if ((this.reportType == "CR" && this.reportHeading == "Consolidated  report") || (this.reportType == "consolidat_reportadmin" && this.reportHeading == "Consolidated  report")) {
        var cashdata = ['', '', '', '', '', 'Cash', this.pharmacashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(6);
        cashval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(7);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', '', 'Card', this.pharmacardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(6);
        cardval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(7);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', '', 'Insurance', this.pharmainsureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(6);
        insval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(7);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', '', 'UPI', this.pharmaupiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(6);
        upival.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        let upiamt = upirow.getCell(7);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var finaldata = ['', '', '', '', '', 'Total', this.pharmatotalAmount];
        let finalrow = worksheet.addRow(finaldata);
        let totalval = finalrow.getCell(6);
        totalval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        totalval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let totalamt = finalrow.getCell(7);
        totalamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        totalamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
      }

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Sales_report" + '-' + new Date().valueOf() + '.xlsx');
      });

    }
    else {
      this.toastr.error(Message_data.exportExcelFailed);
    }




  }

  getRetrievalData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    console.log("get retrieval data" + send)
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          // var values = response.json();
          var values = JSON.parse(JSON.stringify(response));
          console.log("retrive obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  print_area() {
    this.amount = true;
    let printContents, popupWin, totalamt, cashamt, cardamt, insureamt, upiamt, usertable, chargetable, printCRHBRContents,
      CRHBRtotalamt, CRHBRcashamt, CRHBRCRcardamt, CRHBRinsureamt, CRHBRupiamt, CRHBRusertable, printCRDBRContents,

      CRDBRtotalamt, CRDBRcashamt, CRDBRcardamt, CRDBRinsureamt, CRDBRupiamt, printpharmaContents, creditAmt,

      pharmatotalamt, pharmacashamt, pharmacardamt, pharmainsureamt, pharmaupiamt, logo, hospitaldetails, discountamt, totalreturns, finalamount;


    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;

    if (this.print_template != undefined && this.print_template == "banner") {

      if (this.reportType == "DBR" || this.reportType == "salesGst" || this.reportType == "SDI" || this.reportType == "CHBR") {
        if (this.reportType == 'CHBR') {
          this.amount = false;
          printContents = this.printCHBR.nativeElement.innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;

        } else if (this.reportType == 'DBR') {
          this.amount = false;

          printContents = this.printDBR.nativeElement.innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;


        } else if (this.reportType == 'salesGst') {
          this.amount = false;
          printContents = this.printsalesGST.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          totalreturns = this.printsalesreturns.nativeElement.innerHTML;
          finalamount = this.printsalesfinal.nativeElement.innerHTML;
          cashamt = 0.00
          cardamt = 0.00
          insureamt = 0.00
          upiamt = 0.00
          discountamt = 0.00
          insureamt = 0.00
          // cashamt = this.cashamount.nativeElement.innerHTML
          // cardamt = this.cardamount.nativeElement.innerHTML;
          // insureamt = this.insureamount.nativeElement.innerHTML;
          // upiamt = this.upiamount.nativeElement.innerHTML;

        } else if (this.reportType == 'SDI') {
          this.amount = false;
          printContents = this.printSDI.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;

        } else {
          console.log("error")
        }

        if (this.reportType == 'salesGst') {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();
          popupWin.document.write(`
  
      <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
         
          <table style="border:'0';width:100%; margin: 20px;">  
          <thead>
          <tr>
          ${logo}
          </tr>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                 <thead>
                   <tr>
                     <th colspan="3">${totalamt}</th>
                     <th colspan="2">${totalreturns}</th>
                     <th colspan="2">${finalamount}</th>
                     
                  </tr>
                </thead>
               </table>
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
       </body>
        </html>`
          );

          popupWin.document.close();
        } else {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();
          popupWin.document.write(`
  
      <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
         
          <table style="border:'0';width:100%; margin: 20px;">  
          <thead>
          <tr>
          ${logo}
          </tr>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                 <thead>
                   <tr>
                     <th colspan="3">${totalamt}</th>
                     <th colspan="2">${cashamt}</th>
                     <th colspan="2">${cardamt}</th>
                     <th colspan="2">${upiamt}</th>
                     <th colspan="2">${insureamt}</th>
                     <th colspan="2">${discountamt}</th>
                     <th colspan="2">${insureamt}</th>
                  </tr>
                </thead>
               </table>
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
       </body>
        </html>`
          );

          popupWin.document.close();
        }

      }
      else if (this.reportType == "HBR" && this.chargeList.length == 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          creditAmt = this.creditamount.nativeElement.innerHTML;
          // chargetable = this.printchargetable.nativeElement.innerHTML;
        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
   
    @page { size: landscape || potrait; padding: 20px;} 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      * {
      font-size: ${this.printFontSize} !important; 
      }
     </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
    
    

        
        <table style="border:'0';width:100%; margin: 20px;">  
        <thead>
        <tr>
        ${logo}
        </tr>
        </thead>             
           <tfoot> 
            <tr>  

             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 

           <tbody>  
             <tr>  
               <td width="100%"> 
               
               <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table" style="width: 95%;">
          <thead>
            <tr>
              <th colspan="3">${totalamt}</th>
              <th colspan="2">${cashamt}</th>
              <th colspan="2">${cardamt}</th>
              <th colspan="2">${upiamt}</th>
              <th colspan="2">${insureamt}</th>
              <th colspan="2">${discountamt}</th>
              <th colspan="2">${creditAmt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printContents}
    
                </table>
    
               
                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 15px;
                margin-left: 25px;
                width: 95%;">
                ${usertable}
                </table>

	         </td>  
            </tr> 
          </tbody>  
        </table>



 </body>
      </html>`
        );
        // this.applyPrintStyles();
        popupWin.document.close();

      }
      else if (this.reportType == "HBR" && this.chargeList.length != 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          chargetable = this.printchargetable.nativeElement.innerHTML;
          creditAmt = this.creditamount.nativeElement.innerHTML;
        }

        else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
  
    @page { size: landscape || potrait; padding: 20px;} 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
     </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
    
    

       
        <table style="border:'0';width:100%; margin: 20px;">  
        <thead>
        <tr>
        ${logo}
        </tr>
        </thead>             
           <tfoot> 
            <tr>  

             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 

           <tbody>  
             <tr>  
               <td width="100%"> 
               <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${totalamt}</th>
              <th colspan="2">${cashamt}</th>
              <th colspan="2">${cardamt}</th>
              <th colspan="2">${upiamt}</th>
              <th colspan="2">${insureamt}</th>
              <th colspan="2">${discountamt}</th>
              <th colspan="2">${creditAmt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printContents}
    
                </table>
    
                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
                ${chargetable}
                </table>

                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 15px;
                margin-left: 25px;
                width: 95%;">
                
                ${usertable}
                </table>

	         </td>  
            </tr> 
          </tbody>  
        </table>



 </body>
      </html>`
        );
        // this.applyPrintStyles();
        popupWin.document.close();

      }


      else if (this.reportType == "IPR" || this.reportType == "IR" || this.reportType == "SRD" || this.reportType == "Bill_refund" || this.reportType == "Bill due" ||
        this.reportType == "bed_occupancy" || this.reportType == "abc_analysis" || this.reportType == "product_receivables" || this.reportType == "lab_test_report" || this.reportType == "Patreport" || this.reportType == "SURREPO"
        || this.reportType == "stockReceivable" || this.reportType == "NVR" || this.reportType == "gsd" || this.reportType == "returnsReport" || this.reportType == "product" || this.reportType == "sum_of_purchaseOrder"
      ) {

        if (this.reportType == 'IPR') {
          printContents = this.printIPR.nativeElement.innerHTML;
        }
        else if (this.reportType == 'IR') {
          printContents = this.printIR.nativeElement.innerHTML;
        }
        else if (this.reportType == 'SRD') {
          printContents = this.printSRD.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Bill_refund') {
          printContents = this.printBillRefund.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Bill_due') {
          printContents = this.printBillDue.nativeElement.innerHTML;

        }
        else if (this.reportType == 'NVR') {
          printContents = this.printnvr.nativeElement.innerHTML;
        }
        else if (this.reportType == 'bed_occupancy') {
          printContents = this.printBedOcc.nativeElement.innerHTML;

        }
        else if (this.reportType == 'abc_analysis') {
          printContents = this.printabc.nativeElement.innerHTML;

        }
        else if (this.reportType == 'product_receivables') {
          printContents = this.printproductrec.nativeElement.innerHTML;

        }
        else if (this.reportType == 'lab_test_report') {
          printContents = this.printlabrepo.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Patreport') {
          printContents = this.printpatreport.nativeElement.innerHTML;

        }
        else if (this.reportType == 'SURREPO') {
          printContents = this.printSURREPO.nativeElement.innerHTML;

        } else if (this.reportType == 'stockReceivable') {
          printContents = this.printStockRec.nativeElement.innerHTML;
        }
        else if (this.reportType == 'gsd') {
          printContents = this.printgsd.nativeElement.innerHTML;
        }
        else if (this.reportType == 'returnsReport') {
          printContents = this.printReturnsrepo.nativeElement.innerHTML
        }
        else if (this.reportType == 'product') {
          printContents = this.printproduct.nativeElement.innerHTML
        }
        else if (this.reportType == 'sum_of_purchaseOrder') {
          printContents = this.printpurchaseOrder.nativeElement.innerHTML
        }
        else {
          console.log("error")
        }
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
      @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
      @page { size: landscape || potrait;}
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
        padding:0%;
      }
       .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
     
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <table style="border:'0';width:100%">  
        <thead>
        <tr>
        ${logo}
        </tr>
        </thead>             
           <tfoot> 
            <tr>  

             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 

           <tbody>  
             <tr>  
               <td width="100%"> 
               
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
        ${printContents}
        </table>
	         </td>  
            </tr> 
          </tbody>  
        </table>
      </body>
      </html>`
        );
        popupWin.document.close();
      }
      else if (this.reportType === 'CR') {
        if (this.reportType == 'CR') {
          this.amount = false;
          printCRHBRContents = this.printCRHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRHBRtotalamt = this.printCRHBRtotal.nativeElement.innerHTML;
          CRHBRcashamt = this.printCRHBRcash.nativeElement.innerHTML
          CRHBRCRcardamt = this.printCRHBRcard.nativeElement.innerHTML;
          CRHBRinsureamt = this.printCRHBRinsurance.nativeElement.innerHTML;
          CRHBRupiamt = this.printCRHBRupi.nativeElement.innerHTML;
          CRHBRusertable = this.printCRHBRusertable.nativeElement.innerHTML;
          printCRDBRContents = this.printCRDBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRDBRtotalamt = this.printCRDBRtotal.nativeElement.innerHTML;
          CRDBRcashamt = this.printCRDBRcash.nativeElement.innerHTML
          CRDBRcardamt = this.printCRDBRcard.nativeElement.innerHTML;
          CRDBRinsureamt = this.printCRDBRinsurance.nativeElement.innerHTML;
          CRDBRupiamt = this.printCRDBRupi.nativeElement.innerHTML;
          printpharmaContents = this.printpharma.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          pharmatotalamt = this.printpharmatotal.nativeElement.innerHTML;
          pharmacashamt = this.printpharmacash.nativeElement.innerHTML
          pharmacardamt = this.printpharmacard.nativeElement.innerHTML;
          pharmainsureamt = this.printpharmainsurance.nativeElement.innerHTML;
          pharmaupiamt = this.printpharmaupi.nativeElement.innerHTML;

        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
    @page { size: landscape || potrait; } 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      body{
        padding-top:10%;
    }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <div style="margin-bottom:10px">
        <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Hospital Bill Report
    </h5>
    </div>
        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRHBRtotalamt}</th>
              <th colspan="2">${CRHBRcashamt}</th>
              <th colspan="2">${CRHBRCRcardamt}</th>
              <th colspan="2">${CRHBRinsureamt}</th>
              <th colspan="2">${CRHBRupiamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printCRHBRContents}
    
                </table>
    
                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:10px">
                ${CRHBRusertable}
                </table>
      </div>
      <div style="margin-top:30px">
      <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
      <h5>
      Diagnosis Bill Report
      </h5>
      </div>
      <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRDBRtotalamt}</th>
              <th colspan="2">${CRDBRcashamt}</th>
              <th colspan="2">${CRDBRcardamt}</th>
              <th colspan="2">${CRDBRupiamt}</th>
              <th colspan="2">${CRDBRinsureamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printCRDBRContents}
    
                </table>
    
    
      </div>
    <div style="margin-top:30px">
    <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Pharma Sales Report
    </h5>
    </div>
    <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0">
    <thead>
      <tr>
        <th colspan="3">${pharmatotalamt}</th>
        <th colspan="2">${pharmacashamt}</th>
        <th colspan="2">${pharmacardamt}</th>
        <th colspan="2">${pharmainsureamt}</th>
        <th colspan="2">${pharmaupiamt}</th>
     </tr>
    </thead>
    </table>
    
    <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
    ${printpharmaContents}
    </table>
    </div>
      </body>
      </html>`
        );
        // this.applyPrintStyles();
        popupWin.document.close();


      }
      else {
        console.log("Report type did not found")
      }




    }
    else if (this.print_template != undefined && this.print_template == "logowithname") {


      if (this.reportType == "DBR" || this.reportType == "salesGst" || this.reportType == "SDI" || this.reportType == "CHBR") {
        if (this.reportType == 'CHBR') {
          this.amount = false;
          printContents = this.printCHBR.nativeElement.innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
        }
        else if (this.reportType == 'DBR') {
          this.amount = false;
          printContents = this.printDBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
        }
        else if (this.reportType == 'salesGst') {
          this.amount = false;
          printContents = this.printsalesGST.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;

          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          totalreturns = this.printsalesreturns.nativeElement.innerHTML;
          finalamount = this.printsalesfinal.nativeElement.innerHTML;
        }
        else if (this.reportType == 'SDI') {
          this.amount = false;
          printContents = this.printSDI.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;

        }

        else {
          console.log("error")
        }


        if (this.reportType == 'salesGst') {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();


          popupWin.document.write(`
      
          <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          <table style="border:'0';width:100%">  
          <thead>
          <tr>
          ${logo}
          </tr>
          <div>
          ${hospitaldetails}
          </div>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                 <thead>
                   <tr>
                     <th colspan="3">${totalamt}</th>
                     <th colspan="2">${totalreturns}</th>
                     <th colspan="2">${finalamount}</th>

                  </tr>
                </thead>
               </table>
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
       </body>
        </html>
       
  
        `);

          popupWin.document.close();
        } else {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();


          popupWin.document.write(`
      
          <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          <table style="border:'0';width:100%">  
          <thead>
          <tr>
          ${logo}
          </tr>
          <div>
          ${hospitaldetails}
          </div>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                 <thead>
                   <tr>
                     <th colspan="3">${totalamt}</th>
                     <th colspan="2">${cashamt}</th>
                     <th colspan="2">${cardamt}</th>
                     <th colspan="2">${upiamt}</th>
                     <th colspan="2">${insureamt}</th>
                  </tr>
                </thead>
               </table>
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
       </body>
        </html>
       
  
        `);

          popupWin.document.close();
        }




      }
      else if (this.reportType == "HBR" && this.chargeList.length == 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          // chargetable = this.printchargetable.nativeElement.innerHTML;
        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
        <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
      
      
  
          <table style="border:'0';width:100%; margin: 10px">  
          <thead>
          <tr>
          ${logo}
          </tr>
          <div>
          ${hospitaldetails}
          </div>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
            <thead>
              <tr>
                <th colspan="3">${totalamt}</th>
                <th colspan="2">${cashamt}</th>
                <th colspan="2">${cardamt}</th>
                <th colspan="2">${upiamt}</th>
                <th colspan="2">${insureamt}</th>
             </tr>
           </thead>
          </table>
          
          <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
      
      
          ${printContents}
      
                  </table>
      
                  <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
                  ${usertable}
                  </table>
  
             </td>  
              </tr> 
            </tbody>  
          </table>
  
  
  
   </body>
        </html>      
      `);
        // this.applyPrintStyles();
        popupWin.document.close();

      }


      else if (this.reportType == "HBR" && this.chargeList.length != 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          chargetable = this.printchargetable.nativeElement.innerHTML;
        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
        <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
      
      
  
          <table style="border:'0';width:100%; margin: 20px;">  
          <thead>
          <tr>
          ${logo}
          </tr>
          <div>
          ${hospitaldetails}
          </div>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
            <thead>
              <tr>
                <th colspan="3">${totalamt}</th>
                <th colspan="2">${cashamt}</th>
                <th colspan="2">${cardamt}</th>
                <th colspan="2">${upiamt}</th>
                <th colspan="2">${insureamt}</th>
             </tr>
           </thead>
          </table>
          
          <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
      
      
          ${printContents}
      
                  </table>
      
                  <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
                  ${chargetable}
                  </table>

                  <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
                  ${usertable}
                  </table>
  
             </td>  
              </tr> 
            </tbody>  
          </table>
  
  
  
   </body>
        </html>      
      `);
        // this.applyPrintStyles();
        popupWin.document.close();

      }









      else if (this.reportType == "IPR" || this.reportType == "IR" || this.reportType == "SRD" || this.reportType == "Bill_refund" || this.reportType == "Bill due" ||
        this.reportType == "bed_occupancy" || this.reportType == "abc_analysis" || this.reportType == "product_receivables" || this.reportType == "lab_test_report" || this.reportType == "Patreport" || this.reportType == "SURREPO"
        || this.reportType == "stockReceivable" || this.reportType == "gsd" || this.reportType == "returnsReport" || this.reportType == "product" || this.reportType == "sum_of_purchaseOrder"
      ) {

        if (this.reportType == 'IPR') {
          printContents = this.printIPR.nativeElement.innerHTML;
        }
        else if (this.reportType == 'IR') {
          printContents = this.printIR.nativeElement.innerHTML;
        }
        else if (this.reportType == 'SRD') {
          printContents = this.printSRD.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Bill_refund') {
          printContents = this.printBillRefund.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Bill_due') {
          printContents = this.printBillDue.nativeElement.innerHTML;

        }
        else if (this.reportType == 'bed_occupancy') {
          printContents = this.printBedOcc.nativeElement.innerHTML;

        }
        else if (this.reportType == 'abc_analysis') {
          printContents = this.printabc.nativeElement.innerHTML;

        }
        else if (this.reportType == 'product_receivables') {
          printContents = this.printproductrec.nativeElement.innerHTML;

        }
        else if (this.reportType == 'lab_test_report') {
          printContents = this.printlabrepo.nativeElement.innerHTML;

        }
        else if (this.reportType == 'Patreport') {
          printContents = this.printpatreport.nativeElement.innerHTML;

        }
        else if (this.reportType == 'SURREPO') {
          printContents = this.printSURREPO.nativeElement.innerHTML;

        } else if (this.reportType == 'stockReceivable') {
          printContents = this.printStockRec.nativeElement.innerHTML;
        }
        else if (this.reportType == 'gsd') {
          printContents = this.printgsd.nativeElement.innerHTML;
        }
        else if (this.reportType == 'returnsReport') {
          printContents = this.printReturnsrepo.nativeElement.innerHTML
        }
        else if (this.reportType == 'product') {
          printContents = this.printproduct.nativeElement.innerHTML
        }
        else if (this.reportType == 'sum_of_purchaseOrder') {
          printContents = this.printpurchaseOrder.nativeElement.innerHTML
        }
        else {
          console.log("error")
        }
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
        @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
        @page { size: landscape || potrait;}
        @media print and (orientation: landscape) {
            .table {
              width: 100%;
            }
          }
        
          /* Portrait Styles */
          @media print and (orientation: portrait) {
            .table {
              width: auto;
            }
          }
        th{
          padding:0%;
        }
         .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          
          
          
          
          <table style="border:'0';width:100%">  
          <thead>
          <tr>
          ${logo}
          </tr>
          <div>
          ${hospitaldetails}
          </div>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 
          
          <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
          ${printContents}
          </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
        </body>
        </html>
      `
        );
        popupWin.document.close();
      }
      else if (this.reportType === 'CR') {
        if (this.reportType == 'CR') {
          this.amount = false;
          printCRHBRContents = this.printCRHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRHBRtotalamt = this.printCRHBRtotal.nativeElement.innerHTML;
          CRHBRcashamt = this.printCRHBRcash.nativeElement.innerHTML
          CRHBRCRcardamt = this.printCRHBRcard.nativeElement.innerHTML;
          CRHBRinsureamt = this.printCRHBRinsurance.nativeElement.innerHTML;
          CRHBRupiamt = this.printCRHBRupi.nativeElement.innerHTML;
          CRHBRusertable = this.printCRHBRusertable.nativeElement.innerHTML;
          printCRDBRContents = this.printCRDBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRDBRtotalamt = this.printCRDBRtotal.nativeElement.innerHTML;
          CRDBRcashamt = this.printCRDBRcash.nativeElement.innerHTML
          CRDBRcardamt = this.printCRDBRcard.nativeElement.innerHTML;
          CRDBRinsureamt = this.printCRDBRinsurance.nativeElement.innerHTML;
          CRDBRupiamt = this.printCRDBRupi.nativeElement.innerHTML;
          printpharmaContents = this.printpharma.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          pharmatotalamt = this.printpharmatotal.nativeElement.innerHTML;
          pharmacashamt = this.printpharmacash.nativeElement.innerHTML
          pharmacardamt = this.printpharmacard.nativeElement.innerHTML;
          pharmainsureamt = this.printpharmainsurance.nativeElement.innerHTML;
          pharmaupiamt = this.printpharmaupi.nativeElement.innerHTML;

        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();


        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
    @page { size: landscape || potrait; } 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      body{
        padding-top:10%;
    }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <div style="margin-bottom:10px">
        <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Hospital Bill Report
    </h5>
    </div>
        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRHBRtotalamt}</th>
              <th colspan="2">${CRHBRcashamt}</th>
              <th colspan="2">${CRHBRCRcardamt}</th>
              <th colspan="2">${CRHBRinsureamt}</th>
              <th colspan="2">${CRHBRupiamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printCRHBRContents}
    
                </table>
    
                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:10px">
                ${CRHBRusertable}
                </table>
      </div>
      <div style="margin-top:30px">
      <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
      <h5>
      Diagnosis Bill Report
      </h5>
      </div>
      <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRDBRtotalamt}</th>
              <th colspan="2">${CRDBRcashamt}</th>
              <th colspan="2">${CRDBRcardamt}</th>
              <th colspan="2">${CRDBRupiamt}</th>
              <th colspan="2">${CRDBRinsureamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
    
    
        ${printCRDBRContents}
    
                </table>
    
    
      </div>
    <div style="margin-top:30px">
    <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Pharma Sales Report
    </h5>
    </div>
    <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0">
    <thead>
      <tr>
        <th colspan="3">${pharmatotalamt}</th>
        <th colspan="2">${pharmacashamt}</th>
        <th colspan="2">${pharmacardamt}</th>
        <th colspan="2">${pharmainsureamt}</th>
        <th colspan="2">${pharmaupiamt}</th>
     </tr>
    </thead>
    </table>
    
    <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
    ${printpharmaContents}
    </table>
    </div>
      </body>
      </html>`
        );
        // this.applyPrintStyles();
        popupWin.document.close();


      }
      else {
        console.log("Report type did not found")
      }



    }
    else {

      if (this.reportType == "DBR" || this.reportType == "salesGst" || this.reportType == "SDI" || this.reportType == "CHBR") {
        if (this.reportType == 'CHBR') {
          this.amount = false;
          printContents = this.printCHBR.nativeElement.innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
        }
        else if (this.reportType == 'DBR') {
          this.amount = false;
          printContents = this.printDBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          creditAmt = this.creditamount.nativeElement.innerHTML;
        }
        else if (this.reportType == 'salesGst') {
          this.amount = false;
          printContents = this.printsalesGST.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          totalreturns = 0.00;
          finalamount = 0.00;  
          // if(this.printsalesreturns.nativeElement.innerHTML != undefined){
          //   totalreturns = this.printsalesreturns.nativeElement.innerHTML; 
          // }else{
          //   totalreturns = 0.00; 
          // }
          // if(this.printsalesfinal.nativeElement.innerHTML != undefined){
          //   finalamount = this.printsalesfinal.nativeElement.innerHTML ;
          // }else{
          //   finalamount = 0.00; 
          // }
          // cashamt = this.cashamount.nativeElement.innerHTML
          // cardamt = this.cardamount.nativeElement.innerHTML;
          // insureamt = this.insureamount.nativeElement.innerHTML;
          // upiamt = this.upiamount.nativeElement.innerHTML;
          cashamt = 0.00
          cardamt = 0.00
          insureamt = 0.00
          upiamt = 0.00
          discountamt = 0.00
          insureamt = 0.00
          creditAmt = 0.00

        }
        else if (this.reportType == 'SDI') {
          this.amount = false;
          printContents = this.printSDI.nativeElement.innerHTML;
          totalamt = this.printsalesamountgst.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
        }

        else {
          console.log("error")
        }

        if (this.reportType == 'salesGst') {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();


          popupWin.document.write(`
  
      <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
         
          <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;;">  
          <thead>
          <tr>
          <td><div style="height: 130px;"></div></td>    
          </tr>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
                                           <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" style="width:100%">
                      <thead>
                      <tr>
                      <th style="width:100%;text-align:right;font-size:14px">${totalamt}</th>
                      </tr>
                      </thead>
                      </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
          
       </body>
        </html>`
          );

          popupWin.document.close();

        } else {
          popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
          popupWin.document.open();


          popupWin.document.write(`
  
      <head>
        <title>Reports</title>
        
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
          padding:0%;
        }
        .table{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
          font-family:Segoe UI;
          font-size:12px; 
        }
       
       .img{
        vertical-align: middle;
        width:750;
      }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
         
          <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">  
          <thead>
          <tr>
          <td><div style="height: 130px;"></div></td>    
          </tr>
          </thead>             
             <tfoot> 
              <tr>  
  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
  
             <tbody>  
               <tr>  
                 <td width="100%"> 
                 <table style="width:95%;" class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                 <thead>
                   <tr>
                     <th colspan="3">${totalamt}</th>
                     <th colspan="2">${cashamt}</th>
                     <th colspan="2">${cardamt}</th>
                     <th colspan="2">${upiamt}</th>
                     <th colspan="2">${insureamt}</th>
                     <th colspan="2">${discountamt}</th>
                     <th colspan="2">${creditAmt}</th>
  
                  </tr>
                </thead>
               </table>
               
               <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
           
           
               ${printContents}
           
                       </table>
             </td>  
              </tr> 
            </tbody>  
          </table>
          
       </body>
        </html>`
          );

          popupWin.document.close();

        }



      }
      else if (this.reportType == "HBR" && this.chargeList.length == 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          creditAmt = this.creditamount.nativeElement.innerHTML;
          // chargetable = this.printchargetable.nativeElement.innerHTML;

        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
    
    @page { size: landscape || potrait; padding: 20px;} 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
     
      * {
      font-size: ${this.printFontSize} !important; 
      }
     </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        
    
    

        <table style="border:'0';width:98%; margin: 15px;">  
        <thead>
        <tr>
          <td><div style="height: 130px;"></div></td>        
        </tr>
        </thead>             
           <tfoot> 
            <tr>  

             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 

           <tbody>  
             <tr>  
               <td width="100%"> 
               <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table" style="width: 95%;">
          <thead>
            <tr>
              <th colspan="3">${totalamt}</th>
              <th colspan="2">${cashamt}</th>
              <th colspan="2">${cardamt}</th>
              <th colspan="2">${upiamt}</th>
              <th colspan="2">${insureamt}</th>
              <th colspan="2">${discountamt}</th>
              <th colspan="2">${creditAmt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
        
    
    
        ${printContents}
    
                </table>
    
                <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 15px;
                margin-left: 25px;
                width: 95%;">
                ${usertable}
                </table>

	         </td>  
            </tr> 
          </tbody>  
        </table>

       


 </body>
      </html>`
        );
        popupWin.document.close();

      }

      else if (this.reportType == "HBR" && this.chargeList.length != 0) {
        if (this.reportType == 'HBR') {
          this.amount = false;
          printContents = this.printHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          totalamt = this.totalamount.nativeElement.innerHTML;
          discountamt = this.discountamount.nativeElement.innerHTML;
          cashamt = this.cashamount.nativeElement.innerHTML
          cardamt = this.cardamount.nativeElement.innerHTML;
          insureamt = this.insureamount.nativeElement.innerHTML;
          upiamt = this.upiamount.nativeElement.innerHTML;
          usertable = this.printusertable.nativeElement.innerHTML;
          chargetable = this.printchargetable.nativeElement.innerHTML;
          creditAmt = this.creditamount.nativeElement.innerHTML;

        } else {
          console.log("error")
        }

        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
    @page { size: landscape || potrait; } 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      body{
        padding-top:10%;
    }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${totalamt}</th>
              <th colspan="2">${discountamt}</th>
              <th colspan="2">${cashamt}</th>
              <th colspan="2">${cardamt}</th>
              <th colspan="2">${upiamt}</th>
              <th colspan="2">${insureamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
          ${printContents}
        </table>

        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
        ${chargetable}
      </table>

        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:50px">
          ${usertable}
        </table>
        </body>
      </html>`
        );
        popupWin.document.close();

      } else if (this.reportType == "IPR" || this.reportType == "IR" || this.reportType == "SRD" 
        || this.reportType == "Bill_refund" || this.reportType == "Bill due" || this.reportType == 'NVR' 
        || this.reportType == "bed_occupancy" || this.reportType == "abc_analysis" || this.reportType == "product_receivables" 
        || this.reportType == "lab_test_report" || this.reportType == "Patreport" || this.reportType == "SURREPO"
        || this.reportType == "stockReceivable" || this.reportType == "gsd" || this.reportType == "returnsReport" 
        || this.reportType == "product" || this.reportType == "sum_of_purchaseOrder"
      ) {
        if (this.reportType == 'IPR') {
          printContents = this.printIPR.nativeElement.innerHTML;

        } else if (this.reportType == 'NVR') {
          printContents = this.printnvr.nativeElement.innerHTML;

        } else if (this.reportType == 'IR') {
          printContents = this.printIR.nativeElement.innerHTML;

        } else if (this.reportType == 'SRD') {
          printContents = this.printSRD.nativeElement.innerHTML;

        } else if (this.reportType == 'Bill_refund') {
          printContents = this.printBillRefund.nativeElement.innerHTML;

        } else if (this.reportType == 'Bill_due') {
          printContents = this.printBillDue.nativeElement.innerHTML;

        } else if (this.reportType == 'bed_occupancy') {
          printContents = this.printBedOcc.nativeElement.innerHTML;

        } else if (this.reportType == 'abc_analysis') {
          printContents = this.printabc.nativeElement.innerHTML;

        } else if (this.reportType == 'product_receivables') {
          printContents = this.printproductrec.nativeElement.innerHTML;

        } else if (this.reportType == 'lab_test_report') {
          printContents = this.printlabrepo.nativeElement.innerHTML;

        } else if (this.reportType == 'Patreport') {
          printContents = this.printpatreport.nativeElement.innerHTML;

        } else if (this.reportType == 'SURREPO') {
          printContents = this.printSURREPO.nativeElement.innerHTML;

        } else if (this.reportType == 'stockReceivable') {
          printContents = this.printStockRec.nativeElement.innerHTML;

        } else if (this.reportType == 'gsd') {
          printContents = this.printgsd.nativeElement.innerHTML;

        } else if (this.reportType == 'returnsReport') {
          printContents = this.printReturnsrepo.nativeElement.innerHTML;

        } else if (this.reportType == 'product') {
          printContents = this.printproduct.nativeElement.innerHTML;

        } else if (this.reportType == 'sum_of_purchaseOrder') {
          printContents = this.printpurchaseOrder.nativeElement.innerHTML

        } else {
          console.log("error")
        }
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
      @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
      @page { size: landscape || potrait;}
      @media print and (orientation: landscape) {
          .table {
            width: 100%;
          }
        }
      
        /* Portrait Styles */
        @media print and (orientation: portrait) {
          .table {
            width: auto;
          }
        }
      th{
        padding:0%;
      }
       .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      body{
        padding-top:10%;
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
        ${printContents}
        </table>
      
        </body>
      </html>`
        );
        popupWin.document.close();
      }
      else if (this.reportType === 'CR') {
        if (this.reportType == 'CR') {
          this.amount = false;
          printCRHBRContents = this.printCRHBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRHBRtotalamt = this.printCRHBRtotal.nativeElement.innerHTML;
          CRHBRcashamt = this.printCRHBRcash.nativeElement.innerHTML
          CRHBRCRcardamt = this.printCRHBRcard.nativeElement.innerHTML;
          CRHBRinsureamt = this.printCRHBRinsurance.nativeElement.innerHTML;
          CRHBRupiamt = this.printCRHBRupi.nativeElement.innerHTML;
          CRHBRusertable = this.printCRHBRusertable.nativeElement.innerHTML;
          printCRDBRContents = this.printCRDBR.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          CRDBRtotalamt = this.printCRDBRtotal.nativeElement.innerHTML;
          CRDBRcashamt = this.printCRDBRcash.nativeElement.innerHTML
          CRDBRcardamt = this.printCRDBRcard.nativeElement.innerHTML;
          CRDBRinsureamt = this.printCRDBRinsurance.nativeElement.innerHTML;
          CRDBRupiamt = this.printCRDBRupi.nativeElement.innerHTML;
          printpharmaContents = this.printpharma.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
          pharmatotalamt = this.printpharmatotal.nativeElement.innerHTML;
          pharmacashamt = this.printpharmacash.nativeElement.innerHTML
          pharmacardamt = this.printpharmacard.nativeElement.innerHTML;
          pharmainsureamt = this.printpharmainsurance.nativeElement.innerHTML;
          pharmaupiamt = this.printpharmaupi.nativeElement.innerHTML;

        } else {
          console.log("error")
        }
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <head>
      <title>Reports</title>
      
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
    <style>
    @page { size: landscape || potrait; } 
    @media print and (orientation: landscape) {
        .table {
          width: 100%;
        }
      }
    
      /* Portrait Styles */
      @media print and (orientation: portrait) {
        .table {
          width: auto;
        }
      }
    th{
        padding:0%;
      }
      .table{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        font-family:Segoe UI;
        font-size:12px; 
      }
      body{
        padding-top:10%;
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        <div style="margin-bottom:10px">
        <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Hospital Bill Report
    </h5>
    </div>
        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRHBRtotalamt}</th>
              <th colspan="2">${CRHBRcashamt}</th>
              <th colspan="2">${CRHBRCRcardamt}</th>
              <th colspan="2">${CRHBRinsureamt}</th>
              <th colspan="2">${CRHBRupiamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
          ${printCRHBRContents}
        </table>
    
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top:10px">
          ${CRHBRusertable}
        </table>
      </div>
      <div style="margin-top:30px">
      <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
      <h5>
      Diagnosis Bill Report
      </h5>
      </div>
      <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
          <thead>
            <tr>
              <th colspan="3">${CRDBRtotalamt}</th>
              <th colspan="2">${CRDBRcashamt}</th>
              <th colspan="2">${CRDBRcardamt}</th>
              <th colspan="2">${CRDBRupiamt}</th>
              <th colspan="2">${CRDBRinsureamt}</th>
           </tr>
         </thead>
        </table>
        
        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
          ${printCRDBRContents}
        </table>
      </div>
    <div style="margin-top:30px">
    <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
    <h5>
    Pharma Sales Report
    </h5>
    </div>
    <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0">
    <thead>
      <tr>
        <th colspan="3">${pharmatotalamt}</th>
        <th colspan="2">${pharmacashamt}</th>
        <th colspan="2">${pharmacardamt}</th>
        <th colspan="2">${pharmainsureamt}</th>
        <th colspan="2">${pharmaupiamt}</th>
     </tr>
    </thead>
    </table>
    
    <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
    ${printpharmaContents}
    </table>
    </div>
      </body>
      </html>`
        );
        popupWin.document.close();

      } else {
        console.log("Report type did not found")
      }
    }
  }

  todisplaycoverdiv() {
    this.forcoverdiv = true;
    this.backbutton = false;
    this.amountcard = false;
    this.ishide = false;
    this.salesList = [];
  }

  getUserbasedDetails(data) {
    this.filteredSaleslistArray = this.salesList.filter(item =>
      item.user_name.toLowerCase().includes(data.username.toLowerCase())
    );

    if (this.pattype == 'All') {
      this.op_SalestlistArr = this.filteredSaleslistArray.filter(item =>
        item.patient_type == "OP"
      )

      for (var i = 0; i < this.op_SalestlistArr.length; i++) {
        this.op_SalestlistArr[i].sno = i + 1;
      }

      this.ip_SalestlistArr = this.filteredSaleslistArray.filter(item =>
        item.patient_type == "IP"
      )

      for (var j = 0; j < this.ip_SalestlistArr.length; j++) {
        this.ip_SalestlistArr[j].sno = this.op_SalestlistArr.length + j + 1;
      }

      console.log("op_SalestlistArr------", this.op_SalestlistArr)
      console.log("ip_SalestlistArr------", this.ip_SalestlistArr)
    }
  }

  setTable(data) {
    // this.table_th_data = ['S.no', 'Bill No', 'Created Date', 'Paitent Name', 'Bill Amt', 'Discount'];
    // this.table_th_data = [];
    console.log(data)

    this.columns = {
      paid: false,
      cash: false,
      upi: false,
      card: false,
      insurance: false,
      due: false,
      ipOp: false,
      advance: false,
      discount: false,
      doctor: false,
      paidon: false,
      Sno: false,
      OrderNo: false,
      OrderDate: false,
      BillAmount: false,
      Disc: false,
      Exempt: false,
      FiGST: false,
      TWCGST: false,
      TWSGST: false,
      TWEGST:false,
      SISGST: false,
      SICGST: false,
      EIGst: false,
      NICgst: false,
      NISgst: false,
      TWGst: false,
      FOcgst: false,
      FOsgst: false,
      CGST: false,
      SGST: false
    };

    if (data.includes("doctor")) {
      this.colspann = 4;
    } else {
      this.colspann = 3;
    }

    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }

  }

  setGroupbyFlag(data) {
    if (data == 'out-pat') {
      this.grp_by_flag = true;
    } else {
      this.grp_by_flag = false;
    }
  }

  getDiagDepartment() {
    this.department_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', JSON.stringify({ diag_centre_id: this.DiagnosisCenterId }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("getDiagDepartment", obj)
          var dept_data = obj.diag_dept_list;

          if (obj.diag_dept_list.length != 0) {
            for (var i = 0; i < obj.diag_dept_list.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              })
            }
            this.diag_dept = this.department_data[0].diag_department_id;
            this.getTestCategory(this.diag_dept, '0');
            console.log("getDiagDepartment", this.department_data)
          }
        }, error => { });
  }

  getTestCategory(deptId, categ_id) {//get disease cateegory
    this.test_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn',
      JSON.stringify({
        diag_centre_id: this.DiagnosisCenterId,
        diag_dept_id: deptId
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("getTestCategory - obj", obj)
          this.test_category_data = obj.test_categories;
          // this.previous_order_no = obj.test_order;
          // this.order_no = obj.test_order;
          this.diag_test_categ = "All";

          console.log('this.test_category_data', obj)
        }, error => { });
  }
}
