<div class="container-fluid g-0" style="height: 100%;" >
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('manageappointments');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div [hidden]="manageAppointmentFlag" class="menu menu_active" id="manageappointments"
                        (click)="setDoctor('manageappointments');menuNav('manageappointments');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Manage Appointments</span></div>
                    </div>
                    <div [hidden]="viewPrescritionFlag" class="menu" id="prescriptionlist"
                        (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Prescription</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                        <ul *ngIf="billingFlag" class="nav-children">
                            <li (click)="menuNav('frontdeskbilling');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Bill list</a></li>
                            <li [hidden]="advanceFlag" (click)="menuNav('advances');"><a><i
                                        class="fa fa-angle-right"></i> Advances</a></li>
                            <li [hidden]="billingChargesFlag" (click)="menuNav('charges');"><a><i
                                        class="fa fa-angle-right"></i> Charge types</a></li>
                            <li [hidden]="refund" (click)="menuNav('refund');"><a><i class="fa fa-angle-right"></i> Refunds</a></li>
                            <li [hidden]="consolidatedBillingFlag" (click)="menuNav('consolidatedBills');"><a><i class="fa fa-angle-right"></i> Consolidated Bill</a></li>
                        </ul>
                    </div>
                    <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Patient List</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="permissionsFlag" class="menu" id="permission" (click)="menuNav('permission')">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Permission</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="permissionSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('nurse_ward');"><a id="nav-a"><i class="fa fa-angle-right"></i> Nurse by ward</a></li>
                            <li (click)="menuNav('nurse_patient');"><a><i class="fa fa-angle-right"></i> Nurse by patient</a></li>
                            <li (click)="menuNav('nurse_doctor');"><a><i class="fa fa-angle-right"></i> Nurse by doctor</a></li>
                        </ul>
                    </div>
                    <div [hidden]="inpatientMenuFlag" class="menu" id="inpatient"
                        (click)="setAppoinmentHelper('inpatient');menuNav('inpatient');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/Inpatients_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                Inpatient</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                        <ul *ngIf="inpatientFlag" class="nav-children">
                            <li (click)="menuNav('Patients');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Patients</a></li>
                            <li (click)="menuNav('dischargeList');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Discharge list</a></li>
                        </ul>
                    </div>
                    <div [hidden]="consultantFlag" class="menu" id="consultant" (click)="menuNav('consultant');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                Consultant</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="wardMasterFlag" class="menu" id="wardmaster" (click)="menuNav('wardmaster');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span> Ward
                                Master</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="reportsFlag" class="menu" id="inpatient" (click)="menuNav('reports');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/Inpatients_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                Reports</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                        <ul *ngIf="reportsSubmenuFlag" class="nav-children">
                            <li [hidden]="hospBillsReportFlag" (click)="menuNav('hosp_bill_reports');setReport('HBR');">
                                <a id="nav-a"><i class="fa fa-angle-right"></i> Hospital bills reports</a></li>
                            <li [hidden]="diagBillsReportFlag"
                                (click)="menuNav('diag_bill_reports');;setReport('DBR');"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Diagnosis bills reports</a></li>
                            <li [hidden]="inpatReportFlag" (click)="menuNav('inpat_reports');;setReport('IPR');"><a
                                    id="nav-a"><i class="fa fa-angle-right"></i> Inpatient reports</a></li>
                        </ul>
                    </div>
                    <!-- <div class="menu" id="listPackage" (click)="menuNav('listPackage');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Package</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div> -->
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"  onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <!-- <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My Profile</span> </div> -->
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i  class="fas fa-cog"></i><span class="header_model_menu">Change Password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'manageappointments'">
                                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                            </div>
                            <div *ngSwitchCase="'home'">
                                <app-frontdesk-home></app-frontdesk-home>
                            </div>
                            <div *ngSwitchCase="'manageappointments'">
                                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                            </div>
                            <div *ngSwitchCase="'appcreate'">
                                <app-recept-app-create-page></app-recept-app-create-page>
                            </div>
                            <div *ngSwitchCase="'receptionistdoctordetailview'">
                                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
                            </div>
                            <div *ngSwitchCase="'registration'">
                                <app-front-desk-registration></app-front-desk-registration>
                            </div>
                            <div *ngSwitchCase="'receptviewprescription'">
                                <app-receptviewprescription></app-receptviewprescription>
                            </div>
                            <div *ngSwitchCase="'prescriptionlist'">
                                <app-recept-prescription-list></app-recept-prescription-list>
                            </div>
                            <div *ngSwitchCase="'frontdeskbilling'">
                                <app-recept-bill-list></app-recept-bill-list>
                            </div>
                            <div *ngSwitchCase="'receptbillcreate'">
                                <app-recept-bill-create></app-recept-bill-create>
                            </div>
                            <div *ngSwitchCase="'receptbilledit'">
                                <app-recept-bill-edit></app-recept-bill-edit>
                            </div>
                            <div *ngSwitchCase="'receptbilldetail'">
                                <app-recept-bill-detailview></app-recept-bill-detailview>
                            </div>
                            <div *ngSwitchCase="'patient_list'">
                                <app-recept-patient-list></app-recept-patient-list>
                            </div>
                            <div *ngSwitchCase="'nurse_ward'">
                                <app-nurse-ward></app-nurse-ward>
                            </div>
                            <div *ngSwitchCase="'nurse_patient'">
                                <app-nurse-patient></app-nurse-patient>
                            </div>
                            <div *ngSwitchCase="'nurse_doctor'">
                                <app-nurse-doctor></app-nurse-doctor>
                            </div>
                            <div *ngSwitchCase="'advances'">
                                <app-bill-advances></app-bill-advances>
                            </div>
                            <div *ngSwitchCase="'bills'">
                                <app-bill-list></app-bill-list>
                            </div>
                            <div *ngSwitchCase="'billingDetailsView'">
                                <app-billng-detail-view></app-billng-detail-view>
                            </div>
                            <div *ngSwitchCase="'billingDiagDetailsView'">
                                <app-billing-diag-view></app-billing-diag-view>
                            </div>
                            <div *ngSwitchCase="'charges'">
                                <app-billing-charges></app-billing-charges>
                            </div>
                            <div *ngSwitchCase="'refund'">
                                <app-bill-refund></app-bill-refund>
                            </div>
                            <div *ngSwitchCase="'createRefund'">
                                <app-bill-refund-create></app-bill-refund-create>
                            </div>
                            <div *ngSwitchCase="'dischargeList'">
                                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
                            </div>
                            <div *ngSwitchCase="'Patients'">
                                <app-fd-inpatient></app-fd-inpatient>
                            </div>
                            <div *ngSwitchCase="'consultant'">
                                <app-consultant></app-consultant>
                            </div>
                            <div *ngSwitchCase="'diaBillCreate'">
                                <app-diagbillcreate></app-diagbillcreate>
                            </div>
                            <div *ngSwitchCase="'diaAppDetail'">
                                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
                            </div>
                            <div *ngSwitchCase="'diaAppCreate'">
                                <app-diag-app-create></app-diag-app-create>
                            </div>
                            <div *ngSwitchCase="'diaBillingDetailsView'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'wardmaster'">
                                <app-ward-master></app-ward-master>
                            </div>
                            <div *ngSwitchCase="'viewIpatient'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'ipatientBillCreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'consolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            <div *ngSwitchCase="'dietappointments'">
                                <app-diet-appointments></app-diet-appointments>
                            </div>
                            <div *ngSwitchCase="'dietappointmentsdetailed'">
                                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
                            </div>
                            <div *ngSwitchCase="'dietappointmentscreate'">
                                <app-diet-appointments-creation></app-diet-appointments-creation>
                            </div>
                            <div *ngSwitchCase="'hosp_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'diag_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'inpat_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>
                           
                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>  
                            <div *ngSwitchCase="'inpdischargeview'">
                                <app-inp-discharge-view></app-inp-discharge-view>
                            </div> 
                            <div *ngSwitchCase="'docrefcreate'">
                                <app-doc-ref-create></app-doc-ref-create>
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2025 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>