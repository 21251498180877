import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
declare var $: any;
@Component({
  selector: 'app-advance-estimation',
  templateUrl: './advance-estimation.component.html',
  styleUrls: ['./advance-estimation.component.scss']
})
export class AdvanceEstimationComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  dtOptions: DataTables.Settings = {};
  public newbutton: boolean = false;
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public admin_flag: boolean = false;
  public Moduleidlist:any = [];
  public createpage_flag: boolean = false;
  public profileImg: string;
  public mrno;
  public clnt_saldesc;
  public patient_name: string;
  public clnt_age;
  public gender;
  public mobile_no;
  public doctor_name: any;
  public admitted_date;
  public admitted_time;
  public discharge_date;
  public discharge_time;
  public payment_mode;
  public insurer;
  public insurance_flag:boolean = false;
  public bill_type_pharma: any;
  public e_contact;
  public wardName: any;
  public bed_no: any;
  public medical;
  public surgical;
  public hd;
  public day_care;
  public fever;
  public pain;
  public breathing;
  public giddiness;
  public swelling;
  public room_rent;
  public day_count;
  public dr_fees;
  public procedures;
  public diagnostics;
  public Dietry;
  public total;
  public ward_list: any = [];
  public ward_id;
  public billing_print_array;
  public listview: boolean = true;
  public hptl_clinic_id: string;
  public doctorList = [];
  public doctorName;
  public doctor;
  public dog_reg_id;
  public patientList = [];
  public floorvalue;
  public floorarray = [];
  public count;
  public graphicalformat: boolean = false;
  public graph_defaultimg: boolean = false;
  public tabular_format: boolean = true;
  public view_type;
  public name;
  public location;
  public mobile: string;
  public mrnoflag: boolean;
  public locationflag: boolean;
  public nameflag: boolean;
  public fdateflag: boolean;
  public todateflag: boolean;
  public mobileflag: boolean;
  public fromDate;
  public toDate;
  public filterbasedurl;
  public selectvalue;
  public CurrentDatetime;
  public currentTime;
  public currentDate;
  public time;
  public currentMaxDate;
  public filter: string;
  public Filter_based;
  public bill_type: string;
  public Filter_txt;
  public From_show: boolean = true;
  public To_show: boolean = true;
  public dateshow: boolean = false;
  public appointment_list: any = [];
  public mr_no;
  public appoint_txt;
  public patient_selectionflag: boolean = true;
  public patientId;
  public firstName: string;
  public lastName: string;
  public date;
  public mobileNo: string;
  public Docidname;
  public hospital_name;
  public hospitalAddress;
  public Hospital_location;
  public barcode_flag: boolean = false;
  public userinfo;
  public fin_year:any;
  public pay_mode: string;
  public sponser_name: any;
  public sponser_GSTIN: any;
  public enddate: any;
  public endtime: any;
  public selectedAdmissionType: string | null = null;
  public selectedOption: string | null = null;
  public user_id: any;
  public billEstBackButtonFlag:boolean = false;
  public billEstUpdateButtonFlag:boolean = false;
  public print_template: any;
  public hospitalLogo: any;
  public printlogostyle: any;
  public bill_est_id: any;
  public logUserName: any;
  public barcode_url: any;
  public p_tag_style: string;
  public bill_date: any;

  constructor(public doctorservice: MenuViewService,
    public toastr: ToastrService,
    public http: Http,
    public router: Router,
    public messageservice: MenuViewService,
    public masterData: MasterHelperService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 25,
      lengthMenu: [
        [25, 50, -1],
        [25, 50, 'All'],
      ],
      columnDefs: [{ orderable: false, targets: [2, 3, 4, 5, 6] }],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: 'Search chargetypes by name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);
    if (this.userinfo.fin_year != undefined) {
      this.fin_year = this.userinfo.fin_year;
    }
    if (this.userinfo.bill_print_template != undefined) {
      this.print_template = this.userinfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }
    this.user_id = this.userinfo.user_id;
    if (this.userinfo.first_name != undefined) {
      this.logUserName = this.userinfo.first_name;
      if (this.userinfo.last_name != undefined) {
        this.logUserName = this.userinfo.first_name + " " + this.userinfo.last_name;
      }
    }
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == '7') {
          if (this.Moduleidlist[i].edit == '1') {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == '1') {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == '1') {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == '1') {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == '1') {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
      this.admin_flag = true;
    }

    if (this.userinfo.hptl_name == 'SGH') {
      this.p_tag_style = "font-size: 14px;margin-right: 30px !important;text-align: center;";
    } else {
      this.p_tag_style = "font-size: 14px;margin-right: 30px !important;text-align: center;";
    }

    if (Master_Helper.getMasterWardData() != undefined) {
      this.ward_list = Master_Helper.getMasterWardData().wards;
    }
    this.Get_current_date();
    this.bill_est_id = '';
    this.filter = 'Patient_ID';
    this.Filter_based = "mrno";
    this.dog_reg_id = "All";
    this.doctor = "All";
    this.floorvalue = "All";
    this.view_type = "graph";
    this.Filter_txt = "All";
    this.selectvalue = "mrno";
    this.Filter_change();
    this.Filter_changename(this.Filter_based);
    this.Get_current_date();
    this.getDoctors();
    this.getPatients();
    this.viewChange(this.view_type);
    this.Filterbydata(this.selectvalue);

    if (FrontDesk_Helper.getInpatientEstBillList() != undefined && FrontDesk_Helper.getInpatientEstBillList().bill_id != undefined) {
      this.billEstBackButtonFlag = true;
      this.billEstUpdateButtonFlag = true;
      var billEstimateData = FrontDesk_Helper.getInpatientEstBillList();
      console.log(billEstimateData);
      this.viewPatient(billEstimateData);
      setTimeout(()=>{
        this.bill_est_id = billEstimateData.bill_id;
        this.onAdmissionTypeChange(billEstimateData.medical_type);
        this.onCheckboxChange(billEstimateData.medical);
        this.ward_id = billEstimateData.ward_id;
        this.wardName = billEstimateData.ward_name;
        this.day_count = billEstimateData.no_days;
        this.room_rent = billEstimateData.room_rent;
        this.dr_fees = billEstimateData.doctor_fee;
        this.procedures = billEstimateData.procedures;
        this.diagnostics = billEstimateData.diagnostics;
        this.Dietry = billEstimateData.dietry;
        this.total = billEstimateData.total_amount;
        FrontDesk_Helper.setInpatientEstBillList(undefined);
      },500);
    } else {
      this.billEstBackButtonFlag = false;
      this.billEstUpdateButtonFlag = false;
    }
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'adm/curdate',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.currentTime = obj.current_time;
            var timevalue = Time_Formate(this.currentTime);
            var tval = timevalue.split(' ');
            var tval1 = tval[0].split(':');
            var get_date = obj.current_date.split('-');
            this.currentDate = obj.current_date;
            this.bill_date = obj.current_date;
            this.time = Time_Formate(obj.current_time);
            // this.date = obj.current_date;
            Doc_Helper.setBillFromDate(null);
            Doc_Helper.setBillToDate(null);
            Doc_Helper.setBillFromDate(obj.current_date);
            Doc_Helper.setBillToDate(obj.current_date);
          }
        },
        (error) => { }
      );
  }

  getDoctors() {
    console.log("get doc ==" + JSON.stringify({
      country: "IN",
      reg_id: Helper_Class.getInfo().user_id,
      imei: Helper_Class.getIPAddress(),
      type: "front-desk",
      hosp_id: this.hptl_clinic_id,
    }))
    this.doctorList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep',
      {
        country: "IN",
        reg_id: Helper_Class.getInfo().user_id,
        imei: Helper_Class.getIPAddress(),
        type: "front-desk",
        hosp_id: this.hptl_clinic_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
            }
            this.doctor = this.doctorList[0].Doc_ID;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(e) {
    FrontDesk_Helper.setdoctor(undefined);
    FrontDesk_Helper.setdoctor(e);
    this.doctor = e;
    this.dog_reg_id = e;
    this.getPatients();
  }

  selectFromDate(e) {
    this.fromDate = e;
  }

  selectToDate(e) {
    this.toDate = e;
  }
  
  getPatients() {
    this.patientList = [];
    var data = null;
    var send_data = {
      doc_reg_id: this.dog_reg_id,
      hptl_clinic_id: this.hptl_clinic_id,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "Billing",
      floor: this.floorvalue
    }
    console.log("INPATIENT send_data " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          this.floorarray = [];
          this.patientList = [];
          var data = response.json();
          console.log("check datta " + JSON.stringify(data));
          if (data.floors != undefined) {
            this.floorarray.push("All")
            for (var k = 0; k < data.floors.length; k++) {
              this.floorarray.push(data.floors[k])
            }
          }
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }

              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc + ".";
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;
                ward_text = wardname + " - Bed:" + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }

              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";
                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;
                }
              }

              if (values.doc_name != undefined) {
                docname = values.doc_name
              } else {
                docname = ""
              }
              var gender, age;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age, address1, address2, location, district, zipcode, state, country, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + " / " + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }

              if (values.address2 != null && values.address2 != undefined && values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1) + ",";
                if (values.address2 != " " && values.address2 != "null") {
                  if (encrypt_decript.Decript(values.address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(values.address2) + ",";
                  }
                }
              } else if (values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1) + ",";
              } else if (values.address2 != undefined) {
                address2 = encrypt_decript.Decript(values.address2) + ",";
              }

              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.location != undefined) {
                location = values.location + ',';
              }

              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.district != undefined) {
                district = values.district + ' -';
              }

              if (values.zipcode != undefined) {
                zipcode = encrypt_decript.Decript(values.zipcode) + ",";
              }

              if (values.state != undefined) {
                state = values.state + " -";
              }

              if (values.country != undefined) {
                country = values.country + ".";
              }

              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }

              var admission_date, relation_id;
              if (values.admission_date != undefined) {
                admission_date = values.admission_date;
              }

              if (values.relation_id != undefined) {
                relation_id = values.relation_id;
              }
              var address;
              if (values.address2 != undefined && values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1) + ", " + encrypt_decript.Decript(values.address2)
              } else if (values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1);
              } else {
                address = "";
              }

              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0]
              } else {
                discharge_date = "";
              }
              var discharge_time;
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false, barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url" + barcode_url);
              }
              this.patientList.push({
                salutation_desc: salutation_desc,
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + pname,
                patientname: pname,
                surgery_desc: values.surgery_desc,
                patient_namevalue: pname,
                fullname: fullname,
                inpatientId: values.inpatient_id,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                admission_date: admission_date,
                admitted_date: admission_date,
                admitted_time: ctime,
                relation_id: relation_id,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                profile_image: image,
                first_name: values.first_name,
                last_name: values.last_name,
                middle_name: values.middle_name,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,
                speciality: values.speciality,
                specialization_name: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                doctorname: docname,
                doc_reg_id: values.doc_reg_id,
                doc_app_id: values.doc_app_id,
                gender_age: gender_age,
                address: address,
                address1: values.address1,
                address2: values.address2,
                locationcheck: locationcheck,
                location: location,
                districtcheck: districtcheck,
                district: district,
                zipcode: zipcode,
                state: state,
                country: country,
                hosp_inp_idvalue: hosp_inp_id2,
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo: encrypt_decript.Decript(values.mobile),
                patient_id: values.patient_id,
                mlc: values.mlc_type,
                age: values.age,
                gender: gender,
                doc_qualif: values.doc_qualif,
                insurer: values.insurer,
                insCardNo: values.ins_card_no,
                policyno: values.policy_no,
                CCNno: values.ins_ccn_no,
                tpa: values.ins_tpa,
                approved_amount: values.approved_amount,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                rate_plan: values.rate_card_desc,
                payment_mode: values.payment_mode,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url,
              });
              Helper_Class.setinpatientfilterdata(this.patientList);
            }
            console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList));
          } else {
            this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  viewChange(value) {
    if (value == "graph1") {
      this.graphicalformat = true;
      this.graph_defaultimg = false;
      this.tabular_format = false;
    } else if (value == "tabular") {
      this.graphicalformat = false;
      this.graph_defaultimg = false;
      this.tabular_format = true;
    } else {
      this.tabular_format = false;
      this.graphicalformat = false;
      this.graph_defaultimg = true;
    }
  }

  changefloorvalue(value) {
    this.floorvalue = value;
    this.getPatients();
  }

  Filterbydata(data) {
    this.mrno = "";
    this.name = "";
    this.location = "";
    this.mobile = "";
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "location") {
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = true;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "date") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = false;
      this.filtercheck();
    }
    if (data == "mobile") {
      this.fdateflag = false;
      this.todateflag = false;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
    }
    this.patientList = Helper_Class.getinpatientfilterdata();
  }

  filtercheck() {
    console.log("chekc value  --" + JSON.stringify(this.patientList))
    if (this.nameflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.patient_namevalue.toLowerCase().includes(this.name.toLowerCase()));
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      this.filtermethod("name", this.name, '')
    }
    if (this.mobileflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.mobilenumber == this.mobile);
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray));
      this.filtermethod("mobile", this.mobile, '');
    }

    if (this.locationflag == true) {
      let filteredArray1 = this.patientList.filter(obj => obj.locationcheck.trim().toLowerCase() === this.location.trim().toLowerCase());
      console.log("filteredArray loctaion  --" + JSON.stringify(filteredArray1));
      this.filtermethod("location", this.location, '')
    }

    if (this.mrnoflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.hosp_inp_idvalue.toLowerCase() === this.mrno.toLowerCase());
      console.log("filteredArray mrno  --" + JSON.stringify(filteredArray))
      this.patientList = [];
      this.patientList = filteredArray;
      this.filtermethod("mr_no", this.mrno, '');
    }
    if (this.fdateflag == true && this.todateflag == true) {
      let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
      let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));
      let filteredArraycheck = this.patientList.filter(obj => {
        // Convert admit_date to a Date object
        let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));
        // Check if admit_date is between fromDate and toDate
        return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
      });
      console.log("filteredArray date   --" + JSON.stringify(filteredArraycheck));
      this.filtermethod("date", '', '');
    }
  }

  filtermethod(type, name, name1) {
    this.patientList = [];
    var senddata;
    if (type == "date") {
      senddata = {
        filter: type,
        fdate: (this.fromDate),
        tdate: (this.toDate), hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "name") {
      senddata = {
        filter: type,
        fname: encrypt_decript.Encript(this.name).toString(), hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mobile") {
      senddata = {
        filter: type,
        mobile: encrypt_decript.Encript(this.mobile).toString(),
        hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mr_no") {
      senddata = {
        filter: type,
        mr_no: this.mrno, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "location") {
      senddata = {
        filter: type,
        location: this.location, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    console.log("filter basedheck ---" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.filterbasedurl, senddata,
      { headers: headers }).subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(data))
          this.patientList = [];
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc;
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }

              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";
                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;
                }
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var gender, age, location, district;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + "/" + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }
              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0];
              } else {
                discharge_date = "";
              }
              var discharge_time;
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false, barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url" + barcode_url);
              }

              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + " " + pname,
                patient_namevalue: pname,
                fullname: fullname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                patient_id: values.patient_id,
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.inp_hosp_id,
                speciality: values.speciality,
                surgery_desc: values.surgery_desc,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                gender_age: gender_age,
                districtcheck: districtcheck,
                locationcheck: locationcheck,
                hosp_inp_idvalue: hosp_inp_id2,
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo: encrypt_decript.Decript(values.mobile),
                mlc: values.mlc_type,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url,
                approved_amount: values.approved_amount
              });
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList))
            }
          } else {
            this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  Filter_change() {
    if (this.Filter_txt == "All") {
      this.From_show = true;
      this.To_show = true;
    } else if (this.Filter_txt == "Date") {
      this.From_show = false;
      this.To_show = false;
      this.dateshow = true;
    }
  }

  Filter_changename(data) {
    this.appointment_list = [];
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.To_show = true;
      this.mr_no = "";
      this.patient_selectionflag = true;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.appoint_txt = "";
      this.patient_selectionflag = true;
    }
    if (data == "mobile") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
      this.To_show = true;
      this.mobile = "";
      this.patient_selectionflag = true;
    }
  }

  viewPatient(list) {
    console.log("list view  0--" + JSON.stringify(list))
    this.listview = false;
    this.clearData();
    this.filter == 'Patient_ID';
    this.patientId = list.patient_id;
    this.searchPatient();
  }

  clearData() {
    this.createpage_flag = false;
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.billing_print_array = [];
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.appointment_list = [];
    this.createpage_flag = false;
    this.appoint_txt = '';
    this.insurance_flag = false;
  }

  searchPatient() {
    this.appointment_list = [];
    var fieldvalue;
    if (this.filter == 'Patient_ID') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mr_no: this.patientId,
      };
    } else if (this.filter == 'Name') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        first_name: this.firstName,
        last_name: this.lastName,
      };
    } else if (this.filter == 'Date') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        date: this.date,
      };
    } else if (this.filter == 'Mobile_No') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mobile: this.mobileNo,
      };
    }
    console.log(fieldvalue);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'bill/gipbc', fieldvalue, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log("====================== " + JSON.stringify(obj));
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              var wardDetails = [];
              if (
                obj.appointment[i].ward_details != undefined &&
                obj.appointment[i].ward_details.length != 0
              ) {
                wardDetails = obj.appointment[i].ward_details;
              }

              var doc_visits = [];
              if (
                obj.appointment[i].doc_visits != undefined &&
                obj.appointment[i].doc_visits.length != 0
              ) {
                doc_visits = obj.appointment[i].doc_visits;
              }

              var other_charges = [];
              if (
                obj.appointment[i].other_charges != undefined &&
                obj.appointment[i].other_charges.length != 0
              ) {
                other_charges = obj.appointment[i].other_charges;
              }

              var date =
                obj.appointment[i].admitted_date != undefined
                  ? ' (' + Date_Formate(obj.appointment[i].admitted_date) + ')'
                  : ' ';
              if (obj.appointment[i].middle_name != undefined) {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              } else {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              }

              var sessiondata = null;
              if (
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != null
              ) {
                sessiondata = encrypt_decript.Decript(
                  obj.appointment[i].session
                );
              }

              var address1 = '',
                address2 = '';
              if (
                obj.appointment[i].address2 != null &&
                obj.appointment[i].address2 != undefined &&
                obj.appointment[i].address1 != undefined
              ) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
                if (
                  obj.appointment[i].address2 != ' ' &&
                  obj.appointment[i].address2 != 'null'
                ) {
                  if (
                    encrypt_decript.Decript(obj.appointment[i].address2) == ''
                  ) {
                    address2 == '';
                  } else {
                    address2 =
                      encrypt_decript.Decript(obj.appointment[i].address2) +
                      ',';
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
              } else if (obj.appointment[i].address2 != undefined) {
                address2 =
                  encrypt_decript.Decript(obj.appointment[i].address2) + ',';
              }

              var location;
              if (
                obj.appointment[i].location != undefined &&
                obj.appointment[i].location != ''
              ) {
                location = obj.appointment[i].location + ',';
              }

              var city = '';
              if (
                obj.appointment[i].district != undefined &&
                obj.appointment[i].district != null
              ) {
                city = obj.appointment[i].district + '-';
              }

              var zipcode;
              if (
                obj.appointment[i].zipcode != undefined &&
                obj.appointment[i].zipcode != null
              ) {
                zipcode =
                  encrypt_decript.Decript(obj.appointment[i].zipcode) + ',';
              }

              var state = '';
              if (
                obj.appointment[i].state != undefined &&
                obj.appointment[i].state != null
              ) {
                state = obj.appointment[i].state + '-';
              }

              var country = '';
              if (
                obj.appointment[i].country != undefined &&
                obj.appointment[i].country != null
              ) {
                country = obj.appointment[i].country + '.';
              }

              var hospital;
              if (obj.hosp_address2 != undefined) {
                hospital = obj.hosp_address1 + ',' + obj.hosp_address2;
              } else {
                hospital = obj.hosp_address1;
              }

              var docName;
              if (
                obj.appointment[i].dr_middle_name != undefined &&
                obj.appointment[i].dr_middle_name != null
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_middle_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else if (
                obj.appointment[i].dr_first_name != undefined &&
                obj.appointment[i].dr_last_name != undefined
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              }

              if (obj.appointment[i].doc_qualif != undefined && obj.appointment[i].doc_qualif != null && obj.appointment[i].doc_qualif != "") {
                docName = docName + " " + obj.appointment[i].doc_qualif;
              }

              if (obj.appointment[i].profile_image != '') {
                var image =
                  ipaddress.Ip_with_img_address +
                  obj.appointment[i].profile_image;
              }

              var inpatientId =
                obj.appointment[i].inpat_id != undefined
                  ? obj.appointment[i].inpat_id
                  : '-';
              this.hospital_name =
                obj.hosp_name != undefined ? obj.hosp_name : '';
              this.hospitalAddress = hospital;
              this.Hospital_location =
                obj.hosp_location +
                ', ' +
                obj.hosp_district +
                ' - ' +
                obj.hosp_state +
                ' ' +
                obj.hosp_country +
                '  ' +
                obj.hosp_zip;
              this.mobile_no =
                obj.appointment[i].mobile != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].mobile)
                  : '';
              this.e_contact =
                obj.appointment[i].emerg_contact != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].emerg_contact)
                  : '';

              var surgery = obj.appointment[i].surgery_desc != undefined ? obj.appointment[i].surgery_desc : '';
              var bill_type_pharma = obj.appointment[i].bill_incl_pharma != undefined ? obj.appointment[i].bill_incl_pharma : '';
              var barcode;
              if (obj.appointment[i].barcode != undefined && obj.appointment[i].barcode != null) {
                this.barcode_flag = true;
                barcode = ipaddress.Ip_with_img_address + obj.appointment[i].barcode;
              }
              var hosp_charges = [];
              if (obj.appointment[i].hosp_charges != undefined) {
                hosp_charges = obj.appointment[i].hosp_charges;
              }

              var diag_charges = [];
              if (obj.appointment[i].diag_charges != undefined) {
                diag_charges = obj.appointment[i].diag_charges;
              }

              var diagnosis_bills = [];
              if (obj.appointment[i].diagnosis_bills != undefined) {
                diagnosis_bills = obj.appointment[i].diagnosis_bills;
              }

              var pharma_charges = [];
              if (obj.appointment[i].pharma_charges != undefined) {
                pharma_charges = obj.appointment[i].pharma_charges;
              }

              var pharma_bills = [];
              if (obj.appointment[i].pharma_bills != undefined) {
                pharma_bills = obj.appointment[i].pharma_bills;
              }

              var rate_card_disc = obj.appointment[i].rate_card_disc != undefined ? obj.appointment[i].rate_card_disc : 0;
              var isDisInitiate = false;
              if(obj.appointment[i].discharge_initiate != undefined && obj.appointment[i].discharge_initiate == "1") {
                isDisInitiate = true;
              }

              if (obj.appointment[i].pat_type != undefined) {
                var ins_or_nonins = obj.appointment[i].pat_type;
              }
              
              var insurer = obj.appointment[i].insurer != undefined ? obj.appointment[i].insurer : '';
              var sponser_name = obj.appointment[i].tpa != undefined ? obj.appointment[i].tpa : '';
              var sponser_GSTIN = obj.policy_no != undefined ? obj.policy_no : '';

              this.appointment_list.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                doc_reg_id: obj.appointment[i].doc_reg_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                address1: address1,
                address2: address2,
                location: location,
                district: city,
                state: state,
                country: country,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                city: city,
                zipcode: zipcode,
                docid_name: this.Docidname,
                docName: docName,
                advance: obj.appointment[i].advance,
                advance_list: obj.appointment[i].advances,
                balance: obj.appointment[i].balance,
                inpatientId: inpatientId,
                cgst: '12%',
                sgst: '12$',
                admitted_date: obj.appointment[i].admitted_date,
                admitted_time: obj.appointment[i].admitted_time,
                discharge_date: obj.appointment[i].discharge_date,
                discharge_time: obj.appointment[i].discharge_time,
                department_id: obj.appointment[i].department_id,
                department_desc: obj.appointment[i].department_desc,
                ward_id: obj.appointment[i].ward_id,
                ward_name: obj.appointment[i].ward_name,
                bed_no: obj.appointment[i].bed_no,
                profile_image: image,
                patient_id: obj.appointment[i].patient_id,
                age: obj.appointment[i].age,
                gender: obj.appointment[i].gender,
                mobile: this.mobile_no,
                emerg_contact: this.e_contact,
                salutation_desc: obj.appointment[i].salutation_desc,
                ward_details: wardDetails,
                doc_visits: doc_visits,
                other_charges: other_charges,
                surgery_desc: surgery,
                bill_type_pharma: bill_type_pharma,
                barcode: barcode,
                approved_amount: obj.appointment[i].approved_amount,
                hosp_charges: hosp_charges,
                diag_charges: diag_charges,
                diagnosis_bills: diagnosis_bills,
                pharma_charges: pharma_charges,
                pharma_bills: pharma_bills,
                rate_card_disc: rate_card_disc,
                isDisInitiate: isDisInitiate,
                inp_hosp_id: obj.appointment[i].inp_hosp_id,
                ins_or_nonins: ins_or_nonins,
                insurer: insurer,
                sponser_name: sponser_name,
                sponser_GSTIN: sponser_GSTIN
              });
            }

            if (
              this.appointment_list != undefined &&
              this.appointment_list.length === 1
            ) {
              for (var i = 0; i < this.appointment_list.length; i++) {
                this.appoint_txt = this.appointment_list[i].docid_name;
                this.Appointmnet_change(this.appoint_txt);
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

   Appointmnet_change(e) {
    var patientname = e.split('-');
    this.billing_print_array = [];
    this.mrno = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.mobile_no = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.wardName = '';
    this.bed_no = '';
    this.ward_id = '';
    this.profileImg = '';
    this.insurance_flag = false;
    this.insurer = '';
    
    for (var i = 0; i < this.appointment_list.length; i++) {
      if (this.appointment_list[i].doc_app_id == parseFloat(patientname[0])) {
        if (this.appointment_list[i].ins_or_nonins != undefined && this.appointment_list[i].ins_or_nonins == 'insurance') {
          this.insurance_flag = true;
          this.pay_mode = 'Insurance';
          this.insurer = this.appointment_list[i].insurer;
          this.sponser_name = this.appointment_list[i].sponser_name;
          this.sponser_GSTIN = this.appointment_list[i].sponser_GSTIN;
        } else {
          this.insurance_flag = false;
        }

        
        if (this.appointment_list[i].admitted_date != undefined) {
          var admdate =
            this.appointment_list[i].admitted_date != undefined
              ? this.appointment_list[i].admitted_date.split('-')
              : '';
          var admtime =
            this.appointment_list[i].admitted_time != undefined
              ? this.appointment_list[i].admitted_time
              : '';

          if (this.appointment_list[i].discharge_date &&
            this.appointment_list[i].discharge_time != undefined && '' && null) {
            this.enddate = this.appointment_list[i].discharge_date.split('-');
            this.endtime = this.appointment_list[i].discharge_time;

          } else {
            this.enddate = this.CurrentDatetime.split('-');
            this.endtime = this.currentTime;
          }
          console.log("END_TIME", this.endtime);
        }

        this.ward_id = this.appointment_list[i].ward_id;
        console.log('Ward_Id', this.ward_id);

        if (this.appointment_list[i].middle_name != undefined) {
          this.patient_name =
            encrypt_decript.Decript(this.appointment_list[i].first_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].middle_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].last_name);
        } else {
          if (this.appointment_list[i].last_name != undefined) {
            this.patient_name =
              encrypt_decript.Decript(this.appointment_list[i].first_name) +
              ' ' +
              encrypt_decript.Decript(this.appointment_list[i].last_name);
          } else {
            this.patient_name = encrypt_decript.Decript(
              this.appointment_list[i].first_name
            );
          }
        }


        this.dog_reg_id = this.appointment_list[i].doc_reg_id;
        var zipcode = this.appointment_list[i].czipcode != undefined
            ? encrypt_decript.Decript(this.appointment_list[i].czipcode) : '';
        this.mrno = this.appointment_list[i].patient_id;

        if (this.appointment_list[i].salutation_desc != undefined) {
          this.clnt_saldesc = this.appointment_list[i].salutation_desc + '.';
        }

        this.clnt_age = this.appointment_list[i].age;
        if (
          this.appointment_list[i].gender != null &&
          this.appointment_list[i].gender != undefined
        ) {
          this.gender = encrypt_decript.Decript(
            this.appointment_list[i].gender
          );
          this.gender = this.gender[0];
          if (this.gender[0] == 'T') {
            this.gender = 'Tgen';
          }
        }

        this.mobile_no = this.appointment_list[i].mobile != undefined
            ? this.appointment_list[i].mobile : '';
        this.e_contact = this.appointment_list[i].emerg_contact != undefined
            ? this.appointment_list[i].emerg_contact : '';
        this.admitted_date = this.appointment_list[i].admitted_date != undefined
            ? this.appointment_list[i].admitted_date : '';
        this.admitted_time = admtime != undefined ? Time_Formate(admtime) : '';
        this.discharge_date = this.appointment_list[i].discharge_date != undefined
            ? this.appointment_list[i].discharge_date : '';
        this.doctor_name = this.appointment_list[i].docName != undefined
            ? this.appointment_list[i].docName : '';
        this.wardName = this.appointment_list[i].ward_name != undefined
            ? this.appointment_list[i].ward_name : '';
        this.bed_no = this.appointment_list[i].bed_no != undefined
            ? this.appointment_list[i].bed_no : '';
        this.profileImg =
          this.appointment_list[i].profile_image != undefined
            ? this.appointment_list[i].profile_image : '';
        if (this.appointment_list[i].discharge_date != undefined &&
          this.appointment_list[i].discharge_date != '') {
          this.discharge_date =  Date_Formate(
            this.appointment_list[i].discharge_date
          );
        }
        if (this.appointment_list[i].discharge_time != undefined &&
          this.appointment_list[i].discharge_time != '') {
          this.discharge_time = Time_Formate(
            this.appointment_list[i].discharge_time
          );
        }
        this.barcode_url = this.appointment_list[i].barcode;
        this.billing_print_array.push({
          dr_name: this.appointment_list[i].docName,
          licence_code: '',
          hptl_name: '',
          created_date: '',
          address1: this.appointment_list[i].address1,
          address2: this.appointment_list[i].address2,
          location: this.appointment_list[i].location,
          city: this.appointment_list[i].city + this.appointment_list[i].zipcode,
          state: this.appointment_list[i].state + this.appointment_list[i].country,
          telephone: this.appointment_list[i].telephone,
          inpatientId: this.appointment_list[i].inpatientId,
          inp_hosp_id: this.appointment_list[i].inp_hosp_id,
          appointment_id: this.appointment_list[i].doc_app_id,
          client_name: this.patient_name,
          caddress: '',
          clocation: this.appointment_list[i].clocation + ', ',
          clientcity: this.appointment_list[i].ccity + ' - ' + zipcode + ',',
          clestare: this.appointment_list[i].cstate + ' - ' + this.appointment_list[i].ccountry + '.',
          client_reg_id: this.appointment_list[i].client_reg_id,
          advance: this.appointment_list[i].advance,
          balance: this.appointment_list[i].balance,
          relation_id: this.appointment_list[i].relation_id,
          sub_rel_id: this.appointment_list[i].sub_rel_id,
        });
        this.createpage_flag = true
      }
    }
  }

  getdatavaluemrno(value) {
    if (!this.mrno || this.mrno.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mrno);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  getdatavaluelocation(value) {
    if (!this.location || this.location.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.location);
      if (this.location.length >= 3) {
        this.filtermethod("location", this.location, '');
        // Your logic for non-empty value
      }
    }
  }

  getdatamobilenumber(vlaue) {
    if (!this.mobile || this.mobile.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mobile);
      this.filtermethod("mobile", this.mobile, '');
      // Your logic for non-empty value
    }
  }

  getdatavalue(value) {
    if (!this.name || this.name.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.name);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  mrnobased() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }

  backtoApplist(data) {
    if (data == "view") {
      this.listview = true;
      this.selectvalue = "mrno";
      this.mrno = '';
      this.floorvalue = "All";
      this.view_type = "graph";
      this.doctor = "All";
      this.dog_reg_id = "All";
      this.getPatients();
    } else if (data == 'est_list') {
      this.messageservice.sendMessage("inpat_bill_estimate_list");
    }
  } 

  // Method to handle checkbox change
  onAdmissionTypeChange(type: string): void {
    if (this.selectedAdmissionType === type) {
      this.selectedAdmissionType = null; // Unselect if the same option is clicked
    } else {
      this.selectedAdmissionType = type;
    }
  }

  // Check if a checkbox should be checked
  isAdmissionTypeChecked(type: string): boolean {
    return this.selectedAdmissionType === type;
  }
  
  // Method to handle checkbox change
  onCheckboxChange(option: string): void {
    if (this.selectedOption === option) {
      this.selectedOption = null; // Unselect if the same option is clicked
    } else {
      this.selectedOption = option;
    }
  }

  // Check if a checkbox should be checked
  isChecked(option: string): boolean {
    return this.selectedOption === option;
  }

  save() {
    console.log(this.appointment_list);
    var sendData:any = {
      country: ipaddress.country_code.toString(),
      hptl_clinic_id: this.hptl_clinic_id,
      client_reg_id: this.appointment_list[0].client_reg_id,
      relation_id: this.appointment_list[0].relation_id,
      sub_rel_id: this.appointment_list[0].sub_rel_id,
      fin_year: this.fin_year,
      medical_type: this.selectedAdmissionType,
      medical: this.selectedOption,
      no_days: this.day_count,
      ward_id: this.ward_id,
      room_rent: this.room_rent,
      doctor_fee: this.dr_fees,
      procedures: this.procedures,
      diagnostics: this.diagnostics,
      dietry: this.Dietry,
      total_amount: this.total,
      created_by: this.user_id
    }
    if (this.billEstUpdateButtonFlag && this.billEstBackButtonFlag && this.bill_est_id != '') {
      sendData.billing_estimate_id = this.bill_est_id;
    }
    console.log(sendData);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/sbeh' ,
        JSON.stringify(sendData),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.result != null) {
            if (obj.key == '1') {
              this.bill_est_id = obj.Bill_est_id;
              this.toastr.success(obj.result);
              setTimeout(()=>{
                this.cdr.detectChanges();
                this.print_area();
              },500);
              if (this.billEstUpdateButtonFlag && this.billEstBackButtonFlag) {
                this.backtoApplist('est_list');
              } else {
                this.backtoApplist('view');
              }
            } else {
              this.toastr.error(Message_data.getBillUngen());
            }
          }
        },
        (error) => {
          
        }
      );
  }

  print_area() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
     <html>
      <head>
        <title>Print Estimate</title>
        <style>
          @page {
            margin: ${margin_top} 0 0 0;
          }
          body {
            font-family: Arial, sans-serif;
            font-size: 12px;
            line-height: 1.5;
            margin: 0;
            padding: 0;
          }
          .section-label {
              font-weight: 600;
              margin-bottom: 5px;
              font-size: 14px;
          }
          .checkbox-group, .input-field {
            margin-bottom: 10px;
          }
          .header_label {
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              margin-bottom: 20px;
          }
          .section {
            margin-bottom: 20px;
          }
          .input-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .key {
            font-weight: bold;
          }
          .value {
            text-align: right;
          }
          .padding {
            padding-bottom: 3px;
          }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

}
