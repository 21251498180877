<div *ngIf="treatmentFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel">Investigtion<br>
            <textarea class="ipcss" matInput rows="1" [(ngModel)]="investigation"></textarea>
        </mat-label>
        <mat-label class="matlabel">{{therapyLabel}}<br>
            <select required class="ipcss" [(ngModel)]="therapy">
                <option [ngValue]="Select">Select</option>
                <option *ngFor="let therapy of therapyMasterList" value={{therapy.value}}> {{therapy.label}}</option>
            </select>
        </mat-label>
        <mat-label class="matlabel">{{sittingLabel}}<br>
            <input class="ipcss" required [(ngModel)]="sittings" maxlength="3"
                onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)">
        </mat-label>
    </div>
</div>
<div *ngIf="treatmentFlag == 'max'">
    <div class="row">
        <div class="col-8" style="margin-bottom: 5px !important;">
            <mat-label class="matlabel">Investigtion<br>
                <textarea class="ipcss" matInput rows="3" [(ngModel)]="investigation"></textarea>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link " id="therapy-tab" data-bs-toggle="tab" data-bs-target="#therapy"
                        type="button" role="tab" aria-controls="therapy" aria-selected="true"
                        (click)="changetab('therapy')">Therapy</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="Prognosis-tab" data-bs-toggle="tab" data-bs-target="#Prognosis"
                        type="button" role="tab" aria-controls="Prognosis" aria-selected="false"
                        (click)="changetab('prognosis')">Prognosis</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Notes-tab" data-bs-toggle="tab" data-bs-target="#Notes" type="button"
                        role="tab" aria-controls="Notes" aria-selected="false"
                        (click)="changetab('notes')">Notes</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane " id="therapy" role="tabpanel" aria-labelledby="home-tab"
                    [hidden]="showTherapyFlag">
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-6">
                                    <mat-label class="matlabel">{{therapyLabel}}
                                        <select required class="ipcss" [(ngModel)]="therapy">
                                            <option [ngValue]="Select">Select</option>
                                            <option *ngFor="let therapy of therapyMasterList" value={{therapy.value}}>
                                                {{therapy.label}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{sittingLabel}}
                                        <input class="ipcss" required [(ngModel)]="sittings" maxlength="3"
                                            onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)">
                                    </mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{visitLabel}}
                                        <select class="ipcss" required [(ngModel)]="visitFreq" required>
                                            <option>Every day</option>
                                            <option>Alternative days</option>
                                            <option>3 days once</option>
                                            <option>Once in a week</option>
                                            <option>Once in fortnight</option>
                                            <option>Once in a month</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addTherapy()"><img
                                    src="../../../assets/ui_icons/arrow_red.svg" class="saveimg" /></a>
                        </div>
                    </div>
                </div>
                <div class="tab-pane  show active" id="Prognosis" role="tabpanel" aria-labelledby="Prognosis-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea style="height: 150px !important;" class="ipcss" required maxlength="750"
                                [(ngModel)]="prognosis"></textarea>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;" [hidden]="addPrognosisFlag">
                            <a (click)="addPrognosis()"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimg" /></a>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="Notes" role="tabpanel" aria-labelledby="Notes-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea class="ipcss txtarea" style="height: 150px !important;" rows="1" maxlength="750"
                                [(ngModel)]="remarks"></textarea>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addRemarks()"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimg" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 ">
            <div class="table_cover " *ngIf="therapyTableFlag" style="margin-top: 55px;">
                <div class="col-12" style="margin-top: 5px; height: 160px;">
                    <div [hidden]="therapyList.length == 0" class="dig_table_overflow">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Theraphy</th>
                                    <th>No of sittings</th>
                                    <th>Frequency</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let therapy of therapyList">
                                    <td>{{therapy.therapy_name}}</td>
                                    <td>{{therapy.sittings}}</td>
                                    <td>{{therapy.frequency}}</td>
                                    <td>
                                      <a (click)="deleteTherapy(therapy.therapy_id)"><img
                                        src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                        class="editDelicon" /></a>
                                    </td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="col-12" >
                    <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="updateTherapy()" class="saveimgbtn_inpatinfo pointer" style="float: right;"/>
                    <img src="../../../assets/ui_icons/buttons/history.svg" (click)="therapyHistory()" class="saveimgbtn_inpatinfo pointer" style="float: right;"/>
                </div> -->
            </div>
            <div class="table_cover" *ngIf="prognosisTableFlag" style="margin-top: 55px;">
                <div class="col-12" *ngIf="prognosisList.length != 0" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="prognosisList.length != 0">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Doctor name</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let prognosis of prognosisList">
                                    <td style="width: 16%;font-size: 10px;">{{prognosis.date}}</td>
                                    <td style="width: 27%;text-align: left;font-size: 10px;">{{prognosis.doc_name}}</td>
                                    <td style="text-align: left;font-size: 10px;">{{prognosis.prognosis}}</td>
                                    <td style="width: 40px;" *ngIf="prognosis.add_new=='0'"></td>
                                    <td style="width: 40px;" *ngIf="prognosis.add_new=='1'"><img
                                            src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                            (click)="editPrognosis(prognosis)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table_cover" *ngIf="notesTableFlag" style="margin-top: 55px;">
                <div class="col-12" *ngIf="remarksList.length" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="remarksList.length != 0">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th style="width: 150px;">Date</th>
                                    <th>Note</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let remark of remarksList">
                                    <td style="font-size: 10px;">{{remark.Date}}</td>
                                    <td style="text-align: left;font-size: 10px;">{{remark.remarks_data}}</td>
                                    <td style="width: 40px;" *ngIf="remark.add_new=='0'"></td>
                                    <td style="width: 40px;" *ngIf="remark.add_new=='1'"><img
                                            src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                            (click)="editRemarks(remark)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>