import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../ipaddress'; 
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Time_Formate } from '../../../assets/js/common';

@Component({
  selector: 'app-advance-estimate-list',
  templateUrl: './advance-estimate-list.component.html',
  styleUrls: ['./advance-estimate-list.component.scss']
})
export class AdvanceEstimateListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public userInfo;
  public hptl_clinic_id;
  public fin_year;
  public Moduleidlist;
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public newbutton: boolean = false;
  public viewbutton: boolean = false;
  public printbutton: boolean = false;
  public estBillList:any = [];
  public nobill_estimatesFlag: boolean = true;

  constructor(public toastr: ToastrService,
    public http: Http, public frontdeskservice: MenuViewService, public adminservice: MenuViewService, public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    this.hptl_clinic_id = this.userInfo.hptl_clinic_id;
    this.fin_year = this.userInfo.fin_year;
    this.Moduleidlist = Helper_Class.getmodulelist();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by bill no or patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
   
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }
    this.getestBillList();
  }

  getestBillList() {
    var sendData = {
      hptl_clinic_id: this.hptl_clinic_id,
      fin_year: this.fin_year,   
    }
    console.log(sendData);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/gbeh' ,
        JSON.stringify(sendData),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.bill_estimates != null && obj.bill_estimates != undefined && obj.bill_estimates.length != 0) {
            for (var i = 0; i < obj.bill_estimates.length; i++) {
              var clnt_nmae;
              if (obj.bill_estimates[i].middle_name != undefined && obj.bill_estimates[i].middle_name != null) {
                clnt_nmae = encrypt_decript.Decript(obj.bill_estimates[i].first_name) + " " + encrypt_decript.Decript(obj.bill_estimates[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_estimates[i].last_name);
              } else {
                if (obj.bill_estimates[i].last_name != undefined && obj.bill_estimates[i].last_name != null) {
                  clnt_nmae = encrypt_decript.Decript(obj.bill_estimates[i].first_name) + " " + encrypt_decript.Decript(obj.bill_estimates[i].last_name);
                } else {
                  clnt_nmae = encrypt_decript.Decript(obj.bill_estimates[i].first_name);
                }
              }
      
              var salutation = '';
              if (obj.bill_estimates[i].salutation != undefined && obj.bill_estimates[i].salutation != null) {
                salutation = obj.bill_estimates[i].salutation + ".";
              }
      
              var get_date = obj.bill_estimates[i].created_date.split('-');
              if (obj.bill_estimates[i].created_time != undefined) {
                var get_time:any = Time_Formate(obj.bill_estimates[i].created_time);
              }
        
              var mr_no = '';
              mr_no = obj.bill_estimates[i].mr_no != undefined ? obj.bill_estimates[i].mr_no : '';
      
              var ageval,genderval;
              if(obj.bill_estimates[i].age != undefined){
                ageval = obj.bill_estimates[i].age;
              } else {
                ageval = "";
              }
              if(obj.bill_estimates[i].gender != undefined){
                genderval = obj.bill_estimates[i].gender;
              } else {
                genderval = "";
              } 
              
              if (genderval != null && genderval != undefined && genderval != '') {
                genderval = encrypt_decript.Decript(genderval);
                genderval = genderval[0];
                if (genderval != undefined && genderval[0] == 'T') {
                  genderval = 'Tgen';
                }
              }  
                      
              this.estBillList.push({
                bill_id: obj.bill_estimates[i].billing_estimate_id,
                date: get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+ get_time,
                patient_id: mr_no,
                salutation: salutation,
                client_name: clnt_nmae,
                age_gender: ageval + " / " + genderval,
                ward_id: obj.bill_estimates[i].ward_id,
                ward_name: obj.bill_estimates[i].ward_name,
                diagnostics: obj.bill_estimates[i].diagnostics,
                dietry: obj.bill_estimates[i].dietry,
                doctor_fee: obj.bill_estimates[i].doctor_fee,
                medical: obj.bill_estimates[i].medical,
                medical_type: obj.bill_estimates[i].medical_type, 
                no_days: obj.bill_estimates[i].no_days,
                procedures: obj.bill_estimates[i].procedures,
                room_rent: obj.bill_estimates[i].room_rent,
                total_amount: obj.bill_estimates[i].total_amount,
              });
            }
            console.log(this.estBillList);
            if (this.estBillList.length == 0) {
              this.nobill_estimatesFlag = true;
            } else {
              this.nobill_estimatesFlag = false;
            }
          } else {
            this.nobill_estimatesFlag = true;
            this.toastr.error(Message_data.getBillUngen());
          }
        },
        (error) => {
          
        }
      );
  }

  viewBill(bill) {   
    FrontDesk_Helper.setInpatientEstBillList(bill);
    if (bill.bill_id != undefined && bill.bill_id != null) {
      this.frontdeskservice.sendMessage("inpat_bill_estimate");
    }      
  }

}
