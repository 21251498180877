<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Product usage</h5>
            </div>
            <div class="headerButtons">
             
                <mat-label class="matlabel"><br>
                  <!-- <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo" (click)="exportToExcel()" style="position: relative;bottom: 17px !important;"/> -->
                  <img  src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save_data()" class="saveimgbtn_inpatinfo" />
                </mat-label>
             
            </div>
          </div>
  
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-12" style="margin-bottom:15px;">
              <div class="row">
                <div class="col-3">
                    <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                     maxlength="50" [(ngModel)]="patientName" matInput (keyup)="getdatausingname(patientName)"
                    />
  
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 position" *ngIf="this.usertype == 'Admin'">
                  <mat-label class="matlabel">Department<br>
                      <mat-select disableOptionCentering class="ipcss " [(ngModel)]="departname"
                        (selectionChange)="pobase_department($event)">
                        <mat-option *ngFor="let department of departmentarray"
                          value={{department.department_id}}>{{department.description}}</mat-option>
                      </mat-select>
                  </mat-label>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 position" *ngIf="this.usertype == 'Admin'">
                  <mat-label class="matlabel">Store name<br>
                    <mat-select  class="ipcss "  [(ngModel)]="storename">
                     
                      <mat-option *ngFor="let stro of storearray"  value={{stro.store_names_id}} (Change)="changePageList()">{{stro.store_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                

                <div class="col-1" style="margin-left:10px !important">
                  <mat-label class="matlabel"style="position: relative;
                  bottom: 17px !important;" >Per page<br>
                    <select [(ngModel)]="pagelist" class="ipcss" (change)="changePageList()">
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="full">Full</option>
                    </select>
                  </mat-label>
                </div>
              </div>
            </div>
            <div *ngIf="viewbutton">
  
            <div class="col-12 stock_ledger_table" *ngIf="stockData.length != 0">
              <table  class="table-fixed outline_setup rounded-3" >
                <thead>
                  <tr class="background-color_b" style="height: 35px;">
                    <th style="font-weight: 400;width:5%;text-align: center;">S.no</th>
                    <th style="width:30%;font-weight: 400;text-align: center;">Medicine</th>
                    
                    <th style="font-weight: 400;width: 20%;text-align: center;">
                      Batch</th>
                    
                      <th style="font-weight: 400;width:20%;text-align: center;">Avbl qty</th>
                      <th style="font-weight: 400;width:20%;text-align: left;">Reduce by</th>
                     
                      <th></th>
                  </tr>
                </thead>
      
                <tbody>
                  <tr *ngFor="let stock of stockData; let i = index" style="height: 30px;">
                    <td>{{stock.sn}}</td>
                    <td style="font-size: 12px; text-align: left;padding: 5px;"> {{stock.short_name}} - {{stock.name}} </td>
                   
                    <td style="font-size: 12px;text-align: center;"> {{stock.batch_no}} </td>
                   
                    <td style="text-align: center;">{{stock.qty_onhand}}</td>
                    <td style="display:flex;">
                        <div style="width:60px">
                        <img style="width:20px" src="../../../assets/img/minussybl.svg"
                          (click)="subtractFromStock('plus',stock);" [clickable]="stock.qty_onhand !='0'" [hidden]="stock.qty_onhand =='0'">
                        <img style="width:20px;height: 20px;margin-top: 4px;margin-left:10px" src="../../../assets/img/minus1.png"
                          (click)="subtractFromStock('minus',stock);" [clickable]="stock.qty_onhand !='0'" [hidden]="stock.qty_onhand =='0'">
                          </div>
                          <input type="number" class="ipcss" style="width: 21%;margin-left: 20px;" [disabled]="true" [(ngModel)]="stock.quantity">
                    </td>
                    
                        <td>
                            <input type="checkbox"  [checked]="stock.checked" [disabled]="stock.qty_onhand =='0'|| stock.quantity =='0' || stock.quantity =='' || stock.quantity <= '0'" (click)="checkbox_data($event,stock)">
                        </td>
                  </tr>
                </tbody>
              </table>
            </div>
  
            <div>
                <!--  -->
              <div class="row" style="text-align: center;margin-top: 5px;" *ngIf="stockData != undefined && stockData.length !=0">
                  <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                  <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom" >
                  <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                      src="../../../assets/ui_icons/arrow_blue.svg" />
                  </div>
                  <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                  <span class="current_font">{{currentPage}}/{{numberOfPages()||1}}</span>
                  </div>
      
                  <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                  <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                      src="../../../assets/ui_icons/arrow_blue r.svg" />
                  </div>                     
              </div>
          </div> 
  
            </div>
            <div class="col-12" *ngIf="stockData.length == 0">
              <p class="nodata">No data found</p>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
