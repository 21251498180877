<mdb-card-header class="bg-white ">
    <div class="headerCover">
        <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{this.masterpageheading}}</h5>
        </div>
    </div>
</mdb-card-header>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'sampletype'">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Specimen type
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="d-flex">
                                    <input placeholder="sample name" class="inputpw" [(ngModel)]="samplelistinputname"
                                        matInput>
                                    <mat-label class="matlabel ml-5">type</mat-label>
                                    <mat-select class="mat" [(ngModel)]="tube_type_id" multiple>
                                        <mat-option *ngFor="let Diagnostict of testtube_data"
                                            [value]="Diagnostict.tube_type_id">
                                            {{Diagnostict.tube_desc}}
                                        </mat-option>
                                    </mat-select>
                                </div>

                            </div>
                            <div class="col-12 col-md-6">
                                <img src="../../assets/img/add.png" class="saveimgbtn_inpatinfo"
                                    (click)="addsamplelist()" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let samplelist of samplelistarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px; width: 45%">
                                                    {{samplelist.samplelistname}}</td>
                                                <td style="text-align: right;">
                                                    <a
                                                        (click)="editsamplelistDetails(samplelist.samplelistname, samplelist.specimen_type_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            style="margin: 0 5px;" />
                                                    </a>
                                                    <a (click)="deletesamplelistDetails(samplelist.specimen_type_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'DiagnosticD'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Diagnostic Department
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Diagnostic Department" [(ngModel)]="DiagnosticDinputname"
                                    style="margin-left:6%" matInput>
                                <img src="../../../assets/img/add.png" class="saveimgbtn_inpatinfo"
                                    (click)="addDiagnosticD()" />
                            </div>


                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let DiagnosticD of DiagnosticDarray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{DiagnosticD.DiagnosticDname}}</td>
                                                <td class="butnstyle">
                                                    <a
                                                        (click)="editDiagnosticDDetails(DiagnosticD.DiagnosticDname,DiagnosticD.diag_department_id)">

                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            class="btun" />
                                                    </a>
                                                    <a
                                                        (click)="deleteDiagnosticDDetails(DiagnosticD.diag_department_id)">

                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Diagnostict'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Diagnostic tubes
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input placeholder=" Diagnostic tubes" [(ngModel)]="Diagnostictinputname"
                                    style="margin-left:6%" matInput>
                                <input type="color" [(ngModel)]="color_code" class="hi" />
                                <mat-label class="matlabel"> size
                                    <select class="matpw" [(ngModel)]="tube_size">
                                        <option>None</option>
                                        <option value="5mm">
                                            5mm
                                        </option>
                                        <option value="15mm">
                                            15mm
                                        </option>
                                        <option value="20mm">
                                            20mm
                                        </option>
                                    </select>
                                </mat-label>
                                <img src="../../../assets/img/add.png" class="saveimgbtn_inpatinfo"
                                    (click)="addDiagnostict()" />
                            </div>


                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let Diagnostict of Diagnostictarray">


                                    <table id="salesTable" class="table table-sm  discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{Diagnostict.Diagnostictname}}</td>
                                                <td><i [style.color]="Diagnostict.color_code"
                                                        class="fa-solid fa-droplet"></i></td>
                                                <td class="butnstyle">
                                                    <a
                                                        (click)="editDiagnostictDetails(Diagnostict.Diagnostictname,Diagnostict.tube_type_id)">

                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            class="btun1" />
                                                    </a>
                                                    <a (click)="deleteDiagnostictDetails(Diagnostict.tube_type_id)">

                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'DiagnosticR'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Diagnostic Reagent
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input class="input" placeholder="Diagnostic Reagent" [(ngModel)]="DiagnosticRinputname"
                                    matInput>
                                <mat-label class="matlabel">type
                                    <select [(ngModel)]="reagent_type_id">
                                        <option active>None</option>
                                        <option *ngFor="let DiagnosticR of DiagnosticRarraytype"
                                            [value]="DiagnosticR.reagent_type_id">
                                            {{ DiagnosticR.reagent_type_desc }}
                                        </option>
                                    </select>
                                </mat-label>
                                <img src="../../../assets/img/add.png" class="saveimgbtn_inpatinfo"
                                    (click)="addDiagnosticR()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let DiagnosticR of DiagnosticRarray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{ DiagnosticR.DiagnosticRname }}</td>
                                                <td class="butnstyle">
                                                    <a
                                                        (click)="editDiagnosticRDetails(DiagnosticR.DiagnosticRname, DiagnosticR.reagent_type_id)">

                                                        <img class="butn1" src="../../assets/img/edit.png" width="20"
                                                            height="20" />
                                                    </a>
                                                    <a (click)="deleteDiagnosticRDetails(DiagnosticR)">

                                                        <img class="btun" src="../../assets/dist/images/delete.png"
                                                            width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'surgery_doc'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">

                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">

                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">
                                            <input type="text" class="input_surg" maxlength="50"
                                                (keyup)="getReferralData(doctorName)" maxlength="50"
                                                placeholder="Doctor name" [(ngModel)]="doctorName" matInput
                                                [matAutocomplete]="auto1" />
                                            <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                                                <mat-option (click)="Select_refer_doctor(item)"
                                                    *ngFor="let item of ReferedDoctorArray" value="{{item.docname}}">
                                                    {{item.docname}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">
                                            <input type="text" class="input_surg" placeholder="Surgery"
                                                [(ngModel)]="surgery_name" (keyup)="surgeryChange(surgery_name)"
                                                (focus)="clearFields()" matInput [matAutocomplete]="sauto" required />
                                            <mat-autocomplete #sauto="matAutocomplete">
                                                <mat-option (click)="surgerySelect(surgery)"
                                                    *ngFor="let surgery of surgery_list"
                                                    value="{{surgery.surgery_name}}">
                                                    {{surgery.surgery_name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">

                                        <input type="text" class="input_surg" placeholder="Cost" [(ngModel)]="cost"
                                            matInput required />

                                    </div>

                                    <img src="../../../assets/img/add.png" class="saveimgbtn_inpatinfo"
                                        (click)="savve_surgery_cost('','1')" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let item of  surgpaymentarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                                width: 45%">{{item.docname}}</td>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{item.surgery_name}}</td>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{item.cost}}</td>
                                                <td class="butnstyle" style="display:flex">
                                                    <a (click)="editsavve_surgery_cost(item)"> <img src="../../assets/img/edit.png" width="20" height="20" class="btun" />
                                                    </a>
                                                    <a (click)="savve_surgery_cost(item,'0')"> <img src="../../assets/dist/images/delete.png" width="20" height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>