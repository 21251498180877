import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress'; import { Diagnosis_Helper } from 'src/app/Diagnosis_module/Diagnosis_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;

@Component({
  selector: 'app-receptionist-mgmtapplist',
  templateUrl: './receptionist-mgmtapplist.component.html',
  styleUrls: ['./receptionist-mgmtapplist.component.css']
})
export class ReceptionistMgmtapplistComponent implements OnInit {
  @ViewChild('printtoken') printtoken: ElementRef;
  @ViewChild('reprinttoken') reprinttoken: ElementRef;
  public profileImg;
  public refreshtoken: boolean = true;
  public status;
  public ref_tokenno;
  public ref_token;
  public realflag1: boolean = false;
  public realflag2: boolean = false;
  public realflag3: boolean = false;
  public tokendocname;
  public tokendetails:any = [];
  public tockpatient_name;
  public tockmrnumber;
  public appdate;
  public tockdocname;
  public tockspl;
  public tocknumber;
  public ref_token_check;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public noapp: boolean = false;
  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  whatsappNumber: any;
  imgStr: any;
  public currentLimit = 1;

  private cdRef: ChangeDetectorRef

  public getAppDate;
  public userInfo;
  public session;
  public doctorName;
  public doctorList:any = [];
  public appointmentDate;
  public currentDate;
  public doctor;
  public doctorId;
  public patientName;
  public displayPatientName;
  public tokenNo;
  public appTime;
  public splitAppTime;
  dtOptions: DataTables.Settings = {};
  dtOptionscheck: DataTables.Settings = {};

  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public checklistid: any = [];
  public apptOpenCount: number = 0;
  public ipaddress;
  public appType;
  public doctorFlag: boolean;
  public diagLocationList: any = [];
  public datePick;
  public diagAppointmentList: any = [];
  public status_txt;
  public clientName;
  public fromTime;
  public location;
  public sendDiagCenterId;
  public ynopt1 = "doctor";
  public appointmentList: any = [];
  public userId;
  public doctorArr: any;
  public showDoctor;
  public showDiag;
  public showDietician;
  public diagCentreId;
  public cancelResone;
  public showcancel: boolean = true;
  public showcancel1: boolean = true;
  public docName;
  public showphysio;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public deletebutton: boolean = false;
  public newcreate: boolean = false;
  public editbuttondia: boolean = false;
  public newbuttondia: boolean = false;
  public printbuttondia: boolean = false;
  public viewbuttondia: boolean = false;
  public deletebuttondia: boolean = false;
  public editbuttondie: boolean = false;
  public newbuttondie: boolean = false;
  public printbuttondie: boolean = false;
  public viewbuttondie: boolean = false;
  public deletebuttondie: boolean = false;
  public newbutton: boolean = false;
  public editbutton: boolean = false;
  public url:string;

  constructor(public toastr: ToastrService, public frontdeskservice: MenuViewService, public http: Http, public cd: ChangeDetectorRef,) {
  }

  async ngOnInit() {
    this.profileImg = "../../assets/img/default.jpg";
    this.userInfo = Helper_Class.getInfo();
    this.doctorId = "All"
    this.getmoduledoctor();
    this.docName = "All"

    console.log("this.userInfo 00000000000" + JSON.stringify(Helper_Class.getInfo()))
    this.ipaddress = Helper_Class.getIPAddress();
    this.showDoctor = this.userInfo.doctor;
    this.showDiag = this.userInfo.diagnosis;
    this.showDietician = this.userInfo.dietician;
    this.showphysio = this.userInfo.physio;

    if (this.showDiag == "1") {
      this.getmodulediagnosis();
    }
    if (this.showDietician == "1") {
      this.getmoduledietician();
    }
    await this.getCurrentDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [-1] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by Name or Patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.dtOptionscheck = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by Name or Patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (this.showDoctor == '1') {
      this.appType = "doctor";
      this.ynopt1 = "doctor";
      this.userTypeSelect("doctor");
      console.log(FrontDesk_Helper.getmodules());
      if (FrontDesk_Helper.getmodules() != undefined) {
        for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
          if (FrontDesk_Helper.getmodules()[i].module_id == "15") {
            if (FrontDesk_Helper.getmodules()[i].edit == "1") {
              this.editbutton = true;
            }
            if (FrontDesk_Helper.getmodules()[i].create == "1") {
              this.newbutton = true;
            }
            if (FrontDesk_Helper.getmodules()[i].delete == "1") {
              this.deletebutton = true;

            }
            if (FrontDesk_Helper.getmodules()[i].print == "1") {
              this.printbutton = true;
            }
            if (FrontDesk_Helper.getmodules()[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }

      } else {
        if (this.userInfo.user_type == "Admin") {
          this.viewbutton = true;
          this.printbutton = true;
          this.deletebutton = true;
          this.newbutton = true;
          this.realflag1 = true;
          this.editbutton = true;
        }
      }


    } else if (this.showDiag == '1') {
      if (FrontDesk_Helper.getmodules() != undefined) {
        for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
          if (FrontDesk_Helper.getmodules()[i].module_id == "16") {
            if (FrontDesk_Helper.getmodules()[i].edit == "1") {
              this.editbuttondia = true;
            }
            if (FrontDesk_Helper.getmodules()[i].create == "1") {
              this.newbuttondia = true;
            }
            if (FrontDesk_Helper.getmodules()[i].delete == "1") {
              this.deletebuttondia = true;
            }
            if (FrontDesk_Helper.getmodules()[i].print == "1") {
              this.printbuttondia = true;
            }
            if (FrontDesk_Helper.getmodules()[i].view == "1") {
              this.viewbuttondia = true;
            }
          }
        }
      } else {
        if (this.userInfo.user_type == "Admin") {
          this.editbuttondia = true;
          this.newbuttondia = true;
          this.realflag2 = true;
          this.deletebuttondia = true;
          this.printbuttondia = true;
          this.viewbuttondia = true;
        }
      }



      this.appType = "Dignosis";
      this.ynopt1 = "diagnosis";
      this.userTypeSelect("Dignosis");
    } else if (this.showDietician == '1') {
      this.appType = "dietician";
      this.ynopt1 = "dietician";
      this.userTypeSelect("dietician");
      if (FrontDesk_Helper.getmodules() != undefined) {
        for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
          if (FrontDesk_Helper.getmodules()[i].module_id == "17") {
            if (FrontDesk_Helper.getmodules()[i].edit == "1") {
              this.editbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].create == "1") {
              this.newbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].delete == "1") {
              this.deletebuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].print == "1") {
              this.printbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].view == "1") {
              this.viewbuttondie = true;
            }
          }
        }

      } else {
        if (this.userInfo.user_type == "Admin") {
          this.editbuttondie = true;
          this.newbuttondie = true;
          this.realflag3 = true;
          this.deletebuttondie = true;
          this.printbuttondie = true;
          this.viewbuttondie = true;
        }
      }
    } else if(this.showphysio == '1'){
      this.appType = "physio";
      this.ynopt1 = "physio";
      this.userTypeSelect("physio");
      if (FrontDesk_Helper.getmodules() != undefined) {
        for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
          if (FrontDesk_Helper.getmodules()[i].module_id == "17") {
            if (FrontDesk_Helper.getmodules()[i].edit == "1") {
              this.editbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].create == "1") {
              this.newbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].delete == "1") {
              this.deletebuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].print == "1") {
              this.printbuttondie = true;
            }
            if (FrontDesk_Helper.getmodules()[i].view == "1") {
              this.viewbuttondie = true;
            }
          }
        }

      } else {
        if (this.userInfo.user_type == "Admin") {
          this.editbuttondie = true;
          this.newbuttondie = true;
          this.realflag3 = true;
          this.deletebuttondie = true;
          this.printbuttondie = true;
          this.viewbuttondie = true;
        }
      }
    }
    if (this.userInfo.user_type == "Admin") {
      this.getDoctorList();
      this.showDoctor = this.userInfo.doctor;
      this.showDiag = this.userInfo.diagnosis;
      this.showDietician = this.userInfo.dietician;
      this.showphysio = this.userInfo.physio;
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            if (FrontDesk_Helper.getDate() == undefined)
              this.appointmentDate = this.currentDate;
            else
              this.appointmentDate = FrontDesk_Helper.getDate();
            this.getDoctorList();
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(e) {
    this.doctor = e.Doc_Name;
    if (e.type == "front-desk") {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setdoctor(e);
      this.doctorId = e.Doc_ID;
      this.getDoctorList();
    } else if (e.type == "dietician") {
      this.userId = e.Doc_ID;
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDietician(e);
      this.getDieticianPatientList();
    }
  }

  getDoctorList() {
    Helper_Class.setmangamentlist_doctor(null)
    this.appointmentList = [];
    this.apptOpenCount = 0;
    this.getAppDate = undefined;
    this.getAppDate = this.appointmentDate;
    this.showcancel = true;
    this.showcancel1 = true;

    var send_data;
        if(this.ynopt1 == 'doctor'){
      this.url='appointment/getdocapp';
          send_data={
            date: this.getAppDate,
        doc_prov_id: this.doctorId,
        home_care: "0",
        country: ipaddress.country_code,
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        type: "front-desk",

        imei: this.userInfo.user_id
          }
          console.log("send_data_doctlist"+JSON.stringify(send_data))
    }else if(this.ynopt1 == 'physio'){
      this.url='appointment/npapps';
      send_data={
        date: this.getAppDate,
        prov_id: this.doctorId,
        home_care: "0",
        country: ipaddress.country_code,
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        type: "physio",
        imei: this.userInfo.user_id
      }
      console.log("send_data_doctlist"+JSON.stringify(send_data))
    }    


    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.url,send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.appointmentList = [];
          this.appTime = "";
          var obj = response.json();
          console.log("check data obj" + JSON.stringify(obj))
          console.log("check data obj" + JSON.stringify(obj.length))
          if (obj.appointments != null && obj.appointments.length != 0 && obj.appointments[0] != null) {
            this.doctorFlag = true;
            this.noapp = false
            if (this.currentDate == this.appointmentDate) {
              this.showcancel = false;
              this.showcancel1 = false
            }
            if (obj.appointments.length != 0) {
              for (var i = 0; i < obj.appointments.length; i++) {
                this.patientName = "";

                if (obj.appointments[i].middle_name != undefined && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
                  this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
                    encrypt_decript.Decript(obj.appointments[i].middle_name) + " " +
                    encrypt_decript.Decript(obj.appointments[i].last_name);
                } else {
                  if (obj.appointments[i].last_name != undefined) {
                    this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
                      encrypt_decript.Decript(obj.appointments[i].last_name);
                  } else {
                    this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name);
                  }
                }

                this.displayPatientName = "";
                this.tokenNo = "";
                if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
                  this.tokenNo = obj.appointments[i].token;
                  this.ref_token_check = true;
                }
                if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
                  this.displayPatientName = this.patientName;
                  this.ref_token_check = true;
                }
                else if (obj.appointments[i].f_time == "1" && obj.appointments[i].token == undefined) {
                  this.tokenNo = " "
                  this.displayPatientName = this.patientName;
                  this.ref_token_check = true;
                }
                else {
                  this.appTime = "";
                  this.appTime = " ";
                  if (obj.appointments[i].f_time != undefined) {
                    this.splitAppTime = obj.appointments[i].f_time.split(":");
                  }

                  if (encrypt_decript.Decript(obj.appointments[i].session).toString() == "Morning") {
                    //this.ref_token_check =false
                    if (this.splitAppTime[0] == "12") {
                      this.ref_token_check = false
                      this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " PM";
                    } else {
                      this.ref_token_check = false
                      this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " AM";
                    }
                  } else {
                    this.ref_token_check = false
                    this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " PM";
                  }

                  this.displayPatientName = this.patientName;
                  this.tokenNo = this.appTime;
                }

                if (obj.appointments[i].session != undefined) {
                  this.session = this.capitalizeFirstLetter(encrypt_decript.Decript(obj.appointments[i].session));
                }
                if (obj.appointments[i].status == '1' || obj.appointments[i].status == '2') {
                  this.apptOpenCount = this.apptOpenCount + 1;
                }
                var status_txt;
                if (obj.appointments[i].status == "0") {
                  status_txt = "Completed";
                } else if (obj.appointments[i].status == "1") {
                  if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
                    if (obj.appointments[i].f_time != "1") {
                      var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
                      var cur_time = new Date(this.currentDate + " " + this.currentDate);
                      if (get_time > cur_time) {
                        status_txt = "Not visited";
                      } else {
                        status_txt = "Open";
                      }
                    } else {
                      var get_time = new Date(obj.appointments[i].date);
                      var cur_time = new Date(this.currentDate);
                      if (get_time >= cur_time) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else {
                    if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else if (obj.appointments[i].status == "2") {
                  console.log("kjlkjhjh ===" + obj.appointments[i].date + this.currentDate)
                  console.log("kjlkjhjh ===" + new Date(obj.appointments[i].date + " " + this.currentDate))
                  console.log("cur time ===" + new Date(this.currentDate + " " + this.currentDate))
                  if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
                    console.log("obj.appointments[i].date == this.currentDate" + obj.appointments[i].date == this.currentDate)
                    var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
                    var cur_time = new Date(this.currentDate + " " + this.currentDate);
                    status_txt = "Confirmed";

                  } else {
                    console.log(obj.appointments[i].date == this.currentDate)
                    if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                }
                var doctorname;
                if (obj.appointments[i].doc_middle_name != undefined) {
                  doctorname = obj.appointments[i].doc_first_name + " " + obj.appointments[i].doc_middle_name + " " + obj.appointments[i].doc_last_name

                } else if (obj.appointments[i].doc_last_name != undefined)
                  {
                  doctorname = obj.appointments[i].doc_first_name + " " + obj.appointments[i].doc_last_name;
                }else if(obj.appointments[i].doc_first_name != undefined){
                  doctorname = obj.appointments[i].doc_first_name
                }else{
                  doctorname = ''
                }


                if (obj.appointments[i].specialization != undefined) {
                  var specialization = obj.appointments[i].specialization;
                }
                var package_based = obj.appointments[i].package_based == "false" ? "No" : "Yes";
                if (obj.appointments[i].date != undefined) {
                  this.appdate = Date_Formate(obj.appointments[i].date)
                }

                var apptimecheck;
                if (this.appTime != undefined) {
                  if (this.appTime != "") {
                    apptimecheck = this.appTime
                  } else {
                    apptimecheck = undefined
                  }

                } else {
                  apptimecheck = undefined
                }
                this.appointmentList.push({
                  profile_image: ipaddress.Ip_with_img_address + obj.appointments[i].profile_image,
                  token: this.tokenNo,
                  patient_name: this.displayPatientName,
                  doc_app_id: obj.appointments[i].doc_app_id,
                  doc_reg_id: obj.appointments[i].doc_reg_id,
                  session: this.session,
                  patient_id: obj.appointments[i].pat_id,
                  contact: encrypt_decript.Decript(obj.appointments[i].mobile),
                  dr_first_name: obj.appointments[i].doc_first_name,
                  dr_middle_name: obj.appointments[i].doc_middle_name,
                  dr_last_name: obj.appointments[i].doc_last_name,
                  app_time: this.appTime,
                  app_date: obj.appointments[i].date,
                  status: obj.appointments[i].status,
                  status_txt: status_txt,
                  client_reg_id: obj.appointments[i].client_reg_id,
                  package: package_based,
                  index: i + 1,
                  specialization: specialization,
                  doctorname: doctorname,
                  appdate: this.appdate,
                  ref_token_check: this.ref_token_check,
                  apptimecheck: apptimecheck
                });
              }
              Helper_Class.setmangamentlist_doctor(null)
              console.log("app list length --" + JSON.stringify(this.appointmentList.length))
              Helper_Class.setmangamentlist_doctor(this.appointmentList)
              console.log("app list --" + JSON.stringify(this.appointmentList))

            } else {
              this.appointmentList = [];
            }
          } else {
            this.appointmentList = [];
            this.noapp = true
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getPhysioApps() {

  }
  
  getDoctors(type) {
    this.appointmentList = [];
    this.doctorList = [];
    var send_data = {
      country: "IN",
      reg_id: this.userInfo.user_id,
      imei: this.ipaddress,
      type: type,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          console.log(obj);
          if (obj.key != "0") {
            if (obj.doctors != undefined && obj.doctors.length != 0) {
              for (var i = 0; i < obj.doctors.length; i++) {
                this.doctorName = "";
                var fname, mname, lname;
                if (obj.doctors[i].middle_name != undefined && obj.doctors[i].middle_name != "") {
                  this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                  fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
                } else {
                  this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                  fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
                }

                this.doctorList.push({
                  Doc_Name: this.doctorName,
                  Doc_ID: obj.doctors[i].prov_id,
                  type: type,
                });
              }


              this.doctorArr = [];
              if (this.ynopt1 == "doctor") {
                if (FrontDesk_Helper.getdoctor() != undefined && FrontDesk_Helper.getdoctor().type == "front-desk") {
                  this.doctor = FrontDesk_Helper.getdoctor().Doc_Name;
                  this.doctorId = FrontDesk_Helper.getdoctor().Doc_ID;
                  this.doctorArr = FrontDesk_Helper.getdoctor();
                  this.changeDoctor(FrontDesk_Helper.getdoctor());
                } else {
                  this.doctor = this.doctorList[0].Doc_Name;
                  this.userId = this.doctorList[0].Doc_ID;
                  this.doctorArr = this.doctorList[0];
                  this.changeDoctor(this.doctorArr);
                }
              } else if (this.ynopt1 == "dietician") {
                if (FrontDesk_Helper.getDietician() != undefined && FrontDesk_Helper.getDietician().type == "dietician") {
                  this.doctor = FrontDesk_Helper.getDietician().Doc_Name;
                  this.userId = FrontDesk_Helper.getDietician().Doc_ID;
                  this.doctorArr = FrontDesk_Helper.getDietician();
                  this.changeDoctor(FrontDesk_Helper.getDietician());
                } else {
                  this.doctor = this.doctorList[0].Doc_Name;
                  this.userId = this.doctorList[0].Doc_ID;
                  this.doctorArr = this.doctorList[0];
                  this.changeDoctor(this.doctorArr);
                }
              }

            }
          } else {
            this.appointmentList = [];
            this.noapp = true;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getDieticianPatientList() {
    this.appointmentList = [];
    this.apptOpenCount = 0;
    this.getAppDate = undefined;
    this.getAppDate = this.appointmentDate;
    this.showcancel = true;
    this.showcancel1 = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/npapps',
      JSON.stringify({
        prov_id: this.userId,
        country: ipaddress.country_code.toString(),
        date: this.appointmentDate,
        type: "dietician",
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          if (data.appointments != null) {
            var appolist = data.appointments;

            if (appolist.length != "0") {
              if (this.currentDate == this.appointmentDate) {
                this.showcancel = false;
                this.showcancel1 = false;
              }
              for (var i = 0; i < appolist.length; i++) {
                if (appolist[i].status == "0") {
                  var status_txt = "Completed";
                } else if (appolist[i].status == "1") {
                  if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                    if (appolist[i].f_time != "1") {
                      var get_time = new Date(appolist[i].date + " " + this.currentDate);
                      var cur_time = new Date(this.currentDate + " " + this.currentDate);
                      status_txt = get_time > cur_time ? "Not visited" : "Open";
                    } else {

                      var get_time = new Date(appolist[i].date);
                      var cur_time = new Date(this.currentDate);
                      status_txt = (get_time > cur_time || get_time == cur_time) ? "Open" : "Not visited";
                    }
                  } else {
                    status_txt = (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) ? "Open" : "Not visited";
                  }
                } else if (appolist[i].status == "2") {
                  if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                    var get_time = new Date(appolist[i].date + " " + this.currentDate);
                    var cur_time = new Date(this.currentDate + " " + this.currentDate);
                    status_txt = "Confirmed";
                  } else {
                    status_txt = (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) ? "Open" : "Not visited";
                  }
                }

                if (appolist[i].token != null) {
                  var token_time_label = "Token no : " + appolist[i].token;

                } else {
                  var Temptimesplit = appolist[i].f_time;
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');

                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {

                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = "Time : " + Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                }

                var Patient_name;
                if (appolist[i].middle_name != undefined && appolist[i].middle_name != null) {
                  if (encrypt_decript.Decript(appolist[i].middle_name) != "") {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  } else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                } else {
                  Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                }

                var sub_rel = appolist[i].sub_rel_id != null ? appolist[i].sub_rel_id : "0";
                var get_age = appolist[i].age != null ? appolist[i].age : "";
                var add_data = (appolist[i].address2 != null && appolist[i].address2 != "") ? appolist[i].address1 + " " + appolist[i].address2 : appolist[i].address1;
                var zipcode_data = (appolist[i].rel_zipcode != null) ? encrypt_decript.Decript(appolist[i].rel_zipcode) : appolist[i].zipcode;
                if (status_txt == 'Open' || status_txt == 'Confirmed') {
                  this.apptOpenCount = this.apptOpenCount + 1;
                }

                var mobile;
                if (appolist[i].mobile != null) {
                  mobile = encrypt_decript.Decript(appolist[i].mobile);
                }
                var gender = appolist[i].gender != undefined ? encrypt_decript.Decript(appolist[i].gender) : "";
                this.appointmentList.push({
                  index: i + 1,
                  profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                  patient_id: appolist[i].patient_id,
                  patient_name: Patient_name,
                  token: token_time_label,
                  tokenNo: appolist[i].token,
                  time: Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session)),
                  session: encrypt_decript.Decript(appolist[i].session),
                  contact: mobile,
                  status_txt: status_txt,
                  status: appolist[i].status,
                  doc_app_id: appolist[i].app_id,
                  client_reg_id: appolist[i].client_reg_id,
                  relation_id: appolist[i].relation_id,
                  sub_rel: sub_rel,
                  rel_count: appolist[i].country_id,
                  rel_stat: appolist[i].state_id,
                  rel_city: appolist[i].city_id,
                  rel_loc: appolist[i].location_id,
                  rel_loc_desc: appolist[i].location,
                  loc_age: get_age,
                  gender_data: gender,
                  dob_data: appolist[i].dob,
                  life_style_id: appolist[i].life_style_id,
                  address: add_data,
                  state_desc: appolist[i].state_desc,
                  city_desc: appolist[i].city_desc,
                  country_desc: appolist[i].country_desc,
                  rel_zipcode: zipcode_data,
                  app_date: appolist[i].date,
                  dr_first_name: appolist[i].dr_first_name,
                  dr_middle_name: appolist[i].dr_middle_name,
                  dr_last_name: appolist[i].dr_last_name,
                  user_type: appolist[i].user_type,
                  visit_purpose: appolist[i].visit_purpose,
                  first_name: appolist[i].first_name,
                  middle_name: appolist[i].middle_name,
                  last_name: appolist[i].last_name,
                  relation: appolist[i].relation,
                  symptoms: appolist[i].symptoms,
                  hosp_name: appolist[i].hosp_name,
                  hosp_address1: appolist[i].hosp_address1,
                  hosp_address2: appolist[i].hosp_address2,
                  hosp_location: appolist[i].hosp_location,
                  hosp_city: appolist[i].hosp_city,
                  hosp_state: appolist[i].hosp_state,
                  hosp_zipcode: appolist[i].hosp_zipcode,
                  hosp_country: appolist[i].hosp_country,
                  diet_reg_id: appolist[i].diet_reg_id,
                  gender: appolist[i].gender,
                  salutation: appolist[i].salutation,
                  specialization: appolist[i].specialization,
                });
              }
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewAppointment(list) {
    this.status_txt = list.status_txt;
    // this.ref_token =list.token;
    console.log("list view==" + JSON.stringify(list))
    if (list.status_txt == "Confirmed") {
      this.showcancel = true;
      this.refreshtoken = false;
    } else {
      this.refreshtoken = true;
    }
    if (this.appType == "doctor") {
      var view_appointments = {
        doc_app_id: list.doc_app_id,
        session: list.session,
        Appointment_Date: list.app_date,
        doc_name: this.doctor,

        status_txt: list.status_txt,
        app_type:this.appType
      }
      FrontDesk_Helper.setreceptionistcreatepage(view_appointments);
      this.frontdeskservice.sendMessage("receptionistdoctordetailview");

    } else if (this.appType == "dietician") {
      var temo: any;
      if (list.user_type == "temp") {
        temo = {
          user_details: list,

          user_type: "temp",
          status_txt: list.status_txt
        }

      } else {
        temo = {
          doc_app_id: list.doc_app_id,
          session: list.session,
          Appointment_Date: list.app_date,
          doc_name: this.doctor,
          status_txt: list.status_txt
        }
      }
      FrontDesk_Helper.setreceptionistcreatepage(temo);
      this.frontdeskservice.sendMessage("dietappointmentsdetailed");
    }else if(this.appType == 'physio'){
      var view_appointments = {
        doc_app_id: list.doc_app_id,
        session: list.session,
        Appointment_Date: list.app_date,
        doc_name: this.doctor,
        status_txt: list.status_txt,
        app_type:this.appType
      }
      FrontDesk_Helper.setreceptionistcreatepage(view_appointments);
      this.frontdeskservice.sendMessage("receptionistdoctordetailview");
    }
  }

  create() {
    if (this.appType == "Dignosis") {
      var sendData = {
        center_id: this.userInfo.diag_center_id,
        flow: "frontdesk",
        flowcheck: "Inpatient",
        appType: "diagnosis"
      }
      FrontDesk_Helper.setreceptionistcreatepage(null);
      FrontDesk_Helper.setreceptionistcreatepage(sendData)
      Diagnosis_Helper.setCreateDiagAppt(sendData);
      this.frontdeskservice.sendMessage("diaAppCreate");

    } else if (this.appType == "dietician") {
      FrontDesk_Helper.setreceptionistcreatepage(null)
      var dietview_appointments = {
        user_id: this.userInfo.user_id,
        appType: this.appType
      };
      FrontDesk_Helper.setreceptionistcreatepage(dietview_appointments);
      this.frontdeskservice.sendMessage("dietappointmentscreate");


    }
    else {
      FrontDesk_Helper.setreceptionistcreatepage(null)
      var view_appointments = ({
        doc_reg_id: this.doctorId,
        appType: "hospital",
        docType:this.ynopt1,
      })
      FrontDesk_Helper.setreceptionistcreatepage(view_appointments)
      this.frontdeskservice.sendMessage("appcreate");
    }
  }

  selectAppointmentDate(e) {
    Helper_Class.setmangamentlist_doctor(null);
    // this.appointmentList = [];
    this.appointmentDate = e;
    if (this.appType == "doctor") {
      FrontDesk_Helper.setDate(undefined);
      FrontDesk_Helper.setDate(this.appointmentDate);
      this.getDoctorList();
    } else if (this.appType == "dietician") {
      FrontDesk_Helper.setDate(undefined);
      FrontDesk_Helper.setDate(this.appointmentDate);
      this.getDieticianPatientList();
    } else {
      this.diagCentreId = this.userInfo.diag_centre_id
      this.getDiagAppointment();
    }
  }

  checkUncheckAll() {
    this.checklist = this.appointmentList;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.apptOpenCount) {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          var token = "", time = "";
          if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
            token = this.checklist[i].tokenNo
          } else {
            time = this.checklist[i].time;
          }
          var usertype;
          if (this.checklist[i].user_type != undefined) {
            usertype = this.checklist[i].user_type;
          }

          this.checklistid.push({
            index: this.checklist[i].index,
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
            user_type: usertype,
            prov_id: this.checklist[i].doc_reg_id,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }

      } else {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == '1' || this.checklist[i].status == '2') {
            var token = "", time = "";
            if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
              token = this.checklist[i].tokenNo
            } else {
              time = this.checklist[i].time;
            }
            var usertype;
            if (this.checklist[i].user_type != undefined) {
              usertype = this.checklist[i].user_type;
            }
            this.checklistid.push({
              index: this.checklist[i].index,
              app_id: this.checklist[i].doc_app_id,
              session: this.checklist[i].session,
              app_date: this.checklist[i].app_date,
              app_time: time,
              first_name: this.checklist[i].dr_first_name,
              middle_name: this.checklist[i].dr_middle_name,
              last_name: this.checklist[i].dr_last_name,
              mobile: this.checklist[i].contact,
              token: token,
              user_type: usertype,
              prov_id: this.checklist[i].doc_reg_id,
            });
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }

    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == '1' || this.checklist[i].status == '2') {
          var token = "", time = "";
          if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
            token = this.checklist[i].tokenNo
          } else {
            time = this.checklist[i].time;
          }
          var usertype;
          if (this.checklist[i].user_type != undefined) {
            usertype = this.checklist[i].user_type;
          }
          this.checklistid.push({
            index: this.checklist[i].index,
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
            user_type: usertype,
            prov_id: this.checklist[i].doc_reg_id,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
    console.log("check **" + JSON.stringify(this.checklist))

  }

  isAllSelected(list) {
    console.log("checklistid list" + JSON.stringify(list))
    this.status_txt = list.status_txt;
    this.ref_token = list.ref_token_check
    if (list.isSelected == true) {
      if (list.status_txt == "Confirmed") {
        if (list.ref_token_check == true) {
          this.showcancel = true;
          this.refreshtoken = false;
        } else {
          this.refreshtoken = true;
        }
      } else {
        this.refreshtoken = true;
      }
    }
    if (this.refreshtoken == false) {
      this.ref_tokenno = list.token;
    }
    if (list.isSelected == true) {
      if (this.checklistid.length >= 1) {
        for (let i = 0; i < this.checklistid.length; i++) {
          const checklistItem = this.checklistid[i];
          const patientId = checklistItem.patient_id;

          // Find matching patient ID in the appointmentList
          const matchingItem = this.appointmentList.find(item => item.patient_id === patientId);

          // Update isSelected property if a match is found
          if (matchingItem) {
            this.toastr.error("Select one at a time");
            this.refreshtoken = true;
            matchingItem.isSelected = false;
            for (let j = 0; j < this.appointmentList.length; j++) {
              // Update isSelected property to false
              this.appointmentList[j].isSelected = false;
            }
          }
        }

        this.checklistid.splice(0, this.checklistid.length);
      }
      else {
        if (list.dr_middle_name != null && list.dr_middle_name != "") {
          this.tokendocname = list.dr_first_name + " " + list.dr_middle_name + " " + list.dr_last_name;
        } else {
          if (list.dr_first_name != "" && list.dr_first_name != undefined) {
            if (list.dr_last_name != "") {
              this.tokendocname = list.dr_first_name + " " + list.dr_last_name;
            } else {
              this.tokendocname = list.dr_first_name;
            }
          }
        }

        this.tokendetails.push({
          token: list.token,
          patient_name: list.patient_name,
          mrnumber: list.patient_id,
          docname: this.tokendocname,
          spl: list.specialization,
          status: list.status,
        })
        // this.tocknumber=list.token
        this.tockpatient_name = list.patient_name;
        this.tockmrnumber = list.patient_id;
        this.tockspl = list.specialization;
        this.appdate = list.appdate;

        if (this.checklistid != "" && this.checklistid.length != 0) {
          var index = this.checklistid.map((o) => o.index).indexOf(list.index);
          //    var index = this.checklistid.findIndex( x => this.checklistid.index == list.index);
          if (index != -1) {
            var index = this.checklistid.indexOf(list);
            this.checklistid.splice(index, 1);
          } else {
            var token = "", time = "";
            if (list.tokenNo != null && list.tokenNo != "" && list.tokenNo != undefined) {
              token = list.tokenNo
            } else {
              time = list.time;
            }
            var usertype;
            if (list.user_type != undefined) {
              usertype = list.user_type;
            }
            this.checklistid.push({
              index: list.index,
              app_id: list.doc_app_id,
              session: list.session,
              app_date: list.app_date,
              app_time: time,
              first_name: list.dr_first_name,
              middle_name: list.dr_middle_name,
              last_name: list.dr_last_name,
              mobile: list.contact,
              token: token,
              user_type: usertype,
              prov_id: list.doc_reg_id,
              patient_id: list.patient_id, tokencheck: list.token,
              status: list.status_txt
            });
          }

        } else {
          var token = "", time = "";
          if (list.tokenNo != null && list.tokenNo != "" && list.tokenNo != undefined) {
            token = list.tokenNo
          } else {
            time = list.time;
          }
          var usertype;
          if (list.user_type != undefined) {
            usertype = list.user_type;
          }
          this.checklistid.push({
            index: list.index,
            app_id: list.doc_app_id,
            session: list.session,
            app_date: list.app_date,
            app_time: time,
            first_name: list.dr_first_name,
            middle_name: list.dr_middle_name,
            last_name: list.dr_last_name,
            mobile: list.contact,
            token: token,
            user_type: usertype,
            prov_id: list.doc_reg_id,
            patient_id: list.patient_id, status: list.status_txt, tokencheck: list.token
          });
        }
      }
    } else {
      if (this.checklistid.length != 0) {
        for (let i = this.checklistid.length - 1; i >= 0; i--) {
          const item = this.checklistid[i];
          const foundItem = this.appointmentList.find(overallItem => overallItem.patient_id === item.patient_id);
          if (foundItem && !foundItem.checked) {
            this.checklistid.splice(i, 1);
          }
        }
      } else {
        this.toastr.error("Select appointment")
        this.refreshtoken = true;
      }
      this.refreshtoken = true;
      this.showcancel = false; this.showcancel1 = false;
    }



    console.log("check select list --" + JSON.stringify(this.checklistid))

    if (this.checklistid.length == 1) {
      if (this.checklistid[0].status == "Completed") {
        this.showcancel1 = true;
        this.showcancel = true;
      }
      else if (this.checklistid[0].status == "Open") {
        this.showcancel1 = false;
        this.showcancel = false;
      }
      else if (this.checklistid[0].status == "Confirmed") {
        this.showcancel1 = true;
        this.showcancel = false;
      }
      else if (this.checklistid[0].status == "Not visited") {
        this.showcancel1 = true;
        this.showcancel = true;
      }
    }
  }

  getCheckedItemList() {
    if (this.checklist.length == this.checklistid.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checklistid.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.checklistid = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  appointmentConfirm() {
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
    if (this.checklistid.length != 0) {
      if (this.userInfo.token_on_arrival == "1") {
        if (this.checklistid.length > 1) {
          this.toastr.error(Message_data.apptconfirm);

        } else {

          var check:any = []
          for (var i = 0; i < this.checklistid.length; i++) {
            this.checklistid[i].hptl_clinic_id = this.userInfo.hptl_clinic_id;
            check.push(this.checklistid[i].app_id)
            //this.checklistid[i].prov_id = this.doctorId;
          }
          console.log("check ***" + JSON.stringify(check));  
          var sendata = JSON.stringify({
            type: this.appType,
            appointments: check,
          })
          console.log("this.sendata = 3 ***" + JSON.stringify(sendata));

          if (this.checklistid[0].tokencheck != " ") {
            var data = this.checklistid[0].tokencheck.split(" ")
            if (this.checklistid != "" && this.checklistid.length != 0) {

              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'appointment/cdapp',
                sendata,
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    console.log("this.checklistid = 3 ***" + JSON.stringify(obj));
                    if (obj.key != null) {
                      if (obj.key == "1") {
                        this.toastr.success(Message_data.appconf);
                        this.tocknumber = obj.token;
                        console.log("this.tocknumber ***" + JSON.stringify(this.tocknumber));
                        if (obj.token != undefined) {
                          this.cd.detectChanges();
                          this.checklistid = [];

                          //this.getDoctorList();
                          this.print(this.tocknumber);
                        }
                        this.checklistid = [];
                        //this.ngOnInit()                        
                        if (this.appType == "doctor") {
                          this.getDoctorList();

                        } else if (this.appType == "dietician") {
                          this.getDieticianPatientList()


                        }else if (this.appType == 'physio'){
                          this.getDoctorList();
                        }
                         else {
                          this.getDiagAppointment();
                        }
                      }
                    }
                  },
                  error => {
                    this.toastr.error(Message_data.defaultErr);
                  });

            } else {
              this.toastr.error(Message_data.sltApptToConfirm);
            }

          } else {
            var save_dataapp = {
              type: this.appType,
              doc_apps: this.checklistid,
            }

            if (this.checklistid != "" && this.checklistid.length != 0) {
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'appointment/cdtapp',
                JSON.stringify({
                  type: this.appType,
                  doc_apps: this.checklistid,
                }),
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    console.log("this.checklistid = 2 ***" + JSON.stringify(obj));
                    if (obj.key != null) {
                      if (obj.key == "1") {
                        this.toastr.success(Message_data.appconf);
                        this.tocknumber = obj.token;
                        console.log("this.tocknumber ***" + JSON.stringify(this.tocknumber));
                        if (obj.token != undefined) {
                          this.cd.detectChanges();
                          this.checklistid = [];
                          this.print(this.tocknumber);
                        }
                        if (this.appType == "doctor") {
                          this.getDoctorList();

                        } else if (this.appType == "dietician") {
                          this.getDieticianPatientList();

                        } else {
                          this.getDiagAppointment();
                        }
                      }
                    }
                  },
                  error => {
                    this.toastr.error(Message_data.defaultErr);
                  });
            } else {
              this.toastr.error(Message_data.sltApptToConfirm);
            }
          }
        }
      }
    } else {
      this.toastr.error("Select appointment")
    }

  }
  regeneratetoken(next) {

    if (this.status_txt == "Confirmed") {
      if (this.ref_token_check == true) {
        this.print1();
        this.refreshtoken = false;
      }

    } else {
      this.toastr.error("Select confirm status appointment")
    }
  }
  print1() {

    if (this.status_txt == "Confirmed") {
      let printContents, popupWin
      printContents = this.reprinttoken.nativeElement.innerHTML;
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
    <head>
      <title>Reports</title>
      
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
        
        body{
          padding-top:10%;
        }
        .txtcenter{
          text-align:center;
        }
        .algncheck{
      
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <div style="border:1px solid black;width:30%;border-radius:5px;margin: 10px !important">
      ${printContents}  
      </div> 


      </body>
    </html>`
      );
      popupWin.document.close();
    } else {
      this.toastr.error("Select confirm status appointment")
    }


  }
  clearAll() {
    this.checklist = this.appointmentList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  sendCancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var cancel = [];
      for (var i = 0; i < this.checklistid.length; i++) {
        cancel.push(this.checklistid[i].app_id);
      }

      var send_data: any;
      if (this.appType == "doctor") {
        send_data = {
          doc_cancel: cancel,
          home_care: "0",
          reason: this.cancelResone,
          type: "doctor",
        }
      } else if (this.appType == "dietician") {
        send_data = {
          diet_cancel: this.checklistid,
          home_care: "0",
          type: "dietician",
          reason: this.cancelResone
        }
      } else {
        send_data = {
          diet_cancel: this.checklistid,
          home_care: "0",
          type: "diagnosis",
          reason: this.cancelResone
        }
      }
      console.log("send_data" + JSON.stringify(send_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            console.log("send_data dia" + JSON.stringify(response));

            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                $("#staticBackdrop").modal("hide");
                this.toastr.success(Message_data.appcancel);
                this.cancelResone = "";
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  cancel() {
    console.log("this.checklistid" + JSON.stringify(this.checklistid))
    if (this.checklistid != "" && this.checklistid.length != 0) {
      $("#staticBackdrop").modal("show");
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  changeType(id, value) {
    if (value == "doctor") {
      this.appointmentList = [];
      this.diagAppointmentList = [];
      this.showcancel = true;
      this.docName = "";
      this.ynopt1 = "doctor";
      this.userTypeSelect('doctor')

    } else if (value == "diagnosis") {
      this.appointmentList = [];
      this.diagAppointmentList = [];
      this.showcancel = true;
      this.showcancel1 = true;
      this.refreshtoken = true;
      this.docName = "";
      this.ynopt1 = 'diagnosis';
      this.userTypeSelect('Dignosis')

    } else if (value == "dietician") {
      this.appointmentList = [];
      this.diagAppointmentList = [];
      this.docName = "";
      this.refreshtoken = true;
      this.ynopt1 = "dietician";
      this.userTypeSelect('dietician')

    } else if (value == "physio") {
      this.appointmentList = [];
      this.diagAppointmentList = [];
      this.docName = "";
      this.refreshtoken = true;
      this.ynopt1 = "physio";
      this.appType = 'physio'
      this.userTypeSelect('physio')
    }
  }

  userTypeSelect(page) {
    this.appType = page
    if (this.appType == "doctor") {
      this.getDoctorList();

    } else if (this.appType == "Dignosis") {
      this.getLocation();

    } else if (this.appType == "physio") {
      this.getDoctorList();

    } else {
      this.getDoctors("dietician");
      this.doctorFlag = true;
    }
  }

  getLocation() {
    this.diagLocationList = [];
    console.log({
      diag_name: this.userInfo.diag_centre_name,
      flag: "name",
      imei: Helper_Class.getIPAddress(),
      prov_id: this.userInfo.user_id,
      type: "diagnosis"
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: this.userInfo.diag_centre_name,
        flag: "name",
        imei: Helper_Class.getIPAddress(),
        prov_id: this.userInfo.user_id,
        type: "diagnosis"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("DIAGNKK data --" + JSON.stringify(obj))
          this.appointmentList = [];

          if (obj.diag_loc != undefined && obj.diag_loc.length != 0) {
            for (var i = 0; i < obj.diag_loc.length; i++) {
              this.diagLocationList.push({
                centre_id: obj.diag_loc[i].diag_centre_id,
                centre_name: obj.diag_loc[i].diag_centre_name,
                centre_location: obj.diag_loc[i].diag_loc,
                view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
              });
            }
            for (var i = 0; i < this.diagLocationList.length; i++) {
              this.sendDiagCenterId = this.diagLocationList[i].centre_id;
              Diagnosis_Helper.setDiag_centre_id(this.sendDiagCenterId);
            }
            this.diagCentreId = this.diagLocationList[0].centre_id;
            this.getDiagAppointment();
            this.location = this.diagLocationList[0].view_data
          }
        },
        error => { });
  }

  getDiagAppointment() {
    this.diagAppointmentList = [];
    this.getAppDate = undefined;
    this.getAppDate = this.datePick;
    this.datePick = this.appointmentDate;
    this.appointmentList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/diagapps/',
      JSON.stringify({
        diag_center_id: this.diagCentreId,
        date: this.appointmentDate,
        imei: Helper_Class.getIPAddress(),
        prov_id: this.userInfo.user_id,
        type: "diagnosis"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("check data obj" + JSON.stringify(obj))
          this.appointmentList = [];
          if (obj.appointments != null) {
            this.doctorFlag = false;
            for (var i = 0; i < obj.appointments.length; i++) {
              if (this.currentDate == this.appointmentDate) {
                // this.showcancel = false;
              }
              this.status = "";
              this.clientName = "";
              this.fromTime = "";
              var sessiontext = "";

              if (obj.appointments[i].middle_name != undefined && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
                this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                if (obj.appointments[i].first_name != undefined && obj.appointments[i].first_name != "") {
                  if (obj.appointments[i].last_name != undefined && obj.appointments[i].last_name != "") {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  } else {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name);
                  }
                }
              }
              if (obj.appointments[i].dr_first_name != undefined) {
                if (obj.appointments[i].dr_middle_name != undefined) {
                  this.docName = "Dr" + " " + obj.appointments[i].dr_first_name + " " + obj.appointments[i].dr_middle_name + " " + obj.appointments[i].dr_last_name
                } else {
                  this.docName = "Dr" + " " + obj.appointments[i].dr_first_name + " " + obj.appointments[i].dr_last_name;
                }
              }

              if (obj.appointments[i].profile_image != undefined) {
                var image = ipaddress.Ip_with_img_address + obj.appointments[i].profile_image
                this.profileImg = ipaddress.Ip_with_img_address + obj.appointments[i].profile_image;
              }

              if (obj.appointments[i].appointment_date != null) {
                var last_visit = obj.appointments[i].appointment_date;
                var gettmp = last_visit.split("-");
                var Date_value = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              if (obj.appointments[i].status == "1") {
                var get_time = new Date(obj.appointments[i].appointment_date + " " + this.currentDate);
                var cur_time = new Date((this.datePick).toString());

                if (get_time > cur_time)
                  this.status = "Open";
                else if (get_time < cur_time)
                  this.status = " Open";
                else
                  this.status = "Open";
              }
              if (obj.appointments[i].from_time != null) {
                this.fromTime = Time_Formate(obj.appointments[i].from_time);
                this.status = "Time alloted";
              }
              if (obj.appointments[i].status == "2") {
                this.status = "Sample collected";
              }
              if (obj.appointments[i].status == "3") {
                this.status = "Report yet to be finalized";
              }
              if (obj.appointments[i].status == "0") {
                this.status = "Closed";
              }
              if (obj.appointments[i].is_canclled == "1") {
                this.status = "Cancelled";
              }
              if (obj.appointments[i].session != undefined) {
                sessiontext = this.capitalizeFirstLetter(obj.appointments[i].session);
              }
              //specialization
              var checked = this.status == "Open" ? "nonchecked" : "checked"

              this.diagAppointmentList.push({
                App_id: obj.appointments[i].diag_appointment_id,
                App_date: Date_value,
                Relation: obj.appointments[i].relation,
                Clnt_Name: this.clientName,
                Time: this.fromTime,
                Session: sessiontext,
                status: this.status,
                check: checked,
                profile_image: ipaddress.Ip_with_img_address + obj.appointments[i].profile_image,
                docName: this.docName

              });
            }
          } else {
            this.diagAppointmentList = [];
          }
        },
        error => { });
  }

  changeLocation(data) {
    if (this.diagLocationList != undefined) {
      for (var i = 0; i < this.diagLocationList.length; i++) {
        if (this.diagLocationList[i].view_data == data) {
          this.diagCentreId = this.diagLocationList[i].centre_id;
          this.getDiagAppointment();
          this.sendDiagCenterId = this.diagLocationList[i].centre_id;
        }
      }
    }
  }

  viewDiagAppointment(App_id, status) {
    var type_user = {
      user_id: this.userInfo.user_id,
      user_type: "frontDesk",
      diag_centre_id: Helper_Class.getInfo().diag_centre_name,
      diag_id: App_id,
    }
    var set = {
      diag_id: App_id,
      status_txt: status,
      flow: "frontdesk"
    }
    Helper_Class.setdiagnosisappview(set);
    Diagnosis_Helper.setUserID_Type(type_user);
    this.frontdeskservice.sendMessage("diaAppDetail");
  }

  compareById(object1: any, object2: any) {
    return object1 && object2 && object1.Doc_ID === object2.Doc_ID;
  }

  editAppointment(diagid, status) {
    var sendData = {
      center_id: this.userInfo.diag_center_id,
      flow: "frontdesk",
    }
    Diagnosis_Helper.setCreateDiagAppt(sendData);

    var diagnosis = {
      diag_id: diagid,
      status_txt: status,
      centre_id: this.userInfo.diag_center_id,
    }
    Helper_Class.setdiagnosisappview(diagnosis);
    this.frontdeskservice.sendMessage("appedit");
  }

  print(tocknumber) {
    this.tocknumber = tocknumber;
    // console.log("check number--" + tocknumber + this.tocknumber)
    let printContents, popupWin
    printContents = this.printtoken.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Reports</title>
      
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
        
        body{
          padding-top:10%;
        }
        .txtcenter{
          text-align:center;
        }
        .algncheck{
      
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <div style="border:1px solid black;width:30%;border-radius:5px;margin: 10px !important">
      ${printContents}  
      </div> 


      </body>
    </html>`
    );
    popupWin.document.close();
  }

  getDoctorListByName(name) {
    console.log(this.docName)
    if (this.docName.length > 2 && this.docName != undefined) {

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/dbnh',
        JSON.stringify({
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          doc_name: this.docName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.doctorList = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName;
              if (obj.doctors[i].middle_name != undefined) {
                doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }

              this.doctorList.push({
                doc_name: doctorName,
                doc_reg_id: obj.doctors[i].prov_id,
              })
            }
          })
    }
  }

  selectReferredDoctor(item) {
    this.docName = item.doc_name;
    this.doctorId = item.doc_reg_id;
    this.doctorList = [];
    Helper_Class.setmangamentlist_doctor(null)
    this.selectAppointmentDate(this.appointmentDate)
  }

  getmoduledoctor() {
    this.realflag1 = true;
    // this.realflag2 = true;
    // this.realflag3 = true;
    if (FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "15") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbutton = true;
            this.realflag1 = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }

    } else {
      if (this.userInfo.user_type == "Admin") {
        this.viewbutton = true;
        this.printbutton = true;
        this.deletebutton = true;
        this.newbutton = true;
        this.realflag1 = true;
        this.editbutton = true;
      }
    }
  }

  getmodulediagnosis() {
    // this.realflag2 = false;
    this.realflag1 = true;
    // this.realflag3 = true;
    if (FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "16") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbuttondia = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbuttondia = true;
            this.realflag2 = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondia = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondia = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbuttondia = true;
          }
        }
      }

    } else {
      if (this.userInfo.user_type == "Admin") {
        this.editbuttondia = true;
        this.newbuttondia = true;
        this.realflag1 = true;
        this.deletebuttondia = true;
        this.printbuttondia = true;
        this.viewbuttondia = true;
      }
    }
  }

  getmoduledietician() {
    // this.realflag3 = false;
    // this.realflag2 = true;
    this.realflag1 = true;
    if (FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "17") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbuttondie = true;
            this.realflag3 = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbuttondie = true;
          }
        }
      }
    } else {
      if (this.userInfo.user_type == "Admin") {
        this.editbuttondie = true;
        this.newbuttondie = true;
        this.realflag1 = true;
        this.deletebuttondie = true;
        this.printbuttondie = true;
        this.viewbuttondie = true;
      }
    }
  }

  capitalizeFirstLetter(input: string): string {
    if (!input) return input; // Handle empty string
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}
