import { NgModule } from '@angular/core';
import { IndexComponent } from './index/index.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatInputModule } from '@angular/material/input';
import { CommonRoutingModule } from './common-router.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { AngularMatrialModule } from '../angular-matrial.module';
import { LoginpgComponent } from './loginpg/loginpg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ForgotpwdComponent } from './forgotpwd/forgotpwd.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { FrontDeskRegistrationComponent } from './front-desk-registration/front-desk-registration.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DocterRegistrationComponent } from './docter-registration/docter-registration.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangePasswordModule } from './change-password/change-password.module';
import { ClientRegistComponent } from './client-regist/client-regist.component';
import { DiagnosisRegistrationComponent } from './diagnosis-registration/diagnosis-registration.component';
import { PharmaRegistrationComponent } from './pharma-registration/pharma-registration.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { PhysioRegistrationComponent } from './physio-registration/physio-registration.component';
import { NurseRegistrationComponent } from './nurse-registration/nurse-registration.component';
import { RduComponent } from './rdu/rdu.component';
import { WithoutLoginSearchComponent } from './without-login-search/without-login-search.component';
import { CreatePackageComponent } from './create-package/create-package.component';
import { ListPackageComponent } from './list-package/list-package.component';
import { UserPackageListComponent } from './user-package-list/user-package-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QuillModule } from 'ngx-quill';
import { DataTablesModule } from 'angular-datatables';
import { DieticianRegistrationComponent } from './dietician-registration/dietician-registration.component';
import { MenuViewComponent } from './menu-view/menu-view.component';
import { DoctorModule } from '../Doctor_module/DoctorModule.module';
import { FrontDeskModule } from '../FrontDesk_module/front-desk.module';
import { DiagnosisModule } from '../Diagnosis_module/DiagnosisModule.module';
import { DietModule } from '../Diet_module/DietModule.module';
import { AdminModule } from '../Admin_module/Admin-module.module';
import { PharmaModule } from '../pharma/pharma.module';
import { NurseModule } from '../Nurse_module/NurseModule.module';
import { PhysioModule } from '../Physio_module/PhysioModule.module';

import { RegistrationComponent } from './registration/registration.component';
import { ServicesComponent } from './services/services.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { NutritionManagementComponent } from './nutrition-management/nutrition-management.component';
import { HospManagementComponent } from './hosp-management/hosp-management.component';
import { DiagManagementComponent } from './diag-management/diag-management.component';
import { ClinicalDecisionComponent } from './clinical-decision/clinical-decision.component';
import { PharmaManagementComponent } from './pharma-management/pharma-management.component';
import { PredictiveHealthComponent } from './predictive-health/predictive-health.component';
import { ContactusComponent } from './contactus/contactus.component';
import { MastersModule } from '../masters/masters.module';
import { InventoryModuleModule } from '../inventory/inventory-module/inventory-module.module';
import { PdffilepopupComponent } from './pdffilepopup/pdffilepopup.component';
@NgModule({
  declarations: [
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    LoginpgComponent,
    ForgotpwdComponent,
    FrontDeskRegistrationComponent,
    DocterRegistrationComponent,
    ClientRegistComponent,
    DiagnosisRegistrationComponent,
    PharmaRegistrationComponent,
    PrivacyPolicyComponent,
    PhysioRegistrationComponent,
    NurseRegistrationComponent,
    RduComponent,
    WithoutLoginSearchComponent,
    CreatePackageComponent,
    ListPackageComponent,
    UserPackageListComponent,
    MenuViewComponent,
    DieticianRegistrationComponent,
    RegistrationComponent,
    ServicesComponent,
    SolutionsComponent,
    AboutusComponent,
    NutritionManagementComponent,
    HospManagementComponent,
    DiagManagementComponent,
    ClinicalDecisionComponent,
    PharmaManagementComponent,
    PredictiveHealthComponent,
    ContactusComponent,
    PdffilepopupComponent,
    // LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatInputModule,
    MatFormFieldModule, 
    ReactiveFormsModule,
    CommonRoutingModule,
    AngularMatrialModule,
    FormsModule,
    MatListModule,
    AngularMatrialModule,
    MDBBootstrapModule.forRoot(),
    MatAutocompleteModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    ChangePasswordModule,
    MatSlideToggleModule,
    DoctorModule,
    DiagnosisModule,
    NurseModule,
    FrontDeskModule,
    PharmaModule,
    DietModule,
    AdminModule,
    PhysioModule,
    MastersModule,
      InventoryModuleModule,
    // ClientModule,
    DataTablesModule.forRoot(),
    QuillModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  exports:[
    AngularMatrialModule,
    MatFormFieldModule,
    CreatePackageComponent,
    ListPackageComponent,
    UserPackageListComponent,
    PdffilepopupComponent
  ]
  
})
export class CustomeCommonModule { }