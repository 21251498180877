import { Component, OnInit } from '@angular/core';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { ConvertTimeformat, Date_Formate, Time_Formate, Time_Formate1 } from '../../../assets/js/common';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Message_data } from 'src/assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { error } from 'console';
@Component({
  selector: 'app-discharge-timeline',
  templateUrl: './discharge-timeline.component.html',
  styleUrls: ['./discharge-timeline.component.scss']
})
export class DischargeTimelineComponent implements OnInit {
  public profileImg;
  public initiateDischargeFlag;
  public clinicalDischargeFlag;
  public financialDischargeFlag;
  public reportFinalizedFlag;
  public OTDischargeFlag;
  public HouseKeepingDischargeFlag;
  public physicalDischargeFlag;
  public inpatient_id;
  public patientList = [];
  public mrno;
  public patient_name;
  public gender_age;
  public mobile_no;
  public doctor_name;
  public admitted_date;
  public admitted_time;
  public speciality;
  public ward_name;
  public bed_no;
  public location;
  public city;
  public discharge_time1: string;
  public discharge_time2: string;
  public discharge_time3: string;
  public dischage_hrs = [];
  public dischage_min = [];
  public phy_discharge_time1: string;
  public phy_discharge_time2: string;
  public phy_discharge_time3: string;
  public phy_dischage_hrs = [];
  public phy_dischage_min = [];
  public currentDateTime;
  public currentDate;
  public currentTime;
  public timevalue;
  public expt_discharge_date = '';
  public diag_bill_count = '';
  public hosp_bill_count = '';
  public pharma_bill_count = '';
  public phy_discharge_date = '';
  public initiateDischarge:boolean = false;
  public discharge_initiate;
  public discharge_doctor = '';
  public initiate_discharge_remarks = '';
  public clinicalDischarge: boolean = false;
  public discharge_summary: boolean = false;
  public clinical_reports: boolean = false;
  public discharge_summary_value;
  public clinical_reports_value;
  public ot_book: boolean = false;
  public ot_book_cancelled: boolean = false;
  public room_cleaned: boolean = false;
  public discharge_clinical;
  public ot_book_value;
  public ot_book_cancelled_value;
  public room_cleaned_value;
  public physicalDischarge:boolean = false;
  public discharge_physical;
  public clinical_discharge_remarks = '';
  public expt_discharge_time;
  public phy_discharge_time;
  public discharge_doctor_name = '';
  public discharge_id = undefined;
  public address1;
  public address2;
  public zipcode;
  public state;
  public country;
  public billList = [];
  public billingList = [];
  public pharmaBillList = [];
  public filter: string;
  public userInfo: any;
  public diagBillListFlag: boolean = false;
  public hospBillListFlag: boolean = false;
  public billingListFlag: boolean = false;
  public billListFlag: boolean = false;
  public pharmaBillListFlag: boolean = false;
  public client_reg_id;
  public pharmaBillingListFlag: boolean = false;
  public relation_id: any;
  public admission_date: any;
  public discharge_timeline: any;
  public physical_disable: boolean = true;

  constructor(private http: HttpClient,public toastr: ToastrService,public messageservice:MenuViewService) {
    for (var i = 1; i <= 12; i++) {
      if (i < 10) 
        this.phy_dischage_hrs.push('0' + i);
      else 
        this.phy_dischage_hrs.push(i);     
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) 
        this.phy_dischage_min.push('0' + i);
      else 
        this.phy_dischage_min.push(i);     
    }
   }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    if (this.userInfo.discharge_timeline != undefined || this.userInfo.discharge_timeline != null) 
      this.discharge_timeline = this.userInfo.discharge_timeline;     
    this.patientList = Helper_Class.getinpatientfilterdata();
    this.inpatient_id = FrontDesk_Helper.getDischargeTimeLineInpatientId();
    console.log(this.inpatient_id);
    this.getDate();
    this.getPatientDetails();
    this.getDischargeTimeLineDetails();
    this.filter = 'Patient_ID';
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {
        country: ipaddress.country_code.toString()
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;                    
            this.currentDate = Date_Formate(obj.current_date); 
            this.currentTime = obj.current_time;            
            this.timevalue = Time_Formate(this.currentTime);    
            // this.phy_discharge_date = obj.current_date;  
            
            var timeval = this.currentTime.split(":");
            
            if(parseInt(timeval[0]) >12){
              if((parseInt(timeval[0])-12).toString().length ==1){
                this.discharge_time1 = "0"+(parseInt(timeval[0])-12).toString();
              } else {
                this.discharge_time1 = (parseInt(timeval[0])-12).toString();
              }
              
            } else {
              this.discharge_time1 = timeval[0];
            }
            this.discharge_time2 = timeval[1];
            if(parseInt(timeval[0]) > 12) {
              this.discharge_time3="PM";
            } else {
              this.discharge_time3="AM";
            }
          }
        },error => {});
  }

  getPatientDetails() {
    if(this.patientList != undefined && this.patientList.length != 0) {
      for(var i = 0;i < this.patientList.length; i++) {
        if (this.patientList[i].inpatient_id == this.inpatient_id) {
          console.log(this.patientList[i]);
          this.discharge_doctor = this.patientList[i].doc_reg_id;
          this.discharge_doctor_name = this.patientList[i].doctor_name;
          this.client_reg_id = this.patientList[i].client_reg_id;
          this.relation_id = this.patientList[i].relation_id;
          this.admission_date = this.patientList[i].admission_date;
          if (this.patientList[i].profile_image != undefined) 
            this.profileImg = this.patientList[i].profile_image;                 
          this.mrno = this.patientList[i].patient_id != undefined ? this.patientList[i].patient_id : "";
          this.patient_name = this.patientList[i].patient_name != undefined ? this.patientList[i].patient_name : "";
          this.gender_age = this.patientList[i].gender_age != undefined ? this.patientList[i].gender_age : "";
          this.mobile_no = this.patientList[i].mobilenumber != undefined ? this.patientList[i].mobilenumber : "";
          this.doctor_name = this.patientList[i].doctor_name != undefined ? this.patientList[i].doctor_name : "";
          this.admitted_date = this.patientList[i].admit_date != undefined ? this.patientList[i].admit_date : "";
          this.admitted_time = this.patientList[i].admit_time != undefined ? this.patientList[i].admit_time : "";
          this.speciality = this.patientList[i].speciality != undefined ? this.patientList[i].speciality : "";
          this.ward_name = this.patientList[i].ward_name != undefined ? this.patientList[i].ward_name : "";
          this.bed_no = this.patientList[i].bed_no != undefined ? this.patientList[i].bed_no : "";
          this.address1 = this.patientList[i].address1 != undefined ? this.patientList[i].address1 : "";
          this.address2 = this.patientList[i].address2 != undefined ? this.patientList[i].address2 : "";
          this.location = this.patientList[i].location != undefined ? this.patientList[i].location : "";
          this.city = this.patientList[i].district != undefined ? this.patientList[i].district : "";
          this.zipcode = this.patientList[i].zipcode != undefined ? this.patientList[i].zipcode : "";
          this.state = this.patientList[i].state != undefined ? this.patientList[i].state : "";
          this.country = this.patientList[i].country != undefined ? this.patientList[i].country : "";
        }
      }
    }
  }

  getDischargeTimeLineDetails() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'inpat/gdid',
      {
        inpatient_id: this.inpatient_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj != undefined) {
            if (obj.discharge_id != undefined) 
              this.discharge_id = obj.discharge_id;          

            if (obj.discharge_initiate != undefined && obj.discharge_initiate == '1') {
              this.initiateDischarge = true;
              this.change_type(1,'initiate_discharge');
            } else 
              this.initiateDischarge = false;           

            if (obj.discharge_date != undefined) {
              this.expt_discharge_date = obj.discharge_date;
              
            } else {
              this.expt_discharge_date = this.currentDateTime;
            }

            if (obj.discharge_time != undefined) {
              var expected_dis_time;
                  expected_dis_time = Time_Formate1(obj.discharge_time);
                  console.log("expected_dis_time",expected_dis_time);
                  expected_dis_time = expected_dis_time.split(':');
                  this.discharge_time1 = expected_dis_time[0];
                  this.discharge_time2 = expected_dis_time[1];
                  this.discharge_time3 = expected_dis_time[2];
            }
            
            if (obj.discharge_doctor != undefined && this.discharge_doctor != undefined) {             
              if (obj.discharge_doctor == this.discharge_doctor) 
                this.discharge_doctor_name = this.discharge_doctor_name;                          
            }

            if (obj.discharge_remarks != undefined) 
              this.initiate_discharge_remarks = obj.discharge_remarks;           

            if (obj.ot_booking_exists != undefined && obj.ot_booking_exists == '1') {
              this.ot_book = true;
              this.change_type(2,'OT_discharge');
            } else 
              this.ot_book = false;          

            if (obj.ot_booking_cancelled != undefined && obj.ot_booking_cancelled == '1') 
              this.ot_book_cancelled = true;
            else 
              this.ot_book_cancelled = false;           

            if (obj.clinical_reports_ready != undefined && obj.clinical_reports_ready == '1') 
              this.clinical_reports = true;
            else 
              this.clinical_reports = false;         

            if (obj.disch_report_ready != undefined && obj.disch_report_ready == '1') {
              this.discharge_summary = true;
            } else {
              this.discharge_summary = false;
            }

            if (obj.discharge_clinical != undefined && obj.discharge_clinical == '1') {
              this.clinicalDischarge = true;
              this.change_type(3,'clinical_discharge');
            } else 
              this.clinicalDischarge = false;

            if (obj.dis_clinical_remarks != undefined) 
              this.clinical_discharge_remarks = obj.dis_clinical_remarks;            

            if (obj.hosp_bills != undefined) 
              this.hosp_bill_count = obj.hosp_bills;           
            if (obj.diag_bills != undefined) 
              this.diag_bill_count = obj.diag_bills;                     
            if (obj.pharma_bills != undefined) 
              this.pharma_bill_count = obj.pharma_bills;            

            if (this.hosp_bill_count == '0' && this.diag_bill_count == '0' && this.pharma_bill_count == '0') {
              this.physical_disable = false;
              if (obj.dis_physical_date != undefined) 
                this.phy_discharge_date = obj.dis_physical_date;             
  
              if (obj.dis_physical_time != undefined) {
                var dis_physical_time;
                    dis_physical_time = Time_Formate1(obj.dis_physical_time);
                    console.log("dis_physical_time",dis_physical_time);
                    dis_physical_time = dis_physical_time.split(':');
                    this.phy_discharge_time1 = dis_physical_time[0];
                    this.phy_discharge_time2 = dis_physical_time[1];
                    this.phy_discharge_time3 = dis_physical_time[2];
              }   
              
              if (this.clinicalDischarge == true) 
                this.change_type(4,'financial_discharge');            
            } else 
              this.physical_disable = true;           

            if (obj.discharge_physical != undefined && obj.discharge_physical == '1') {
              this.physicalDischarge = true;
              this.change_type(6,'physical_discharge');
            } else 
              this.physicalDischarge = false;             

            if (obj.room_cleaned != undefined && obj.room_cleaned == '1') {
              this.room_cleaned = true;
              this.change_type(7,'house_keeping_discharge');
            } else 
              this.room_cleaned = false;                         
          }      
        },
        error => {
          this.toastr.error(Message_data.network);
    });
  }

  change_type(id, value) {
    if (value == "initiate_discharge") {
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = false;
      this.clinicalDischargeFlag = false;
      this.financialDischargeFlag = false;   
      this.reportFinalizedFlag = false;   
      this.physicalDischargeFlag = false;        
      this.HouseKeepingDischargeFlag = false;     
    } else if(value == "OT_discharge"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = false;
      this.financialDischargeFlag = false;   
      this.reportFinalizedFlag = false;  
      this.physicalDischargeFlag = false;   
      this.HouseKeepingDischargeFlag = false;
    } else if(value == "clinical_discharge"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = false;   
      this.reportFinalizedFlag = false;
      this.physicalDischargeFlag = false;
      this.HouseKeepingDischargeFlag = false;
    } else if(value == "financial_discharge"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = false;   
      this.physicalDischargeFlag = false;      
      this.HouseKeepingDischargeFlag = false;      
    } else if(value == "report_finalized"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = true;  
      this.physicalDischargeFlag = false;
      this.HouseKeepingDischargeFlag = false;         
    } else if(value == "physical_discharge"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = true;
      this.physicalDischargeFlag = true;
      this.HouseKeepingDischargeFlag = false;          
    } else if(value == "house_keeping_discharge"){
      this.initiateDischargeFlag = true;
      this.OTDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = true;  
      this.physicalDischargeFlag = true;
      this.HouseKeepingDischargeFlag = true;         
    }
  }

  backClicked() {
    if (this.userInfo.user_type == "doctor") 
      this.messageservice.sendMessage('in_patient');
    else 
      this.messageservice.sendMessage('Patients');    
  }

  save() {
    if (this.initiateDischarge == true) 
      this.discharge_initiate = '1';
    else 
      this.discharge_initiate = '0';   

    if (this.clinicalDischarge == true) 
      this.discharge_clinical = '1';
    else 
      this.discharge_clinical = '0';

    if (this.clinical_reports == true) 
      this.clinical_reports_value = '1';
    else 
      this.clinical_reports_value = '0';

    if (this.discharge_summary == true) 
      this.discharge_summary_value = '1';
    else 
      this.discharge_summary_value = '0';

    if (this.ot_book == true) 
      this.ot_book_value = '1';
    else 
      this.ot_book_value = '0';

    if (this.ot_book_cancelled == true) 
      this.ot_book_cancelled_value = '1';
    else 
      this.ot_book_cancelled_value = '0';

    if (this.room_cleaned == true) 
      this.room_cleaned_value = '1';
    else 
      this.room_cleaned_value = '0';

    if (this.physicalDischarge == true) 
      this.discharge_physical = '1';
    else 
      this.discharge_physical = '0';

    if (this.discharge_time1 != undefined && this.discharge_time2 != undefined && this.discharge_time3 != undefined)     
      this.expt_discharge_time = ConvertTimeformat('',this.discharge_time1 + ':' + this.discharge_time2 + ' ' + this.discharge_time3);
    if (this.phy_discharge_time1 != undefined && this.phy_discharge_time2 != undefined && this.phy_discharge_time3 != undefined) 
      this.phy_discharge_time = ConvertTimeformat('',this.phy_discharge_time1 + ':' + this.phy_discharge_time2 + ' ' + this.phy_discharge_time3);

    var flag = true; 
    if (this.initiateDischarge == false) {
      flag = false;
      this.toastr.error("Please select initiate discharge");
    }
    if (this.initiateDischarge == true) {
      if (this.expt_discharge_date == '') {
        flag = false;
        this.toastr.error("Please select the expected discharge date");
      } else if (this.expt_discharge_time == undefined) {
        flag = false;
        this.toastr.error("Please select the expected discharge time");
      } else if (this.initiate_discharge_remarks == '') {
        flag = false;
        this.toastr.error("Please fill the initiate discharge remarks");
      }
    }

    if (this.physicalDischarge == true) {
      if (this.phy_discharge_date == '') {
        flag = false;
        this.toastr.error("Please select the physical discharge date");
      } else if (this.phy_discharge_time == undefined) {
        flag = false;
        this.toastr.error("Please select the physical discharge time");
      } 
    }

    if (this.clinicalDischarge == true) {
      if (this.clinical_discharge_remarks == '') {
        flag = false;
        this.toastr.error("Please fill the clinical discharge remarks");
      }
    }
     
    if (flag == true) {
      var sendData,sendURL;
      if (this.discharge_id != undefined) {
        sendURL = 'inpat/udistim';
        sendData = {
          inpatient_id: this.inpatient_id,
          discharge_id: this.discharge_id,
          discharge_doctor: this.discharge_doctor,
          clinical_reports: this.clinical_reports_value,
          disch_report: this.discharge_summary_value,
          discharge_clinical: this.discharge_clinical,
          ot_booking_exists: this.ot_book_value,
          ot_booking_cancelled: this.ot_book_cancelled_value,
          room_cleaned: this.room_cleaned_value,
          discharge_timeline: this.discharge_timeline
        }
      } else {
        sendURL = 'inpat/sdistim';
        sendData = {
          inpatient_id: this.inpatient_id,        
          discharge_doctor: this.discharge_doctor,         
          clinical_reports: this.clinical_reports_value,
          disch_report: this.discharge_summary_value,
          discharge_clinical: this.discharge_clinical,
          ot_booking_exists: this.ot_book_value,
          ot_booking_cancelled: this.ot_book_cancelled_value,
          room_cleaned: this.room_cleaned_value,
          discharge_timeline: this.discharge_timeline
        }
      }

      if (this.initiate_discharge_remarks != '') 
        sendData.discharge_remarks = this.initiate_discharge_remarks;
      if (this.clinical_discharge_remarks != '') 
        sendData.dis_clinical_remarks = this.clinical_discharge_remarks;
      if (this.expt_discharge_date != '' && this.expt_discharge_time != undefined) {
        sendData.discharge_initiate = this.discharge_initiate;
        
        var dateval = this.expt_discharge_date.split("-")
        if(dateval[0].length !=4) {
          sendData.discharge_date = dateval[2]+"-"+dateval[1]+"-"+dateval[0];
        } else {
          sendData.discharge_date = this.expt_discharge_date;
        }
        sendData.discharge_time = this.expt_discharge_time;
      } 
      if (this.physical_disable == false && this.phy_discharge_date != '' && this.phy_discharge_time != undefined) {
        sendData.discharge_physical = this.discharge_physical;
        console.log(this.discharge_physical);
        sendData.dis_physical_date = this.phy_discharge_date;
        sendData.dis_physical_time = this.phy_discharge_time;
      }  
      console.log(sendURL);
      console.log(sendData);
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + sendURL, sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj); 
            if (obj.key == '1') {
              this.toastr.success("Discharge timeline saved successfully");
              this.backClicked();
            } else {
              this.toastr.error("Unable to save discharge timeline,please try later");
            }        
          },
          error => {
            this.toastr.error("Unable to save discharge timeline,please try later");
      });
    }   
  }  

  diagnosisPathFunction(type) {
    if (type === 'diagnosis') {
      if (this.diagBillListFlag == false) {
        this.diagBillListFlag = true;
        this.searchBill(type);
      } else if (this.diagBillListFlag == true) {
        this.diagBillListFlag = false;
        this.billingList = [];
      }
    }
  }

  hospitalPathFunction(type) {
    if (type === 'doctor') {
      if (this.hospBillListFlag == false) {
        this.hospBillListFlag = true;
        this.searchBill(type);
      } else if (this.hospBillListFlag == true) {
        this.hospBillListFlag = false;
        this.billList = [];
      }
    }
  }

  pharmaPathFunction(type) {
    if (type === 'pharmacy') {
      if (this.pharmaBillListFlag == false) {
        this.pharmaBillListFlag = true;
        this.searchPharmaBill(type);
      } else if (this.pharmaBillListFlag == true) {
        this.pharmaBillListFlag = false;
        this.pharmaBillList = [];
      }
    }
  }

  searchBill(type) { 
    var fieldvalue;
    var inpatient = "yes";        
    var providerID = type == "diagnosis" ? this.userInfo.hospitals[0].diag_center_id : this.userInfo.hospitals[0].hptl_clinic_id;
    var appType = undefined;
    
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        provider_id: providerID,
        patient_id: this.mrno,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Mobile") {
      fieldvalue = {
        provider_id: providerID,
        mobile: this.mobile_no,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    }
    console.log("BILL FILEDS " + JSON.stringify(fieldvalue));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', { fieldvalue },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.bills != 0) {
            if (type == "diagnosis") {
              this.inSetBillList(obj);           
            } else {
              this.setBillList(obj);             
            }
          } 
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  setBillList(obj) {
    this.billList = [];
    console.log(obj);
    if (obj.bills != null) {
      for (var i = 0; i < obj.bills.length; i++) {
        var status;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") 
            status = "Closed";
          else if (obj.bills[i].status === "1") 
            status = "Open";
          else if (obj.bills[i].status === "2") 
            status = "Finalized";         
          else if (obj.bills[i].status === "3") 
            status = "Settled";                   
        }
        var get_date = obj.bills[i].date.split('-');
        if (obj.bills[i].status === "1") {
          this.billList.push({
            bill_id: obj.bills[i].bill_id,
            fin_year: obj.bills[i].fin_year,
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            pay_type: obj.bills[i].pay_type,
            amount: parseFloat(encrypt_decript.Decript(obj.bills[i].amount)),
            status: status,
          });
        }        
      }
      if (this.billList.length != 0) 
        this.billListFlag = true;
      else 
        this.billListFlag = false;     
      console.log(this.billList);   
    }
  }

  inSetBillList(obj) {
    this.billingList = [];
    if (obj.bills != null) {
      console.log("BILL DETAILS " + obj);
      for (var i = 0; i < obj.bills.length; i++) {
        var status;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") 
            status = "Closed";
          else if (obj.bills[i].status === "1") 
            status = "Open";
          else if (obj.bills[i].status === "2") 
            status = "Finalized";          
          else if (obj.bills[i].status === "3") 
            status = "Settled";                  
        }
        var get_date = obj.bills[i].date.split('-');
        if (obj.bills[i].status === "1") {
          this.billingList.push({
            bill_id: obj.bills[i].bill_id,
            fin_year: obj.bills[i].fin_year,
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],       
            amount: parseFloat(encrypt_decript.Decript(obj.bills[i].amount)),       
            status: status,         
          });
        }       
      }
      if (this.billingList.length != 0) 
        this.billingListFlag = true;
      else
        this.billingListFlag = false;     
    }
    console.log("this.billingList" + JSON.stringify(this.billingList));
  }

  searchPharmaBill(type) {    
    var send_data = {  
        inpatient_id: this.inpatient_id  
      }
     
    console.log(send_data);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpbbi", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("PharmacyList"+JSON.stringify(obj));
          this.pharmaBillList = [];                 
          if (obj.pharma_bills != null) {
            console.log("PHARMA BILL DETAILS " + obj);
            for (var i = 0; i < obj.pharma_bills.length; i++) {
              var get_date = obj.pharma_bills[i].order_date.split('-');
              if (obj.pharma_bills[i].status === "Open") {
                this.pharmaBillList.push({
                  bill_id: obj.pharma_bills[i].bill_no,
                  date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],       
                  amount: parseFloat(obj.pharma_bills[i].bill_amount),       
                  status: obj.pharma_bills[i].status,         
                });
              }       
            }
            if (this.pharmaBillList.length != 0) 
              this.pharmaBillingListFlag = true;
            else 
              this.pharmaBillingListFlag = false;           
          }
          console.log("this.pharmaBillList" + JSON.stringify(this.pharmaBillList));
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }  

}
