<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Purchase order</h5>
          </div>


          <div class="headerButtons" *ngIf="tabtype == 'pending'">
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="purchase_view()" />
            <img class="saveimgbtn_inpatinfo" *ngIf="approvalboxandbutton && prarray.length != 0"  src="../../../assets/ui_icons/buttons/approve_button.svg" (click)="approved()">
          </div>

          <div class="headerButtons" *ngIf="tabtype == 'completed'">
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="purchase_view()" />
          </div>

        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin: 0 auto;">


          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 position" *ngIf="this.usertype == 'Admin'">
              <mat-label class="matlabel">Department<br>
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="departname"
                    (selectionChange)="pobase_department($event)">
                    <mat-option *ngFor="let department of departmentarray"
                      value={{department.department_id}}>{{department.description}}</mat-option>
                  </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 position" *ngIf="this.usertype == 'Admin'">
              <mat-label class="matlabel">Store name<br>
                <mat-select  class="ipcss "  [(ngModel)]="storename">
                  <mat-option *ngFor="let stro of storearray"  value={{stro.store_names_id}} (Change)="get_list()">{{stro.store_desc}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
          </div>
          <div class="Details">
              <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0"
                  (selectedTabChange)="selectTab($event)">

                  <mat-tab label="pending">
                      <ng-template mat-tab-label style="margin-left:10px !important;">
                          <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px"
                              height="auto" />
                          &nbsp; Pending
                      </ng-template>
                      <mdb-card style="width: 99% !important;margin-left: 3px;">
                          <mdb-card-body>
                            <div class="row justify-content-center">
                              <div class="col-12 col-lg-12 col-md-12 col-xl-12 d-flex flex-wrap">
                                <p class="nodata" *ngIf="orderArray.length == 0">No order(s) found</p>
                                <div class="table-responsive" *ngIf="orderArray.length != 0" >
                                 <table>
                                      <thead   style="  position: sticky;
                                      top: 0;
                                      z-index: 10;">
                                        <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                          <div style="width:100%;display: flex;
                                          justify-content: space-evenly">
                                          <th style="width:15%">PO no</th>
                                          <th style="width:20%">Supplier name</th>
                                          <th style="width:20%">Department</th>
                                          <th style="width:20%">Store name</th>
                                          <th style="width:20%">Date</th>
                                          <th style="width:20%">Status</th>
                                          </div>
                                          <td><td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                       <tr *ngFor="let list of orderArray" style="height: 62px !important;">
                                          <div style="width:100%;display: flex;
                                          justify-content: space-evenly;text-align:left;height: 60px" (click)="view_details(list.po_id,list)" >
                                          <td style="width:20%;position: sticky;
                                          top: 0;">{{list.po_no}}</td>
                                          <td style="width:20%;position: sticky;
                                          top: 0;">{{list.supplier_name}}</td>
                                          <td style="width:20% ;position: sticky;
                                          top: 0;">{{list.dept_name ||"-"}}</td>
                                          <td style="width:20% ;position: sticky;
                                          top: 0;">{{list.store_name || "-"}}</td>
                                          
                                          <td style="width:20% ;position: sticky;
                                          top: 0;font-size:11px">{{list.po_date}}/{{list.po_time}}</td>
                                          <td style="width:20%;position: sticky;
                                          top: 0;">
                                            <img *ngIf="list.status == 'Open'" width="30px;" height="22px"
                                            src="../../../assets/ui_icons/opened_icon.svg" />
                     
                                            <img *ngIf="list.status == 'Approved'" width="30px;" height="22px"
                                            src="../../../assets/ui_icons/awaiting_for_rating.svg" />
                     
                                            <p style="margin: 0px 0 0px">{{list.status}}</p>
                                          </td>
                                        </div>
                                          <td>
                                          <input *ngIf="approvalboxandbutton && list.status == 'Open'" type="checkbox" [checked]="accepted" (click)="checkeddata(list)">
                                          <input *ngIf="approvalboxandbutton && list.status == 'Approved'"
                                            style=" cursor:no-drop;color:blue" type="checkbox" [checked]="list.status === 'Approved'"
                                            [disabled]="list.status === 'Approved'">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                 </div>
                              </div>

                            </div>
                              
                          </mdb-card-body>
                      </mdb-card>
                  </mat-tab>

                  <mat-tab label="completed">
                      <ng-template mat-tab-label>
                          <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px"
                              height="auto" />
                          &nbsp; Completed
                      </ng-template>
                      <mdb-card style="width: 99% !important;margin-left: 3px;">
                          <mdb-card-body>
                            <div class="col-12 col-lg-12 col-md-12 col-xl-12 d-flex flex-wrap">
                              <p class="nodata" *ngIf="orderArray.length == 0">No order(s) found</p>
                              <div class="table-responsive" *ngIf="orderArray.length != 0" >
                               <table>
                                    <thead   style="  position: sticky;
                                    top: 0;
                                    z-index: 10;">
                                      <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                        <div style="width:100%;display: flex;
                                        justify-content: space-evenly">
                                        <th style="width:15%">Order no</th>
                                        <th style="width:20%">Supplier name</th>
                                        <th style="width:20%">Department</th>
                                        <th style="width:20%">Store name</th>
                                        <th style="width:20%">Date</th>
                                        <th style="width:20%">Status</th>
                                        </div>
                                        <td><td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                   
                                        <tr *ngFor="let list of orderArray" style="height: 62px !important;">
                                          <div style="width:100%;display: flex;
                                          justify-content: space-evenly;text-align:left;height: 60px" (click)="view_details(list.po_id,list)" >
                                          <td style="width:20%;position: sticky;
                                          top: 0;">{{list.po_no}}</td>
                                          <td style="width:20%;position: sticky;
                                          top: 0;">{{list.supplier_name}}</td>
                                          <td style="width:20% ;position: sticky;
                                          top: 0;">{{list.dept_name ||"-"}}</td>
                                          <td style="width:20% ;position: sticky;
                                          top: 0;">{{list.store_name || "-"}}</td>
                                          
                                          <td style="width:20% ;position: sticky;
                                          top: 0;font-size:11px">{{list.po_date}}/{{list.po_time}}</td>
                                          <td style="width:20%;position: sticky;
                                          top: 0;">
                                            <img *ngIf="list.status == 'Open'" width="30px;" height="22px"
                                            src="../../../assets/ui_icons/opened_icon.svg" />
                     
                                            <img *ngIf="list.status == 'Complete'" width="30px;" height="22px"
                                            src="../../../assets/ui_icons/Completed_icon.svg" />
                     
                                            <p style="margin: 0px 0 0px">{{list.status}}</p>
                                          </td>
                                        </div>
                                          <td>
                                          <input *ngIf="approvalboxandbutton && list.status == 'Open'" type="checkbox" [checked]="accepted" (click)="checkeddata(list)">
                                          <input *ngIf="approvalboxandbutton && list.status == 'Complete'"
                                            style=" cursor:no-drop;color:blue" type="checkbox" [checked]="list.status === 'Complete'"
                                            [disabled]="list.status === 'Complete'">
                                          </td>
                                        </tr>
                                      
                                   
                                      <tr>
                                      </tr>
                                    </tbody>
                                  </table>
                    </div>
                            </div>                                
                          </mdb-card-body>
                      </mdb-card>
                  </mat-tab>
              </mat-tab-group>
              
          </div>
      </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>