import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ipaddress } from 'src/app/ipaddress';
import { Http, Headers } from '@angular/http';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';

@Component({
  selector: 'app-logreport',
  templateUrl: './logreport.component.html',
  styleUrls: ['./logreport.component.scss']
})
export class LogreportComponent implements OnInit {
  @ViewChild('printlogrep') printlogrep: ElementRef;
  @ViewChild('printdiaglogrep') printdiaglogrep: ElementRef;
  @ViewChild('printpharmalogrep') printpharmalogrep: ElementRef;
  @ViewChild('printcondlogrep') printcondlogrep: ElementRef;
  @ViewChild('printoutsourcelogrep') printoutsourcelogrep: ElementRef;
  @ViewChild('printimage') printimage: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  public noData: boolean;
  public hasData: boolean;
  tmr = new Date();
  public fromDate;
  public toDate;
  public currentDate;
  public header = [];
  public ishide: boolean = false;
  public logdata: any = [];
  public columns = {
    sno: true,
    Bill_no: true,
    Bill_date: true,
    Name: true,
    bill_amount: true,
    Modified_amount: true,
    Created_by: true,
    Created_date: true,
    Modified_by: true,
    Modified_date: true,
  };
  public pharmacyid;
  public userInfo;
  selectedDuration: string = 'select';
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public selected_filter: any = [];
  public print_template;
  public hospital_logo;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;
  public reporttype;
  public reportname;
  public url;
  public diaglogdata: any = [];
  public pharmalogdata: any = [];
  public conddocdata: any = [];
  public outpaydata: any = [];
  public hsp_id: string;
  public docbutton: boolean = false;
  public outcentlist:any=[];
  public outcentflag: boolean = false;
  public outcentid:string;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.selected_filter = [
      'sno',
      'Bill_no',
      'Bill_date',
      'Name',
      'bill_amount',
      'Modified_amount',
      'Created_by',
      'Created_date',
      'Modified_by',
      'Modified_date',
    ]
  }

  ngOnInit(): void {
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.user_type == 'pharmacy') {
      this.pharmacyid = this.userInfo.hospitals[0].hosp_id;

    } else {
      this.pharmacyid = this.userInfo.hospitals[0].hptl_clinic_id
    }

    this.reporttype = Helper_Class.getReportType()
    if (this.reporttype == 'diag_log') {
      this.reportname = "Diagnosis log report";
      this.url = "misr/gdlr";
      this.header = ["Test name", "Name", "Sample date", "Sample by", "Assertion date", "Asserted by", "Entry date", "Uploaded date", " Approved date", "Approved by"];

      this.hsp_id = this.userInfo.user_type == 'Admin' ? this.userInfo.hospitals[0].hptl_clinic_id :
        this.userInfo.user_type == 'front-desk' ? this.userInfo.hospitals[0].hptl_clinic_id :
          this.userInfo.user_type == 'Accounts' ? this.userInfo.hospitals[0].hptl_clinic_id :
            this.userInfo.user_type == 'Billing' ? this.userInfo.hospitals[0].hptl_clinic_id :
              this.userInfo.user_type == 'doctor' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.hospitals[0].hosp_id

    } else if (this.reporttype == 'pharma_log') {
      this.reportname = "Pharmacy log report"
      this.url = 'misr/gplr';
      this.header = ["Id", "Name", "Estimate Date", "Estimate by", "Order id", "Order Date", "Order by", "Bill amount", "Paid amount", "Payment mode"]
      this.hsp_id = this.userInfo.user_type == 'Admin' ? this.userInfo.hospitals[0].hptl_clinic_id :
        this.userInfo.user_type == 'front-desk' ? this.userInfo.hospitals[0].hptl_clinic_id :
          this.userInfo.user_type == 'Accounts' ? this.userInfo.hospitals[0].hptl_clinic_id :
            this.userInfo.user_type == 'Billing' ? this.userInfo.hospitals[0].hptl_clinic_id :
              this.userInfo.user_type == 'doctor' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.hospitals[0].hosp_id

    } else if (this.reporttype == 'cond_doc') {
      this.reportname = "Doctor payments"
      this.url = 'misr/gcdor';
      this.header = ["Date","Appointemtn id", "Name", "Doctor", "Test", "Cost", "Paid", "Balance", "Paid by", "Paid date"]
      this.hsp_id =
        this.userInfo.user_type == 'Admin' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'front-desk' ?
          this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'Accounts' ? this.userInfo.hospitals[0].hptl_clinic_id :
            this.userInfo.user_type == 'Billing' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'doctor' ?
              this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.hospitals[0].hosp_id

    } else if (this.reporttype == 'out_pay') {
      this.outcentflag = true;
      this.reportname = "Outsource payments report"
      this.url = 'misr/gosor';
      this.header = ["Appointment id", "Test", "Outsource", "Invoice", "Bill id", "Created date", "Cost", "Balance", "Status"]
      this.hsp_id =
        this.userInfo.user_type == 'Admin' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'front-desk' ?
          this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'Accounts' ? this.userInfo.hospitals[0].hptl_clinic_id :
            this.userInfo.user_type == 'Billing' ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_type == 'doctor' ?
              this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.hospitals[0].hosp_id

    } else {
      this.reportname = "Log report"
      this.url = 'misr/gbal';
      this.header = ['S no', 'Bill no', 'Name', 'bill amount', 'Modified amount', 'Created by', 'Created date', 'Created time', 'Modified by', 'Modified date', 'Modified time'];
    }
    this.getDate();
    this.getRetrievalData();
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(" get_obj = " + JSON.stringify(obj))
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.get_log_data()
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  get_log_data() {
    var send_data, send_data1, patientname, crt_date, crt_time, update, update_by, createdby, uptime, updbill, prevbill;
    if (this.reporttype == 'diag_log') {
      send_data = {
        hptl_clinic_id: this.hsp_id,
        from_date: this.fromDate,
        to_date: this.toDate
      }
      
    } else if (this.reporttype == 'pharma_log') {
      send_data = {
        hptl_clinic_id: this.hsp_id,
        from_date: this.fromDate,
        to_date: this.toDate
      }
     
    } else if (this.reporttype == 'cond_doc') {
      send_data = {
        hptl_clinic_id: this.hsp_id,
        from_date: this.fromDate,
        to_date: this.toDate
      }
      
    } else if (this.reporttype == 'out_pay') {
      send_data = {
        hptl_clinic_id: this.hsp_id,
        from_date: this.fromDate,
        to_date: this.toDate,
        outcentid:this.outcentid
      }

      send_data1 = {
        diag_center_id: this.hsp_id,
        type:'hospital'
      }
      
    } else {
      send_data = {
        hptl_clinic_id: this.pharmacyid,
        from_date: this.fromDate,
        to_date: this.toDate
      }
    }

    if(this.reporttype == 'out_pay' && this.outcentlist.length == 0){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "diagnosiscontroller/gosl", send_data1,
        { headers: headers }).subscribe(
          data => {
            var dataval = data.json();
            var outlist = dataval.outsource_list;
            if(outlist != undefined && outlist.length !=0) {
              this.outcentlist.push({
                outsource_id:'All',
                centre_name:'All'
              })

              outlist.forEach(out => {
                this.outcentlist.push({
                  outsource_id:out.outsource_id,
                  centre_name:out.centre_name
                })
              });
              if(this.outcentlist.length !=0) {
                this.outcentid = this.outcentlist[0].outsource_id
              }
            }
          });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.url, send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log("dataval = " + JSON.stringify(dataval))
          if (this.reporttype == "diag_log") {
            var objdiag = dataval.diagnosis_log
            console.log("objdiag = " + JSON.stringify(objdiag))
            this.diaglogdata = [];
            if (objdiag != undefined) {
              if (objdiag.length != 0) {
                objdiag.forEach(diaglog => {
                  if (diaglog.clnt_first_name != undefined) {
                    if (diaglog.clnt_middle_name != undefined) {
                      patientname = encrypt_decript.Decript(diaglog.clnt_first_name) + " " + encrypt_decript.Decript(diaglog.clnt_middle_name) + " " + encrypt_decript.Decript(diaglog.clnt_last_name);
                    } else {
                      patientname = encrypt_decript.Decript(diaglog.clnt_first_name) + " " + encrypt_decript.Decript(diaglog.clnt_last_name);
                    }
                  } else {
                    patientname = ''
                  }
                  var collected_date = diaglog.collected_date != undefined && diaglog.collected_date != "" ? Date_Formate(diaglog.collected_date) : "";
                  var collect_time = diaglog.collect_time != undefined && diaglog.collect_time != "" ? Time_Formate(diaglog.collect_time) : "";
                  var assertion_date = diaglog.assertion_date != undefined && diaglog.assertion_date != "" ? Date_Formate(diaglog.assertion_date) : "";
                  var assertion_time = diaglog.assertion_time != undefined && diaglog.assertion_time != "" ? Time_Formate(diaglog.assertion_time) : "";
                  var entry_date = diaglog.entry_date != undefined && diaglog.entry_date != "" ? Date_Formate(diaglog.entry_date) : "";
                  var entry_time = diaglog.entry_time != undefined && diaglog.entry_time != "" ? Time_Formate(diaglog.entry_time) : "";
                  var approved_date = diaglog.approved_date != undefined && diaglog.approved_date != "" ? Date_Formate(diaglog.approved_date) : "";
                  var approved_time = diaglog.approved_time != undefined && diaglog.approved_time != "" ? Time_Formate(diaglog.approved_time) : "";
                  
                  this.diaglogdata.push({
                    diag_tests_id: diaglog.diag_tests_id,
                    test_name: diaglog.test_name,
                    name: patientname,
                    collected_date: collected_date + " " + collect_time,
                    collected_by: diaglog.collected_by,
                    assertion_date: assertion_date + " " + assertion_time,
                    asserted_by: diaglog.asserted_by,
                    entry_date: entry_date + " " + entry_time,
                    uploaded_by: diaglog.uploaded_by,
                    approved_date: approved_date + " " + approved_time,
                    approved_by: diaglog.approved_by,
                  })
                  console.log("APPROVED DATE ============ "+JSON.stringify(this.diaglogdata))
                });
                this.docbutton = true;
              } else {
                this.docbutton = false;
              }
            } else {
              this.docbutton = false;
            }
            console.log("diaglogdata = ", JSON.stringify(this.diaglogdata))
          } else if (this.reporttype == 'pharma_log') {
            this.pharmalogdata = []

            var objpharma = dataval.pharma_log
            if (objpharma != undefined) {
              if (objpharma.length != 0) {
                objpharma.forEach(pharma => {
                  if (pharma.clnt_first_name != undefined) {
                    if (pharma.clnt_middle_name != undefined) {
                      patientname = encrypt_decript.Decript(pharma.clnt_first_name) + " " + encrypt_decript.Decript(pharma.clnt_middle_name) + " " + encrypt_decript.Decript(pharma.clnt_last_name);
                    } else {
                      patientname = encrypt_decript.Decript(pharma.clnt_first_name) + " " + encrypt_decript.Decript(pharma.clnt_last_name);
                    }
                  } else {
                    patientname = ''
                  }
                  pharma.estimate_date = pharma.estimate_date != undefined && pharma.estimate_date != "" ? Date_Formate(pharma.estimate_date) : "";
                  pharma.estimate_time = pharma.estimate_time != undefined && pharma.estimate_time != "" ? Time_Formate(pharma.estimate_time) : "";
                  pharma.order_date = pharma.order_date != undefined && pharma.order_date != "" ? Date_Formate(pharma.order_date) : "";
                  pharma.order_time = pharma.order_time != undefined && pharma.order_time != "" ? Time_Formate(pharma.order_time) : "";
                  pharma.bill_amount = pharma.bill_amount != undefined && pharma.bill_amount != "" ? pharma.bill_amount : 0.00;
                  pharma.paid_amount = pharma.paid_amount != undefined && pharma.paid_amount != "" ? parseFloat(pharma.paid_amount) : 0.00;
                  pharma.payment_mode = pharma.payment_mode != undefined && pharma.payment_mode != "" ? pharma.payment_mode : "";
                  pharma.est_created_by = pharma.est_created_by != undefined && pharma.est_created_by != "" ? pharma.est_created_by : "";
                  pharma.ord_created_by = pharma.ord_created_by != undefined && pharma.ord_created_by != "" ? pharma.ord_created_by : "";
                  this.pharmalogdata.push({
                    estimate_id: pharma.estimate_id,
                    estimate_date: pharma.estimate_date + " " + pharma.estimate_time,
                    est_created_by: pharma.est_created_by,
                    order_id: pharma.order_id,
                    order_date: pharma.order_date + " " + pharma.order_time,
                    ord_created_by: pharma.ord_created_by,
                    bill_amount: pharma.bill_amount,
                    paid_amount: pharma.paid_amount,
                    payment_mode: pharma.payment_mode,
                    name: patientname
                  })
                })
                this.docbutton = true;
              } else {
                this.docbutton = true;
              }
            } else {
              this.docbutton = true;
            }

          } else if (this.reporttype == 'cond_doc') {
           
            this.conddocdata = [];
            var docdata = dataval.conduction_log
            if (docdata != undefined) {
              if (docdata.length != 0) {
                docdata.forEach(docdata => {
                  var docname;
                  if (docdata.first_name != undefined) {
                    if (docdata.middle_name != undefined) {
                      patientname = encrypt_decript.Decript(docdata.first_name) + " " + encrypt_decript.Decript(docdata.middle_name) + " " + encrypt_decript.Decript(docdata.last_name);
                    } else {
                      patientname = encrypt_decript.Decript(docdata.first_name) + " " + encrypt_decript.Decript(docdata.last_name);
                    }
                  } else {
                    patientname = ''
                  }
                  if (docdata.dr_first_name != undefined) {
                    if (docdata.dr_middle_name != undefined) {
                      docname = docdata.dr_first_name + " " + docdata.dr_middle_name + " " + docdata.dr_last_name;
                    } else {
                      docname = docdata.dr_first_name + " " + docdata.dr_last_name;
                    }
                  } else {
                    docname = ''
                  }
                  docdata.paid_date = docdata.paid_date != undefined && docdata.paid_date != "" ? Date_Formate(docdata.paid_date) : "";
                  docdata.paid_time = docdata.paid_time != undefined && docdata.paid_time != "" ? Time_Formate(docdata.paid_time) : "";
                  docdata.balance = docdata.balance != undefined && docdata.balance != "" ? parseFloat(docdata.balance) : "0.00";
                  docdata.paid_amount = docdata.paid_amount != undefined && docdata.paid_amount != "" ? parseFloat(docdata.paid_amount) : "0.00";
                  docdata.test_cost = docdata.test_cost != undefined && docdata.test_cost != "" ? parseFloat(docdata.test_cost) : "0.00";
                  docdata.paid_by = docdata.paid_by != undefined && docdata.paid_by != "" ? docdata.paid_by : "";
                  this.conddocdata.push({
                    diag_appointment_id: docdata.diag_appointment_id,
                    test_name: docdata.test_name,
                    name: patientname,
                    docname: docname,
                    paid_amount: docdata.paid_amount,
                    balance: docdata.balance,
                    paid_by: docdata.paid_by,
                    paid_date: docdata.paid_date + " " + docdata.paid_time,
                    test_cost: docdata.test_cost,
                    conduct_date:Date_Formate(docdata.approved_date),
                    conduct_time:Time_Formate(docdata.approved_time),
                    pay_mode: docdata.pay_mode,
                    type: docdata.type,
                    mr_no: docdata.mr_no,
                  })
                })
                this.docbutton = true;
              } else {
                this.docbutton = false;
              }
            } else {
              this.docbutton = false;
            }
            
          } else if (this.reporttype == 'out_pay') {
            this.outpaydata = [];
            var payment = dataval.outsource_log
            if (payment != undefined) {
              if (payment.length != 0) {
                payment.forEach(pay => {
                  pay.created_date = pay.created_date != undefined && pay.created_date != "" ? Date_Formate(pay.created_date) : "";
                  pay.created_time = pay.created_time != undefined && pay.created_time != "" ? Time_Formate(pay.created_time) : "";

                  pay.paid_date = pay.paid_date != undefined && pay.paid_date != "" ? Date_Formate(pay.paid_date) : "";
                  pay.paid_time = pay.paid_time != undefined && pay.paid_time != "" ? Time_Formate(pay.paid_time) : "";

                  pay.balance = pay.balance != undefined && pay.balance != "" ? parseFloat(pay.balance) : "0.00";
                  pay.cost = pay.cost != undefined && pay.cost != "" ? parseFloat(pay.cost) : "0.00";
                  pay.test_name = pay.test_name != undefined && pay.test_name != "" ? pay.test_name : '';
                  pay.outsource_name = pay.outsource_name != undefined && pay.outsource_name != "" ? pay.outsource_name : '';

                  var cname;
                  if(pay.middle_name != undefined){
                    cname = encrypt_decript.Decript(pay.first_name)+" "+encrypt_decript.Decript(pay.middle_name)+" "+encrypt_decript.Decript(pay.last_name);
                  } else {
                    cname = encrypt_decript.Decript(pay.first_name)+" "+encrypt_decript.Decript(pay.last_name);
                  }
                  this.outpaydata.push({
                    diag_appointment_id: pay.diag_appointment_id,
                    diag_tests_id: pay.diag_tests_id,
                    test_name: pay.test_name,
                    outsource_name: pay.outsource_name,
                    invoice_no: pay.invoice_no,
                    billing_id: pay.billing_id,
                    created_date: pay.created_date + " " + pay.created_time,
                    cost: pay.cost,
                    balance: pay.balance,
                    paid_flag: pay.paid_flag,
                    paid_date: pay.paid_date + " " + pay.paid_time,
                    paid_by: pay.paid_by,
                    name:cname,
                    mr_no: pay.mr_no,
                  })
                })
                this.docbutton = true;

              } else {
                this.docbutton = false;
              }

            } else {
              this.docbutton = false;
            }
          }
          else {
            var obj = dataval.bill_log;
            this.logdata = [];
            // if(dataval != undefined ){

            // }else{
            //   this.logdata = [];
            //     this.noData = false;
            //     this.hasData = true;
            //     this.backbutton=false;
            //     this.docbutton=false;
            // }
            if (obj != undefined) {
              if (obj.length != 0) {
                if (dataval.length != 0) {
                  this.hasData = false;
                }
                console.log("log_obj = ", JSON.stringify(obj))
                for (var i = 0; i < obj.length; i++) {
                  if (obj[i].first_name != undefined) {
                    if (obj[i].middle_name != undefined) {
                      patientname = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                    } else {
                      patientname = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                    }
                  } else {
                    patientname = ''
                  }

                  if (obj[i].cfname != undefined) {
                    if (obj[i].cmname != undefined) {
                      createdby = obj[i].cfname + " " + obj[i].cmname + " " + obj[i].clname;
                    } else {
                      createdby = obj[i].cfname + " " + obj[i].clname;
                    }
                  } else {
                    createdby = ''
                  }

                  if (obj[i].ufname != undefined) {
                    if (obj[i].umname != undefined) {
                      update_by = obj[i].ufname + " " + obj[i].umname + " " + obj[i].ulname;
                    } else {
                      update_by = obj[i].ufname + " " + obj[i].ulname;
                    }
                  } else {
                    update_by = ''
                  }

                  if (obj[i].created_date != undefined) {
                    crt_date = Date_Formate(obj[i].created_date)
                  } else {
                    crt_date = ''
                  }
                  if (obj[i].created_time != undefined) {
                    crt_time = Time_Formate(obj[i].created_time)
                  } else {
                    crt_time = ''
                  }
                  if (obj[i].updated_date != undefined) {
                    update = Date_Formate(obj[i].updated_date)
                  } else {
                    update = ''
                  }
                  if (obj[i].updated_time != undefined) {
                    uptime = Time_Formate(obj[i].updated_time)
                  } else {
                    uptime = ''
                  }

                  if (obj[i].bill_amount != undefined) {
                    prevbill = obj[i].bill_amount

                  } else {
                    prevbill = ''
                  }

                  if (obj[i].prev_bill_amount != undefined) {
                    updbill = obj[i].prev_bill_amount

                  } else {
                    updbill = ''
                  }
                  this.logdata.push({
                    sno: i + 1,
                    billing_id: obj[i].billing_id,
                    patient_name: patientname,
                    prev_bill_amount: prevbill,
                    upd_bill_amount: updbill,
                    created_by: createdby,
                    created_date: crt_date,
                    created_time: crt_time,
                    updated_by: update_by,
                    updated_date: update,
                    updated_time: uptime,
                  })
                  this.forcoverdiv = false;
                  this.backbutton = true
                  this.ishide = true;
                }
                this.docbutton = true;
              } else {
                this.logdata = [];
                this.noData = false;
                this.hasData = true;
                this.backbutton = false;
              }
            } else {
              this.logdata = [];
              this.noData = false;
              this.hasData = true;
              this.backbutton = false;
              this.docbutton = false;
            }
          }

        }, error => { });
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }


  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
    this.get_log_data()
  }



  print_area() {
    let printContents, popupWin, totalamt, cashamt, cardamt, insureamt, upiamt, logo, hospitaldetails;
    if (this.reporttype == "diag_log") {
      printContents = this.printdiaglogrep.nativeElement.innerHTML;
    } else if (this.reporttype == "pharma_log") {
      printContents = this.printpharmalogrep.nativeElement.innerHTML;
    } else if (this.reporttype == "cond_doc") {
      printContents = this.printcondlogrep.nativeElement.innerHTML;
    } else if (this.reporttype == 'out_pay') {
      printContents = this.printoutsourcelogrep.nativeElement.innerHTML;
    }
    else {
      printContents = this.printlogrep.nativeElement.innerHTML;
    }
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;
    if (this.print_template != undefined && this.print_template == "banner") {
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
         width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:2% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>`
      );

      popupWin.document.close(); ``
    }
    else if (this.print_template != undefined && this.print_template == "logowithname") {
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();

      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width:100%">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   

    `);

      popupWin.document.close();
    }
    else {
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <head>
        <title>Reports</title>
        
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
        @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
        @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
        th{
          padding:0%;
        }
        .tbl{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        }
        body{
          padding-top:10%;
        }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">
          ${printContents}
         </table>
        
          </body>
        </html>`
      );
      popupWin.document.close();
    }
  }

  setTable(data) {
    console.log(data)

    this.columns = {
      sno: false,
      Bill_no: false,
      Bill_date: false,
      Name: false,
      bill_amount: false,
      Modified_amount: false,
      Created_by: false,
      Created_date: false,
      Modified_by: false,
      Modified_date: false,

    };

    // if (data.includes("doctor")) {
    //   this.colspann = 4;
    // } else {
    //   this.colspann = 3;
    // }

    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }

  todisplaycoverdiv() {
    this.forcoverdiv = true;

    this.backbutton = false;
    this.ishide = false;
    this.logdata = [];
    this.hasData = true;
  }

  getExcelData(type) {
    if (this.reporttype == "diag_log") {
      if (this.diaglogdata.length != 0) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Diag log report');
        worksheet.getColumn(1).width = 10;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 20;
        let headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        })
        var diaglog = [];
        this.diaglogdata.forEach(data => {
          diaglog.push({
            test_name: data.test_name,
            name: data.name,
            collected_date: data.collected_date,
            collected_by: data.collected_by,
            assertion_date: data.assertion_date,
            entry_date: data.entry_date,
            approved_date: data.approved_date,
            approved_by: data.approved_by,
          })
        })
        for (let x1 of diaglog) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
        });
      } else {
        this.toastr.error(Message_data.exportExcelFailed);
      }
    } else if (this.reporttype == "pharma_log") {
      if (this.pharmalogdata.length != 0) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Pharmacy log report');
        worksheet.getColumn(1).width = 10;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 20;
        let headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        })
        var pharmalog = [];
        this.pharmalogdata.forEach(data => {
          pharmalog.push({
            estimate_id: data.estimate_id,
            name: data.name,
            estimate_date: data.estimate_date,
            est_created_by: data.est_created_by,
            order_id: data.order_id,
            order_date: data.order_date,
            ord_created_by: data.ord_created_by,
            bill_amount: data.bill_amount,
            paid_amount: data.paid_amount,
            payment_mode: data.payment_mode,
          })
        })
        for (let x1 of pharmalog) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
        });
      } else {
        this.toastr.error(Message_data.exportExcelFailed);
      }
    } else if (this.reporttype == "cond_doc") {
      if (this.conddocdata.length != 0) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Conduction doctor payment report');
        worksheet.getColumn(1).width = 10;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 20;
        let headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        })
        var conddoclog = [];
        this.conddocdata.forEach(data => {
          conddoclog.push({
            conduct_date:data.conduct_date +" "+data.conduct_time,
            diag_appointment_id: data.diag_appointment_id,
            name: data.name,
            docname: data.docname,
            test_name: data.test_name,
            test_cost: data.test_cost,
            paid_amount: data.paid_amount,
            balance: data.balance,
            paid_by: data.paid_by,
            paid_date: data.paid_date,
          })
        })
        for (let x1 of conddoclog) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
        });
      } else {
        this.toastr.error(Message_data.exportExcelFailed);
      }

    } else if (this.reporttype == "out_pay") {
      if (this.outpaydata.length != 0) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Out-source payment report');
        worksheet.getColumn(1).width = 10;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 20;
        let headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        })
        var outpaylog = [];
        this.outpaydata.forEach(data => {
          outpaylog.push({
            diag_appointment_id: data.diag_appointment_id,
            test_name: data.test_name,
            outsource_name: data.outsource_name,
            invoice_no: data.invoice_no,
            billing_id: data.billing_id,
            created_date: data.created_date,
            cost: data.cost,
            balance: data.balance,
            paid_flag: data.paid_flag,
          })
        })
        for (let x1 of outpaylog) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
        });
      } else {
        this.toastr.error(Message_data.exportExcelFailed);
      }
    } else {
      if (this.logdata.length != 0) {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Log report');
        worksheet.getColumn(1).width = 10;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 20;
        let headerRow = worksheet.addRow(this.header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
        })
        for (let x1 of this.logdata) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          let chilrow = worksheet.addRow(temp)
          let salesamt = chilrow.getCell(5);
          salesamt.alignment = {
            vertical: 'middle',
            horizontal: 'right'
          }
        }

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
        });
      } else {
        this.toastr.error(Message_data.exportExcelFailed);
      }
    }

  }


  getRetrievalData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    console.log("get retrieval data" + send)
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          // var values = response.json();
          var values = JSON.parse(JSON.stringify(response));
          console.log("retrive obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

}
