import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { ServerApi } from 'src/app/server-api';
import moment from 'moment';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from '../../../../assets/js/Message_data';

@Component({
  selector: 'app-pharma-returns',
  templateUrl: './pharma-returns.component.html',
  styleUrls: ['./pharma-returns.component.scss']
})
export class PharmaReturnsComponent implements OnInit {
  public userInfo;
  public pharmacistID;
  public pharmacyID;
  public LoadLocationArray;
  public locationList;
  public location;
  public stockData = [];
  dtOptions: DataTables.Settings = {};
  public checklist = [];
  public checklistid = [];
  public appin_open_count;
  public masterSelected: boolean = false;
  public nodata_flag: boolean = true;
  public productReturn;
  public expiryDate;
  public resonListArray: any = [];
  public resonurl = "pharmacontrol/gsrr";
  public returnsdata: boolean = true;
  public reasondata;
  public return_reason: any = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public pagelist: any;
  public sno;
  public currentPage: any;
  public pageSize;
  public pageNavigate: boolean;
  public clientCount
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public returnsproductname;
  public filtertype;
  public productflag: boolean;
  public suppliersflag: boolean = true;
  public prodname_flag: boolean = true;
  public supp_id;
  public supp_name:string;
  public supplierList: any = [];
  //public suppID;
  public gst_no;
  public product_data: any = [];
  public productdata_detal: any = [];
  public prod_name;
  private supp_products:any=[];

  constructor(public httpp: Http, public http: HttpClient, public serviceAPI: ServerApi, public toastr: ToastrService, private messageServive: MenuViewService, private messageServive1: MenuViewService) {
    this.pageSize = 50;
    this.currentPage = 1;
    this.pageNavigate = true

  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = this.userInfo.pharma_id;
      this.pharmacistID = this.userInfo.po_approver;

    } else {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.userInfo.user_id;
    }
    this.returnsproductname = undefined;
    this.productReturn = this.userInfo.hospitals[0].product_return;
    this.expiryDate = moment().add(this.productReturn, 'months').format('MM-YYYY');
    this.productflag = false;
    this.filtertype = "products";
    this.pagelist = "50"
    this.navpagelist()
    this.reason();
    this.getSuppliers();

    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "59" && Helper_Class.getmodulelist()[i].description == "Stock returns") {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
  }

  async get_stock() {
    
    var send_data;
    if (this.returnsproductname != undefined && this.returnsproductname.length != 0) {
      this.prodname_flag=false;
      send_data = {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
        product_return: this.productReturn,
        expiry_date: this.expiryDate,
        prod_name: this.returnsproductname,
        supp_id:this.supp_id,
      }

    } else {
      this.prodname_flag=true;
      send_data = {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
        product_return: this.productReturn,
        expiry_date: this.expiryDate,
        limit: this.pagelist,
        sno: this.sno,
        supp_id:this.supp_id
      }
    }

    var response = await this.serviceAPI.PostData('pharmacontrol/gpstockl', send_data).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      console.log("response ===== " + JSON.stringify(obj))
      //  this.stockData = obj.stock_onhand;
      this.appin_open_count = obj.stock_onhand.length;

      this.pageNavigate = obj.stock_count > 50 ? false : true;
      this.clientCount = obj.stock_count
      this.stockData = [];
      for (var i = 0; i < obj.stock_onhand.length; i++) {
        if (obj.stock_onhand[i].qty_onhand != "0") {
          var shortname;
          if(obj.stock_onhand[i].short_name != undefined) {
            shortname = obj.stock_onhand[i].short_name+ "-";
          } else {
            shortname = "";
          }
          this.stockData.push({
            id: i + 1,
            rec_item_id:obj.stock_onhand[i].rec_item_id,
            product_id: obj.stock_onhand[i].product_id,
            medicine: shortname + obj.stock_onhand[i].name,
            genericName: obj.stock_onhand[i].generic_name,
            uomDesc: obj.stock_onhand[i].uom_desc,
            // supplier_id:obj.stock_onhand[i].
            supp_name: obj.stock_onhand[i].supp_name,
            supp_id: obj.stock_onhand[i].supp_id,
            batchNo: obj.stock_onhand[i].batch_no,
            exp_date: obj.stock_onhand[i].exp_date,
            qty_onhand: obj.stock_onhand[i].qty_onhand,
            cost_price: obj.stock_onhand[i].cost_price,
            sales_price: obj.stock_onhand[i].sales_price,
            mrp: obj.stock_onhand[i].mrp,
            mfg_date: obj.stock_onhand[i].mfg_date,
            gst:obj.stock_onhand[i].gst,
            cgst:obj.stock_onhand[i].cgst,
            sgst:obj.stock_onhand[i].sgst,
            checked: false,
          });
        }
      }
      this.nodata_flag = true;
      //this.returnsproductname= undefined;
      console.log("this.stockData" + JSON.stringify(this.stockData));
    }
  }

  checkUncheckAll(action) {
    if (action == "select") {
      for (var i = 0; i < this.stockData.length; i++) {
        if (this.supp_products.length != 0) {
          var costamt = parseInt(this.stockData[i].qty_onhand) *parseFloat(this.stockData[i].cost_price);
          var cgstamt = costamt *(parseFloat(this.stockData[i].cgst)/100);
          var sgstamt =  costamt* (parseFloat(this.stockData[i].sgst)/100);
          var amount=(costamt+cgstamt+sgstamt).toFixed(2);
          for(var j=0;j<this.supp_products.length;j++) {
            if(this.supp_products[j].supp_id == this.stockData[i].supp_id) {
              console.log("VERIFY ONE")
              var test_product:any=[];
              test_product = this.supp_products[j].products;
              var amountval = this.supp_products[j].refund_amount;
              for(var k=0;k<test_product.length;k++) {
                if(test_product[k].product_id != this.stockData[i].product_id) {
                  amountval = (parseFloat(amountval)+parseFloat(amount)).toFixed(2);
                  test_product.push({
                    index: this.stockData[i].id,
                    rec_item_id:this.stockData[i].rec_item_id,
                    product_id: this.stockData[i].product_id,
                    batch_no: this.stockData[i].batchNo,
                    qty_return: this.stockData[i].qty_onhand,
                    cost_price: this.stockData[i].cost_price,
                    sales_price: this.stockData[i].sales_price,
                    mrp: this.stockData[i].mrp,
                    mfg_date: this.stockData[i].mfg_date,
                    mfg_name: this.stockData[i].mfgName,
                    expiry_date: this.stockData[i].exp_date,
                    return_reason: this.stockData[i].return_reason,
                    cgst:this.stockData[i].cgst,
                    sgst:this.stockData[i].sgst,
                    amount:amount,
                  })
                  this.supp_products[j].refund_amount = amountval;
                  this.stockData[i].checked = true;
                }
              }
              console.log("=========== "+JSON.stringify(this.supp_products))
            } else {
              console.log("VERIFY ELSE")
              test_product.push({
                index: this.stockData[i].id,
                rec_item_id:this.stockData[i].rec_item_id,
                product_id: this.stockData[i].product_id,
                batch_no: this.stockData[i].batchNo,
                qty_return: this.stockData[i].qty_onhand,
                cost_price: this.stockData[i].cost_price,
                sales_price: this.stockData[i].sales_price,
                mrp: this.stockData[i].mrp,
                mfg_date: this.stockData[i].mfg_date,
                mfg_name: this.stockData[i].mfgName,
                expiry_date: this.stockData[i].exp_date,
                return_reason: this.stockData[i].return_reason,
                cgst:this.stockData[i].cgst,
                sgst:this.stockData[i].sgst,
                amount:amount,
              })
              this.stockData[i].checked = true;
              this.supp_products.push({
                "supp_id" : this.stockData[i].supp_id,
                "products":test_product,
                "refund_amount":amount,
              })
            }
          }
          this.stockData[i].checked = true;
        } else {
          
          var test_product:any=[];
          var costamt = parseInt(this.stockData[0].qty_onhand) *parseFloat(this.stockData[0].cost_price);
          var cgstamt = costamt *(parseFloat(this.stockData[0].cgst)/100);
          var sgstamt =  costamt* (parseFloat(this.stockData[0].sgst)/100);
          var amount=(costamt+cgstamt+sgstamt).toFixed(2);
          test_product.push({
            index: this.stockData[0].id,
            rec_item_id:this.stockData[0].rec_item_id,
            product_id: this.stockData[0].product_id,
            batch_no: this.stockData[0].batchNo,
            qty_return: this.stockData[0].qty_onhand,
            cost_price: this.stockData[0].cost_price,
            sales_price: this.stockData[0].sales_price,
            mrp: this.stockData[0].mrp,
            mfg_date: this.stockData[0].mfg_date,
            mfg_name: this.stockData[0].mfgName,
            expiry_date: this.stockData[0].exp_date,
            return_reason: this.stockData[0].return_reason,
            cgst:this.stockData[0].cgst,
            sgst:this.stockData[0].sgst,
            amount:amount,
          })
  
          this.supp_products.push({
            "supp_id" : this.stockData[0].supp_id,
            "products":test_product,
            "refund_amount":amount,
          })
          console.log("INSIDE VALUE OF ELSE "+JSON.stringify(this.supp_products))
          this.stockData[0].checked = true;
        }
      }
      this.masterSelected = true;
      console.log("THE PRODUCT DETAILS =========== "+JSON.stringify(this.supp_products))
    } else {
      this.supp_products = [];
      for (var i = 0; i < this.stockData.length; i++) {
        this.stockData[i].checked = false;
      }
      this.masterSelected = false;
    }
  }

  isAllSelected(list, action) {
    console.log("SUPPLIER ID "+list.supp_id+" ===== "+list.supp_name)
    if (list.return_reason == undefined) {
      this.toastr.error(Message_data.returnreason);

    } else if (list.product_id == undefined) {
      this.toastr.error(Message_data.returnproduct_id);

    } else if (list.batchNo == undefined) {
      this.toastr.error(Message_data.returnbatchno);

    } else if (list.exp_date == undefined) {
      this.toastr.error(Message_data.returnexpiry_date);

    } else if (list.supp_name == undefined) {
      this.toastr.error(Message_data.returnmfg_name);

    } else if (list.qty_onhand == undefined) {
      this.toastr.error(Message_data.returnqty_return);

    } else {
      for (var i = 0; i < this.stockData.length; i++) {
        console.log(JSON.stringify(this.stockData[i]))
        if (this.stockData[i].id == list.id) {
          var costamt = parseInt(list.qty_onhand) *parseFloat(list.cost_price);
          var cgstamt = costamt *(parseFloat(list.cgst)/100);
          var sgstamt =  costamt* (parseFloat(list.sgst)/100);
          var amount=(costamt+cgstamt+sgstamt).toFixed(2);
          console.log("AMOUNT VALUE ======== "+amount)
          if (action == "select") {
            if(this.supp_products.length !=0) {
              for(var p=0;p<this.supp_products.length;p++) {
                var test_product:any=[];
                if(this.supp_products[p].supp_id == list.supp_id) {
                  test_product = this.supp_products[p].products;
                  var amountval = this.supp_products[p].refund_amount;
                  for(var k=0;k<test_product.length;k++) {
                    if(test_product[k].product_id != list.product_id) {
                      amountval = (parseFloat(amountval)+parseFloat(amount)).toFixed(2);
                      test_product.push({
                        index: list.id,
                        rec_item_id:list.rec_item_id,
                        product_id: list.product_id,
                        batch_no: list.batchNo,
                        qty_return: list.qty_onhand,
                        cost_price: list.cost_price,
                        sales_price: list.sales_price,
                        mrp: list.mrp,
                        mfg_date: list.mfg_date,
                        mfg_name: list.mfgName,
                        expiry_date: list.exp_date,
                        return_reason: list.return_reason,
                        cgst:list.cgst,
                        sgst:list.sgst,
                        amount:amount,
                      })
                      this.supp_products[p].refund_amount = amountval;
                      this.stockData[i].checked = true;
                    }
                  }

                } else {
                  test_product.push({
                    index: list.id,
                    rec_item_id:list.rec_item_id,
                    product_id: list.product_id,
                    batch_no: list.batchNo,
                    qty_return: list.qty_onhand,
                    cost_price: list.cost_price,
                    sales_price: list.sales_price,
                    mrp: list.mrp,
                    mfg_date: list.mfg_date,
                    mfg_name: list.mfgName,
                    expiry_date: list.exp_date,
                    return_reason: list.return_reason,
                    cgst:list.cgst,
                    sgst:list.sgst,
                    amount:amount,
                  })
                  this.stockData[i].checked = true;
                  this.supp_products.push({
                    "supp_id" : list.supp_id,
                    "products":test_product,
                    "refund_amount":amount,
                  })
                }
              }

            } else {
              var test_product:any=[];
              test_product.push({
                index: list.id,
                rec_item_id:list.rec_item_id,
                product_id: list.product_id,
                batch_no: list.batchNo,
                qty_return: list.qty_onhand,
                cost_price: list.cost_price,
                sales_price: list.sales_price,
                mrp: list.mrp,
                mfg_date: list.mfg_date,
                mfg_name: list.mfgName,
                expiry_date: list.exp_date,
                return_reason: list.return_reason,
                cgst:list.cgst,
                sgst:list.sgst,
                amount:amount,
              })

              this.supp_products.push({
                "supp_id" : list.supp_id,
                "products":test_product,
                "refund_amount":amount,
              })
              
              this.stockData[i].checked = true;
              console.log("PRODUCT DETAILS ===== "+JSON.stringify(this.supp_products))
            }
            
          } else {
            for (var j = 0; j < this.supp_products.length; j++) {
              if (this.supp_products[j].supp_id == list.supp_id) {
                for(var s=0;s<this.supp_products[j].products.length;s++){
                  if(this.supp_products[j].products[s].product_id == list.product_id) {
                    this.supp_products[j].products.splice(s, 1);
                    this.stockData[i].checked = false;
                    this.stockData[i].return_reason = "";
                    break;
                  }
                }
                if(this.supp_products[j].products.length == 0) {
                  this.supp_products.splice(j,1);
                }
              }
            }
          }
          break;
        }
      }
      console.log("PRODUCT DETAILS ===== "+JSON.stringify(this.supp_products))
    }
  }

  quantity(list) {
    if (list.checked == true) {
      for (var i = 0; i < this.checklistid.length; i++) {
        if (this.checklistid[i].index == list.id) {
          this.checklistid[i].qty_return = list.qty_onhand;
        }
      }
    }
  }

  changeSelected(product){
    for(var i=0;i<this.stockData.length;i++) {
      if(this.stockData[i].rec_item_id == product.rec_item_id){
        this.stockData[i].checked = true;
        this.isAllSelected(product, "select");
      }
    }
  }
  async saveOrder() {
    if (this.supp_products.length != 0) {
      // for(var i=0;i<this.checklistid.length;i++){
      //   console.log(" check id = "+JSON.stringify(this.checklistid[i]));
      //   // ,,,,,
      // }

      var send_data;
      send_data = {
        pharma_id: this.pharmacyID,
        supp_prods: this.supp_products,
        country: "IN"
      }
      var response = await this.serviceAPI.PostData('pharmacontrol/sprt', send_data).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj));
        if (obj.key == 1) {
          this.messageServive.sendMessage("productReturns");
          this.toastr.success("Product return as been intiated successfully");
          for (var q = 0; q < this.stockData.length; q++) {
            if (this.stockData[q].checked == true) {
              this.stockData[q].checked = false;
              this.stockData[q].return_reason = ""
            }
          }
          this.supp_products=[];

        } else {
          this.toastr.error("Unable to save  product returns");
        }
      }

    } else {
      this.toastr.error("Please select atleast one product to return");
    }
  }

  reason() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + this.resonurl, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.reasondata = obj.return_reasons
        }
      )
  }

  printreturndata() {
  }

  navpagelist() {
    this.currentPage = 1;
    this.sno = 1;
    this.pageSize = this.pagelist;
    this.get_stock();
    
    if (this.pagelist == "25") {
      if (this.clientCount > 25) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      }

    } else if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }

    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
  }

  pageLimitnext() {
    if (this.currentPage == this.noOfPages) {
      this.nextPageCount = true;
    } else {
      this.currentPage = parseInt(this.currentPage);
      this.pageSize = this.pagelist;
      this.prevPageCount = false;
      this.sno = (parseInt(this.currentPage) * parseInt(this.pagelist)) + 1;
      console.log("SERIAL NUMBER IS "+this.sno)
      this.get_stock();
      if (this.currentPage == this.noOfPages)
        this.nextPageCount = true;

      this.currentPage = this.currentPage + 1;
    }
  }

  pageLimitPrevious() {
    if (this.currentPage == "1") {
      this.prevPageCount = true;

    } else {
      this.currentPage = this.currentPage - 1;
      this.pageSize = this.pagelist;
      if (this.currentPage != 1)
        this.sno = ((parseInt(this.currentPage) - 1) * parseInt(this.pagelist)) + 1;
      else if (this.currentPage == 1)
        this.sno = 1;

      this.get_stock();
      this.nextPageCount = false;
    }
  }

  numberOfPages() {
    this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
    return Math.ceil(this.clientCount / this.pagelist);
    // console.log("client count "+this.clientCount+"...."+this.pagelist)
    // this.noOfPages = Math.floor(this.clientCount / this.pagelist);
    console.log("client count " + this.noOfPages)

    // return Math.floor(this.clientCount / this.pagelist);
  }

  pagetype(value) {
    if (value == "products") {
      this.productflag = false;
      this.suppliersflag = true;
      this.supp_id = undefined;
      this.supp_name=undefined;
      this.get_stock();

    } else {
      this.productflag = true;
      this.suppliersflag = false;
    }
  }

  getSuppliers() {
    // if(this.supp_name != undefined && this.supp_name.length >2){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID

      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("supplier name  = " + JSON.stringify(obj))
          for (var i = 0; i < obj.supplier_details.length; i++) {
            this.supplierList.push({
              name: obj.supplier_details[i].name,
              supp_id: obj.supplier_details[i].supp_id,
              supp_code: obj.supplier_details[i].supp_code,
              gst_no: obj.supplier_details[i].gst_no
            })
          }
        },
        error => {
          this.toastr.error(Message_data.unabletofetsupl);
        }
      )
  }

  supplier_change() {
    for(var i=0;i<this.supplierList.length;i++) {
      if(this.supplierList[i].name == this.supp_name){
        this.supp_id = this.supplierList[i].supp_id;
        this.gst_no = this.supplierList[i].gst_no;
        this.get_stock();
      }
    }
  }

  clearSupplier() {
    this.supp_name=undefined;
  }
}
