<div class="row">
    <div class="col-12">
        <mdb-card>
            <!-- <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Admin Dashboard</h5>Outpatient
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header> -->
            <mdb-card-body>

                <div class="row" style="margin: 0 auto;">
                    <div class="Details">
                        <!-- <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0"
                            (selectedTabChange)="selectTab($event)">

                            <mat-tab label="outpatient">
                                <ng-template mat-tab-label style="margin-left:10px !important;">
                                    <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px"
                                        height="auto" />
                                    &nbsp; Outpatient
                                </ng-template>




                            </mat-tab>

                            <mat-tab label="inpatient">
                                <ng-template mat-tab-label>
                                    <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px"
                                        height="auto" />
                                    &nbsp; Inpatient
                                </ng-template>
                                <mdb-card style="width: 99% !important;margin-left: 3px;">
                                    <mdb-card-body>
                                        <div class="row mb-3">
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <select disableOptionCentering class="ipcss widthappt"
                                                    (change)="Appointment_type_change()" [(ngModel)]="appointment_type"
                                                    style="border: 1px solid black !important;">
                                                    <option value="Today">Today</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Fort Night">Fort Night</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="3 Months">3 Months</option>
                                                    <option value="6 Months">6 Months</option>
                                                    <option value="Yearly">Yearly</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">From :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="from_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">To :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="to_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-7">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Appointments</h2>
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_completedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Open</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_openApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Confirmed</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_confirmedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Inpatients</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_inpatApp}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Diagnostic Order</h2>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">15</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">12</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Pending</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">3</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" hidden>
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Mode of payments</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="modeOfPayCanvasIpAdm" width="361"
                                                                    height="175" style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospbillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Hospital Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{hosp_totalIpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmabillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="card-container">
                                                        <h2 class="card-heading">Indents</h2>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="indent-card-container-head">{{total_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{complete_pos_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Pending</h2>
                                                                <div class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{pending_indents}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagbillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Diagnosis Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{diag_totalIpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div class="col-6" *ngIf="!isBar_pharma_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_pharma_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="!isBar_hosp_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_hosp_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div class="col-6" *ngIf="!isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>          
                                                                                                 
                                            </div>
                                        </div>                                        
                                    </mdb-card-body>
                                </mdb-card>
                            </mat-tab>
                        </mat-tab-group> -->


                        <mdb-card style="width: 99% !important;margin-left: 3px;">
                            <mdb-card-body>
                                <div class="row mb-3">
                                    <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                        style="margin-top: 5px;width: auto;">
                                        <mat-label class="matlabel"
                                            style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <select disableOptionCentering class="ipcss widthappt"
                                            (change)="Appointment_type_change()" [(ngModel)]="appointment_type"
                                            style="border: 1px solid black !important;">
                                            <option value="Today">Today</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Fort Night">Fort Night</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="3 Months">3 Months</option>
                                            <option value="6 Months">6 Months</option>
                                            <option value="Yearly">Yearly</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                        style="margin-top: 5px;width: auto;">
                                        <mat-label class="matlabel"
                                            style="font-size: 15px; font-weight: 600;">From :</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <input type="date" [(ngModel)]="from_date" class="ipcss widthappt"
                                            [disabled]="true">
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                        style="margin-top: 5px;width: auto;">
                                        <mat-label class="matlabel"
                                            style="font-size: 15px; font-weight: 600;">To :</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <input type="date" [(ngModel)]="to_date" class="ipcss widthappt"
                                            [disabled]="true">
                                    </div>
                                </div>
                                <div class="container-fluid" style="padding:0">
                                    <div class="row" hidden>
                                        <div class="col-7">
                                            <div class="card-container mb-3">
                                                <h2 class="card-heading">Appointments</h2>
                                                <div class="row">
                                                    <div class="col-3">
                                                        <h2 class="card-heading-2">Total</h2>
                                                        <div
                                                            class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="indent-card-container-head">{{total_docApps}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h2 class="card-heading-2">Completed</h2>
                                                        <div
                                                            class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="card-container-head">{{doc_completedApp}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h2 class="card-heading-2">Open</h2>
                                                        <div
                                                            class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="card-container-head">{{doc_openApp}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h2 class="card-heading-2">Confirmed</h2>
                                                        <div
                                                            class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="indent-card-container-head">{{doc_confirmedApp}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <h2 class="card-heading-2">Inpatients</h2>
                                                        <div
                                                            class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="indent-card-container-head">{{doc_inpatApp}}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="card-container mb-3">
                                                <h2 class="card-heading">Diagnostic Order</h2>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <h2 class="card-heading-2">Total</h2>
                                                        <div
                                                            class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="indent-card-container-head">{{total_diagApps}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <h2 class="card-heading-2">Completed</h2>
                                                        <div
                                                            class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="card-container-head">{{diag_completedApp}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <h2 class="card-heading-2">Open</h2>
                                                        <div
                                                            class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                            <h2 class="card-container-head">{{diag_openApp}}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" hidden>
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Mode of payments</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="modeOfPayCanvasIpAdm" width="361"
                                                            height="175" style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                          <div class="ten">
                                            <h1>Hospital bills</h1>
                                          </div>
                                        <div class="col-6">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bills</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="hospBillsOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bill amount</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="hospbillAmtOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 bill-amt-card">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Total Amount</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important; color: #000;">
                                                        {{hosp_totalOpBillAmt}}</h3>
                                                </div>
                                            </div>
                                        </div> 
                                          <div class="ten">
                                            <h1>Pharmacy bills</h1>
                                          </div>
                                        <div class="col-6">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bills</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="pharmBillsOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bill Amount</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="pharmabillAmtOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="card-container">
                                                <h2 class="card-heading">Indents</h2>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2 class="card-heading-2">Total</h2>
                                                        <div class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                            style="padding: 8px;">
                                                            <h2 class="indent-card-container-head">{{total_indents}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <h2 class="card-heading-2">Completed</h2>
                                                        <div class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center"
                                                            style="padding: 8px;">
                                                            <h2 class="card-container-head">{{complete_pos_indents}}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <h2 class="card-heading-2">Pending</h2>
                                                        <div class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center"
                                                            style="padding: 8px;">
                                                            <h2 class="card-container-head">{{pending_indents}}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ten">
                                            <h1>Diagnosis bills</h1>
                                          </div>
                                        <div class="col-6">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bills</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="diagBillsOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Bill amount</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="diagbillAmtOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 bill-amt-card">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Total Amount</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important; color: #000;">
                                                        {{diag_totalOpBillAmt}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="!isBar_pharma">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="pharmaBillsPaytypeOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="isBar_pharma">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="pharmaBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="!isBar_hosp">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Hospital bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="hospBillsPaytypeOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="isBar_hosp">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Hospital bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="hospBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="col-6" *ngIf="!isBar_diag">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="diagBillsPaytypeOpAdm" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="isBar_diag">
                                            <div class="card-container" style="height: 340px;">
                                                <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                <div class="graph-container mb-3">
                                                    <div>
                                                        <canvas id="diagBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                            style="display: block; "></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                          
                                    </div>

                                    <div class="row">
                                            <div class="col-9">
                                                <div class="row">
                                                <div class="col-7">
                                                    <div class="card-container mb-3">
                                                        <div class="header_lable_chart">
                                                            Appointments
                                                        </div>
                                                            <div class="row">
                                                                <div class="col-6" style="padding:0;margin-top:auto;margin-bottom:auto">
                                                                    <div class="grid-container">
                                                                      
                                                                       <div class="item1 bg-image" style="display:flex;flex-direction:column;justify-content: center">
                                                                        <span class="app_span_heading" style="font-size:1.5rem">Total</span> <p style="font-size:1.5rem;display:block">{{this.appoint_total_app}}</p>
                                                                       
                                                                        </div>
                                                                        <div class="item2 box" style ="background-color:#129c6e">
                                                                            <span class="app_span_heading">New</span> <span class="app_span_data"> {{this.appoint_new_app}}</span>
                                                                        </div>
                                                                       <div class="item3 box" style ="background-color:#8b75d7">
                                                                        <span class="app_span_heading">Follow up</span> <span class="app_span_data" >{{this.appoint_followup_app}}</span></div>
                                                                       <div class="item4 box" style ="background-color:#3d9736">
                                                                        <span class="app_span_heading">Completed</span> <span class="app_span_data">{{this.appoint_completed}}</span></div>
                                                                       <div class="item5 box" style ="background-color:#ff6178">
                                                                        <span class="app_span_heading">Not visited</span> <span class="app_span_data">{{appoint_not_visited}}</span></div>
                                                                       <div class="item6 box" style ="background-color:#febc3a">
                                                                        <span class="app_span_heading">Pending</span> <span class="app_span_data">{{appoint_pending}}</span></div>
                                                                        <div class="item7 box" style ="background-color:#1f0f57">
                                                                            <span class="app_span_heading">Discounted</span> <span class="app_span_data">{{appoint_non_discount}}</span></div>
                                                                        <div class="item8 box" style ="background-color:#0081e2">
                                                                            <span class="app_span_heading">Non-Discounted</span> <span class="app_span_data">{{appoint_discounted}}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="image-container">
                                                                    <canvas id="appointments_chart" class="attendance_bg"></canvas>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <div class="card-container mb-3">
                                                        <div class="header_lable_chart">
                                                            Diagnostic order
                                                        </div>
                                                       
                                                            <div style="width:30%;margin-left:auto;height: 24px;" >
                                                            </div>
                                                            <div class="image-container chart_width" >
                                                                <canvas id="diagnosticorder_chart"  class="attendance_bg"></canvas>
                                                            </div>
                                                            <div class="payment_label_div">
                                                                    <div style='display:flex;flex-direction:row'>
                                                                    <div >
                                                                    <img src="../../../assets/img/blue.svg" alt="Icon" style="width: 18px;height: 18px;" />
                                                                    <span class="span_data">{{this.diagnostic_total}}</span>
                                                                    <span class="payment_label_type">
                                                                        
                                                                        Total</span>
                                                                    </div>
                                                                    </div>

                                                                    <div >
                                                                        
                                                                        <div >
                                                                            <img src="../../../assets/img/green.svg" alt="Icon" style="width: 18px;height: 18px;"/>
                                                                        <span class="span_data">{{this.diagnostic_complete}}</span>
                                                                        <span class="payment_label_type">
                                                                            
                                                                            Complete</span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        
                                                                        <div>
                                                                        <img src="../../../assets/img/red.svg" alt="Icon" style="width: 18px;height: 18px;" />
                                                                        <span class="span_data">{{this.diagnostic_pending}}</span>
                                                                        <span class="payment_label_type">
                                                                            
                                                                            Pending</span>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-7">
                                                        <div class="card-container mb-3">
                                                            <div class="header_lable_chart">
                                                                Billing
                                                            </div>
                                                            <div style="width:30%;margin-left:auto">
                                                            <select disableOptionCentering class="ipcss widthappt"
                                                            (change)="select_dept_type()" [(ngModel)]="bill_dept_type">
                                                            <option value="hospital">Hospital</option>
                                                            <option value="pharmacy">Pharmacy</option>
                                                            <option value="diagnosis">Diagnosis</option>
                                                            </select>
                                                            </div>
                                                            <div class="image-container">
                                                                    <canvas id="billing_chart" class="attendance_bg"></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="card-container mb-3">
                                                            <div class="header_lable_chart">
                                                                Payment type
                                                            </div>
                                                            <div style="width:30%;margin-left:auto">
                                                                <select disableOptionCentering class="ipcss widthappt"
                                                                (change)="select_dept_payment()" [(ngModel)]="dept_payment">
                                                                <option value="hospital">Hospital</option>
                                                                <option value="pharmacy">Pharmacy</option>
                                                                <option value="diagnosis">Diagnosis</option>
                                                                </select>
                                                            </div>
                                                            <div class="image-container" style="width: 100%; max-width: 250px; margin: 0 auto;">
                                                                    <canvas id="payment_chart" class="attendance_bg"></canvas>
                                                            </div>
                                                            <div class="payment_label_div">
                                                                <div >
                                                                    <span class="payment_label" style="background-color:#c3a12e"></span>
                                                                    <span class="payment_label_type">Cash</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="payment_label" style="background-color:#dc2928eb"></span>
                                                                        <span class="payment_label_type">Card</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="payment_label" style="background-color:#1c4f8d"></span>
                                                                        <span class="payment_label_type">UPI</span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="payment_label" style="background-color:#512c7c"></span>
                                                                            <span class="payment_label_type">Cheque</span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="payment_label" style="background-color:#0b4c26"></span>
                                                                            <span class="payment_label_type">EMI</span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="payment_label" style="background-color:#c1194c"></span>
                                                                            <span class="payment_label_type">Insurance</span>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-container  mb-3">
                                                            <div class="header_lable_chart">
                                                                Medicines
                                                            </div>
                                                            <div class="table-responsive" #scrollContainer (scroll)="onScroll($event)">
                                                            <table class="headerfixed">
                                                                <thead>
                                                                    <tr>
                                                                        <th style="width:5%">S.NO</th>
                                                                        <th style="width: 15%">Name</th>
                                                                        <th style="width: 7%">Batch</th>
                                                                        <th>Expiry date</th>
                                                                        <th>Received qty</th>
                                                                        <th>Sold qty</th>
                                                                        <th>Return to supplier</th>
                                                                        <th>Avaliable qty</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let data of medicinearray;let i=index">
                                                                        <td >{{i+1}}</td>
                                                                        <td>{{data.name}}</td>
                                                                        <td>{{data.batch}}</td>
                                                                        <td [ngStyle]="{color:data.expiry_color}">{{data.expiryDate}}</td>
                                                                        <td>{{data.receivedQty}}</td>
                                                                        <td>{{data.soldQty}}</td>
                                                                        <td>{{data.returnToSupplier}}</td>
                                                                        <td>{{data.availableQty}}</td>
                                                                    </tr>
                                                                </tbody>    
                                                            </table>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="col-3">
                                                    <div class="col-12">
                                                        <div class="card-container mb-3">
                                                            <div class="header_lable_chart">
                                                                Revenue
                                                            </div>
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                <div class="image-container">
                                                                <canvas id="revenue_chart" class="attendance_bg"></canvas>
                                                                </div>
                                                                </div>
                                                                    <div class="col-4 align_element">
                                                                    <p>
                                                                    <span class="span_data" style="color:#6a4ef6">{{this.Revenue_Total}}</span>                                                                                                                  
                                                                    <span class="span_heading">Total</span>
                                                                    </p>
                                                                    <p>
                                                                      <span class="span_data" style="color:#c697ff">{{this.Revenue_Expenses}}</span>
                                                                      <span class="span_heading">Expenses</span>
                                                                    </p>
                                                                    <p>
                                                                    <span class="span_data" style="color:#e0d0ff">{{this.Revenue_Earnings}}</span>
                                                                    <span class="span_heading">Earnings</span>
                                                                    </p>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-container cont_rev mb-3">
                                                            <div class="header_lable_chart">
                                                                Attendance
                                                            </div>
                                                            <div>
                                                            <p class="attendance_present">{{this.emppresent}}<br>Present</p>
                                                            </div>
                                                            <div class="image-container">
                                                                <img src="../../../assets/img/attendance_svgpng.png" class="attendance_bg">
                                                            </div>
                                                            <div>
                                                                <p class="attendance_total">{{this.totalemp}} Total</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-container cont_rev mb-3">
                                                            <div class="header_lable_chart">
                                                                Inpatients
                                                            </div>

                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <div class="image-container">
                                                                            <canvas id="inpatients_chart" class="attendance_bg" ></canvas>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 align_element" style="padding:0">
                                                                            <p>
                                                                            <span class="span_data" style="color:#16C47F;text-align: center;">{{this.inpatients}}</span>                                                                                                                  
                                                                            <span class="span_heading change_size">In-patients</span>
                                                                            </p>
                                                                            <p>
                                                                            <span class="span_data" style="color:#6a4ef6;text-align: center;">{{this.inpatient_noninsure}}</span>                                                                                                                  
                                                                            <span class="span_heading change_size">Non-Insurance</span>
                                                                            </p>
                                                                            <p>
                                                                              <span class="span_data" style="color:#ffb059;text-align: center;">{{this.inpatient_insure}}</span>
                                                                              <span class="span_heading change_size">Insurance</span>
                                                                            </p>
                                                                            <p>
                                                                            <span class="span_data" style="color:#f54b4b;text-align: center;">{{this.inpatient_mlc}}</span>
                                                                            <span class="span_heading change_size">MLC</span>
                                                                            </p>
                                                                    </div>
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-container mb-3">
                                                            <div class="header_lable_chart">
                                                                Stock value
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-8">
                                                            <div class="image-container">
                                                            <canvas id="stockvalue_chart" class="attendance_bg"></canvas>
                                                            </div>
                                                            </div>
                                                                <div class="col-4 align_element">
                                                                <p>
                                                                    
                                                                <span class="span_data" style="color:#99c84e">{{this.stockval_stock}}</span>                                                                                                                  
                                                                <span class="span_heading change_size">Total stock</span>
                                                                </p>
                                                                <p>
                                                                  <span class="span_data" style="color:#6d9c40">{{this.stockval_purchase}}</span>
                                                                  <span class="span_heading change_size">Purchased</span>
                                                                </p>
                                                                <p>
                                                                <span class="span_data" style="color:#365126">{{this.stockval_sale}}</span>
                                                                <span class="span_heading change_size">Sales</span>
                                                                </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-container mb-3">
                                                            <div class="header_lable_chart">
                                                                Ambulance calls
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-4 align_element">
                                                                    <p>
                                                                    <span class="span_data" style="color:#6a4ef6;text-align:center">{{this.ambulancevalue_total}}</span>                                                                                                                  
                                                                    <span class="span_heading change_size">Total</span>
                                                                    </p>
                                                                    <p>
                                                                      <span class="span_data" style="color:#c595ff;text-align:center">{{this.ambulancevalue_completed}}</span>
                                                                      <span class="span_heading change_size">Completed</span>
                                                                    </p>
                                                                    <p>
                                                                    <span class="span_data" style="color:#a275ff;text-align:center">{{this.ambulancevalue_pending}}</span>
                                                                    <span class="span_heading change_size">pending</span>
                                                                    </p>
                                                                    </div>    
                                                                    <div class="col-8">
                                                                    <div class="image-container">
                                                                    <canvas id="ambulance_chart" class="attendance_bg"></canvas>
                                                                    </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>