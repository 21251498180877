<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Outpatient Bill Details</h5>
          </div>
          <div class="headerButtons">     
            <a (click)="back()">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" />
            </a>&nbsp;
            <a (click)="history()" *ngIf="viewbutton">
              <img src="../../../assets/ui_icons/buttons/History_Bill_icon.svg" class="saveimgbtn_inpatinfo" />
            </a>&nbsp;
            <a *ngIf="printbutton" (click)="print()">
              <img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
            </a>         
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="padding-top: 0;">
        <div *ngFor="let billprint of billingDetailList">         
          <div class="row chwidth">
            <div class="col-12">
              <div class="cover_div" style="font-size: 13px !important;">
                <div class="header_lable">
                   Patient details
                </div>
                <div class="content_cover">                              
                  <div class="row">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{billprint.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="profileimage">
                    </div>
                    <div class="col-10">
                      <div class="row">                                                                              
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>MR No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.uhid}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Patient</b></td>
                                <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.client_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Age/Gender</b></td>
                                <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billprint.age}} <span *ngIf="billprint.gender != '' && billprint.gender != undefined">/</span> {{billprint.gender}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3" *ngIf="billprint.mobile != undefined && billprint.mobile != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Mobile</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.mobile}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>      
                        <div class="col-3" *ngIf="billprint.dr_name != '' && billprint.dr_name != undefined">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Doctor</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.dr_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> 
                        <!-- <div class="col-3" *ngIf="inpatientflag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 100px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Inpatient Id</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inpatientid}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> -->
                        <div class="col-3" *ngIf="!inpatientflag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Visit Id</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inpatientid}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- <div class="col-3" *ngIf="inpatientflag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 100px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>DOA</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billprint.admitteddate | date : "dd-MM-yyyy"}} {{billprint.admittedtime}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                    -->
                        <!-- <div class="col-3" *ngIf="billprint.wardname != undefined && billprint.wardname != '' && inpatientflag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 100px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Ward/Bed</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.wardname}} <span *ngIf="billprint.bedno != undefined && billprint.bedno != ''">/</span> {{billprint.bedno}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>   -->
                        <!-- <div class="col-3" *ngIf="surgery != undefined && surgery != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 100px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Surgery name</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{surgery}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                        -->
                        <div class="col-3" *ngIf="billId != undefined && billId != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billId}} 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> 
                        <div class="col-3" *ngIf="billprint.created_date != undefined && billprint.created_date != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill Date/Time</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.created_date}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>       
                        <div class="col-3" [hidden]="mainBalanceFlag">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill Amt (र)</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billAmount}} 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>   
                        <div class="col-3" *ngIf="credit_type != undefined && credit_type != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Credit bill</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{credit_type}} 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                                 
                        <div class="col-6">
                          <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                    <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                      <b>Address</b></td>
                                    <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                      {{billprint.caddress1}} {{billprint.caddress2}} {{billprint.clocation}} {{ billprint.clientcity}} {{billprint.clestare}}
                                  </td>
                                </tr>                 
                              </tbody>
                          </table>
                        </div>                                              
                      </div>                 
                    </div>                    
                  </div>
                </div>
              </div>          
            </div>
          </div>

          <div class="row">
            <div class="col-12" [hidden]="billingPrintViewFlag" style="font-family: Arial, Helvetica, sans-serif;">
              <p  *ngIf="billingPrintView.length !=0"
                class="tervys_heading" style="font-weight: 600;">Bill particulars</p>
                <div class="table-responsive dig_table_overflow">
                  <table id="card_tbl" *ngIf="billingPrintView.length" class="table table-nowrap table-sm" ><!--table-hover-->
                    <thead class="thvalues">
                      <tr style="height: 25px;">
                        <th style="width: 5% !important;">Sno</th>
                        <th style="width: 10% !important;">Service</th>
                        <th style="width: 5% !important;" [hidden]="paytypreq">Payment type</th>
                        <th style="width: 3% !important;">Rate</th>
                        <th style="width: 3% !important;" [hidden]="discount_flag">Discount(%)</th>
                        <th style="width: 3% !important;" [hidden]="!bill_qty_flag || !inpatient_flag">Quantity</th>
                        <th style="width: 3% !important;" [hidden]="bill_qty_flag && discount_flag && !inpatient_flag">Amount</th>                              
                      </tr>
                    </thead>
                    <tbody *ngIf="!grouping_flag">
                      <tr *ngFor="let person of billingPrintView">
                        <td class="billlistcenter"> {{person.sno}} </td>
                        <td class="billlistleft"> {{person.bill_type}} </td>
                        <td class="billlistleft" [hidden]="paytypreq"> {{person.pay_type}} </td>
                        <td class="billlistright" [hidden]="!bill_qty_flag"> {{person.amount_txt}} </td>
                        <td class="billlistright" [hidden]="bill_qty_flag"> {{person.amount_txt}} x {{person.quantity}}</td>
                        <td class="billlistright" [hidden]="discount_flag"> {{person.concession}} </td>
                        <td class="billlistright" [hidden]="!bill_qty_flag || !inpatient_flag"> {{person.quantity}} </td>
                        <td class="billlistright" [hidden]="multiply_flag"> {{person.multiply_value}} </td>                 
                        <td class="billlistright" [hidden]="discount_flag"> {{person.fee}} </td>                                          
                      </tr>
                    </tbody>
                    <tbody *ngIf="grouping_flag">
                      <tr *ngFor="let charge of categoryChargeList">
                        <td colspan="8" *ngIf="charge.category_flag"> 
                          <div>
                            <div>
                              <p class="tervys_heading1 col-12"><b
                                style="text-transform: capitalize;">{{charge.category}}</b></p>
                              <table class="table" style="margin-bottom: 0;">
                                <tbody>
                                  <tr *ngFor='let person of charge.charges'>
                                    <td class="billlistcenter" style="width: 5% !important;">{{person.sno}}</td>
                                    <td class="billlistleft" style="width: 10% !important;">{{person.bill_type}}</td>
                                    <td class="billlistleft" style="width: 5% !important;" [hidden]="paytypreq">{{person.pay_type}}</td>
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag">{{person.amount_txt}}</td>
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="bill_qty_flag">{{person.amount_txt}} x {{person.quantity}}</td>
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag">{{person.concession}}</td>
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag || !inpatient_flag">{{person.quantity}}</td>
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="multiply_flag">{{person.multiply_value}}</td>                 
                                    <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag">{{person.fee}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>           
          </div>

          <div [hidden]="treatementPlanFlag">
            <p class="casesheet_para">Treatement plan</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatementPlanFlag">
            <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th class="tbl_txt_center">Estimate</th>
                  <th class="tbl_txt_center">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of estimatePrintData let i = index">
                  <td class="billlistleft">{{person.treatment_desc}}</td>
                  <td class="billlistright">{{person.estimate}}</td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
          <div class="row">
            <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-9">
              <div class="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <table  style="margin-top: 10px;font-family: Arial, Helvetica, sans-serif !important;"> 
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;font-size: 12px;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;font-size: 12px;text-align: right;">{{pay.amount}}</td>
                  </tr>
                </table>
              </div>
            </div>           
      
            <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-3" style="margin-top: 10px;">
              <div class="row ">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="cgstFlag">
                  <mat-label class="matlabel ">CGST ({{billprint.cgst}} %) र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="cgstFlag">
                  <span class="billlistright"> {{cgst}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="sgstFlag">
                  <mat-label class="matlabel ">SGST ({{billprint.sgst}} %) र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="sgstFlag">
                  <span class="billlistright"> {{sgst}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="prevBalanceFlag">
                  <mat-label class="matlabel"> Previous balance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="prevBalanceFlag">
                  <span class="billlistright">{{prevBalance}}</span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalBillAmount == billAmount">
                  <mat-label class="matlabel "> Total र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="totalBillAmount == billAmount">
                  <span class="">{{totalBillAmount}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="discount_amount == 0">
                  <mat-label class="matlabel "> Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="discount_amount == 0">
                  <span class="">{{discount_amount}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel "> Bill amount र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="totalFlag">
                  <span class="">{{billAmount}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="advanceFlag">
                  <mat-label class="matlabel">Advance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" [hidden]="advanceFlag">
                  <span class="">{{advancevalue}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" >
                  <mat-label class="matlabel">Paid र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright" >
                  <span class="">{{paid}} </span>
                </div>

                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight">
                  <mat-label class="matlabel">Balance र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright paddingright">
                  <span class="">{{billprint.balance}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="true">
          <div #billprint id="billprint">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <div>
                  <table style="width: 100%;">
                      <tr>
                        <td style="display: flex;justify-content: center;">
                          <div>
                            <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                          </div>
                          <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                            <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                            <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                            <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                            <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                          </div>
                        </td>
                      </tr>
                  </table>
                </div>
                <table style="margin-left: 50px;width: 100%;">
                  <tr>
                    <td colspan="2">
                      <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br *ngIf="billprint.inpatid != undefined" />
                          <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                          <br />
                          <span><strong> MR.No : </strong>{{billprint.uhid}}</span>
                          <br />
                          <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                          <span><strong>Bill number : </strong>{{billId}} </span><br/>
                          <span *ngIf="credit_type != undefined"><strong>Credit bill : </strong>{{credit_type}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;vertical-align:top">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 != undefined || this.clientAddress2 != undefined">
                          <span><strong>Address</strong></span>
                          <br />
                          <span> {{billprint.caddress1}} {{billprint.caddress2}}</span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                        </div>
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 == undefined && this.clientAddress2 == undefined">
                          <span><strong>Address</strong></span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>                      
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                
                <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 800px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="row">
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Particulars</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_pay_flag"><b>Payment type</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Rate</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                            (%)</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"> <b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billingPrintView">
                        <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                        data-th="sno">{{billprinttable.sno}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.bill_type}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" [hidden]="bill_pay_flag">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee" [hidden]="bill_cons_flag">{{billprinttable.amount_txt}}</td>
                          <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt" >{{billprinttable.fee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="main_class row">
                    <table style="border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                        <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                      </tr>
                      <tr *ngFor="let estimate of estimatePrintData">
                        <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                          data-th="Plan">{{estimate.treatment_desc}}</td>
                        <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Estimate">{{estimate.estimate}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              
                <div style="width: 100%;">
                  <table>
                    <tr>
                      <td style="vertical-align: top; width: 68%;">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                          </tr>
                        </table>
                      </td>
                      <td style="width: 32%;">
                        <table  style="float: right;margin-left: 40px !important;">
                          <tr [hidden]="totalFlag">                         
                            <th style="text-align: left;">Total (र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                             {{totalBillAmount}} </td>
                          </tr>
                          <tr [hidden]="cgstFlag">                           
                            <th style="text-align: left;"><strong>Central GST</strong> % </th>
                            <td style="text-align: right; width: 100px;" data-th="Central GST"
                              class="fee_txt">
                              {{cgst}}</td>
                          </tr>
                          <tr [hidden]="sgstFlag">                           
                            <th style="text-align: left;"><strong>State GST</strong> % </th>
                            <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                              {{sgst}}</td>
                          </tr>                    
                          <tr [hidden]="prevBalanceFlag">                           
                            <th style="text-align: left;">Previous balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">{{prevBalance}}</td>
                          </tr>
                          <tr [hidden]="totalFlag">                         
                            <th style="text-align: left;">Bill amount (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Bill amount"
                              class="fee_txt">
                              {{billAmount}}</td>
                          </tr>
                          <tr [hidden]="advanceFlag">                          
                            <th style="text-align: left;">Advance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{advancevalue}}</td>
                          </tr>
                          <tr [hidden]="advanceFlag">                   
                            <th style="text-align: left;">Paid (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{paid}}</td>
                          </tr>
                          <tr>                           
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                              {{billprint.balance}}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>                
                </div>
              </div>             
            </div>               
          </div>

          <div #noheader id="noheader">
            <table style="border:'0';width:100%">  
              <thead>  
               <tr>  
                <th style="width:100%;height: 100px;"></th>  
              </tr>  
             </thead>  
             <tfoot> 
              <tr>  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;height: 100px;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
             <tbody>  
               <tr>  
                 <td width="100%">  
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">                                                            
                      <table style="margin-left: 50px;width: 100%;margin-top: 200px;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> MR.No : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span><br/>
                                <span *ngIf="credit_type != undefined"><strong>Credit bill : </strong>{{credit_type}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 != undefined || this.clientAddress2 != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress1}} {{billprint.caddress2}}</span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                               
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 == undefined && this.clientAddress2 == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                             
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                     
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                              <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBill}}</td> 
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>           
                  </div>                              
                 </td>
               </tr>
             </tbody>
            </table>
          </div>

          <div [hidden]="true" #printbanner id="printbanner">
            <table style="border:'0';width:100%">              
             <thead>
              <tr>
                <th style="width:100%;">                                 
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div style="width: 100%; height: 205px !important;"> 
                      <div style="width: 100%;">
                        <img alt="image" src={{hospitalLogo}}   [style]="printlogostyle"> 
                      </div>                                                             
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{client_name}} </td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{caddress1}} {{caddress2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{age}} / {{gender}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{clocation}} {{clientcity}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td>: {{dr_name}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{clestare}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{created_date}}</td>
                          </tr>
                          <tr *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                                                   
                          </tr>                         
                          <tr *ngIf="credit_type != undefined && credit_type != ''">
                            <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                            <td>: {{credit_person}}</td>
                          </tr>                        
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>
                            </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td> 
                          </tr>                         
                        </tbody>
                      </table>
                    </div>
                  </div>               
                </th>
              </tr>
             </thead>
             <tbody >  
               <tr>  
                 <td width="100%">  
                  <p [style]="p_tag_style"><b>Bill/Receipt</b></p>
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                            <thead>
                              <tr>
                                <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                                <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                              </tr>
                            </thead>
                            <tbody *ngIf="!grouping_flag">
                              <tr *ngFor="let billprinttable of billingPrintView">
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="sno">{{billprinttable.sno}}</td>
                                <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type">{{billprinttable.bill_type}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                <td [style]="amount_td_style"
                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                              </tr>
                            </tbody> 
                            <tbody *ngIf="grouping_flag">
                              <tr *ngFor="let charge of categoryChargeList">
                                <td colspan="8" *ngIf="charge.category_flag"> 
                                  <div>
                                    <div>
                                      <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                text-align: center !important;"><b
                                        style="text-transform: capitalize;">{{charge.category}}</b></p>
                                      <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;">
                                        <tbody>
                                          <tr *ngFor='let billprinttable of charge.charges'>
                                            <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                              data-th="sno">{{billprinttable.sno}}</td>
                                            <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                              data-th="Type">{{billprinttable.bill_type}}</td>
                                            <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                              data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                            <td [style]="amount_td_style"
                                              data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                            <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                              data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                            <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                              data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                            <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                              data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                            <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                              data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                            <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                              data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>                                                    
                          </table>
                        </div>
                      </div>
                      <div [hidden]="treatementPlanFlag" style="width: 800px;margin-right: 20px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>                   
                      <div style="width: 100%;">
                        <table  style="margin-bottom: -20px;">
                          <tr>
                            <td style="vertical-align: top; width: 26%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                </tr>
                              </table>            
                            </td>                           
                            <td style="vertical-align: top;width: 31%;">
                              <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="discount_amount == 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                              <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="discount_amount === 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                            </td>                        
                          </tr>
                        </table>
                      </div>
                      <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                        <div class="row">
                          <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid != 0">
                            <tr>
                              <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{billprint.numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>                           
                 </td>
               </tr>
             </tbody>
             <tfoot class="footer">
              <tr>
                <td width="100%">
                  <table style="border: 0; width: 100%;">
                    <tr>
                      <td style="width: 100%; height: 100px;">
                        <div class="container-fluid">
                          <div class="row d-flex flex-row justify-content-center">
                            <table style="width: 100%;">
                              <tr>
                                <td style="width: 90%; text-align: center;">
                                  <div class="row">
                                    <div class="d-flex flex-row justify-content-center align-items-end">
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;"></p>
                                          <p></p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                          <p style="margin-bottom: 3px !important;"></p>
                                          <p><strong></strong></p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: right; margin-right: 30px;">
                                          <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                          <p><strong>Created by</strong></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
             </tfoot>   
            </table>
          </div>

          <div [hidden]="true" #printnoheader id="printnoheader">
            <table style="border:'0';width:100%;">  
              <thead>
                <tr>
                  <th style="width:100%;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div [style]="nobanner_style">
                        <div style="height: 5px !important;"></div>
                        <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                          <tbody>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                              <td>: {{client_name}} </td>
                              <td style="padding-left: 5px;"><strong>Address</strong></td>
                              <td>: {{caddress1}} {{caddress2}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                              <td>: {{age}} / {{gender}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{clocation}} {{clientcity}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                              <td>: {{dr_name}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{clestare}} </td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                              <td>: {{billId}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                              <td>: {{created_date}}</td>                     
                            </tr>
                            <tr *ngIf="surgery != undefined && surgery != ''">
                              <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                              <td>: {{surgery}}</td>                          
                            </tr>
                            <tr *ngIf="credit_type != undefined && credit_type != ''">
                              <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                              <td>: {{credit_person}}</td>                            
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td>
                              </td> 
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td *ngIf="barcode_flag">
                                <img [style]="barcode_style"
                                  src={{barcode_url}} /></td>
                            </tr>          
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%;" >  
                <tr>  
                  <td width="100%">  
                    <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                    <div class="bill_border">
                      <div *ngFor="let billprint of billingDetailList">
                        <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                              <thead>
                                <tr>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                  <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                                  <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                  <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                  <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                                </tr>
                              </thead>
                              <tbody *ngIf="!grouping_flag">
                                <tr *ngFor="let billprinttable of billingPrintView">
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="sno">{{billprinttable.sno}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type">{{billprinttable.bill_type}}</td>
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                  <td [style]="amount_td_style"
                                    data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                  <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                </tr>
                              </tbody> 
                              <tbody *ngIf="grouping_flag">
                                <tr *ngFor="let charge of categoryChargeList">
                                  <td colspan="8" *ngIf="charge.category_flag"> 
                                    <div>
                                      <div>
                                        <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                  text-align: center !important;"><b
                                          style="text-transform: capitalize;">{{charge.category}}</b></p>
                                        <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;">
                                          <tbody>
                                            <tr *ngFor='let billprinttable of charge.charges'>
                                              <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                data-th="sno">{{billprinttable.sno}}</td>
                                              <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type">{{billprinttable.bill_type}}</td>
                                              <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                              <td [style]="amount_td_style"
                                                data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                              <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                              <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                              <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                              <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                              <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>                                                     
                            </table>
                          </div>
                        </div>
                        <div [hidden]="treatementPlanFlag" style="width: 800px;margin-right: 20px;">
                          <h3 class="heading">Bill particulars</h3>
                          <div class="main_class row">
                            <table style="border: 1px solid black;border-collapse: collapse;">
                              <tr>
                                <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                                <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                              </tr>
                              <tr *ngFor="let estimate of estimatePrintData">
                                <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Plan">{{estimate.treatment_desc}}</td>
                                <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Estimate">{{estimate.estimate}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>                     
                        <div style="width: 100%;">
                          <table style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;">
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>              
                              </td>                           
                              <td style="vertical-align: top;width: 31%;">
                                <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                  <tr [hidden]="cgstFlag">
                                    <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">
                                      {{cgst}}</td>
                                  </tr>
                                  <tr [hidden]="sgstFlag">
                                    <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                      {{sgst}}</td>
                                  </tr>        
                                  <tr [hidden]="prevBalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevBalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == billAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount == 0">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="billAmount == undefined || billAmount == 0">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{billAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advancevalue == 0">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advancevalue}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paid}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{billprint.balance}}</td>
                                  </tr> 
                                </table>
                                <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                  <tr [hidden]="cgstFlag">
                                    <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">
                                      {{cgst}}</td>
                                  </tr>
                                  <tr [hidden]="sgstFlag">
                                    <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                      {{sgst}}</td>
                                  </tr>        
                                  <tr [hidden]="prevBalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevBalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == billAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount == 0">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="billAmount == undefined || billAmount == 0">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{billAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advancevalue == 0">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advancevalue}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paid}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{billprint.balance}}</td>
                                  </tr> 
                                </table>
                              </td>                        
                            </tr>
                          </table>
                        </div>                                              
                        <div style="width: 100%;margin-left: 20px;margin-top: 15px;">
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid != 0">
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{billprint.numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </td>
                </tr>
              </tbody>  
              <tfoot class="footer">
                <tr>
                  <td width="100%">
                    <table style="border: 0; width: 100%;">
                      <tr>
                        <td style="width: 100%; height: 100px;">
                          <div class="container-fluid">
                            <div class="row d-flex flex-row justify-content-center">
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 90%; text-align: center;">
                                    <div class="row">
                                      <div class="d-flex flex-row justify-content-center align-items-end">
                                        <div class="col-4">
                                          <div style="text-align: center;">
                                            <p style="margin-bottom: 3px !important;"></p>
                                            <p></p>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div style="text-align: center;">
                                            <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                            <p style="margin-bottom: 3px !important;"></p>
                                            <p><strong></strong></p>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div style="text-align: right; margin-right: 30px;">
                                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                            <p><strong>Created by</strong></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div> 

          <div [hidden]="true" #vtc_template id="vtc_template">
            <table style="border:'0';width:100%;">  
              <thead>
                <tr>
                  <th style="width:100%;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div style="width: 100%; height: 150px;">
                        <div style="width: 100%;">
                          <p style="padding-right: 200px;font-size: 17px !important;margin: 0px 0px 10px 0px;">VIJAYA TRAUMA CARE CENTER </p>
                          <p style="padding-right: 175px;font-size: 15px !important;margin: 0px 0px 5px 0px;">{{hospital_address}}</p> 
                        </div>
                        <table style="width: 100%;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                          <tbody>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                              <td>: {{client_name}} </td>
                              <td style="padding-left: 5px;"><strong>Address</strong></td>
                              <td>: <strong>{{caddress1}} {{caddress2}}</strong></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                              <td>: {{age}} / {{gender}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility"> :</span> <strong>{{clocation}} {{clientcity}}</strong></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                              <td>: {{dr_name}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility"> :</span> <strong>{{clestare}}</strong></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                              <td>: {{billId}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                              <td>: {{created_date}}</td>                     
                            </tr>
                            <tr *ngIf="surgery != undefined && surgery != ''">
                              <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                              <td>: {{surgery}}</td>                          
                            </tr>
                            <tr *ngIf="credit_type != undefined && credit_type != ''">
                              <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                              <td>: {{credit_person}}</td>                            
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td>
                              </td> 
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td *ngIf="barcode_flag">
                                <img [style]="barcode_style"
                                  src={{barcode_url}} /></td>
                            </tr>          
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%;">  
                <tr>  
                  <td width="100%">  
                    <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                    <div class="bill_border">
                      <div *ngFor="let billprint of billingDetailList">
                        <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                              <thead>
                                <tr>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                  <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                                  <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                  <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                  <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                                </tr>
                              </thead>
                              <tbody *ngIf="!grouping_flag">
                                <tr *ngFor="let billprinttable of billingPrintView">
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="sno">{{billprinttable.sno}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type">{{billprinttable.bill_type}}</td>
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                  <td [style]="amount_td_style"
                                    data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                  <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                </tr>
                              </tbody> 
                              <tbody *ngIf="grouping_flag">
                                <tr *ngFor="let charge of categoryChargeList">
                                  <td colspan="8" *ngIf="charge.category_flag"> 
                                    <div>
                                      <div>
                                        <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                  text-align: center !important;"><b
                                          style="text-transform: capitalize;">{{charge.category}}</b></p>
                                        <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;">
                                          <tbody>
                                            <tr *ngFor='let billprinttable of charge.charges'>
                                              <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                data-th="sno">{{billprinttable.sno}}</td>
                                              <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type">{{billprinttable.bill_type}}</td>
                                              <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                              <td [style]="amount_td_style"
                                                data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                              <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                              <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                              <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                              <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                              <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>                                                     
                            </table>
                          </div>
                        </div>
                        <div [hidden]="treatementPlanFlag" style="width: 800px;margin-right: 20px;">
                          <h3 class="heading">Bill particulars</h3>
                          <div class="main_class row">
                            <table style="border: 1px solid black;border-collapse: collapse;">
                              <tr>
                                <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                                <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                              </tr>
                              <tr *ngFor="let estimate of estimatePrintData">
                                <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Plan">{{estimate.treatment_desc}}</td>
                                <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Estimate">{{estimate.estimate}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>                     
                        <div style="width: 100%;">
                          <table style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;">
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>              
                              </td>                           
                              <td style="vertical-align: top;width: 31%;">
                                <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                  <tr [hidden]="cgstFlag">
                                    <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">
                                      {{cgst}}</td>
                                  </tr>
                                  <tr [hidden]="sgstFlag">
                                    <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                      {{sgst}}</td>
                                  </tr>        
                                  <tr [hidden]="prevBalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevBalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == billAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount == 0">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="billAmount == undefined || billAmount == 0">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{billAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advancevalue == 0">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advancevalue}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paid}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{billprint.balance}}</td>
                                  </tr> 
                                </table>
                                <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                  <tr [hidden]="cgstFlag">
                                    <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">
                                      {{cgst}}</td>
                                  </tr>
                                  <tr [hidden]="sgstFlag">
                                    <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                      {{sgst}}</td>
                                  </tr>        
                                  <tr [hidden]="prevBalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevBalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == billAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount == 0">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="billAmount == undefined || billAmount == 0">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{billAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advancevalue == 0">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advancevalue}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paid}}</td>
                                  </tr>
                                  <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{billprint.balance}}</td>
                                  </tr> 
                                </table>
                              </td>                        
                            </tr>
                          </table>
                        </div>                                              
                        <div style="width: 100%;margin-left: 20px;margin-top: 15px;">
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid != 0">
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{billprint.numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="container-fluid">
                          <div class="row d-flex flex-row justify-content-center">
                            <table style="width: 100%;">
                              <tr>
                                <td style="width: 90%; text-align: center;">
                                  <div class="row">
                                    <div class="d-flex flex-row justify-content-center align-items-end">
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;"></p>
                                          <p></p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                          <p style="margin-bottom: 3px !important;"></p>
                                          <p><strong></strong></p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: right; margin-right: 30px;">
                                          <p><strong>Created by</strong></p>
                                          <p >{{logUserName}}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div style="margin-left: 20px;margin-top: 10px;margin-right: 30px;page-break-before: always;">
                        <div class="row">
                          <div style="text-align: right;">
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'BP'}}:</b>
                            </p>
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'Pulse'}}:</b>
                            </p>
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'SpO2'}}:</b>
                            </p>
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'Temp'}}: </b>
                            </p>
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'Ht'}}:</b>
                            </p>
                            <p class="refformat">
                              <b style="font-size: 14px !important;">{{'Wt'}}:</b>
                            </p>                        
                          </div>
    
                          <div>
                            <p><strong>Rx</strong></p>
                          </div>
                          <div>
                            <p style="height: 260px !important;">&nbsp;</p>
                          </div>
                        </div>
                        <div class="container-fluid">
                          <div class="row d-flex flex-row justify-content-center">
                            <table style="width: 100%;">
                              <tr>
                                <td style="width: 90%; text-align: center;">
                                  <div class="row">
                                    <div class="d-flex flex-row justify-content-center align-items-end">
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;"></p>
                                          <p>&nbsp;</p>
                                          <p>&nbsp;</p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: center;">
                                          <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                          <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                          <p>&nbsp;</p>
                                        </div>
                                      </div>
                                      <div class="col-4">
                                        <div style="text-align: right; margin-right: 30px;">
                                          <p style="margin-bottom: 3px !important;">&nbsp;</p>
                                          <p><strong>Created by</strong></p>
                                          <p >{{logUserName}}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </td>
                </tr>
              </tbody>  
            </table>
          </div>

          <div #logowithname id="logowithname">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <table style="border:'0';width:100%">  
                  <thead>  
                   <tr>  
                    <th style="width:100%;height: 100px;">
                      <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center;">
                            <div>
                              <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                              <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                              <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                              <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                            </div>
                          </td>
                        </tr>
                    </table>
                    </th>  
                  </tr>  
                 </thead>  
                 <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                 </tfoot> 
                 <tbody>  
                   <tr>  
                     <td width="100%">                   
                      <table style="margin-left: 50px;width: 100%;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> MR.No : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span><br/>
                                <span *ngIf="credit_type != undefined"><strong>Credit bill : </strong>{{credit_type}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 != undefined || this.clientAddress2 != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress1}}</span>
                                <br />
                                <span> {{billprint.caddress2}} {{billprint.clocation}}</span>
                                <br />
                                <span>{{billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                               
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 == undefined && this.clientAddress2 == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                                                      
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                     </td>
                   </tr>
                 </tbody>
                </table>      
              </div>          
            </div>                     
          </div>

          <div #logowithname id="logowithnamenopayconsqty">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <table style="border:'0';width:100%">  
                  <thead>  
                   <tr>  
                    <th style="width:100%;height: 100px;">
                      <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center;">
                            <div>
                              <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                              <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                              <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                              <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </th>  
                  </tr>  
                 </thead>  
                 <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                 </tfoot> 
                 <tbody>  
                   <tr>  
                     <td width="100%">              
                      <table style="margin-left: 50px;width: 100%;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                    <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> MR.No : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span><br/>
                                <span *ngIf="credit_type != undefined"><strong>Credit bill : </strong>{{credit_type}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%; vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 != undefined || this.clientAddress2 != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress1}}</span>
                                <br />
                                <span> {{billprint.caddress2}} {{billprint.clocation}}</span>
                                <br />
                                <span>{{billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                                          
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress1 == undefined && this.clientAddress2 == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>                               
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                     </td>
                   </tr>
                 </tbody>
                </table>
              </div>   
            </div>                   
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>