<!-- <h1>index works!</h1> -->
<app-header></app-header>

<div class="banner-section-bg d-flex flex-column justify-content-center"
   style="background-image: url('../../../assets/images/Medical-banner.jpg');">
   <div class="px-5">
      <h1> Get Personalized Care Right <br> At Your Fingertips</h1>
      <p>You may overcome your disease with "confidence" thanks to our cutting-edge <br> connected gadgets and the
         skilled guidance of our doctors and health coaches.</p>
      <div>
         <a routerLink="/aboutus" class="btn btn-dark" role="button">Know More</a>
         <a routerLink="/contactus" class="btn btn-outline-dark mx-2" role="button">Contact Us</a>
      </div>
   </div>
</div>

<div class="wcu-section pt-5 pb-5" id="wcuSection">
   <div>
      <div class="container">
         <div class="row">
            <div class="text-center">
               <h1 class="wcu-heading" style="font-size: 30px;">Why Choose Us?</h1>
               <p>We leverage technology to be widely accessible and human support to provide each Tervys user <br>with
                  personalised healthcare recommendations.</p>
            </div>
            <div class="col-12 col-md-4">
               <div class="wcu-card text-center">
                  <img [src]="family" class="wcu-card-icon" />
                  <h2 class="wcu-heading pt-3">Home Care</h2>
                  <p class="wcu-para">We bring healthcare to the doorstep of the elderly. One of the biggest honours is
                     to take care of those who took care of us in the past.</p>
               </div>
            </div>
            <div class="col-12 col-md-4">
               <div class="wcu-card text-center">
                  <img [src]="deadline" class="wcu-card-icon" />
                  <h2 class="wcu-heading pt-3">Quick Appointments</h2>
                  <p class="wcu-para">If you rely on doctors appointments, you know how much effort it takes to manage
                     your calendar. Take the headache out of scheduling with our app.</p>
               </div>
            </div>
            <div class="col-12 col-md-4">
               <div class="wcu-card text-center">
                  <img [src]="healthcheckup" class="wcu-card-icon" />
                  <h2 class="wcu-heading pt-3">Health Analytics</h2>
                  <p class="wcu-para">Our analytics services provide the tools, project management, and knowledge base
                     needed to achieve healthcare delivery insights.</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="speciality-section pt-5 pb-5" id="specialitySection">
   <div class="container">
      <div class="row">
         <div class="text-center">
            <h1 class="speciality-heading" style="font-size: 35px;">Specialities we cover</h1>
         </div>
         <div class="container my-5">
            <div>
               <div class="col-12">
                  <div id="carouselExampleIndicators" class="carousel slide" style="display: block;"
                     data-bs-ride="carousel">
                     <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                           class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                           aria-label="Slide 2"></button>
                     </div>

                     <div class="carousel-inner" style="width:100%">
                        <div class="carousel-item active">
                           <div class="row">
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="OrthopedicCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Orthopedic</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="PsychiatryCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Psychiatry</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="OphthalmologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Ophthalmology</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="DentalCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Dental</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="NeurologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Neurology</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="CardiologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Cardiology</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="PulmonologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Pulmonology</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="NephrologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Nephrology</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="carousel-item">
                           <div class="row">
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="DiabetesCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Diabetes</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="UrologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Urology</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="ENTCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">ENT</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="GynecologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Gynecology</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="GastroenterologyCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Gastroenterology</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="IVFCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">IVF</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-md-6 col-xl-3 mb-4">
                                 <div class="mr-3">
                                    <div class="specialty-card text-center mb-3">
                                       <img [src]="GeneralsurgeryCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">General surgery</p>
                                    </div>
                                    <div class="specialty-card text-center">
                                       <img [src]="InfectiousdiseaseCarsoule" class="specialty-card-icon" />
                                       <p class="specialty-para pt-2">Infectious disease</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="solutions-section pt-5 pb-5" id="solutionsSection">
   <div class="container">
      <div class="row">
         <div class="text-center">
            <h1 class="wcu-heading" style="font-size: 30px;">Our Solutions</h1>
            <p>We leverage technology to be widely accessible and human support to provide each Tervys user <br>with
               personalised healthcare recommendations.</p>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card1 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Hospital Management System</h2>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card2 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Clinic Management System</h2>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card3 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Diagnostic Management System</h2>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card4 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Pharmacy Management System</h2>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card5 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Clinical Decision Support System</h2>
         </div>
         <div class="col-12 col-md-4 text-center mt-2">
            <div class="solutions-card6 text-center">
            </div>
            <h2 class="solutions-heading pt-3">Nutrition Management System</h2>
         </div>
      </div>
   </div>
</div>
<footer>
   <div class="pt-3 pb-3 vr-footer-section">
      <div class="container">
         <div class="row">
            <div class="col-12 col-md-3">
               <div class="text-center text-md-left mt-3">
                  <img [src]="myLogo" class="vr-logo" />
                  <div class="d-flex flex-row justify-content-center justify-content-lg-start mt-3 mb-3">
                     <div class="vr-icon-container">
                        <i class="icon fa-brands fa-google"></i>
                     </div>
                     <div class="vr-icon-container">
                        <i class="icon fa-brands fa-twitter"></i>
                     </div>
                     <div class="vr-icon-container">
                        <i class="icon fa-brands fa-instagram"></i>
                     </div>
                     <div class="vr-icon-container">
                        <i class="icon fa-brands fa-linkedin"></i>
                     </div>
                  </div>
                  <p class="vr-footer-section-text"> No: 31, Vembuliamman koil street, Pazhavanthangal, Chennai 600114,
                     Tamil Nadu, India</p>
               </div>
            </div>
            <div class="col-7 col-md-3 mt-3">
               <h1 class="vr-footer-section-heading pb-2"> Contact Us </h1>
               <ul class="vr-footer-section-list">
                  <li class="mb-3">
                     <i class="fa-solid fa-envelope icon2 mr-2"></i>
                     <span class="vr-footer-section-text">info@gritsys.com</span>
                  </li>
                  <li class="mb-3">
                     <i class="fa-solid fa-phone icon2 mr-2"></i>
                     <span class="vr-footer-section-text">044-49526808</span>
                  </li>
                  <li class="mb-3">
                     <i class="fa-solid fa-globe icon2 mr-2"></i>
                     <span class="vr-footer-section-text">www.gritsys.com</span>
                  </li>
               </ul>
            </div>
            <div class="col-5 col-md-3 mt-3">
               <h1 class="vr-footer-section-heading pb-2">Services </h1>
               <p class="vr-footer-section-text"> Primarycare & internal medicine</p>
               <p class="vr-footer-section-text"> Pain & spain care</p>
               <p class="vr-footer-section-text"> Home visit services </p>
               <p class="vr-footer-section-text"> Consultation </p>
            </div>
            <div class="col-6 col-md-3 mt-3">
               <h1 class="vr-footer-section-heading pb-2">Why Choose Us? </h1>
               <p class="vr-footer-section-text"> Home Care</p>
               <p class="vr-footer-section-text"> Quick Appointments</p>
               <p class="vr-footer-section-text"> Analytics </p>
               <p class="vr-footer-section-text"> Easy Access </p>
            </div>
         </div>
         <hr class="hr-line" />
         <div class="text-center">
            <i class="fa-solid fa-copyright icon2"></i>
            <span class="vr-footer-section-cp"> 2025 All rights reserved - www.tervys.com</span>
         </div>
      </div>
   </div>
</footer>