import { Component, OnInit } from '@angular/core';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../../app/helper_class';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../../assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { CommonDataService } from '../../../../app/providers/common-data.service';
import { Date_Formate } from 'src/assets/js/common';



@Component({
  selector: 'app-consingment',
  templateUrl: './consingment.component.html',
  styleUrls: ['./consingment.component.scss']
})
export class ConsingmentComponent implements OnInit {
  public hsnNO: string = "";
  public newFlag: boolean;
  public productName: string = "";
  public filteredList = [];
  public userInfo;
  public pharmacyID;
  public PharmauserID;
  public data_type:string;
  public hospitalid;
  public department_id;
  public store_id;
  public productFilterList:any = [];
  public mdeicareTypeArray: any = [];
  public shortName: string = "";
  productID: string = "";
  public productType: any = [];
  public medicareType: any = [];
  public categoryType: any = [];
  public productTypeArray: any = [];
  public categoryTypeArray: any = [];
  uomListArray: any;
  uomTypeArray: any = [];
  public productTypeID;
  public categoryCtgID;
  public uomCode;
  public supp_name: string;
  public supplierList: any = [];
  public suppID;
  public scheduleType;
  public scheduleList;
  public prodCenteralGST;
  public prodStateGST;
  public avail_qty;
  public MRP;
  public c_price;
  public s_price;
  public expirydura;
  public hide_show_btn;
  isCoverVisible: boolean = true;
  public username;
  public batch_no;
  public consing_data=[];
  public returnedprod;
  public returnedaccepted:boolean;
  public consignment_id;
  public reversedate;
  public editflag:boolean;
  public clearflag:boolean;
  public cgst:any;
  public sgst:any;
  public gst:any;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public pharmaservice: MenuViewService) { 
    this.newFlag = false;
  }

  ngOnInit(): void {

    this.userInfo = Helper_Class.getInfo();
    console.log("user info = "+JSON.stringify(this.userInfo))
    this.department_id=this.userInfo.hospitals[0].department_id;
    this.store_id=this.userInfo.hospitals[0].store_id	;
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.PharmauserID = this.userInfo.po_approver;
      this.data_type='hospital'
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='pharmacy'
    }
    else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hosp_id	;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='hospital'
    }

    if(this.userInfo.middle_name != undefined ){
      this.username= this.userInfo.first_name+" "+this.userInfo.middle_name+" "+this.userInfo.last_name
    }else if(this.userInfo.first_name !=undefined && this.userInfo.last_name != undefined){
      this.username= this.userInfo.first_name+" "+this.userInfo.last_name
    }else if(this.userInfo.first_name !=undefined){
      this.username= this.userInfo.first_name
    }else{
      this.username=''
    }

    if (pharmacy_helper.getManufacturer() == true || pharmacy_helper.getSupplier() == true) {
      if (pharmacy_helper.getProductMst() != undefined) {
        var productMst = pharmacy_helper.getProductMst();
        this.hsnNO = productMst.hsn_no;
        this.productName = productMst.name;
        // this.shortName = productMst.short_name;
        // this.genericName = productMst.generic_name;
        // this.productTypeID = productMst.product_type_id;
        // this.medicareMedID = productMst.medicare_id;
        // this.categoryCtgID = productMst.category_type_id;
        // this.uomCode = productMst.uom_code;
        // this.suppID = productMst.supp_id;
        // this.manufacturerMfgID = productMst.mfg_id;
        // this.quantity = productMst.per_unit;
        // this.costPrice = productMst.cost_price;
        // this.sellingPrice = productMst.sales_price;
        // this.MRP = productMst.MRP;
        // this.prodCenteralGST = productMst.prod_cgst;
        // this.prodStateGST = productMst.prod_sgst;
        pharmacy_helper.setProductMst(undefined);
        if (pharmacy_helper.getManufacturer() == true) {
          pharmacy_helper.setManufacturer(undefined);
        } else {
          pharmacy_helper.setSupplier(undefined);
        }
      }
    }
    this.GetProductdata();
    this.getCategory();
    //this.getManufacturer();
    this.getUOM();
    this.getSuppliers();
    this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    this.getData();
    this.editflag=false;
    this.clearflag=false;

  }



  productNameFilter(e) {
    this.filteredList = [];
    this.productName = e.target.value.toString();
    if (this.productName !== "" && this.productName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpbyn',
        JSON.stringify({
          product_name: this.productName,
         
        }),
        { headers: headers })
        .subscribe(
          response => {
            console.log( "product filter send_data = "+JSON.stringify({
              product_name: this.productName,
              dept_id:this.department_id,
              store_id:this.store_id,
            }))
            var hospital_txt:any = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.products != null) {
              this.productFilterList = obj.products;
              for (var i = 0; i < this.productFilterList.length; i++) {
                
                hospital_txt.push(this.productFilterList[i].name);
                console.log("name "+JSON.stringify(hospital_txt))
              }
              this.filteredList = hospital_txt.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.product_name.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { }
        )
    } else {
      this.filteredList = [];
    }
  }

  selectItem(data) {
    this.productName = data;
    this.filteredList = [];
    for (var i = 0; i < this.productFilterList.length; i++) {
      if (data == this.productFilterList[i].name) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpd',
          JSON.stringify({
            product_id: this.productFilterList[i].product_id
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.hsn_no != null) {
                this.hsnNO = obj.hsn_no;
                this.shortName = obj.short_name;
                // this.genericName = obj.generic_name;
                // this.quantity = obj.per_unit;;
                this.productID = obj.product_id;
                for (var j = 0; j < this.mdeicareTypeArray.length; j++) {
                  if (this.mdeicareTypeArray[j].medicare_id == obj.medicare_id) {
                    this.medicareType = this.mdeicareTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.productTypeArray.length; j++) {
                  if (this.productTypeArray[j].med_code == obj.product_type_id) {
                    this.productType = this.productTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.categoryTypeArray.length; j++) {
                  if (this.categoryTypeArray[j].category_id == obj.category_type_id) {
                    this.categoryType = this.categoryTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.uomListArray.length; j++) {
                  if (this.uomListArray[j].uom_code == obj.uom_code) {
                    this.uomTypeArray = this.uomListArray[j];
                    break;
                  }
                }
                // for (var j = 0; j < this.manufacList.length; j++) {
                //   if (this.manufacList[j].mfg_id == obj.mfg_id) {
                //     this.manufacturer = this.manufacList[j];
                //     break;
                //   }
                // }
              }
            },
            error => {
              this.toastr.error(JSON.stringify(error.json()));
            }
          )
      }
    }
  }

  GetProductdata() {
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        if (data.json().med_details != null) {
          this.productTypeArray = data.json().med_details;
        }
      },
      error => { });
  }

  getCategory() {
    this.http.get(ipaddress.getIp + "pharmacontrol/gpcat/").subscribe(
      data => {
        if (data.json().product_categories != null) {
          this.categoryTypeArray = data.json().product_categories;
          this.categoryTypeArray.forEach(element => {
          });
        }
      },
      error => { });
  }

  getUOM() {
    var send_data;
    if(this.userInfo.user_type == "pharmacy"){
      send_data={
        pharmacy_id: this.pharmacyID, 
         type:this.data_type
      }
    }else[
      send_data={
        pharmacy_id: this.hospitalid, 
           type:this.data_type
      }
    ]
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/guom/", 
      // JSON.stringify({ 
      //   pharmacy_id: this.pharmacyID, 
      //   type:this.data_type
      // })
      send_data
      , { headers: headers }).subscribe(
      data => {
        if (data.json().product_uoms != null) {
          this.uomListArray = data.json().product_uoms;
        }
      },
      error => { });
  }

  getSuppliers() {
   
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gsup/",
        JSON.stringify({
          pharmacy_id: this.pharmacyID,
          supp_name: this.supp_name,
          dept_id:this.department_id,
          store_id:this.store_id
        }), { headers: headers }).subscribe(
          data => {
             var obj=data.json().supplier_details;
             console.log("supplier name  = "+JSON.stringify(obj))
           for(var i=0;i<obj.length;i++){
             var exists = this.supplierList.some(function(supplier) {
              return supplier.supp_id === obj[i].supp_id;
          });
            if (!exists) {
            this.supplierList.push({
              name:obj[i].name,
              supp_id:obj[i].supp_id,
              supp_code:obj[i].supp_code,
              gst_no:obj[i].gst_no
            })
          }
          }
          },
          error => {
            this.toastr.error(Message_data.unabletofetsupl);
          }
        )
  }

  supplierdatafunction(data){
    this.suppID=data.supp_id
  }

  getSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/gsch", { headers: headers }).subscribe(
      data => {
        this.scheduleList = [];
        var obj = data.json();
        if (obj != undefined && obj.schedules.length != 0) {
          this.scheduleList = obj.schedules;
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchSech);
      }
    )
  }
 
  toggleCover() {
    if(this.editflag == true){
      this.isCoverVisible=true 
    }else if(this.clearflag == true){
      this.isCoverVisible=true
    }else{
      this.isCoverVisible = !this.isCoverVisible 
    }
    if(this.isCoverVisible == false){
      this.hide_show_btn="../../../assets/ui_icons/buttons/down.svg";
    }else{
      this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    }
    this.editflag =false;
    this.clearflag =false;
  }

  getData(){
    var send_data={
      hptl_clinic_id:this.pharmacyID,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gcbh", send_data, { headers: headers }).subscribe(
      data => {
        var obj=JSON.parse(data["_body"]);
        var consin_data=obj.consignments;
        console.log("get consi_data"+JSON.stringify(obj))
        this.consing_data=[];
        var expdate;
        for(var i=0;i< consin_data.length;i++){
          
          if(consin_data[i].expiry_date != undefined){
            // expdate=Date_Formate(consin_data[i].expiry_date)
            expdate = Date_Formate(consin_data[i].expiry_date)
            this.reversedate=consin_data[i].expiry_date
          }else{
            expdate=''
          }
          if(consin_data[i].consignment_id != undefined){
            consin_data[i].consignment_id=consin_data[i].consignment_id
          }else{
            consin_data[i].consignment_id=''
          }

          if(consin_data[i].is_prod_returned == "1" ){
            consin_data[i].is_prod_returne=true
          }else{
            consin_data[i].is_prod_returne=false
          }

          this.consing_data.push({
            sn:i+1,
          consignment_name	:	consin_data[i].consignment_name,
          quantity	:	consin_data[i].quantity,
          batch_no	:	consin_data[i].batch_no,
          expiry_date	:	expdate,
          supplier_id	:	consin_data[i].supplier_id,
          cost_price	:	consin_data[i].cost_price,
          sale_price	:	consin_data[i].sale_price,
          mrp	:	consin_data[i].mrp,
          received_by	:	consin_data[i].received_by,
          is_prod_returned	:	consin_data[i].is_prod_returned,
          status	:	consin_data[i].status,
          hsn_no	:consin_data[i].hsn_no,
          supp_name	:	consin_data[i].supp_name,
          consignment_id: consin_data[i].consignment_id,
          cgst:consin_data[i].cgst,
          sgst:consin_data[i].sgst,
          gst:consin_data[i].gst
        })
        }

      },error =>{

      });
  }
  
  edit_consign_Product(data){
        this.productName=data.consignment_name
       this.avail_qty=data.quantity
        this.batch_no=data.batch_no
        this.expirydura =data.expiry_date
        if (data.expiry_date != '') {
          this.expirydura = this.reversedate;
      } else {
          this.expirydura = ''; // Handle the case where expiry_date is undefined
      }
      this.c_price=data.cost_price
      this.s_price=data.sale_price
      this.MRP=data.mrp
      this.hsnNO=data.hsn_no
       this.supp_name=data.supp_name
       this.suppID=data.supplier_id;
       this.cgst=data.cgst;
       this.sgst=data.sgst;
       this.gst=data.gst;

       if(data.consignment_id != undefined){
        this.consignment_id=data.consignment_id
       }else{
        this.consignment_id=''
       }

       if(data.is_prod_returned != undefined){
        this.returnedprod=data.is_prod_returned
       }else{
        this.returnedprod="1"
       } 
       if(this.returnedprod == "1"){
        this.returnedaccepted=true
      }
      else
      {
        this.returnedaccepted=false
      }
       
      this.editflag=true;
      this.toggleCover()
  }

  cgstcal(cgst){
    if(this.sgst != undefined && this.sgst != ''){
      this.gst = parseFloat(this.cgst) + parseFloat(this.sgst)
    }else{
      this.gst = parseFloat(this.cgst) + 0  
    }
  }


  gstcal(sgst){
    if(this.cgst != undefined && this.cgst !=''){
      
      this.gst = parseFloat(this.cgst) + parseFloat(this.sgst)  
    }else{
      this.gst = 0 + parseFloat(this.sgst)
      
    }
  }

  saveData(){
    // returnedprod
    if(this.hsnNO == undefined){
      this.toastr.error("HSN no should be empty")
    }
    else if(this.hsnNO == ''){
      this.toastr.error("HSN no should be empty")
    }
    else if(this.productName == undefined){
      this.toastr.error("Product name should not be empty")
    }
    else if(this.productName == ''){
      this.toastr.error("Product name should not be empty")
    }
    else if(this.batch_no == undefined){
      this.toastr.error("Batch no should not be empty")
    }
    else if(this.batch_no == ''){
      this.toastr.error("Batch no should not be empty")
    }
    else if(this.supp_name == undefined){
      this.toastr.error("Supplier name should not be empty")
    }
    else if(this.supp_name == ''){
      this.toastr.error("Supplier name should not be empty")
    }
    else if(this.avail_qty == undefined){
      this.toastr.error("Avaliable quantity should not be empty")
    }
    else if(this.avail_qty == ''){
      this.toastr.error("Avaliable quantity should not be empty")
    }
    else if(this.expirydura == undefined){
      this.toastr.error("Select the expiry  date")
    }
    else if(this.expirydura == ''){
      this.toastr.error("Select the expiry  date")
    }
    else if(this.MRP == undefined){
      this.toastr.error("MRP should not be empty")
    }
    else if(this.MRP == ''){
      this.toastr.error("MRP should not be empty")
    }
    else if(this.s_price  == undefined){
      this.toastr.error("Sales price should not be empty")
    }
    else if(this.s_price  ==''){
      this.toastr.error("Sales price should not be empty")
    }
    else if( this.c_price  == undefined){
      this.toastr.error("Cost price should not be empty")
    }
    else if( this.c_price  == ''){
      this.toastr.error("Cost price should not be empty")
    }
    else if(this.cgst  == undefined){
      this.toastr.error("CGST should not be empty")
    }
    else if(this.cgst  == ''){
      this.toastr.error("CGST should not be empty")
    }
    else if( this.sgst  == undefined){
      this.toastr.error("SGST should not be empty")
    }
    else if( this.sgst  == ''){
      this.toastr.error("SGST should not be empty")
    }
    else if(this.gst  == undefined){
      this.toastr.error("GST should not be empty")
    }
    else if(this.gst  == ''){
      this.toastr.error("GST should not be empty")
    }else{
      this.returnedprod=this.returnedprod != true ? "0":"1";
      var send_data;
     if(this.consignment_id != undefined){
      send_data={
        hptl_clinic_id:this.pharmacyID,
        consignment_name:this.productName,
        quantity:this.avail_qty,
        batch_no:this.batch_no,
        expiry_date:this.expirydura,
        supp_id: this.suppID,
        cost_price:this.c_price,
        sale_price:this.s_price,
        cgst:this.cgst,
        sgst:this.sgst,
        gst:this.gst,
        mrp:this.MRP,
        received_by:this.username,
        is_prod_returned:this.returnedprod,
        consignment_id:this.consignment_id,
        status:'1',
        hsn_no:this.hsnNO
      };
     }else{
      send_data={
        hptl_clinic_id:this.pharmacyID,
        consignment_name:this.productName,
        quantity:this.avail_qty,
        batch_no:this.batch_no,
        expiry_date:this.expirydura,
        supp_id: this.suppID,
        cost_price:this.c_price,
        sale_price:this.s_price,
        cgst:this.cgst,
        sgst:this.sgst,
        gst:this.gst,
        mrp:this.MRP,
        received_by:this.username,
        is_prod_returned:this.returnedprod,
        status:'1',
        hsn_no:this.hsnNO
      };
     }
      console.log("senddata_data"+JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/sch", send_data, { headers: headers }).subscribe(
        data => {
          var obj=JSON.parse(data["_body"]);
          console.log("save consi_data"+JSON.stringify(obj))
          if(obj.key != '0' && obj.key != undefined){
            this.toastr.success("Product to saved successfully") 
            this.productName=''
            this.productName=''
            this.avail_qty=''
            this.batch_no=''
            this.expirydura=''
            this.suppID=''
            this.c_price=''
            this.s_price=''
            this.MRP=''
            this.hsnNO=''
            this.supp_name='';
            this.returnedaccepted=false
          }
          else{
            this.toastr.error("Unable to save")  
          } 
            
          
          this.getData()
        },error =>{
  
        });
  
    }
   

  }

  cleararrayconsign(data){
    this.productName=''
    this.productName=''
    this.avail_qty=''
    this.batch_no=''
    this.expirydura=''
    this.suppID=''
    this.c_price=''
    this.s_price=''
    this.MRP=''
    this.hsnNO=''
    this.supp_name=''
    this.returnedaccepted=false;
    this.clearflag=true;
    this.toggleCover();
  }



}
