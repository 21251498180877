<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white ">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Estimate List</h5>
            </div>
            <div class="headerButtons">
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body style="padding: 0rem 1rem;">
          <div>         
            <div class="row" style="margin-top: 15px;">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="nodata" *ngIf="nobill_estimatesFlag">No bill(s) found</p>
                <table *ngIf="estBillList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                  <thead>
                    <tr>
                      <th class="delete">Bill Estimate No</th>
                      <th>Date/Time</th>
                      <th>MR.No</th>
                      <th>Client name</th>  
                      <th>Age/Gender </th> 
                      <th>Ward</th>  
                      <th>Medical Type</th>         
                      <th>Estimate Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bill of estBillList; let i = index">
                      <td style="font-size: 12px;text-align: center;" (click)="viewBill(bill)" >
                        {{bill.bill_id}}
                      </td>
                      <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill)">
                        {{bill.date}}
                      </td>
                      <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill)">
                        {{bill.patient_id}}
                      </td>
                      <td style="font-size: 12px;text-align: left;" (click)="viewBill(bill)">
                        {{bill.salutation}}{{bill.client_name}}
                      </td>
                      <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill)">
                        {{bill.age_gender}}
                      </td>
                      <td style="font-size: 12px;text-align: left;"  (click)="viewBill(bill)">
                        {{bill.ward_name}}
                      </td>
                      <td style="font-size: 12px;text-align: left;"  (click)="viewBill(bill)">
                        {{bill.medical_type}}
                      </td>
                      <td style="font-size: 12px;text-align: right;" (click)="viewBill(bill)">
                        {{bill.total_amount}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>