import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress'; import { Doc_Helper } from '../Doc_Helper';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-med-pres-view',
  templateUrl: './med-pres-view.component.html',
  styleUrls: ['./med-pres-view.component.scss']
})
export class MedPresViewComponent implements OnInit {
  @ViewChild('printbannerfullpage') printbannerfullpage: ElementRef;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public patient_name;
  public intake_modeflag:boolean;
  public intake_mode;
  public clnt_pulse;
  public Appoint_Date;
  public fulladdress;
  public headerstyle;
  public footerstyle;
  public complaints;
  public dis_txt;
  public clnt_bp;
  public pulseflag
  public param_four;
  public print_labarray: any = [];
  public print_labarraynew: any = [];
  public print_radioarray: any = [];
  public print_radioarraynew: any = [];
  public heartrateflag:boolean;
  public clnt_heartrate:string;
  public url_prescription;
  public printnext_package: any = [];
  public next_package: any = [];
  public package: any = [];
  public print_package: any = [];
  public med_view_list;
  public prescription_pres_id;
  public labarray;
  public radioarray;
  public docnameflag: boolean;
  public data_prescription: any = [];
  public personalinfo: any = [];
  public next_txt;

  public kidney_flag: boolean = false;
  public weight_flag: boolean = false;
  public height_flag: boolean = false;
  public rsflag: boolean = false;
  public rrflag: boolean = false;
  public bp_flag: boolean = false;
  public cns_flag: boolean= false;
  public abd_flag: boolean = false;
  public cbg_flag: boolean= false;
  public clntAbd;
  public clntcbg;
  public clntCns;
  public clntRR;
  public dure_flag;
  public doc_telephone;
  public doc_loc;
  public work_time;
  public pharma_control;
  public intake_txt;
  public afterfood_txt;
  public dure_write;
  public has_multi_tab;
  public pharma_id;
  public pharmacy_name;
  public has_pharma;
  public phar_addr1;
  public phar_addr2;
  public phar_loc;
  public phar_city;
  public phar_zipcode;
  public phar_state;
  public phar_cnty;
  public pharcy_details: any = [];
  public store_pick;
  public home_del;
  public val_days;
  public disease_flag;
  public sub_rel_id;
  public rel_id;
  public rel_name;
  public radioarraynew: any = [];
  public labarraynew: any = [];
  public every_six;
  doctor_qualify_data;
  public doct_clientname;
  public salutation;
  public doc_city;
  public doc_state;
  public doc_country
  public licence_code;
  public print_instruction;
  public oberse_med;
  public nextvisitflag;
  public next_txt1;
  public hospflag;
  public hptl_name
  public instructionflag: boolean = false;
  public diagnosisflag: boolean = false;
  public doc_reg_id;
  public total_name;
  public instructions;
  public instructionsflag;
  public instr_flag;

  public diseaseNamevalue;
  public persondataflag;
  public speclization_arry;
  public ageflag;
  public patient_age;
  public genderflag;
  public patient_gender;
  public addressdetails;
  public doctor_name;
  public inpatientnuber;
  public mrnflag;
  public mrnno;
  public profileImg;
  public patient_image;
  public visitpurpose;
  //public ageflag;
  elementType: 'svg';
  lineColor = '#000000';
  width = 0.8;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 0;
  marginRight = 10;
  public mrNumber;
  public showdetails_flag: boolean;
  public docname_flag: boolean;
  private userinfo;
  private prescriptionUrl: string;
  public med_pres_list: any = [];
  public med_pres_list_data: any = [];
  public age;
  public docRegId;
  public pharmacyDetailList = [];
  public pharmacyFlag: boolean;
  public doctorQualification;
  public hospLogo;
  public hptlName;
  public address;
  public doctorLocation;
  public doctorCity;
  public doctorState;
  public doctorCountry;
  public doctorTelephone;
  public prescriptionPresId;
  public prescriptionFlag: boolean = false;
  public show: boolean;
  public patientName: string;
  public doctorName: string;
  public prescriptionDate: string;
  public diseaseName: string;
  public diseaseDescription: string;
  public subRelId: string;
  public relationId: string;
  public relationName: string;
  public hasPharmaFlag: boolean = false;
  public pharmacyName: string;
  public pharmacyAddr1: string;
  public pharmacyAddr2: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyCountry: string;
  public telephone: string;
  public pharmaId: string;
  public storePickUpFlag: boolean = false;
  public delopt: boolean = false;
  public homeDeliveryFlag: boolean = false;
  public validityDays;
  public workTime;
  public sendPresIdData;
  public appointment: boolean = false;

  public balanceQuantity: number = 0;
  public hospitalFlag: boolean;
  public listProducts = [];
  public duration: string;
  public durationFlag: boolean;
  public intake: string;
  public instructionFlag: boolean;
  public gender: string;
  public licenceCode: string;
  public printTemplate: string;
  public language: string;
  public clntBp: string;
  public clntPulse: string;
  public clntCvs: string;
  public clntRs: string;
  public clntKidney: string;
  public diagnosis: string;
  public treatment;
  public nextVisit: string;
  public hptlPresLogo: string;
  public investigation: string;
  public temparature_flag: boolean = false;
  public cvsflag: boolean = false;

  public orthoFlag: boolean = false;
  public bpFlag: boolean = false;
  public treatFlag: boolean = false;
  public pulmFlag: boolean = false;
  public backFlag: boolean = false;
  public getViewPresData;
  public pft;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription: string;
  public disease_name: string;
  public disease_describtion: string;

  public pharmacyLogo;
  public height;
  public weight;
  public temparature;
  public heightFlag: boolean;
  public weightFlag: boolean;
  public temparatureFlag: boolean;
  public printFlag: boolean;
  public dur_flag: boolean = false;
  public dur_time_flag: boolean = false;
  public notes_flag: boolean = false;
  public print_view_flag: boolean = true;
  public complaintsflag: boolean = false;

  public spo2Flag: boolean = false;
  public prFlag: boolean = false;
  
  public userInfo;
  public mobile;
  public preslogo_style;
  public med_style;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public note_style;
  public apptype: string;
  public clntSpo2: string;
  public clntPr: string;
  public clntUHID: string;
  public prescriptionTime: string;
  public tablestyle;
  public print_template: string;
  public medstyle: boolean = true;
  public medstyle1: boolean = false;
  public medstyle2: boolean = false;
  public medstyle3: boolean = false;
  public userlist;
  public print_same_page: boolean;
  public printLanguageflag: boolean = false;
  constructor(public sanitizer: DomSanitizer, public toastr: ToastrService,
    public http: Http, public router: Router, public el: ElementRef,
    public doctormessageservice: MenuViewService, private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.print_same_page = true;
    this.prescriptionUrl = "prescription/mpdoc";
    this.userlist = this.userinfo = Helper_Class.getInfo();
    console.log("this.userlist.clientaddress2 " + JSON.stringify(this.userlist))
    this.age = Doc_Helper.getClient_Info().Age_data;
    this.gender = Doc_Helper.getClient_Info().Gender_data;
    this.mrNumber = Doc_Helper.getClient_Info().pat_id;
    this.getPrescriptionList();

    this.showdetails_flag = true;
    if (this.userinfo.hospitals != undefined) {
      this.language = this.userinfo.hospitals[0].language;
      // this.translate.setDefaultLang('tamil');
      if (this.language == "English") {
        this.translate.use('english');
        this.printLanguageflag = false;
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")

      } else if (this.language == "Tamil") {
        this.translate.use('tamil');
        this.printLanguageflag = true;
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("15ml தண்ணீருடன்")
        // this.mixtype.push("100ml தண்ணீருடன்")
        // this.mixtype.push("தயிருடன்")

      } else if (this.language == "Telugu") {
        this.printLanguageflag = true;
        this.translate.use('telugu');

        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")

      } else if (this.language == "Malayalam") {
        this.printLanguageflag = true;
        this.translate.use('malayalam');
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")
      } else if (this.language == "Kannada") {
        this.printLanguageflag = true;
        this.translate.use('kannada');
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")

      } else if (this.language == "Oriya") {
        this.printLanguageflag = true;
        this.translate.use('oriya');
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")
      } else if (this.language == "Bengali") {
        this.printLanguageflag = true;
        this.translate.use('bangla');
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")

      } else if (this.language == "Hindi") {
        this.printLanguageflag = true;
        this.translate.use('hindi');
        // this.intakeOption = [];
        // this.mixtype = [];
        // this.intakeOption.push("Empty stomach");
        // this.intakeOption.push("After food");
        // this.intakeOption.push("Before food");
        // this.intakeOption.push("With Food");

        // this.mixtype.push("with 100 ml water")
        // this.mixtype.push("with 15 ml water")
        // this.mixtype.push("with curd")
      }
    }

    if (this.userinfo.hospitals[0].pres_print_template != undefined) {
      this.print_template = this.userinfo.hospitals[0].pres_print_template;
    } else {
      this.print_template = "noheader";
    }
    this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style;
    this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style;

    this.headerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_heading);
    this.footerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_footer);

    if (this.showdetails_flag == true) {
      this.tablestyle = "padding:10px 32px; min-height: 200px !important; width:300px !important";
    }
    //  public patient_age;



    if (this.userlist.client_name != "") {
      this.patient_name = this.userlist.salutation + " " + this.userlist.client_name;
      this.persondataflag = true
    }

    if (this.userlist.spl != "") {
      this.speclization_arry = this.userlist.spl;
    }

    if (this.userlist.Age_data != "") {
      this.ageflag = true;
      this.patient_age = this.userlist.Age_data;
    }

    if (this.userlist.Gender_data != "") {
      this.genderflag = true;
      var gender = this.userlist.Gender_data;
      if (gender == "Male") {
        this.patient_gender = "M"
      } else if (gender == "Female") {
        this.patient_gender = "F"
      } else {
        this.patient_gender = "T"
      }

    }
      if(this.userlist.middle_name != undefined){
        this.doctorName = "Dr" + " " + this.userlist.first_name+" "+this.userlist.middle_name+" "+this.userlist.last_name;
      }else{
        this.doctorName = "Dr" + " " + this.userlist.first_name+" "+this.userlist.last_name;
      }   
    console.log("this.userlist.clientaddress2 " + JSON.stringify(this.userlist))
    console.log("this.Doc_Helper.getAppFlow(.clientaddress2 " + typeof (this.userlist.clientaddress2))
    if (this.userlist.clientaddress2 != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (this.userlist.clientaddress2 != "null") {
          this.addressdetails = (this.userlist.clientaddress1) + "," + (this.userlist.clientaddress2) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
        } else {
          this.addressdetails = (this.userlist.clientaddress1) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
        }
      } else {
        this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1) + "," + encrypt_decript.Decript(this.userlist.clientaddress2) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
      }
    } else {
      if (Doc_Helper.getAppFlow() != "Inpatient") {
        if (this.userlist.clientaddress2 != "null" && this.userlist.clientaddress2 != undefined && this.userlist.clientaddress2 != null) {
          this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1) + "," + 
                                encrypt_decript.Decript(this.userlist.clientaddress2) + "," + 
                                this.userlist.clientlocation + "," + 
                                this.userlist.clientcity + "-" + 
                                encrypt_decript.Decript(this.userlist.clientzipcode) + "," + 
                                this.userlist.clientstate + "-" + this.userlist.clientcountry;
        }
      } else if(this.userlist.clientaddress1 != undefined && this.userlist.clientaddress1 != null) {
        this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
      }
    }

    if (Doc_Helper.getAppFlow() != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (Helper_Class.getInfo().middle_name != undefined) {
          this.doctor_name = "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().middle_name + " " + Helper_Class.getInfo().last_name;
        } else {
          this.doctor_name == "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().last_name;
        }
        // bed_no
        //  if(this.userlist.wardlist != undefined){
        //   const bedName = this.getBedName(this.userlist.bed_no);
        //   if (bedName) {
        //     console.log(` is occupied by ${bedName}.`);
        // } else {
        //     console.log(` is vacant.`);
        // }
        //  }
        // this.inpatflag = true;
        // this.wardno = this.userlist.wardlist;
        // this.admission_time = this.userlist.admission_time;
        // if (this.userlist.admission_date != undefined) {
        //   this.admission_date = Date_Formate(this.userlist.admission_date)
        // } else {
        //   this.admission_date = "";
        // }

        // this.surgery_inpat = this.userlist.surgery_name;
        // this.bedno = this.userlist.bed_no
        //  this.wardno 
      }
    }

    if (this.userlist.visitpurpose != "") {
      this.visitpurpose = this.userlist.visitpurpose;

    }
    if (this.userlist.inpatientnuber != undefined) {
      this.inpatientnuber = this.userlist.inpatientnuber;

    } else {
      if (this.userlist.app_id != undefined) {
        this.inpatientnuber = this.userlist.app_id;

      }
    }


    //doctor_name
    if (this.userlist.pat_id != "" && this.userlist.pat_id != undefined) {
      this.mrnno = this.userlist.pat_id;
      this.mrnflag = true;
    }
    if (this.userlist.profile_image != undefined) {
      this.profileImg = this.userlist.profile_image;
    }
    // this.patient_name = this.userlist.client_name;
    // this.patient_age = this.userlist.Age_data;
    // this.patient_gender = this.userlist.Gender_data;

    this.patient_image = this.userlist.image;
    if (this.dur_flag == true && this.notes_flag == true) {
      this.med_style = "width: 275px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.med_style = "width: 250px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
    } else {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 50px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width:50px;border: 1px solid black;border-collapse: collapse;";
    }
  }

  backToList() {
    this.showdetails_flag = true;
    this.backFlag = false;
  }

  backClicked() {
    this.doctormessageservice.sendMessage("appointment_list");
  }

  getPrescriptionList() {
    this.med_pres_list = [];
    var agerange;
    if (this.age == "<1") {
      agerange = "<1";
    } else if (this.age == "1" || this.age == "2" || this.age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.age == "4" || this.age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.age == "6" || this.age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.age == "8" || this.age == "9" || this.age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.age == "11" || this.age == "12" || this.age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.age == "14" || this.age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.age == "16" || this.age == "17" || this.age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.age == "19" || this.age == "20" || this.age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

    var sendata;
    if (Doc_Helper.getClient_Info().Client_id != null && Doc_Helper.getClient_Info().sub_id != undefined &&
      Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
      sendata = JSON.stringify
        ({
          client_id: Doc_Helper.getClient_Info().Client_id,
          relation_id: Doc_Helper.getClient_Info().rel_id,
          sub_rel_id: Doc_Helper.getClient_Info().sub_id,
          spl_name: Doc_Helper.getClient_Info().spl,
        });

    } else {
      sendata = JSON.stringify
        ({
          client_id: Doc_Helper.getClient_Info().Client_id,
          relation_id: Doc_Helper.getClient_Info().rel_id,
          spl_name: Doc_Helper.getClient_Info().spl,
        });
    }

    if (this.med_pres_list.length == 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyclntid/', sendata,
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            console.log("new de" + JSON.stringify(data))

            if (data != null) {
              this.med_pres_list_data = data.prescriptions;
              if (this.med_pres_list_data != null) {
                this.showdetails_flag = true;
                for (var i = 0; i < this.med_pres_list_data.length; i++) {
                  var new_datalist = this.med_pres_list_data[i];
                  this.docname_flag = false;
                  var Docname = "";
                  if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                    Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;

                  } else {
                    Docname = new_datalist.first_name + " " + new_datalist.last_name;
                  }

                  var App_date = "";
                  if (new_datalist.date != null) {
                    App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
                  }

                  this.med_pres_list.push({
                    doctorname: Docname,
                    date: App_date,
                    diseasechk: new_datalist.disease,
                    pres_drug_id: this.med_pres_list_data[i].pres_drug_id,
                    docname_flag: this.docname_flag,
                    remarks: this.med_pres_list_data[i].note
                  });
                }
              }
              // this.med_pres_listdata = this.med_pres_list.length == 0 ? true : false;
            }
          },
          error => { }
        )
    }
  }

  getPresDetails(prescription) {
    this.pharmacyDetailList = [];
    var send_data = {
      pres_id: prescription.pres_drug_id,
    }
    this.url_prescription = "prescription/mpdoc";
    this.listProducts = [];
    this.med_view_list = true;
    this.prescription_pres_id = prescription.pres_drug_id;

    this.prescriptionFlag = true;
    this.backFlag = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.url_prescription,
      JSON.stringify(
        send_data
      ),
      { headers: headers }).subscribe(
        response => {
          this.labarray = [];
          this.radioarray = [];
          this.print_labarray = [];
          this.print_labarraynew = [];
          this.print_radioarray = [];
          this.print_radioarraynew = [];
          this.print_package = [];
          this.package = [];
          this.printnext_package = [];
          this.next_package = [];
          var obj = response.json();
          console.log("mp doc 000" + JSON.stringify(obj))
          this.docnameflag = false;
          this.data_prescription = obj;
          if (obj.length != 0) {
            this.showdetails_flag = false;
            if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.middle_name != null && obj.middle_name != "") {
                this.dr_total_name = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
              } else {
                this.dr_total_name = obj.first_name + ' ' + obj.last_name;
              }
              this.docnameflag = true;
            }
        
          
            if (obj.clntUHID != undefined && obj.clntUHID != "") {
              this.clntUHID = obj.clntUHID;
              this.mrNumber = obj.clntUHID;
            }

            if (obj.clntPr != undefined && obj.clntPr != "") {
              this.clntPr = obj.clntPr;
              this.prFlag = true;
            } else {
              this.prFlag = false;
            }

            if (obj.spo2 != undefined && obj.clntSpo2 != "") {
              this.clntSpo2 = obj.spo2;
              this.spo2Flag = true;
            } else {
              this.spo2Flag = false;
            }

            if (obj.cvs != undefined && obj.cvs.trim() != "" && obj.cvs != null && (obj.cvs.trim()).length != 0) {
              this.clntCvs = obj.cvs;
              this.cvsflag = true;
            } else {
              this.cvsflag = false;
            }
            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
              this.patientName = obj.salutation + "." + encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.patientName = obj.salutation + "." + encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            // if(obj.salutation != undefined){
            //   this.patientName = obj.salutation+". "+this.patientName
            // }
            //Appoint_Date
            if (obj.doc_reg_id != null) {
              this.docRegId = obj.doc_reg_id; 
            }
            if (obj.pres_date != undefined) {
              this.Appoint_Date = Date_Formate(obj.pres_date) + " " + Time_Formate(obj.pres_time);
            }
            
            if (obj.address2 != undefined && obj.address2 != null && obj.address2 != "null") {
              this.fulladdress = encrypt_decript.Decript(obj.address1) + "," + encrypt_decript.Decript(obj.address2) + "," + obj.location + "," + obj.city + "-" + encrypt_decript.Decript(obj.zipcode) + "," + obj.state + "," + obj.country;
            
            
            } else {
              this.fulladdress = encrypt_decript.Decript(obj.address1) + "," + obj.location + "," + obj.city + "-" + encrypt_decript.Decript(obj.zipcode) + "," + obj.state + "," + obj.country;

            }
            if (obj.mobile != undefined) {
              var mob = encrypt_decript.Decript(obj.mobile)
              if (mob != undefined) {
                this.mobile = mob
              } else {
                this.mobile = ""
              }
            }

            if (obj.hptl_pres_logo != undefined) {
              this.hptlPresLogo = ipaddress.Ip_with_img_address + obj.hptl_pres_logo;
            }

            this.hospLogo = obj.hosp_logo != undefined ? ipaddress.Ip_with_img_address + obj.hosp_logo : "assets/imgs/hospital.png";


            if (obj.kidney != undefined && obj.kidney != "") {
              this.clntKidney = obj.kidney;
              this.kidney_flag = true
              this.kidney_flag = true;
            } else {
              this.orthoFlag = false;
            }

            if (obj.rs != undefined && obj.rs != "") {
              this.clntRs = obj.rs;
              this.orthoFlag = true;
            } else {
              this.orthoFlag = false;
            }

            if (obj.pft != undefined && obj.pft != "") {
              this.pft = obj.pft;
              this.pulmFlag = true;
            } else {
              this.pulmFlag = false;
            }

            if (obj.height != undefined && obj.height != null && obj.height != "") {
              this.height = obj.height + obj.height_measure;
              this.heightFlag = true;
              this.height_flag = true;
            } else {
              this.heightFlag = false;
              this.height_flag = false;
            }
            if (obj.weight != undefined && obj.weight != null && obj.weight != "") {
              this.weight = obj.weight + obj.weight_measure;
              this.weightFlag = true;
              this.weight_flag = true;
            } else {
              this.weightFlag = false;
              this.weight_flag = false;
            }
            if (obj.temparature != undefined && obj.temparature != null) {
              this.temparature = obj.temparature;
              this.temparature_flag = true;
            } else {
              this.temparature_flag = false;
            }
            if (obj.complaints != undefined && obj.complaints != "" && obj.complaints != null) {
              this.complaintsflag = false;
              this.complaints = obj.complaints;
            } else {
              this.complaintsflag = true;
              this.complaints = "";
            }

            if (obj.pulse != undefined) {
              this.pulseflag = true;
              this.clnt_pulse = obj.pulse;
            } else {
              this.pulseflag = false;
              this.clnt_pulse = "";
            }
            
            if (obj.rr_txt != undefined) {
              this.rrflag = true;
              this.clntRR = obj.rr_txt;
            } else {
              this.rrflag = false;
              this.clntRR = "";
            }
            if (obj.cns != undefined) {
              this.cns_flag = true;
              this.clntCns = obj.cns;
            } else {
              this.cns_flag = false;
              this.clntCns = "";
            }
            if (obj.abd != undefined) {
              this.abd_flag = true;
              this.clntAbd = obj.abd;
            } else {
              this.abd_flag = false;
              this.clntAbd = "";
            }
            if (obj.cbg != undefined) {
              this.cbg_flag = true;
              this.clntcbg = obj.cbg;
            } else {
              this.cbg_flag = false;
              this.clntcbg = "";
            }
            
            if (obj.heart_rate != undefined && obj.heart_rate != "") {
              this.heartrateflag = true;
              this.clnt_heartrate = obj.heart_rate
            //  this.ortho_flag = true;
            } else {
              this.clnt_heartrate = "";
            }
            if (obj.treatment != undefined && obj.treatment != "") {
              this.treatment = this.sanitizer.bypassSecurityTrustHtml(obj.treatment);
              this.treatFlag = true;
            } else {
              this.treatFlag = false;
            }
            if (obj.blood_pressure != undefined ) {
              if(obj.blood_pressure != ""){
                this.clnt_bp = obj.blood_pressure+" "+"mmHg";
                this.bp_flag = true;
              }else{
              this.clnt_bp = obj.blood_pressure;
              this.bp_flag = true;
              }
            
           
            } else {
              this.bp_flag = false;
            }

            this.dis_txt = obj.disease;
            if (obj.next_visit != undefined) {
              var nextdate = obj.next_visit.split('-');
              this.nextVisit = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
            }

            if (obj.qualification != null) {
              this.doctor_qualify_data = obj.qualification;
            }
            if (obj.age != null) {
              this.age = obj.age;
            }
            if (obj.gender != null) {
              this.gender = encrypt_decript.Decript(obj.gender);
            }


            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
              this.total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            if (obj.salutation != undefined) {
              if (this.personalinfo.salutation != undefined) {
                this.doct_clientname = this.personalinfo.salutation + "." + this.personalinfo.client_name;
              } else {
                this.doct_clientname = this.personalinfo.client_name;
              }
              this.salutation = obj.salutation;
              this.total_name = obj.salutation + "." + this.total_name;
            }

            if (obj.doc_reg_id != null) {
              this.doc_reg_id = obj.doc_reg_id;
            }
            //this.instructions=obj.instructions;
            this.licence_code = obj.licence_code;

            if (obj.instructions != null && obj.instructions != undefined && obj.instructions != "") {
              this.instructions = this.sanitizer.bypassSecurityTrustHtml(obj.instructions);
              this.print_instruction = this.instructions;
              this.instr_flag = false;
              this.instructionsflag = true;
            } else {
              this.instructions = "";
              this.print_instruction = "";
              this.instr_flag = true;
              this.instructionsflag = false;
            }

            if (obj.treatment != null && obj.treatment != undefined && obj.treatment != "") {
              this.treatment = this.sanitizer.bypassSecurityTrustHtml(obj.treatment);
            } else {
              this.treatment = "";
            }

            if (obj.investigation != null && obj.investigation != undefined && obj.investigation != "") {
              this.investigation = obj.investigation;
              this.oberse_med = this.investigation;
            } else {
              this.investigation = "";
              this.oberse_med = "";
            }

            if (obj.next_visit != null && obj.next_visit != undefined && obj.next_visit != "") {
              this.next_txt1 = Date_Formate(obj.next_visit);
              this.next_txt = this.next_txt1;
              // this.nextvisit_print=true;
              this.nextvisitflag = true;
            } else {
              this.next_txt1 = "";
              this.next_txt = "";
            }

            if ((obj.hptl_name != null && obj.hptl_name != undefined) && (obj.address1 != null && obj.address1 != undefined)) {
              this.hospflag = false;
              if (obj.hptl_name != null) {
                this.hptl_name = obj.hptl_name;
              }
              if ((obj.address1 != null && obj.address1 != undefined) && (obj.address2 != null && obj.address2 != undefined && obj.address2 != "")) {
                this.address = (obj.address1.toString()) + ' ' + (obj.address2.toString() + ', ');

              } else {
                if (obj.address1 != null && obj.address1 != undefined) {
                  this.address = (obj.address1.toString()) + ', ';
                }
              }
              if (obj.qualification != null) {
                var qualification;
                for(var j=0; j<obj.qualification.length;j++){
                  qualification.push( obj.qualification[j]);
                }
                console.log(">qualification>"+JSON.stringify(qualification))
                this.doctorQualification = Helper_Class.getQualification()
              }
              if (obj.first_name != null && obj.first_name != undefined) {
                if (obj.middle_name != null && obj.middle_name != "") {
                  this.doctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
                } else {
                  this.doctorName = obj.first_name + ' ' + obj.last_name;
                }
              }

              if (obj.location != null) {
                this.doc_loc = obj.location;
              }

              if (obj.city != null && obj.zipcode != null) {
                this.doc_city = obj.city + " - " + obj.zipcode;
              }

              if (obj.state != null) {
                this.doc_state = obj.state;
              }

              if (obj.country != null) {
                this.doc_country = obj.country;
              }

              if (obj.telephone != null) {
                this.doc_telephone = obj.telephone;
              }

            } else {
              this.hospflag = true;
            }
            this.doctorQualification = Helper_Class.getQualification()
            if (obj.pharmacy_id != null) // Suggested Pharmacy
            {
              this.pharma_control = true;
              if (obj.pharmacy_id != null) {
                this.pharma_id = obj.pharmacy_id;
              }
              if (obj.pharmacy_name != null) {
                this.pharmacy_name = obj.pharmacy_name;
                this.has_pharma = true;
              } else {
                this.has_pharma = false;
              }

              if (obj.par_address1 != null) {
                this.phar_addr1 = obj.par_address1;
              }

              if (obj.par_address2 != null) {
                this.phar_addr2 = obj.par_address2;
              }

              if (obj.par_location != null) {
                this.phar_loc = obj.par_location;
              }

              if (obj.par_city != null) {
                this.phar_city = obj.par_city;
              }

              if (obj.par_zipcode != null) {
                this.phar_zipcode = obj.par_zipcode;
              }

              if (obj.par_state != null) {
                this.phar_state = obj.par_state;
              }

              if (obj.par_country != null) {
                this.phar_cnty = obj.par_country;
              }

              if (obj.par_telephone != null) {
                this.telephone = obj.par_telephone;
              }

              this.pharcy_details.push({
                pharmacy_name: this.pharmacy_name,
                phar_addr1: this.phar_addr1,
                phar_addr2: this.phar_addr2,
                phar_loc: this.phar_loc,
                phar_city: this.phar_city,
                phar_zipcode: this.phar_zipcode,
                phar_state: this.phar_state,
                phar_cnty: this.phar_cnty,
                telephone: this.telephone
              })
            }

            if (obj.store_pickup != null || (obj.store_pickup != null && obj.store_pickup == "1")) {
              this.store_pick = true;
              this.delopt = true;
            }

            if (obj.home_delivery != null || (obj.home_delivery != null && obj.home_delivery == "1")) {
              this.home_del = true;
              this.delopt = true;
            }

            if (obj.store_pickup_validity_days != null) {
              this.val_days = obj.store_pickup_validity_days;
            }

            this.date_prescription = obj.pres_date.split('-')[2] + "-" + obj.pres_date.split('-')[1] + "-" + obj.pres_date.split('-')[0];
            if (obj.disease != null && obj.disease != undefined && obj.disease != "") {
              this.disease_name = obj.disease;
              this.disease_flag = true;
              this.disease_describtion = obj.dis_desc;
            }


            if (obj.sub_rel_id != null) {
              this.sub_rel_id = obj.sub_rel_id;
            }

            if (obj.relation_id != null) {
              this.rel_id = obj.relation_id;
            }

            if (obj.relationship_name != null) {
              this.rel_name = obj.relationship_name;
            }

            if (obj.available_from != null) {
              this.work_time = "Timings : " + obj.available_from + " - " + obj.available_to;
            }

            for (var i = 0; i < obj.drug_list.length; i++) {
              if (i != 0) {
                this.has_multi_tab = true;

              } else {
                this.has_multi_tab = false;
              }
              for (var i = 0; i < obj.drug_list.length; i++) {
                if (obj.drug_list[i].show_short_form == "1") {
                  if (obj.drug_list[i].show_intake == "0") {
                    this.every_six = obj.drug_list[i].morning;
                    this.param_four = true;
                  }else{
                  var shortform = obj.drug_list[i].short_form;
                  if (obj.drug_list[i].intake == "1") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - 0";
                  } else if (obj.drug_list[i].intake == "2") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + " - 0";

                  } else if (obj.drug_list[i].intake == "3") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + shortform;

                    // this.every_six = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "4") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "5") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + shortform;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "6") {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + shortform;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "7") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "8") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "9") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + shortform + " - Every 6 hours";

                  } else if (obj.drug_list[i].intake == "10") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + shortform + " - Every 8 hours";

                  } else if (obj.drug_list[i].intake == "11") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + shortform + " - Every 12 hours ";

                  } else if (obj.drug_list[i].intake == "12") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - Once a day";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "13") {
                    this.every_six = obj.drug_list[i].morning + shortform + " SOS - if required";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "14") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "15") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "16") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                  } else if (obj.drug_list[i].intake == "17") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + shortform + " STAT";
                  }
                  else if (obj.drug_list[i].intake == "18") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + shortform + "Once a month";
                  }
                  else if (obj.drug_list[i].intake == "19") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night;
                  }
                  else if (obj.drug_list[i].intake == "20") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;
                  }
                  else if (obj.drug_list[i].intake == "21") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    this.param_four=false;
  
                  }
                  else if (obj.drug_list[i].intake == "22") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    this.param_four=false;
  
                  }
                  else if (obj.drug_list[i].intake == "23") {
                    this.every_six = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    this.param_four=false;
  
                  }
                  
                  }

                  if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {

                    this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                  } else {
                    this.dure_write = "";
                    this.dure_flag = true;
                  }

                  if (this.afterfood_txt == undefined) {
                    this.afterfood_txt = "";
                  }
                  var frequency;
                  if (obj.drug_list[i].intake == "14" || obj.drug_list[i].intake == "15" || obj.drug_list[i].intake == "16" || obj.drug_list[i].intake == "18" || obj.drug_list[i].intake == "20") {
                    frequency = obj.drug_list[i].intake_desc;

                  } else {
                    if (obj.drug_list[i].intake_desc == "Stat" || obj.drug_list[i].intake_desc == "SOS") {
                      frequency = " ";
                    } else {
                      frequency = "Daily";
                    }

                  }
                  if (obj.drug_list[i].intake_mode != undefined){
                    this.intake_mode=obj.drug_list[i].intake_mode
                  }
                  var product: any = {
                    med_typetxt: obj.drug_list[i].drug_type_name,
                    short_name: obj.drug_list[i].short_name,
                    //  genericname:obj.drug_list[i].generic_name,
                    drug_id: obj.drug_list[i].drug_id,
                    // //genericname: this.ganericName,
                    drug_name: obj.drug_list[i].drug_name,
                    days: obj.drug_list[i].days,
                    period: obj.drug_list[i].period,
                    drug_intake: obj.drug_list[i].drug_intake,
                    intake: obj.drug_list[i].intake,
                    every_six: this.every_six,
                    dure_txt_table: this.dure_write,
                    time_duration: obj.drug_list[i].time_duration,
                    
                    morning: obj.drug_list[i].morning !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].morning} ${obj.drug_list[i].short_form}` : obj.drug_list[i].morning,
                    afternoon: obj.drug_list[i].afternoon !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].afternoon} ${obj.drug_list[i].short_form}` : obj.drug_list[i].afternoon,
                    evening: obj.drug_list[i].evening !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].evening} ${obj.drug_list[i].short_form}` : obj.drug_list[i].evening,
                    night: obj.drug_list[i].night !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].night} ${obj.drug_list[i].short_form}` : obj.drug_list[i].night,
                    drug_type_id: obj.drug_list[i].drug_type_id,
                    frequency: frequency,
                    show_intake: obj.drug_list[i].show_intake,
                    remarks: obj.drug_list[i].remarks,
                    mixval: obj.drug_list[i].mixtype,
                    param_four: this.param_four,
                    intake_mode:this.intake_mode
                    
                  }
                  if (obj.drug_list[i].generic_name !== undefined) {
                    product.genericname = obj.drug_list[i].generic_name;
                  }
                  this.listProducts.push(product);
                  console.log("mp 1 000" + JSON.stringify(this.listProducts))
                } else {
                  if (obj.drug_list[i].show_intake == "0") {
                    this.every_six = obj.drug_list[i].morning;
                    this.param_four = true;
                  }else{
                  if (obj.drug_list[i].intake == "1") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "2") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "3") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "4") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "5") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "6") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "7") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "8") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "9") {
                    this.every_six = obj.drug_list[i].morning + " - Every 6 hours";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "10") {
                    this.every_six = obj.drug_list[i].morning + " - Every 8 hours";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "11") {
                    this.every_six = obj.drug_list[i].morning + " - Every 12 hours";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "12") {
                    this.every_six = obj.drug_list[i].morning + " - Once a day";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "13") {
                    this.every_six = obj.drug_list[i].morning + " SOS - if required";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "14") {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    this.param_four = true;
                  } else if (obj.drug_list[i].intake == "15") {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    this.param_four = true;
                  } else if (obj.drug_list[i].intake == "16") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "17") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " STAT";
                  }
                  else if (obj.drug_list[i].intake == "18") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " Once a month";
                  }
                  else if (obj.drug_list[i].intake == "19") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  }
                  else if (obj.drug_list[i].intake == "20") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  }
                  else if (obj.drug_list[i].intake == "21") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "22") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "23") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                }
                  if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                    this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                  } else {
                    this.dure_write = "";
                    this.dure_flag = true;
                  }

                  if (this.afterfood_txt == undefined) {
                    this.afterfood_txt = "";
                  }
                  var frequency;
                  console.log("obj.drug_list[i].intake_desc" + obj.drug_list[i].intake_desc)
                  if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" || obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Alternative day") {
                    frequency = this.intake_txt;

                  } else {
                    if (obj.drug_list[i].intake_desc == "Stat" || obj.drug_list[i].intake_desc == "SOS") {
                      frequency = " ";
                    } else {
                      frequency = "Daily";
                    }

                  }
                  if (obj.drug_list[i].intake_mode != undefined){
                    this.intake_mode=obj.drug_list[i].intake_mode
                  }
                  var product: any = {
                    med_typetxt: obj.drug_list[i].drug_type_name,
                    drug_id: obj.drug_list[i].drug_id,
                    drug_name: obj.drug_list[i].drug_name,
                    //  genericname:obj.drug_list[i].generic_name,
                    short_name: obj.drug_list[i].short_name,
                    days: obj.drug_list[i].days,
                    period: obj.drug_list[i].period,
                    drug_intake: obj.drug_list[i].drug_intake,
                    intake: obj.drug_list[i].intake,
                    every_six: this.every_six,
                    dure_txt_table: this.dure_write,
                    time_duration: obj.drug_list[i].time_duration,
                   
                    morning: obj.drug_list[i].morning !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].morning} ${obj.drug_list[i].short_form}` : obj.drug_list[i].morning,
                    afternoon: obj.drug_list[i].afternoon !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].afternoon} ${obj.drug_list[i].short_form}` : obj.drug_list[i].afternoon,
                    evening: obj.drug_list[i].evening !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].evening} ${obj.drug_list[i].short_form}` : obj.drug_list[i].evening,
                    night: obj.drug_list[i].night !== "0" && obj.drug_list[i].short_form ? `${obj.drug_list[i].night} ${obj.drug_list[i].short_form}` : obj.drug_list[i].night,
                    drug_type_id: obj.drug_list[i].drug_type_id,
                    frequency: frequency,
                    show_intake: obj.drug_list[i].show_intake,
                    remarks: obj.drug_list[i].remarks,
                    param_four: this.param_four,
                    intake_mode:this.intake_mode
                  }

                  if (obj.drug_list[i].generic_name !== undefined) {
                    product.genericname = obj.drug_list[i].generic_name;
                  }
                  this.listProducts.push(product);
                  console.log("mp else 000" + JSON.stringify(this.listProducts))

                }
              }

              // if (this.balance_quantity == 0 && localStorage.getItem('app_nurse_physio') == undefined) {
              //   this.has_pharma = false; // Normal flow
              //   this.has_Search = false;
              // }

              // if (Helper_Class.getapp_flow() != null && this.balance_quantity == 0) {
              //   if (Helper_Class.getapp_flow() == "order") {
              //     this.has_pharma = true;

              //     if (this.tablet_tracker.length != 0) {
              //       this.has_Search = true;

              //     } else {
              //       this.has_Search = false;
              //     }
              //   }
              // }
            }
            console.log("mp else 000" + JSON.stringify(this.listProducts))


          } else {
            this.print_view_flag = true;
          }
         
          if (obj.Laboratory !== undefined) {
            for (var i = 0; i < obj.Laboratory.length; i++) {
            
                var testName = obj.Laboratory[i].test_name;
                this.print_labarray.push({ test_name: testName });
                this.labarray.push({ test_name: testName });
            }
        }
          
          console.log("object lab"+JSON.stringify(this.labarray))        


          if (obj.Package !== undefined) {
            for (var i = 0; i < obj.Package.length; i++) {
                var testName = obj.Package[i].test_name;
                this.print_package.push({ test_name: testName });
                this.package.push({ test_name: testName });
            }
        }
 
        if (obj.next_package !== undefined) {
          for (var i = 0; i < obj.next_package.length; i++) {
              var testName = obj.next_package[i].test_name;
              this.printnext_package.push({ test_name: testName });
              this.next_package.push({ test_name: testName });
          }
        }
        
        if (obj.Radiology !== undefined) {
          for (var i = 0; i < obj.Radiology.length; i++) {
              var testName = obj.Radiology[i].test_name;
              this.print_radioarray.push({ test_name: testName });
              this.radioarray.push({ test_name: testName });
          }
        }
           
        if (obj.next_lab !== undefined) {
          for (var i = 0; i < obj.next_lab.length; i++) {
               
              var testName = obj.next_lab[i].test_name;
              this.print_labarraynew.push({ test_name: testName });
              this.labarraynew.push({ test_name: testName });
          }
        }
           
        if (obj.next_radio !== undefined) {
          for (var i = 0; i < obj.next_radio.length; i++) {
              var testName = obj.next_radio[i].test_name;
              this.print_radioarraynew.push({ test_name: testName });
              this.radioarraynew.push({ test_name: testName });
          }
        }
        },
        error => {

        }
      )
    // this.presListView = false;
    // this.list_button_flag = true;
    // this.naviagationService.filter('');
    // this.naviagationService.filter('detail_view');
  }
  print_area() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
      printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "bannerfullpage") {
      printContents = this.printbannerfullpage.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    }
    else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }
    console.log("PRINT CONTENT " + JSON.stringify(printContents))
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">

        <style>
        @page { size: auto;  margin: 0mm; }
          table_class {
          background-color: rgb(108, 180, 248);
        }
        .test-names-container {
          display: flex;
          flex-wrap: wrap;
        }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .block1_grid{
          margin: 7px auto;
          width: 90%;
        }
        .content{
          width:90%;
        }
        .block1{
          margin:3px 0;
        }
        .rowformat{
          display: flex;
          flex-wrap: wrap;
        }
        .test-name {
          margin-right: 10px; /* Adjust spacing between test names */
          white-space: nowrap; /* Prevent wrapping before reaching the width limit */
        }
          .refformat{
          margin: 10px 10px 10px 0px;
          }
        .casesheet_container {
           width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .row-format p {
          margin: 2px !important;
          white-space: nowrap;
        }
        .row-format {
          display: flex;
          flex-wrap: wrap;
          margin-left: 50px;
        }
        .row-format p:not(:last-child) {
          margin-right: 10px;
        }
        .headertxt{
          margin :20px !important;
        }
        .content{
          width:100% !important;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
            
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
    </footer>
    </html>`
    );
    popupWin.document.close();
    printContents = "";
  }
}
