import { ReturnStatement } from "@angular/compiler";

export class FrontDesk_Helper {

    static discharge_time_line_inpat_id;
    static setDischargeTimeLineInpatientId(value: any) {
        this.discharge_time_line_inpat_id = null;
        this.discharge_time_line_inpat_id = value;
    }
    static getDischargeTimeLineInpatientId() {
        return this.discharge_time_line_inpat_id;
    }

    static physioID;
    static setphysioapp(value: any) {
        this.physioID = null;
        this.physioID = value;

    }
    static getphysioapp() {
        return this.physioID;

    }
    static createsent_bill;
    static setfrontdeskbillcreatesent_bill(value: any) {
        this.createsent_bill = null;
        this.createsent_bill = value;
    }
    static getfrontdeskbillcreatesent_bill() {
        return this.createsent_bill;
    }
    //IPAddress
    static ipaddress;
    static setIPAddress(value: any) {
        this.ipaddress = null;
        this.ipaddress = value;
    }
    static getIPAddress() {
        return this.ipaddress;
    }

    static receptionistinfo;
    static setreceptionistcreatepage(value: any) {
        this.receptionistinfo = null;
        this.receptionistinfo = value
    }
    static getreceptionistcreatepage() {
        return this.receptionistinfo
    }
    static clientidinfo
    static setClientId(value: any) {
        this.clientidinfo = null;
        this.clientidinfo = value
    }
    static getClientId() {
        return this.clientidinfo
    }
    static hospital1
    static setHospitalId(value: any) {
        this.hospital1 = null;
        this.hospital1 = value;
    }
    static getHospitalId() {
        return this.hospital1;
    }

    static receptbilldetail;
    static setreceptbilldetailview(value: any) {
        this.receptbilldetail = null;
        this.receptbilldetail = value;
    }

    static getreceptbilldetailview() {
        return this.receptbilldetail
    }

    static receptbilleditinfo;
    static setreceptbilledit(value: any) {
        this.receptbilleditinfo = null;
        this.receptbilleditinfo = value;
    }

    static getreceptbilledit() {
        return this.receptbilleditinfo
    }
    static viewpreceiptionlist;
    static setreceptviewprecription(value: any) {
        this.viewpreceiptionlist = null;
        this.viewpreceiptionlist = value;
    }
    static getreceptviewprecription() {
        return this.viewpreceiptionlist
    }
    static receptcount;
    static setClientCount(value: any) {
        this.receptcount = null;
        this.receptcount = value;
    }
    static getClientCount() {
        return this.receptcount
    }
    static data;
    static setdoctor(value: any) {
        this.data = null;
        this.data = value;
    }
    static getdoctor() {
        return this.data
    }
    //setDietician
    static Dietician;
    static setDietician(value: any) {
        this.Dietician = null;
        this.Dietician = value;
    }
    static getDietician() {
        return this.Dietician
    }


    static frontdeskinfo;
    static setfrontdeskinfo(value: any) {
        this.frontdeskinfo = null;
        this.frontdeskinfo = value;
    } static getfrontdeskinfo() {
        return this.frontdeskinfo
    }

    static bgimage;
    static setbgimage(value: any) {
        this.bgimage = null;
        this.bgimage = value;
    }
    static getbgimage() {
        return this.bgimage;
    }

    static notification;
    static setnotificationid(value: any) {
        this.notification = null;
        this.notification = value;
    }
    static getnotificationid() {
        return this.notification;
    }

    static prescriptiondeatils;
    static setfrontdeskprescriptiondetails(value: any) {
        this.prescriptiondeatils = null;
        this.prescriptiondeatils = value;
    }
    static getfrontdeskprescriptiondetails() {
        return this.prescriptiondeatils;
    }

    //setBillFromDate
    static BillFromDate;
    static setBillFromDate(value: any) {
        this.BillFromDate = null;
        this.BillFromDate = value;
    }
    static getBillFromDate() {
        return this.BillFromDate;
    }

    //setBillToDate
    static BillToDate;
    static setBillToDate(value: any) {
        this.BillToDate = null;
        this.BillToDate = value;
    }
    static getBillToDate() {
        return this.BillToDate;
    }

    //setDocPrescription
    static DocPrescription;
    static setDocPrescription(value: any) {
        this.DocPrescription = null;
        this.DocPrescription = value;
    }
    static getDocPrescription() {
        return this.DocPrescription;
    }

    //setDate
    static appDate;
    static setDate(value: any) {
        this.appDate = null;
        this.appDate = value;
    }
    static getDate() {
        return this.appDate;
    }
    // static inpatientDate;
    //     static setDate(value:any){
    //         this.inpatientDate=null;
    //         this.inpatientDate=value;
    //     } 
    //     static getDate(){
    //         return this.inpatientDate;
    //     }
    static billDetails;
    static setBillsDetails(value: any) {
        this.billDetails = null;
        this.billDetails = value;
    }
    static getBillsDetails() {
        return this.billDetails;
    }

    //setBillFlag
    static BillFlag;
    static setBillFlag(value: any) {
        this.BillFlag = null;
        this.BillFlag = value;
    }
    static getBillFlag() {
        return this.BillFlag;
    }

    //inpatient_id
    static inpatient_id;
    static setInpatientId(value: any) {
        this.inpatient_id = null;
        this.inpatient_id = value;
    }
    static getInpatientId() {
        return this.inpatient_id;
    }

    static hospital_print_template;
    static setHospital_print_template(value: any) {
        this.hospital_print_template = null;
        this.hospital_print_template = value;
    }
    static getHospital_print_template() {
        return this.hospital_print_template;
    }

    //Hospital_Pres_Logo
    static hospital_pres_logo;
    static setHospital_pres_logo(value: any) {
        this.hospital_pres_logo = null;
        this.hospital_pres_logo = value;
    }
    static getHospital_pres_logo() {
        return this.hospital_pres_logo;
    }

    // get application date
    static appDate1;
    static getAppDate() {

        return this.appDate1;
    }
    static setAppDate(value) {
        this.appDate1 = null;
        this.appDate1 = value;
    }

    // discharge summary print template
    static disprinttemp;
    static setDisPrintTemp(value: any) {
        this.disprinttemp = null;
        this.disprinttemp = value;
    }
    static getDisPrintTemp() {
        return this.disprinttemp;
    }

    // discharge summary header addl text
    static disprintheadtext;
    static setDisPrintHeaderText(value: any) {
        this.disprintheadtext = null;
        this.disprintheadtext = value;
    }
    static getDisPrintHeaderText() {
        return this.disprintheadtext;
    }

    // discharge summary footer addl text
    static disprintfoottext;
    static setDisPrintFooterText(value: any) {
        this.disprintfoottext = null;
        this.disprintfoottext = value;
    }
    static getDisPrintFooterText() {
        return this.disprintfoottext;
    }

    // get modules from login

    static moduleList;
    static setmodules(value: any) {
        this.moduleList = null;
        this.moduleList = value;
    }

    static getmodules() {
        return this.moduleList;
    }

    // inpatientEstBillList
    static inpatientEstBillList;
    static setInpatientEstBillList(value: any) {
        this.inpatientEstBillList = null;
        this.inpatientEstBillList = value;
    }
    static getInpatientEstBillList() {
        return this.inpatientEstBillList;
    }
}